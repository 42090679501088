import * as React from 'react';
import {
	Modal,
	mergeStyleSets,
	FontWeights,
	ContextualMenu,
	IconButton,
} from 'office-ui-fabric-react';
import { theme } from 'styles/theme';

const dragOptions = {
	moveMenuItemText: 'Move',
	closeMenuItemText: 'Close',
	menu: ContextualMenu,
};
const cancelIcon = { iconName: 'Cancel' };

const contentStyles = mergeStyleSets({
	container: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'stretch',
	},
	header: [
		theme.fonts.xLargePlus,
		{
			flex: '1 1 auto',
			borderTop: `4px solid ${theme.palette.themePrimary}`,
			color: theme.palette.neutralPrimary,
			display: 'flex',
			alignItems: 'center',
			fontWeight: FontWeights.semibold,
			padding: '12px 12px 14px 24px',
		},
	],
	body: {
		flex: '4 4 auto',
		padding: '0 24px 24px 24px',
		overflowY: 'hidden',
		selectors: {
			p: { margin: '14px 0' },
			'p:first-child': { marginTop: 0 },
			'p:last-child': { marginBottom: 0 },
		},
	},
});
const iconButtonStyles = {
	root: {
		color: theme.palette.neutralPrimary,
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',
	},
	rootHovered: {
		color: theme.palette.neutralDark,
	},
};

const Popup = props => {
	const {
		title,
		isModalOpen,
		closeModal,
		isDraggable,
		children,
		onClickCanel,
		showButtonModal,
	} = props;
	return (
		<>
			<Modal
				titleAriaId={title}
				isOpen={isModalOpen}
				onDismiss={closeModal}
				isModeless
				containerClassName={contentStyles.container}
				dragOptions={isDraggable ? dragOptions : undefined}
			>
				<div className={contentStyles.header}>
					<span>{title}</span>
					{!showButtonModal && <IconButton
						styles={iconButtonStyles}
						iconProps={cancelIcon}
						ariaLabel="Close popup modal"
						onClick={onClickCanel || closeModal}
					/>
					}
				</div>

				<div className={contentStyles.body}>{children}</div>
			</Modal>
		</>
	);
};

export default Popup;
