/* global Excel, Office */
import { isEmpty, find, filter, get, cloneDeep } from 'lodash';
import { createAction } from 'utils/helpers';
import { getLocalStorageItem, getSessionStorageItem, updateSessionStorage } from 'utils/CommonUtil';
import {
	clearExcelData,
	dataPopulationOutput,
} from 'utils/excelUtils/ExcelDataPopulate';

const explodeNodes = (nodes, searchTxt) => {
	return nodes.filter(data => {
		return data.colText.toLowerCase().includes(searchTxt.toLowerCase());
	});
};
// Add expand attribute to child nodes
export const attributesMapping = nodes => {
	nodes.map(item => {
		item.expand = false;
		item.selected = false;
		if (!isEmpty(item.dropDowns)) {
			attributesMapping(item.dropDowns);
		}
		return null;
	});
};
export const findSelectedId = (storageData, item, itemList) => {
	find(storageData, selectedItem => {
		if (selectedItem.colId === item.colId) {
			item.selected = true;
			if (!isEmpty(itemList)) {
				itemList.expand = true;
			}
		} else {
			item.selected = false;
		}
		return selectedItem.colId === item.colId;
	});
};
export const selectAllChildNodes = (field, selected) => {
	field.map(item => {
		item.mandatoryYN === 'Y' ? item.selected =item.selected : item.selected = selected;
		
		item.expand = selected;
		if (!isEmpty(item.dropDowns)) {
			selectAllChildNodes(item.dropDowns, selected);
		}
		return null;
	});
};
export const childNodesCheckbox = (field, selected, colId) => {
	field.map(item => {
		if (!isEmpty(item.dropDowns)) {
			childNodesCheckbox(item.dropDowns, selected, colId);
		} else if (item.colId === colId) {
			item.selected = selected;
		}
		return null;
	});
};
export const childNodesExpand = (field, colId) => {
	field.map(item => {
		if (item.colId === colId[0]) {
			item.expand = !item.expand;
		} else if (!isEmpty(item.dropDowns)) {
			return childNodesExpand(item.dropDowns, colId);
		}
		return null;
	});
};
export const selectedMapping = (nodes, storageData, items) => {
	nodes.map(item => {
		if (!isEmpty(item.dropDowns)) {
			selectedMapping(item.dropDowns, storageData, item);
		}
		findSelectedId(storageData, item, items);
		return null;
	});
	return nodes;
};

export const nodeManipulate = (nodes, storageData) => {
	nodes.map(item => {
		item.expand = true;
		if (!isEmpty(item.dropDowns)) {
			selectedMapping(item.dropDowns, storageData);
		}
		findSelectedId(storageData, item);
		return null;
	});
};
export const nodeAttribute = (nodes, newArrangeFieldOrder) => {
	nodes.map(item => {
		if (!newArrangeFieldOrder) {
			item.selected = false;
		}
		item.expand = true;
		if (!isEmpty(item.dropDowns)) {
			attributesMapping(item.dropDowns);
		}
		return null;
	});
};
export const filteredNodes = (searchTxt, nodes) => {
	let childData;
	return nodes.filter(data => {
		if (data.colText.toLowerCase().includes(searchTxt.toLowerCase())) {
			if (
				!isEmpty(data.dropDowns) &&
				!isEmpty(explodeNodes(data.dropDowns, searchTxt))
			) {
				data.expand = true;
			}
			return data;
		}
		if (!isEmpty(data.dropDowns)) {
			childData = filteredNodes(searchTxt, data.dropDowns);
			if (!isEmpty(childData)) {
				data.expand = true;
				data.dropDowns = childData;
				return data;
			}
		}
		return null;
	});
};
export const getDataFromDatasetOutputnode = (
	selectedDataset,
	updateCellOnDuplicate,
) => {
	if (selectedDataset) {
		const outputNodesData = get(selectedDataset, 'outputNodes');
		const { nonMandatoryFields } = outputNodesData;
		const duplicateClicked = get(selectedDataset, 'duplicateClicked');
		if (!duplicateClicked) {
			updateCellOnDuplicate(outputNodesData);
		}
		return nonMandatoryFields;
	}
	return null;
};
export const removeCellDataValue = selectedDataset => {
	const outputDataSet = get(selectedDataset, 'outputNodes');
	const sessionCellValue = `${getSessionStorageItem(
		'startOutputCellValue',
	)}:${getSessionStorageItem('endOutputCellValue')}`;
	const duplicateClicked = get(selectedDataset, 'duplicateClicked');
	if (outputDataSet) {
		const selectedDatasetRange = selectedDataset
			? selectedDataset.datasetRange
			: getLocalStorageItem('outputDataRange');
		const dataRangeToClear = !duplicateClicked
			? selectedDatasetRange
			: getLocalStorageItem('outputDataRange');
		if (dataRangeToClear) {
			const range = dataRangeToClear.split('!');
			if (!getLocalStorageItem('isCreateNewDataset')) {
				clearExcelData(range[0], range[1]);
			}
		}
	}
	const removeRange =
		outputDataSet && !duplicateClicked
			? `${outputDataSet.outputCellNo}:${outputDataSet.endOutputCellValue}`
			: sessionCellValue;
	const removeSheet =
		outputDataSet && !duplicateClicked
			? `${outputDataSet.outputSheetName}`
			: getSessionStorageItem('removeOutputSheetName');
	return { removeRange, removeSheet };
};
export const shortCondition = (dataif, dataelse, datathen) => {
	return dataif ? datathen : dataelse;
};
export const arrangeItemsNodes = (field, arrangeFieldNode) => {
	field.forEach(item => {
		arrangeFieldNode = filter(arrangeFieldNode, selectedItem => {
			return selectedItem.colId === item.colId;
		});
	});
	return arrangeFieldNode;
};
export const arrangeFieldDataNodes = (field, arrangeData) => {
	field.map(item => {
		if (!isEmpty(item.dropDowns)) {
			arrangeData = arrangeFieldDataNodes(item.dropDowns, arrangeData);
		} else if (item.selected && !find(arrangeData, { colId: item.colId })) {
			arrangeData.push(item);
		} else if (!item.selected && find(arrangeData, { colId: item.colId })) {
			arrangeData = filter(arrangeData, itm => {
				return itm.colId !== item.colId;
			});
		}
		return null;
	});
	return arrangeData;
};
export function updateArrangeNodeData(nodeData) {
	return dispatch => {
		dispatch(createAction('UPDATE_ARRANGE_NODE', nodeData));
	};
}
const resetChildExpandedNodes = nodes => {
	nodes.map(item => {
		item.expand = false;
		if (!isEmpty(item.dropDowns)) {
			resetChildExpandedNodes(item.dropDowns);
		}
		return null;
	});
};
export const resetExpandedNodes = nodes => {
	nodes.map(item => {
		item.expand = true;
		if (!isEmpty(item.dropDowns)) {
			resetChildExpandedNodes(item.dropDowns);
		}
		return null;
	});
};

// on success of metadata service method used to create inputFields for setting form
export const manipulateOutPutNodes = (
	object,
	updateCellOnDuplicate,
	setOutPutNodes,
	setSearchOutPutNodes,
	setArrangeFieldNodes,
	setSearchArrangeFieldNodes,
	updateLocaleData,
) => {
	const [
		outputNodeItems,
		selectedDataset,
		arrangeFieldNodes,
		isDialogBox,
		isClickClear,
	] = object;
	const outputDatasetObj = getDataFromDatasetOutputnode(
		selectedDataset,
		updateCellOnDuplicate,
	);
	const arrangeData = shortCondition(
		arrangeFieldNodes.length > 0,
		outputDatasetObj,
		arrangeFieldNodes,
	);
	const nodes = get(outputNodeItems, 'NonMandatoryFields');
	const newArrangeFieldOrder = get(outputNodeItems, 'arrangeFeildsOrder');
	const arrangeFeildOrder = shortCondition(
		isDialogBox,
		newArrangeFieldOrder,
		null,
	);
	const isArrangeData = shortCondition(
		!isEmpty(arrangeData),
		arrangeFeildOrder,
		arrangeData,
	);
	let storageData;
	if (newArrangeFieldOrder) {
		storageData = shortCondition(isClickClear, isArrangeData, arrangeData);
	} else {
		storageData = arrangeData;
	}
	resetExpandedNodes(nodes);
	if (!isEmpty(storageData)) {
		nodeManipulate(nodes, storageData);
	} else {
		nodeAttribute(nodes, newArrangeFieldOrder);
	}
	setOutPutNodes(nodes);
	setSearchOutPutNodes(nodes);
	if (storageData) {
		setArrangeFieldNodes(storageData);
		setSearchArrangeFieldNodes(storageData);
		updateLocaleData(storageData);
	}
};
export const setCellSeetValue = (
	cellValue,
	sheetName,
	setSelectedDatasetCellValue,
	moveToCellSheet,
	selectedDataset,
	checkValidCellSelected,
) => {
	if (cellValue && sheetName) {
		setSelectedDatasetCellValue(cellValue);
		moveToCellSheet(cellValue, sheetName);
	}
	if (!selectedDataset) {
		checkValidCellSelected(cellValue);
	}
};

const onSearchSelectField = (
	searchValue,
	searchOutputNodes,
	arrangeFieldNodes,
	setOutPutNodes,
) => {
	const searchNode = cloneDeep(searchOutputNodes);
	if (!isEmpty(arrangeFieldNodes)) {
		selectedMapping(searchNode, arrangeFieldNodes);
	}
	const newNodes =
		searchValue === null
			? searchNode
			: filteredNodes(searchValue, searchNode);
	setOutPutNodes(newNodes);
};
export const searchField = (
	tabValue,
	searchValue,
	searchOutputNodes,
	arrangeFieldNodes,
	setOutPutNodes,
	onSearchArrangeField,
) => {
	if (tabValue === 'Select Fields')
		onSearchSelectField(
			searchValue,
			searchOutputNodes,
			arrangeFieldNodes,
			setOutPutNodes,
		);
	else onSearchArrangeField(searchValue);
};
export const cellSheetNameSet = (
	value,
	setSheetName,
	setCellValue,
	data,
	setIsUsedCellAddress,
	setIsFirstRowSelected,
	setIsValidExcelField,
) => {
	const [selectedDataset, isCancelled] = data;
	let flagEdit = true;
	if(selectedDataset && selectedDataset.editClicked == true)
	{flagEdit =  false;
	}
	if (flagEdit) {
		if (!isCancelled.current) {
			setSheetName(value.sheetName);
			setCellValue(value.cellValue);
			if (selectedDataset) {
				const outputCellNo = get(
					selectedDataset,
					'outputNodes.outputCellNo',
				);
				const usedCellAddress =
					outputCellNo === value.isInputCellValid ||
					value.rangeValue === '';
				setIsUsedCellAddress(usedCellAddress);
			} else {
				setIsUsedCellAddress(value.isInputCellValid);
			}
			setIsFirstRowSelected(value.isFirstRowSelected);
			setIsValidExcelField({
				isValidCellId: value.cellValue !== '',
				isValidSheetName: value.sheetName !== '',
			});
		}
	}
};
export const resetSearchResult = (
	setOutPutNodes,
	searchOutputNodes,
	setArrangeFieldNodes,
	searchArrangeFieldNodes,
) => {
	const newNodes = selectedMapping(
		searchOutputNodes,
		searchArrangeFieldNodes,
	);
	setOutPutNodes(newNodes);
	setArrangeFieldNodes(searchArrangeFieldNodes);
};
export const isValidExcelSheet = (
	data,
	validateCellIdSheetName,
	scrollToBottom,
	setIsValidExcelField,
	messagesEndRef,
	isUsedCellAddress,
	isFirstRowSelected,
) => {
	const [cellValue, sheetName] = data;
	const { isValidCellId, isValidSheetName } = validateCellIdSheetName(
		cellValue,
		sheetName,
	);
	if (!isValidCellId || !isValidSheetName) {
		scrollToBottom(messagesEndRef);
	}
	setIsValidExcelField({ isValidCellId, isValidSheetName });
	return (
		isValidCellId &&
		isValidSheetName &&
		isUsedCellAddress &&
		!isFirstRowSelected
	);
};
// XDE-5631
export const onNextBtnClick = (
	data,
	updateCellValueIntoExcel,
	updateDatasetCellAddress,
	updateArrangeNode,
	history,
	startOutputCellOldValue,
	outputSheetOldValue
	) => {
	const [isValidData, searchArrangeFieldNodes, selectedDataset] = data;
	const duplicateClicked = get(selectedDataset, 'duplicateClicked');
	if (isValidData) {
		updateCellValueIntoExcel(startOutputCellOldValue, outputSheetOldValue);
		if (selectedDataset && !duplicateClicked) {
			updateDatasetCellAddress(startOutputCellOldValue, outputSheetOldValue);
			const { outputNodes } = selectedDataset;
			const { outputCellNo, outputSheetName } = outputNodes;
			selectedDataset.outputNodes.outputCellNo = startOutputCellOldValue || outputCellNo;	
			selectedDataset.outputNodes.outputSheetName = outputSheetOldValue || outputSheetName;	

			dataPopulationOutput(
				searchArrangeFieldNodes,
				selectedDataset.outputNodes.outputCellNo,
				selectedDataset.outputNodes.outputSheetName,
				!!selectedDataset,
			);
		} else {
			dataPopulationOutput(searchArrangeFieldNodes);
		}	
		updateArrangeNode(searchArrangeFieldNodes);
		history.push(`/display-settings`);
	}
};
// XDE-5631
export const updateSelectedDatasetOutput = (
	nodes,
	selectedDataset,
	updateSelectedDatasetItems,
) => {
	if (selectedDataset) {
		const outputNodesData = get(selectedDataset, 'outputNodes');
		outputNodesData.nonMandatoryFields = nodes;
		updateSelectedDatasetItems(selectedDataset);
	}
};
export const outputNodeSelect = (nodes, data, removeUnselectedItem) => {
	const [selected, colId, arrangeFieldNodes] = data;
	let arrangeFieldData = [...arrangeFieldNodes];
	nodes.forEach(item => {
		if (!isEmpty(item.dropDowns)) {
			childNodesCheckbox(item.dropDowns, selected, colId);
		} else if (item.colId === colId) {
			item.selected = selected;
			// return;
		}
		const arrangedItems = filter(arrangeFieldNodes, selectedItem => {
			if (!isEmpty(item.dropDowns)) {
				return arrangeItemsNodes(item.dropDowns, arrangeFieldNodes);
			}
			return selectedItem.colId === item.colId;
		});
		if (!isEmpty(item.dropDowns)) {
			arrangeFieldData = arrangeFieldDataNodes(
				item.dropDowns,
				arrangeFieldData,
			);
		} else if (isEmpty(arrangedItems) && item.selected) {
			arrangeFieldData.push(item);
		} else if (!isEmpty(arrangedItems) && !item.selected) {
			arrangeFieldData = removeUnselectedItem(arrangeFieldData, item);
		}
	});
	return [nodes, arrangeFieldData];
};
export const selectAll = (nodes, arrangeFieldNodes) => {
	const arrangeFieldData = [...arrangeFieldNodes];
	nodes.forEach(item => {
		item.selected = true;
		if (!isEmpty(item.dropDowns)) {
			selectAllChildNodes(item.dropDowns, true);
		}
		const arrangedItems = filter(arrangeFieldNodes, selectedItem => {
			return selectedItem.colId === item.colId;
		});
		if (isEmpty(arrangedItems) && item.selected) {
			if (!isEmpty(item.dropDowns)) {
				arrangeFieldDataNodes(item.dropDowns, arrangeFieldData);
			} else {
				arrangeFieldData.push(item);
			}
		}
		return item.colId;
	});
	return [nodes, arrangeFieldData];
};
export const clearSelection = (
	searchBoxValue,
	searchOutputNodes,
	outputNodes,
) => {
	const nodes = searchBoxValue ? [...searchOutputNodes] : [...outputNodes];
	nodes.forEach(item => {
		item.selected = false;
		if (!isEmpty(item.dropDowns)) {
			selectAllChildNodes(item.dropDowns, false);
		}
		return item.colId;
	});
	return nodes;
};
export const defaultSelection = (
	outputNodeItems,
	setOutPutNodes,
	setSearchOutPutNodes,
	setArrangeFieldNodes,
	setSearchArrangeFieldNodes,
	updateLocaleData,
) => {
	const nodes = get(outputNodeItems, 'NonMandatoryFields');
	const arrangeFeildsOrder = get(outputNodeItems, 'arrangeFeildsOrder');
	resetExpandedNodes(nodes);
	if (!isEmpty(arrangeFeildsOrder)) {
		nodes.map(item => {
			item.expand = true;
			if (!isEmpty(item.dropDowns)) {
				selectedMapping(item.dropDowns, arrangeFeildsOrder);
			}
			findSelectedId(arrangeFeildsOrder, item);
			return null;
		});
		setOutPutNodes(nodes);
		setSearchOutPutNodes(nodes);
		setArrangeFieldNodes(arrangeFeildsOrder);
		setSearchArrangeFieldNodes(arrangeFeildsOrder);
		updateLocaleData(arrangeFeildsOrder);
	}
};
export const setNodes = (
	data,
	setArrangeFieldNodes,
	setSearchArrangeFieldNodes,
	updateLocaleData,
	setOutPutNodes,
	setSearchOutPutNodes,
	updateStateOnCloseCancel,
) => {
	const [messageFromDialog, applyButtonMsg] = data;
	const { selectedItem, messageType, outputNodesExpand } = messageFromDialog;
	if (messageType === applyButtonMsg) {
		const newNodes = selectedMapping(outputNodesExpand, selectedItem);
		setArrangeFieldNodes(selectedItem);
		setSearchArrangeFieldNodes(selectedItem);
		updateLocaleData(selectedItem);
		setOutPutNodes(newNodes);
		setSearchOutPutNodes(outputNodesExpand);
	} else {
		updateStateOnCloseCancel();
	}
};
export const isValidCell = (
	isCancelled,
	excelCellClickCheck,
	cellVal,
	setIsUsedCellAddress,
) => {
	Office.onReady(() => {
		const document = get(Office, 'context.document');
		if (document) {
			document.addHandlerAsync(
				Office.EventType.DocumentSelectionChanged,
				() => {
					if (!isCancelled.current) {
						Excel.run(() => {
							return excelCellClickCheck(cellVal).then(res => {
								setIsUsedCellAddress(res);
							});
						});
					}
				},
			);
		}
	});
};
export const renderData = (
	data,
	clearAndPopulateDatasetTitle,
	renderInputOuputExcelData,
	renderGetData,
	isGetDataRendered,
) => {
	const [
		isValidData,
		searchArrangeFieldNodes,
		selectedDataset,
		updateSelectedDatasetItems,
		updateCellValueIntoExcel,
		updateDatasetCellAddress,
		objectForGetData,
		getData,
	] = data;
	if (isValidData) {
		updateSelectedDatasetOutput(
			searchArrangeFieldNodes,
			selectedDataset,
			updateSelectedDatasetItems,
		);
		updateCellValueIntoExcel();
		if (selectedDataset) {
			updateDatasetCellAddress();
		}
		clearAndPopulateDatasetTitle(objectForGetData);
		renderInputOuputExcelData(true, objectForGetData);
		renderGetData(objectForGetData, getData, isGetDataRendered);
	}
};
