const DayPickerStrings = {
	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],

	shortMonths: [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	],

	days: [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	],

	shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

	prevMonthAriaLabel: 'Go to previous month',
	nextMonthAriaLabel: 'Go to next month',
	prevYearAriaLabel: 'Go to previous year',
	nextYearAriaLabel: 'Go to next year',
	closeButtonAriaLabel: 'Close date picker',

	isRequiredErrorMessage: 'Please select a smart date from the Calendar',

	invalidInputErrorMessage: 'Invalid date format.',

	startDateStrings: {
		label: 'Start Date',
		placeholder: 'Start Date',
		id: 'startDate',
		emptyErrorMsg: 'Please select Start Date',
		startDateGreaterErrorMsg: 'Start Date cannot be greater than End Date',
		startDateEqualErrorMsg: 'Start Date cannot be equal to End Date',
	},
	endDateStrings: {
		label: 'End Date',
		placeholder: 'End Date',
		id: 'endDate',
		warningMsg:
			'Changing End Date will imply Ending Market Value Column will contain values for different dates.',
		startDateDiffErrorMsg:
			'Date range cannot exceed one year for rolling days.',
	},
};

export { DayPickerStrings };
