/* global Excel, Office */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, find, toUpper, isEmpty, filter } from 'lodash';
import { DynamicForm, Header, ThrobberMessage } from 'components';
import {
	updateLocalStorage,
	updateSessionStorage,
	getSessionStorageItem,
	getLocalStorageItem,
	removeStorage,
	showGetDataBtn,
} from 'utils/CommonUtil';
import {
	cellSheetPopulation,
	moveToCellSheet,
	onInputCellChange,
} from 'utils/excelUtils/ExcelDataPopulate';
import {
	validateFieldValues,
	validateCellIdSheetName,
	validateDateRange,
} from 'utils/validationRules';
import { excelCellClickCheck } from 'utils/excelUtils/ExcelCellValidate';
import { getSmartDate } from 'utils/dateUtil';
import {
	checkMultiselect,
	defaultTimePeriod,
	getDefaultValueOptional,
	updateDD,
	manipulateAccountGroupData,
	getElementId,
	getMultiselectedValue,
	updateElementId,
	updateShowCTP,
	getCustomTP,
} from 'utils/inputFormUtil';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import LoadingOverlay from 'react-loading-overlay';
import {
	objectRequiredforPayload,
	updateCellValueIntoExcel,
	updateDatasetCellAddress,
	onClickGetDataBtn,
	updateCellValueOnDuplicate,
	updateRunAllStatus,
} from 'utils/GetDataUtil';
import {
	manipulateSelectedDataset,
	updateDatasetOptionalField,
	getInputData,
} from 'utils/datasetUtil';
import {
	updateNodeData,
	updateSelectedDataset,
} from 'containers/createNewDataset/CreateNewDatasetAction';
import { updateDataset } from '../datasetManager/DatasetManagerAction';
import {
	getAccountGroupIds,
	getDefaultValue,
	createNonItem,
	getIsValid,
	getLocalSmartDateKey,
} from './SettingFormAction';
import {
	displaySettingAction,
	onDataRendered,
	updateDatasetToCosmos,
} from '../displaySettings/DisplaySettingsAction';
import './settingForm.scss';
import messages from './messages/CreateNewDataInitTexts';
import { SettingFormConst } from './CreateNewDatasetConst';

export class SettingFormContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputFields: {
				mandatoryFields: [],
				optionalFields: [],
			},
			inputData: {},
			cellValue: getSessionStorageItem('inputCellValue') || '',
			sheetName: getSessionStorageItem('inputSheetName') || '',
			isFormValid: false,
			backWarning: false,
			smartDateKey: null,
			isUsedCellAddress: true,
			isValidExcelField: {
				isValidCellId: true,
				isValidSheetName: true,
			},
			isFirstRowSelected: false,
			isCellSheetSelected: true,
			isValueChange: getLocalStorageItem('inputData'),
		};
		this.mounted = false;
		this.inputData = {};
		this.sponsorCodeField = '';
		this.showCTP = false;
		this.onSelectionChange = this.onSelectionChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);
		const { cellValue, sheetName } = this.state;
		const { inputNodes } = this.props;
		const data = {
			inputNodes: { ...inputNodes },
		};
		this.manipulateInputNodes(data);
		cellSheetPopulation(this.setCellSheet);
		if (cellValue && sheetName && this.mounted) {
			moveToCellSheet(cellValue, sheetName);
			this.checkValidCellSelected(cellValue);
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	// Dataset InputNode
	getDataFromDatasetInput = dataParameterObject => {
		const {
			mandatoryNodes,
			optionalNodes,
			selectedDataset,
		} = dataParameterObject;
		if (!selectedDataset) {
			return null;
		}
		const { inputNodes, duplicateClicked } = selectedDataset;
		const { inputData } = this.state;
		const {
			mandatoryFields,
			nonMandatoryFields,
			inputCellNo,
			inputSheetName,
		} = inputNodes;
		inputNodes.dataSheetDetail = `${inputCellNo}!${inputSheetName}`;
		if (!duplicateClicked) {
			updateCellValueOnDuplicate(selectedDataset);
		}
		const dataMandatoryNode = manipulateSelectedDataset(
			mandatoryNodes,
			mandatoryFields,
			inputData,
		);
		const dataNonMandatoryNode = manipulateSelectedDataset(
			optionalNodes,
			nonMandatoryFields,
			inputData,
		);

		this.inputData = inputData;
		this.setState({
			cellValue: inputCellNo,
			sheetName: inputSheetName,
		});
		return { dataMandatoryNode, dataNonMandatoryNode };
	};

	// check selected cell already used by input data.
	checkValidCellSelected = cellValue => {
		Office.onReady(() => {
			const document = get(Office, 'context.document');
			if (document) {
				document.addHandlerAsync(
					Office.EventType.DocumentSelectionChanged,
					() => {
						if (this.mounted) {
							Excel.run(() => {
								return excelCellClickCheck(cellValue).then(
									res => {
										this.setState({
											isUsedCellAddress: res,
										});
									},
								);
							});
						}
					},
				);
			}
		});
	};

	checkShowCTP = (selectedItems, elementId) => {
		let showCustomeTime = false;
		if (elementId === 'TimePeriod') {
			const customeTimePeriod = find(selectedItems, item => {
				return item.value === 'CTP';
			});
			showCustomeTime = customeTimePeriod && customeTimePeriod.selected;
			this.showCTP = showCustomeTime;
		}

		return showCustomeTime;
	};

	// method removes duplicates if drop down options has and push CTP in time period drop down
	getUniqDropDownData = items => {
		const { PerformanceIdentifier, multiRefCurrency } = SettingFormConst;
		if (items.groupId === PerformanceIdentifier) {
			return items.dropDowns;
		}
		let uniqData = filter(items.dropDowns, item => item.key !== 'none');
		const isCTPPresent = filter(
			items.dropDowns,
			item => item.key === 'CTP',
		);
		uniqData = uniqData.map(itme => {
			return {
				listId: itme.listId,
				listVal: itme.listVal,
				key: itme.listId,
				text: itme.listVal,
				label: itme.listVal,
				value: itme.listId,
				elementId: items.groupId,
			};
		});
		if (
			!items.mandatory &&
			items.groupId !== 'Category' &&
			items.groupId !== multiRefCurrency
		) {
			const nonValueItem = createNonItem(items);
			uniqData.unshift(nonValueItem);
		}
		if (items.groupId === 'TimePeriod' && isEmpty(isCTPPresent)) {
			const ctp = getCustomTP(items);
			// this.checkShowCTP(item.value, items.groupId);
			uniqData.push(ctp);
		}
		return uniqData;
	};

	// used to create inputField object for dynamic form rendering
	getInputNodeData = (nodeData, mandatory) => {
		const nodeObject = [];
		let isMultiSelect = false;
		const localData = JSON.parse(localStorage.getItem('inputData'));
		const referenceDateValue = get(localData, 'ReferenceDate.value');
		let referenceDate;
		if (referenceDateValue) {
			referenceDate = new Date(referenceDateValue);
		}
		nodeData.forEach(item => {
			isMultiSelect = checkMultiselect(item.groupId);
			const showCTPstatus = item.showCTP;
			item.mandatory = mandatory;
			item.isMultiSelect = isMultiSelect;
			item.dropDowns = this.getUniqDropDownData(item);
			item.value = getDefaultValue(item, isMultiSelect);
			item.errorMsg = '';
			item.valid = getIsValid(item) || true;
			item.showCTP = showCTPstatus;
			item.toolTipMsg = item.colHelp;
			item.referenceDate = referenceDate;
			item.showDefaultValue =
				toUpper(item.groupId) === toUpper('Category');
			this.checkShowCTP(item.value, item.groupId);
			this.inputData[item.groupId] = getInputData(item);
			nodeObject.push(item);
		});
		return nodeObject;
	};

	updateInputData = (dataNode, defaultAsOfdate, newDate) => {
		const { timePeriod, referenceDateId } = SettingFormConst;
		dataNode.map(item => {
			if (item.groupId === referenceDateId) {
				item.defaultOption = defaultAsOfdate.key;
				item.smartDateKey = defaultAsOfdate.key;
				item.referenceDate = new Date(newDate);
				item.value = new Date(newDate);
				this.inputData.SmartDate = { key: item.defaultOption };
			} else if (
				item.groupId === timePeriod &&
				!isEmpty(item.dropDowns)
			) {
				item.value = defaultTimePeriod(item);
			} else if (!isEmpty(item.dropDowns)) {
				item.value = getDefaultValueOptional(item, item.defaultOption);
			}
			this.inputData[item.groupId] = {
				value: item.value,
				colText: item.colText,
			};
			return item.colId;
		});
		return dataNode;
	};

	updateAccountGroupDropdown = dataMandatoryNode => {
		const { PerformanceObject } = SettingFormConst;
		find(dataMandatoryNode, item => {
			if (item.groupId === PerformanceObject) {
				this.sponsorCodeField = item;
				const masterId = get(item, 'value.value');
				this.retrieveAccountGroupId(masterId, false);
			}
		});
	};

	selectedExternalMasterTrust = (selectedDataset, PerformanceObject) => {
		let selectedMasterTrustId = null;
		if (selectedDataset) {
			const newMandatoryFields = get(
				selectedDataset,
				'inputNodes.mandatoryFields',
			);
			find(newMandatoryFields, itemId => {
				if (itemId.groupId === PerformanceObject) {
					selectedMasterTrustId = get(itemId.value, 'listId');
				}

				return itemId.groupId === PerformanceObject;
			});
		}
		return selectedMasterTrustId;
	};

	externalUserMasterTrust = MandatoryFields => {
		const { masterTrustDD, selectedDataset } = this.props;
		const { PerformanceObject } = SettingFormConst;
		if (!isEmpty(masterTrustDD)) {
			MandatoryFields.map(item => {
				if (item.groupId === PerformanceObject) {
					const selectedMasterTrustId = this.selectedExternalMasterTrust(
						selectedDataset,
						PerformanceObject,
					);

					item.dropDowns = updateDD(masterTrustDD);
					item.defaultOption = !selectedMasterTrustId
						? masterTrustDD[0].masterTrustNameId
						: null;
					this.retrieveAccountGroupId(
						selectedMasterTrustId || masterTrustDD[0].id,
						false,
					);
				}
				return null;
			});
		}
	};

	// on success of metadata service method used to create inputFields for setting form
	manipulateInputNodes = data => {
		const { inputFields } = this.state;
		const { selectedDataset, profile } = this.props;
		const { inputNodes } = data;
		const { MandatoryFields, NonMandatoryFields } = inputNodes;

		const defaultAsOfdate = { key: 'last_month' };
		const newDate = getSmartDate(defaultAsOfdate);
		const isInternal = get(profile, 'isInternal');
		if (!getLocalStorageItem('inputData') && isInternal === 'N') {
			this.externalUserMasterTrust(MandatoryFields);
		}

		let mandatoryNodes = this.getInputNodeData(MandatoryFields, true);
		let optionalNodes = this.getInputNodeData(NonMandatoryFields, false);
		if (!selectedDataset) {
			if (!getLocalStorageItem('inputData')) {
				mandatoryNodes = this.updateInputData(
					mandatoryNodes,
					defaultAsOfdate,
					newDate,
				);
				optionalNodes = this.updateInputData(
					optionalNodes,
					defaultAsOfdate,
					newDate,
				);
			}
			inputFields.mandatoryFields = [...mandatoryNodes];
			inputFields.optionalFields = updateShowCTP(
				optionalNodes,
				this.showCTP,
			);
			if (!getLocalStorageItem('inputData')) {
				this.onDateRangeChange(new Date(newDate), 'endDate', false);
			}
		} else {
			/* Below code is for Dataset Node */
			const datasetParameter = {
				mandatoryNodes,
				optionalNodes,
				selectedDataset,
			};
			const {
				dataMandatoryNode,
				dataNonMandatoryNode,
			} = this.getDataFromDatasetInput(datasetParameter);
			this.updateAccountGroupDropdown(dataMandatoryNode);
			inputFields.mandatoryFields = [...dataMandatoryNode];
			inputFields.optionalFields = updateDatasetOptionalField(
				dataNonMandatoryNode,
			);
		}
		this.inputData.SmartDate = getLocalSmartDateKey();
		this.setState({ inputFields, inputData: this.inputData }, () => {
			updateLocalStorage('inputData', this.inputData);
		});
	};

	getSelectedDataRef = option => {
		if (option.value === 'CTP' && !option.selected) {
			const { inputData } = this.state;
			this.onDateRangeChange(null, 'startDate', false);
			this.onDateRangeChange(
				inputData.ReferenceDate.value,
				'endDate',
				false,
			);
		}
		if (option.value === 'CTP' && option.selected) {
			this.onDateRangeChange(null, 'startDate', false);
		}
	};

	modifySelectedValue = (option, item, selectedItems, elementId, event) => {
		if (option.elementId) {
			selectedItems = getMultiselectedValue(item.value, option);
			item.value = selectedItems;
			this.inputData[elementId] = {
				value: selectedItems,
				colText: item.colText,
			};
			this.updateSelectedDatasetValue(elementId, selectedItems);
		} else {
			item.value = event || [];
			this.inputData[elementId] = {
				value: event || [],
				colText: item.colText,
			};
			this.updateSelectedDatasetValue(elementId, item.value);
		}
	};

	// on change of drop down data updating values in inputFields object
	getSelectedValue = (fields, event, option) => {
		let selectedItems;
		const isMultiSelect =
			Array.isArray(event) ||
			(option && Array.isArray(option.value)) ||
			option.elementId === 'TimePeriod';
		const elementId = getElementId(isMultiSelect, event, option);
		this.getSelectedDataRef(option);
		find(fields, item => {
			if (isMultiSelect && item.groupId === elementId) {
				this.modifySelectedValue(
					option,
					item,
					selectedItems,
					elementId,
					event,
				);
				item.valid = true;
				item.errorMsg = '';
			} else if (item.groupId === elementId) {
				const selectedValue = option && option.text ? option : event;
				item.value = selectedValue;
				item.valid = true;
				item.errorMsg = '';
				this.inputData[elementId] = {
					value: event && event.elementId ? event : option,
					colText: item.colText,
				};
				this.updateSelectedDatasetValue(elementId, selectedValue);
			}
			this.checkShowCTP(selectedItems, elementId);
			return item.groupId === elementId;
		});
		return fields;
	};

	getCTPValue = () => {
		const timePeriodCTP = [];
		Object.keys(this.inputData).map(itemData => {
			if (
				itemData === 'TimePeriod' &&
				!isEmpty(this.inputData[itemData])
			) {
				this.inputData[itemData].value.map(itemValue => {
					const value = get(itemValue, 'value');
					timePeriodCTP.push({ value });
					return timePeriodCTP;
				});
			}
			return null;
		});
		return find(timePeriodCTP, item => {
			return item.value === 'CTP';
		});
	};

	// on change of drop down data method used to updated selected values in state
	onSelectionChange = (event, option) => {
		const { inputFields } = this.state;
		const { mandatoryFields, optionalFields } = inputFields;
		const { PerformanceObject } = SettingFormConst;
		let isMandatory = false;
		const id = getElementId(false, event, option);
		find(mandatoryFields, item => {
			if (item.mandatory && item.groupId === id) {
				isMandatory = true;
			}
			return item.mandatory && item.groupId === id;
		});
		if (option && (option.mandatory || isMandatory)) {
			if (id === PerformanceObject) {
				const masterId = get(option, 'key');
				this.retrieveAccountGroupId(masterId, true);
			}

			inputFields.mandatoryFields = this.getSelectedValue(
				mandatoryFields,
				event,
				option,
			);
		} else {
			inputFields.optionalFields = this.getSelectedValue(
				optionalFields,
				event,
				option,
			);
		}
		this.showCTP = this.getCTPValue();
		inputFields.optionalFields = updateShowCTP(
			optionalFields,
			this.showCTP,
		);
		this.setState(
			{
				inputFields,
				inputData: this.inputData,
				isValueChange: true,
			},
			() => updateLocalStorage('inputData', this.inputData),
		);
	};

	populateAccountGroupData = (data, sponsorCodeChange) => {
		const { inputFields } = this.state;
		const { mandatoryFields } = inputFields;
		const { profile } = this.props;
		const { PerformanceObject } = SettingFormConst;
		const isInternal = get(profile, 'isInternal');
		find(mandatoryFields, item => {
			if (data && item.groupId === data.groupId) {
				const defaultValue = sponsorCodeChange
					? []
					: getDefaultValue(item, true);
				const localData = !isEmpty(defaultValue)
					? { colText: item.colText, value: defaultValue }
					: [];
				item.value = defaultValue;
				this.inputData[item.groupId] = localData;
				item.dropDowns = data.dropDowns;
			}
			if (item.groupId === PerformanceObject && isInternal !== 'N') {
				item.value = this.sponsorCodeField.value;
			}
			return data && item.groupId === data.groupId;
		});
		inputFields.mandatoryFields = mandatoryFields;
		this.setState(
			{
				inputFields,
				inputData: this.inputData,
			},
			() => updateLocalStorage('inputData', this.inputData),
		);
	};

	// retrieving account/group id on selection of sponsor code
	retrieveAccountGroupId = (trustId, sponsorCodeChange) => {
		const userId = get(this, 'props.profile.personaId');
		const { PerformanceIdentifier } = SettingFormConst;
		getAccountGroupIds(trustId, userId)
			.then(res => {
				let accountGroupIdData;
				if (res && res.data && res.data.length > 0) {
					accountGroupIdData = get(res, 'data');
					updateElementId(accountGroupIdData, PerformanceIdentifier);
					accountGroupIdData = {
						dropDowns: manipulateAccountGroupData(
							accountGroupIdData,
						),
						groupId: PerformanceIdentifier,
					};
				}
				this.populateAccountGroupData(
					accountGroupIdData,
					sponsorCodeChange,
				);
				return accountGroupIdData;
			})
			.catch(error => {
				return error;
			});
	};

	setSmartDateKey = () => {
		let smartKeyvalue = null;
		Object.keys(this.inputData).map(item => {
			if (item === 'SmartDate' && this.inputData[item]) {
				smartKeyvalue = this.inputData[item].key;
			}
			return null;
		});
		return smartKeyvalue;
	};

	/* Update Selected Dataset Value */
	updateSelectedDatasetValue = (elementId, value) => {
		const { selectedDataset, updateSelectedDatasetItems } = this.props;

		if (selectedDataset) {
			const { mandatoryFields, nonMandatoryFields } = get(
				selectedDataset,
				'inputNodes',
			);

			find(mandatoryFields, itemList => {
				if (itemList.groupId === elementId) {
					itemList.value = value;
				}
				if (itemList.groupId === 'ReferenceDate') {
					itemList.smartDateKey = this.setSmartDateKey();
				}
			});
			find(nonMandatoryFields, itemList => {
				if (itemList.groupId === elementId) {
					itemList.value = value;
				}
				if (itemList.groupId === 'CTP1') {
					itemList.showCTP = this.getCTPValue();
				}
			});
			updateSelectedDatasetItems(selectedDataset);
		}
	};

	updateDatasetSmartDateValue = (elementId, value) => {
		const { selectedDataset, updateSelectedDatasetItems } = this.props;
		if (selectedDataset) {
			const { mandatoryFields } = get(selectedDataset, 'inputNodes');
			find(mandatoryFields, itemList => {
				if (itemList.groupId === elementId) {
					itemList.smartDateKey = value;
				}
			});
			updateSelectedDatasetItems(selectedDataset);
		}
	};

	// on sponsor code change updating value in inputField for sponsor code and making API call for account/group id
	onSponsorCodeChange = event => {
		const { inputFields } = this.state;
		const { elementId } = event;
		const { mandatoryFields } = inputFields;

		const sponsorCodeField = find(mandatoryFields, item => {
			if (item.groupId === event.elementId) {
				this.inputData[elementId] = {
					value: event,
					colText: item.colText,
				};
			}
			item.valid = true;
			item.errorMsg = '';
			return item.groupId === event.elementId;
		});
		sponsorCodeField.value = this.inputData[elementId].value;
		this.sponsorCodeField = sponsorCodeField;
		this.updateSelectedDatasetValue(
			elementId,
			this.inputData[elementId].value,
		);
		this.retrieveAccountGroupId(event.value, true);
		this.setState({
			isValueChange: true,
		});
	};

	onDateChange = event => {
		const { inputFields } = this.state;
		const { mandatoryFields } = inputFields;

		find(mandatoryFields, item => {
			if (item.groupId === 'ReferenceDate' && event) {
				item.value = event;
				item.smartDateKey = null;
				this.inputData[item.groupId] = {
					value: item.value,
					colText: item.colText,
				};
				item.valid = true;
				item.errorMsg = '';
				this.onDateRangeChange(event, 'endDate', false);
				this.inputData.SmartDate = { key: null };
				this.updateSelectedDatasetValue(item.groupId, event);
			}
		});
		inputFields.mandatoryFields = mandatoryFields;
		updateLocalStorage('inputData', this.inputData);
		this.setState({
			inputFields,
			inputData: this.inputData,
			smartDateKey: null,
			isValueChange: true,
		});
	};

	onChangeEndDate = date => {
		const { inputFields } = this.state;
		const { mandatoryFields } = inputFields;
		find(mandatoryFields, item => {
			if (item.groupId === 'ReferenceDate') {
				if (!item.value && date) {
					item.valid = false;
					item.errorMsg = `Please select ${item.colText}`;
				}
			}
		});

		return mandatoryFields;
	};

	updateSmartDateKey = (MandatoryFields, selectedSmartDateKey) => {
		find(MandatoryFields, item => {
			if (item.groupId === 'ReferenceDate') {
				item.smartDateKey = selectedSmartDateKey;
			}
			return item.groupId === 'ReferenceDate';
		});
	};

	onSmartDateChange = (event, option) => {
		const newDate = getSmartDate(option);
		const { inputFields } = this.state;
		const { inputNodes } = this.props;
		const { mandatoryFields } = inputFields;
		const { MandatoryFields } = inputNodes;
		find(mandatoryFields, item => {
			if (item.groupId === 'ReferenceDate') {
				if (newDate) {
					item.value = newDate;
					item.smartDateKey = null;
					this.inputData[item.groupId] = {
						value: item.value,
						colText: item.colText,
					};
					item.valid = true;
					item.errorMsg = '';
					this.updateSelectedDatasetValue(item.groupId, newDate);
				} else {
					item.value = newDate;
					item.smartDateKey = null;
					this.inputData[item.groupId] = {
						value: item.value,
						colText: item.colText,
					};
					this.updateSelectedDatasetValue(item.groupId, newDate);
					if (option.key !== 'none' && option.key !== null) {
						item.valid = false;
						item.errorMsg = `Please select ${item.colText}`;
					}
				}
			}
		});

		const selectedSmartDateKey = option.key !== 'none' ? option.key : null;
		this.inputData.SmartDate = { key: selectedSmartDateKey };
		this.onDateRangeChange(newDate, 'endDate', false);
		inputFields.mandatoryFields = mandatoryFields;
		updateLocalStorage('inputData', this.inputData);
		this.updateSmartDateKey(MandatoryFields, selectedSmartDateKey);
		this.updateDatasetSmartDateValue('ReferenceDate', selectedSmartDateKey);
		this.setState({
			inputFields,
			inputData: this.inputData,
			smartDateKey: selectedSmartDateKey,
			isValueChange: true,
		});
	};

	// below method triggers on Back button click and show warning msg if value is selected.
	onCancelClick = () => {
		const { history, isGetData } = this.props;
		const { backWarning, isCellSheetSelected, isValueChange } = this.state;
		if (backWarning || (!isValueChange && isCellSheetSelected)) {
			removeStorage(isGetData);
			history.push(`/create-new-dataset`);
		} else {
			this.setState({ backWarning: true, isCellSheetSelected: true });
		}
	};

	checkOptionalFieldValidity = formValid => {
		const { inputFields } = this.state;
		const { optionalFields } = inputFields;
		find(optionalFields, item => {
			if (item.groupId === 'CTP1' && item.showCTP) {
				const { startDate, endDate } = item.value;
				const checkOptionalStatus = validateDateRange(item);
				if (startDate && endDate) {
					if (checkOptionalStatus) {
						formValid = true;
					} else {
						formValid = false;
					}
				} else {
					formValid = false;
				}
			}
		});
		return { optionalFields, formValid };
	};

	// validating all form fields and updating errorMsg in inputFields object
	validateFields = () => {
		const { inputFields } = this.state;
		let { isFormValid } = this.state;
		const { mandatoryFields } = inputFields;
		const nonValidField = validateFieldValues(mandatoryFields);
		const checkFormValid = isEmpty(nonValidField);
		const checkOptionalFieldValid = this.checkOptionalFieldValidity(
			checkFormValid,
		);
		inputFields.mandatoryFields = mandatoryFields;
		inputFields.optionalFields = checkOptionalFieldValid.optionalFields;
		isFormValid = checkFormValid && checkOptionalFieldValid.formValid;
		this.setState({
			inputFields,
			isFormValid,
		});
		return isFormValid;
	};

	validateExcelCellSheetName = () => {
		const {
			cellValue,
			sheetName,
			isUsedCellAddress,
			isFirstRowSelected,
		} = this.state;
		const { isValidCellId, isValidSheetName } = validateCellIdSheetName(
			cellValue,
			sheetName,
		);
		this.setState({
			isValidExcelField: { isValidCellId, isValidSheetName },
		});
		return (
			isValidCellId &&
			isValidSheetName &&
			isUsedCellAddress &&
			!isFirstRowSelected
		);
	};

	validationStatus = () => {
		const isAllFieldsValid = this.validateFields();
		const isValidCellSheet = this.validateExcelCellSheetName();
		return isAllFieldsValid && isValidCellSheet;
	};

	onSubmitForm = event => {
		const { inputData, cellValue, sheetName } = this.state;
		const {
			history,
			inputNodes,
			updateNodeDataItems,
			selectedDataset,
		} = this.props;
		const isValidateStatus = this.validationStatus();
		inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
		if (isValidateStatus) {
			updateCellValueIntoExcel(
				inputData,
				selectedDataset,
				cellValue,
				sheetName,
			);
			updateNodeDataItems(inputNodes);
			if (selectedDataset && !selectedDataset.duplicateClicked) {
				updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
			}
			history.push(`/output-form`);
		}
		event.preventDefault();
	};

	isGetDataRendered = datasetRange => {
		const { updateDatasetValue, isDataRendered } = this.props;
		const dataset = updateDatasetValue(datasetRange);
		updateRunAllStatus(dataset, isDataRendered);
	};

	onClickGetData = event => {
		event.preventDefault();
		const { cellValue, sheetName } = this.state;
		const dataProps = this.props;
		const { inputNodes, updateNodeDataItems } = dataProps;
		inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
		const objectForGetData = objectRequiredforPayload(dataProps);
		const { getData } = objectForGetData;
		const isValidateStatus = this.validationStatus();
		if (isValidateStatus) {
			updateNodeDataItems(inputNodes);
			onClickGetDataBtn(
				dataProps,
				getData,
				cellValue,
				sheetName,
				this.isGetDataRendered,
			);
		}
	};

	onCellChange = event => {
		const {
			cellValue,
			sheetName,
			isValidSheetName,
			isInputCellValid,
			isValidCellId,
			isFirstRowSelected,
		} = onInputCellChange(event);
		const { inputNodes } = this.props;
		inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
		this.setState({
			cellValue,
			sheetName,
			isValidExcelField: { isValidCellId, isValidSheetName },
			isUsedCellAddress: isInputCellValid,
			isFirstRowSelected,
			isCellSheetSelected: !cellValue && !sheetName,
		});
	};

	checkSelectedDatasetCell = () => {
		const { selectedDataset } = this.props;
		if (selectedDataset) {
			const { inputCellNo } = get(selectedDataset, 'inputNodes');
			return inputCellNo;
		}
		return null;
	};

	setCellSheet = value => {
		if (this.mounted) {
			const {
				sheetName,
				cellValue,
				isFirstRowSelected,
				isInputCellValid,
				rangeValue,
			} = value;
			const newCellSelectedData = this.checkSelectedDatasetCell();
			const validCellAddress =
				newCellSelectedData === isInputCellValid || rangeValue === '';
			const { inputNodes } = this.props;
			inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
			this.setState(
				{
					sheetName: value.sheetName,
					cellValue: value.cellValue,
					isValidExcelField: {
						isValidCellId: value.cellValue !== '',
						isValidSheetName: value.sheetName !== '',
					},
					isUsedCellAddress: validCellAddress,
					isFirstRowSelected,
					isCellSheetSelected: !cellValue && !sheetName,
				},
				() => {
					updateSessionStorage('inputCellValue', value.cellValue);
					updateSessionStorage('inputSheetName', value.sheetName);
				},
			);
		}
	};

	setDateRange = (date, type) => {
		const { settingFormTexts } = messages;
		const { dateRangeText } = settingFormTexts;
		const inputData = {
			...this.inputData.CTP1,
			[type]: {
				value: date,
				colText: dateRangeText[type],
			},
		};
		this.inputData.CTP1 = inputData;
		/* UPdate CTP Start Date End Date */
		const valueCTP = this.inputData.CTP1;
		const startDateValue = get(valueCTP, 'startDate.value');
		const endDateValue = get(valueCTP, 'endDate.value');
		const value = {
			startDate:
				startDateValue !== null ? new Date(startDateValue) : null,
			endDate: new Date(endDateValue),
		};
		this.updateSelectedDatasetValue('CTP1', value);
	};

	onDateRangeChange = (date, type, showEndDateWarnging) => {
		const { inputFields } = this.state;
		const { mandatoryFields, optionalFields } = inputFields;
		const referenceDate = get(this.inputData, 'ReferenceDate.value');
		find(optionalFields, item => {
			if (item.groupId === 'CTP1') {
				if (type === 'startDate') {
					item.valid = {
						...item.valid,
						[type]: true,
						startDateErrorMsg: '',
					};
					item.errorMsg = '';
					item.value = {
						...item.value,
						[type]: date,
					};
				}
				if (type === 'endDate') {
					this.onChangeEndDate(date);
					item.valid = {
						...item.valid,
						[type]: true,
						endDateErrorMsg: '',
					};
					item.value = {
						...item.value,
						[type]: referenceDate ? date : null,
					};
				}
				item.referenceDate = referenceDate;
				item.showWarnging = showEndDateWarnging;
			}
			return item.groupId === 'CTP1';
		});
		inputFields.optionalFields = optionalFields;
		inputFields.mandatoryFields = mandatoryFields;
		this.setDateRange(date, type);
		this.setState({ inputFields, inputData: this.inputData }, () => {
			updateLocalStorage('inputData', this.inputData);
		});
	};

	render() {
		const {
			inputFields,
			inputData,
			cellValue,
			sheetName,
			backWarning,
			smartDateKey,
			isValidExcelField,
			isUsedCellAddress,
			isFirstRowSelected,
		} = this.state;
		const { settingFormTexts, backWarningMessage } = messages;
		const { selectedServiceName, selectedDataset, isLoading } = this.props;
		return (
			<LoadingOverlay
				active={isLoading}
				spinner={<Spinner size={SpinnerSize.large} />}
				text={<ThrobberMessage />}
			>
				<div
					className="setting-form-container"
					data-test="inputContainer"
				>
					<Header
						title={settingFormTexts.title}
						description={settingFormTexts.description}
						data-test="headerContainer"
						onClickGetData={this.onClickGetData}
						showGetDataButton={showGetDataBtn(selectedDataset)}
					/>
					<DynamicForm
						fields={inputFields}
						inputData={inputData}
						onSelectionChange={this.onSelectionChange}
						onMasterTrustChange={this.onSponsorCodeChange}
						onCancelClick={this.onCancelClick}
						backWarning={backWarning}
						backWarningMessage={backWarningMessage}
						messages={settingFormTexts}
						cellValue={cellValue}
						sheetName={sheetName}
						isValidExcelField={isValidExcelField}
						isUsedCellAddress={isUsedCellAddress}
						isFirstRowSelected={isFirstRowSelected}
						onCellChange={this.onCellChange}
						submitForm={this.onSubmitForm}
						onDateChange={this.onDateChange}
						onSmartDateChange={this.onSmartDateChange}
						smartDateKey={smartDateKey}
						onDateRangeChange={this.onDateRangeChange}
						serviceName={selectedServiceName}
						stepPage={1}
						isButtonDisable={!!selectedDataset}
					/>
				</div>
			</LoadingOverlay>
		);
	}
}

const mapStateToProps = state => ({
	selectedServiceName: get(state, 'data.createNewDataSet.selectedService'),
	inputNodes: get(state, 'data.createNewDataSet.inputNodes'),
	masterTrustDataList: get(
		state,
		'data.createNewDataSet.masterTrustDataList',
	),
	masterTrustDD: get(state, 'data.createNewDataSet.masterTrust_DD'),
	isGetData: get(state, 'data.getData.isGetDataClicked'),
	servicePath: get(state, 'data.createNewDataSet.servicePath'),
	selectedDataset: get(state, 'data.createNewDataSet.selectedDataset'),
	isLoading: get(state, 'data.getData.loading'),
	profile: get(state, 'data.userInContext.user.profile'),
	user: get(state, 'data.userInContext.user'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			// createNewDatasetValue,
			// redirectTo,
			updateNodeDataItems: updateNodeData,
			updateSelectedDatasetItems: updateSelectedDataset,
			getData: displaySettingAction,
			isDataRendered: onDataRendered,
			storeDataset: updateDatasetToCosmos,
			updateDatasetValue: updateDataset,
		},
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SettingFormContainer);
// export default SettingFormContainer;
