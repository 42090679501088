import React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { ThankYouContainer } from 'containers';

const FeedBack = () => {
	return (
		<Fabric>
			<ThankYouContainer />
		</Fabric>
	);
};

export default FeedBack;
