import { Api } from 'api/Api';
import { createAction } from 'utils/helpers';
import { updateResWithRadioBtn } from 'utils/inputFormUtil';
import { CreateNewDataConst } from './CreateNewDatasetConst';

const {
	FETCH_SERVICE_BEGIN,
	FETCH_SERVICE_SUCCESS,
	FETCH_SERVICE_FAILURE,
	FETCH_CREATE_DATA_SUCCESS,
	FETCH_CREATE_DATA_FAILURE,
	BACK_BUTTON_CLICKED,
	NEW_SERVICE_SELECTED,
	UPDATE_NODE_DATA,
	NEW_GET_DATA_BEGIN,
	SELECTED_DATASET,
	UPDATE_SELECTED_DATASET,
} = CreateNewDataConst;

export const onBackClick = () => dispatch => {
	dispatch(createAction(BACK_BUTTON_CLICKED));
};

// on service selection below method getting called and dispatch data to store
export const onServiceClick = (selectedSerivceObj, history) => {
	return dispatch => {
		const {
			servicename,
			serviceMetaDataPath,
			displayOrientation,
			valuationFrequency,
		} = selectedSerivceObj;
		dispatch(createAction(FETCH_SERVICE_BEGIN));
		dispatch(createAction(NEW_GET_DATA_BEGIN));
		return Api.retriveServiceData(serviceMetaDataPath, servicename)
			.then(res => {
				let { data } = res;
				if (displayOrientation || valuationFrequency) {
					data = updateResWithRadioBtn(
						data,
						selectedSerivceObj,
						true,
					);
				}
				dispatch(
					createAction(NEW_SERVICE_SELECTED, {
						selectedSerivceObj,
					}),
				);
				dispatch(createAction('INITIAL_STATE'));
				dispatch(createAction(FETCH_SERVICE_SUCCESS, data));
				history.push('/input-form');
				return data;
			})
			.catch(error =>
				dispatch(createAction(FETCH_SERVICE_FAILURE, error)),
			);
	};
};

// below method getting called on create new dataset on load and dispatch data to store
export function createNewDatasetValue() {
	return dispatch => {
		dispatch(createAction(FETCH_SERVICE_BEGIN));
		return Api.retriveServiceList()
			.then(json => {
				dispatch(createAction(FETCH_CREATE_DATA_SUCCESS, json.data));
				return json.data;
			})
			.catch(error =>
				dispatch(createAction(FETCH_CREATE_DATA_FAILURE, error)),
			);
	};
}

export function updateNodeData(nodeData) {
	return dispatch => {
		dispatch(createAction(UPDATE_NODE_DATA, nodeData));
	};
}
export function updateSelectedDataset(nodeData) {
	return dispatch => {
		dispatch(createAction(UPDATE_SELECTED_DATASET, nodeData));
	};
}
export function onDatasetSelect(nodeData) {
	return dispatch => {
		dispatch(createAction(SELECTED_DATASET, nodeData));
	};
}
