/* global Office */
import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/styles.scss';
import GTMInstance from 'utils/GTM/GTMInstance';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from 'office-ui-fabric-react';
import {
	ensureStateInitialized,
	updateRibbon,
} from 'utils/excelUtils/office-apis-helpers';
import App from './App';
import 'office-ui-fabric-react/dist/css/fabric.min.css';

initializeIcons();
window.analytics = GTMInstance();

const render = Component => {
	ReactDOM.render(
		<AppContainer>
			<Component />
		</AppContainer>,
		document.getElementById('app'),
	);
};

Office.initialize = () => {
	ensureStateInitialized(true);
	render(App);
	updateRibbon();
	// readEnvConfig();
};

render(App);

if (module.hot) {
	module.hot.accept('./App', () => {
		// eslint-disable-next-line global-require
		const NextApp = require('./App').default;
		render(NextApp);
	});
}
