import React from 'react';
import NtSvg from 'images/NT_welcome_Logo.png';
import { Image } from 'office-ui-fabric-react';

const NtLogo = props => {
	const { className, alttext } = props;
	return <Image className={className} src={NtSvg} alt={alttext} />;
};

export default NtLogo;
