import React from 'react';
import {
	Label,
	Pivot,
	PivotItem,
	FocusZone,
	FocusZoneDirection,
	initializeIcons,
} from 'office-ui-fabric-react';

initializeIcons(undefined, { disableWarnings: true });
const PivotItemList = props => {
	const { messages, categoryList, noContentText, labelStyles } = props;

	return (
		<Pivot className="footer-margin">
			<PivotItem
				key={messages[0]}
				className="pivot-header"
				headerText={messages[0].text}
				headerButtonProps={{
					'data-order': 1,
					'data-title': messages[0].text,
				}}
				id="product-groups"
			>
				<Label styles={labelStyles}>
					<FocusZone direction={FocusZoneDirection.vertical}>
						<div>{categoryList}</div>
					</FocusZone>
				</Label>
			</PivotItem>
			{messages[1].isEnable ? (
				<PivotItem key={messages[1]} headerText={messages[1].text}>
					<Label styles={labelStyles}>{noContentText}</Label>
				</PivotItem>
			) : null}

			{messages[2].isEnable ? (
				<PivotItem key={messages[2]} headerText={messages[2].text}>
					<Label styles={labelStyles}>{noContentText}</Label>
				</PivotItem>
			) : null}

			{messages[3].isEnable ? (
				<PivotItem key={messages[3]} headerText={messages[3].text}>
					<Label styles={labelStyles}>{noContentText}</Label>
				</PivotItem>
			) : null}
		</Pivot>
	);
};
export default PivotItemList;
