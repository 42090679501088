import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
	ClickAnalyticsPlugin,
	BehaviorMapValidator,
} from '@microsoft/applicationinsights-clickanalytics-js';
import { behaviorMap } from './BehaviourMap';

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
	/**
	 * Initialize the Application Insights class
	 * @param {string} instrumentationKey - Application Insights Instrumentation Key
	 * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
	 * @return {void}
	 */
	const initialize = (instrumentationKey, browserHistory) => {
		if (!browserHistory) {
			throw new Error('Could not initialize Telemetry Service');
		}
		if (!instrumentationKey) {
			throw new Error(
				'Instrumentation key not provided in ./src/utils/AppInsights/telemetry-provider.jsx',
			);
		}
		const clickPluginInstance = new ClickAnalyticsPlugin();
		const clickPluginConfig = {
			dataTags: {
				customDataPrefix: 'data-custom-',
				aiBlobAttributeTag: 'customblob',
				parentDataTag: 'parent-group',
				donotTrackDataTag: 'dnt',
				captureAllMetaDataContent: true,
				useDefaultContentNameOrId: true,
			},
			behaviorValidator: BehaviorMapValidator(behaviorMap),
			defaultRightClickBhvr: 'rc',
		};
		reactPlugin = new ReactPlugin();

		appInsights = new ApplicationInsights({
			config: {
				instrumentationKey,
				maxBatchInterval: 0,
				disableFetchTracking: false,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: {
						history: browserHistory,
					},
					[clickPluginInstance.identifier]: clickPluginConfig,
				},
			},
		});

		appInsights.loadAppInsights();
		appInsights.trackPageView();
	};

	return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
