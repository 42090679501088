import React, { memo, useRef } from 'react';
import { initializeIcons, Image } from 'office-ui-fabric-react';

// Initialize icons in case this example uses them
initializeIcons();
const dragEnterClass = {
	backgroundColor: '#edebe9',
	margin: '6px',
	textAlign: 'left',
	height: '14px',
	fontSize: '10.5px',
	padding: '6px',
	fontFamily:
		'"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
};
const marginClass = {
	marginTop: '-12px',
	marginLeft: '12px',
};

const DetailsListDragDrop = props => {
	const { items, isArrangeField, handleDragDropEvents, columns } = props;
	const dragItem = useRef();
	const dragOverItem = useRef();

	const dragStart = (e, position) => {
		dragItem.current = position;
	};

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
	};
	const onDrop = e => {
		const copyListItems = [...items];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		handleDragDropEvents(copyListItems);
	};

	const newDragDrop = () => {
		const { iconClassName, iconPath } = columns[0];
		return items.map((itemList, index) => {
			return (
				<div
					style={dragEnterClass}
					onDragStart={e => dragStart(e, index)}
					onDragEnter={e => dragEnter(e, index)}
					onDragEnd={isArrangeField ? onDrop : null}
					key={index}
					draggable
				>
					<Image
						className={iconClassName}
						src={iconPath.moveIcon}
						alt={itemList.name}
					/>
					<div style={marginClass}>{itemList.colText}</div>
				</div>
			);
		});
	};

	return newDragDrop();
};

export default memo(DetailsListDragDrop);
