import React, { memo } from 'react';
import { TextField, PrimaryButton } from 'office-ui-fabric-react';
import InformationIcon from '../informationIcon/InformationIcon';
import './SearchBox.scss';

const SearchBox = props => {
	const {
		onFormData,
		onChangeTextField,
		onClickedIndexSearchButton,
		indexSearchButtonId,
	} = props;
	
	const onRenderLabel = items => {
		const { label, required, tooltipMessage } = items;
		return (
			<InformationIcon
				labelId={label}
				label={label}
				tooltipMessage={tooltipMessage}
				mandatory={required}
			/>
		);
	};

	const searchButtonStyle  = {
		"margin-top": "5px", 
		"float": "right"
	}
	return (
		<>	
				<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">		
					<TextField
					label={onFormData.colText}
					name={onFormData.name}
					value={onFormData.value}
					className={onFormData.control}
					required={onFormData.mandatory}
					placeholder={onFormData.placeholder}
					ariaLabel={onFormData.colId}
					id={onFormData.colId}
					onChange={onChangeTextField}
					errorMessage={onFormData.errorMsg}
					tooltipMessage={onFormData.colHelp}
					onRenderLabel={onRenderLabel}/>
				</div>	
				<div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
				<div className= "search-box">
				<div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">	
									
					<PrimaryButton 					
					id={indexSearchButtonId}
					text={'Search'}
					styles={'search-box'}
					onClick={onClickedIndexSearchButton}/>
				</div>				
				</div>
					
		</>
	);
};

export default SearchBox;
