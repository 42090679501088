import FeedBack from './FeedBack';

export default {
	routeProps: {
		path: '/feedback',
		exact: true,
		component: FeedBack,
	},
	name: 'FeedBack',
};
