import React, { useState } from 'react';
import { Icon } from 'office-ui-fabric-react';
import { Popup } from 'components';

// Renders a modal to the modal root and handles the visibility state
// of this modal.
//
// NOTE: Each modal you want to render should use a separate hook!!!
// Otherwise your modals will share their visibility state which might lead
// to overlapping and unclosable elements.
export const useModal = () => {
	const [isVisible, setIsVisible] = useState(false);
	const show = () => setIsVisible(true);
	const hide = () => setIsVisible(false);

	const iconStyle = { marginLeft: '4px' };

	const RenderModal = ({ title, children, showIcon, onClickCanel, showButtonModal }) => {
		return (
			<>
				{isVisible && (
					<Popup
						closeModal={hide}
						isModalOpen={show}
						onClickCanel={onClickCanel}
						title={
							<div>
								<span>{title}</span>
								{showIcon ? (
									<Icon style={iconStyle} iconName="sad" />
								) : null}
							</div>
						}
						showButtonModal = {showButtonModal}
					>
						{children}
					</Popup>
				)}
			</>
		);
	};

	return {
		show,
		hide,
		RenderModal,
	};
};
