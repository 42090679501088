// import { get } from 'lodash';
import { PERFORMANCE } from './PerformanceConst';

const {
	INITIAL_STATE,
	MANIPULATE_INPUT_NODES,
	BACK_BUTTON_CLICKED,
	UPDATE_VALUES,
	VALIDATE_FIELDS,
	CELL_CHANGE,
	UPDATE_RADIO_OPTIONS,
	DEFAULT_SERVICE_NAME,
	UPDATE_BACK_WARNING,
	UPDATE_TEMPLATE,
	FETCH_TEMPLATE_SERVICE,
	FETCH_TEMPLATE_SERVICE_FAILURE,
	OPEN_POPUP,
	READ_ONLY_TEXT,
	DISPLAY_SETTING_CELL_CHANGED,
	FETCH_INDEX_SEARCH,
	SUCCESS_INDEX_SEARCH
} = PERFORMANCE;

const initialState = {
	inputNodes: {
		mandatoryFields: [],
		optionalFields: [],
	},
	cellValue: '',
	sheetName: '',
	isAllFieldValid: true,
	isUsedCellAddress: true,
	isCellSheetSelected: true,
	isFirstRowSelected: false,
	isValidExcelField: {
		isValidCellId: true,
		isValidSheetName: true,
	},
	performanceGetData: false,
	serviceName: '',
	backWarning: false,
	isValueUpdate: false,
	templates: '',
	loading: false,
	isEditable: false,
	isReadOnlyText: false,
	isDisplaySettingCellChanged: false
};

export default function productReducer(state = initialState, action = {}) {
	const { payload } = action;
	switch (action.type) {
		case MANIPULATE_INPUT_NODES:
			return {
				...state,
				inputNodes: {
					mandatoryFields: [...payload.mandatoryFields],
					optionalFields: [...payload.optionalFields],
				},
			};
		case UPDATE_VALUES:
			return {
				...state,
				performanceGetData: true,
				inputNodes: {
					mandatoryFields: [...payload.mandatoryFields],
					optionalFields: [...payload.optionalFields],
				},
				isValueUpdate: payload.isValueUpdated,
			};
		case DEFAULT_SERVICE_NAME:
			return {
				...state,
				serviceName: payload.serviceName,
			};
		case FETCH_TEMPLATE_SERVICE:
			return {
				...state,
				loading: true,
			};
		case UPDATE_TEMPLATE:
			return {
				...state,
				templates: payload.data,
				loading: false,
			};
		case FETCH_TEMPLATE_SERVICE_FAILURE:
			return {
				...state,
				loading: false,
			};
		case VALIDATE_FIELDS:
			return {
				...state,
				inputNodes: {
					mandatoryFields: [...payload.inputData.mandatoryFields],
					optionalFields: [...payload.inputData.optionalFields],
				},
				isAllFieldValid: payload.isValidFields,
				isUsedCellAddress: payload.isUsedCellAddress,
				isValidExcelField: payload.isValidExcelField,
			};
		case CELL_CHANGE:
			return {
				...state,
				cellValue: payload.cellValue,
				sheetName: payload.sheetName,
				isValidExcelField: payload.isValidExcelField,
				isUsedCellAddress: payload.isUsedCellAddress,
				isFirstRowSelected: payload.isFirstRowSelected,
				isCellSheetSelected: payload.isCellSheetSelected,
				isValueUpdate: true,
			};
		case UPDATE_RADIO_OPTIONS:
			return {
				...state,
				serviceName: payload.serviceName,
				inputNodes: {
					mandatoryFields: [...payload.mandatoryFields],
					optionalFields: [...state.inputNodes.optionalFields],
				},
				isValueUpdate: true,
			};
		case UPDATE_BACK_WARNING:
			return {
				...state,
				backWarning: payload.backWarning,
				isValueUpdate: payload.isValueUpdate,
			};
		case INITIAL_STATE:
		case BACK_BUTTON_CLICKED:
			return {
				...state,
				...initialState,
			};
		case OPEN_POPUP:
			return {
				...state,
				isEditable: action.payload,
			};
		case READ_ONLY_TEXT:
			return {
				...state,
				isReadOnlyText: action.payload,
			};
		case DISPLAY_SETTING_CELL_CHANGED:
			return {
				...state,
				isDisplaySettingCellChanged: action.payload,
			};
		case FETCH_INDEX_SEARCH:
			return {
				...state,
				loading: true,
			};
		case SUCCESS_INDEX_SEARCH:
			return {
				...state,
				loading: false,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
