import React from 'react';
import { PrimaryButton } from 'office-ui-fabric-react';
import NtLogo from 'components/common/NtLogo';

const DatasetManagerDefaultBody = props => {
	const { datasetTexts, onCreateNewDatasetClick } = props;
	return (
		<div className="dataset-manager-container">
			<div className="ms-Grid">
				<div>
					<NtLogo
						className="datasetmanager-nt-logo"
						id="welcome-logo"
						alttext="Stacked Centered Default Anchor Logo"
					/>
				</div>
				<div className="ms-Grid-row dataset-manager-content">
					<div className="ms-Grid-col ms-sm12">
						{datasetTexts.noAvailableDatasetText}
					</div>
				</div>
				<div className="ms-Grid-row dataset-manager-content">
					<div className="ms-Grid-col ms-sm12">
						{datasetTexts.clickBelowText}
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 create-dataset-button">
						<PrimaryButton
							className="create-dataset"
							id="create-dataset-button"
							text={datasetTexts.createNewButtonText}
							onClick={onCreateNewDatasetClick}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DatasetManagerDefaultBody;
