import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Fabric } from 'office-ui-fabric-react';
import WelcomeBody from 'components/welcome/WelcomeBody';
import messages from 'containers/welcome/messages/WelcomeTexts';
import { updateUniqueId } from 'utils/GetDataUtil';
import { createReportDatasetObject } from 'utils/datasetUtil';
import {
	onServiceClick,
	onDatasetSelect,
} from 'containers/createNewDataset/CreateNewDatasetAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty } from 'lodash';
import { gtmEventTrack } from 'utils/GTM/GTMUtil';
import { gaEventTags } from 'utils/GTM/GATags';
import { updateRibbon , signOutOnSessionTimeOut} from 'utils/excelUtils/office-apis-helpers';
import { getWorkbookId } from 'utils/excelUtils/ExcelDataPopulate';
import { userInContext } from 'containers/login/LoginAction';
import { AuthService } from '../../services/AuthService';
import { getGlobal } from '../../commands/commands';
import { config, checkEnv } from 'utils/config';
import { ENVIRONMENT } from 'utils/Constants';
import { createHashHistory } from 'history';


import {
	getDataset,
	cleareStoredDataset,
	retrieveMasterTrust,
	retrieveWelcomeMessages,
} from './WelcomeAction';

const Welcome = props => {
	const {
		serviceClicked,
		history,
		selectedDataset,
		readDataset,
		dataSet,
		clearDataset,
		user,
		getTrust,
		getWelcomeMessages,
	} = props;
	const [workbookId, setWorkbookId] = useState('');
	const [currentService, setCurrentService] = useState('');
	const [datasetObject, setDatasetObject] = useState([]);
	const [serviceDataObject, setServiceData] = useState([]);
	const [reportDatasetObject, setReportDatasetObject] = useState([]);
	const [heading, setHeading] = useState('');
	const [welcomeListMessage, setWelcomeListMessage] = useState([]);
	const [isWelcomeShow, setIsWelcomeShow] = useState(false);
	const firstRender = useRef(true);

	// create dataset dropdown object
	const createDatasetObject = data => {
		const { reports } = data;
		const dropDownObj = [
			{
				key: null,
				text: null,
			},
		];
		if (!isEmpty(reports)) {
			reports.map(listItem => {
				const serviceName = get(listItem, 'service.name');
				setServiceData(listItem.serviceData);
				dropDownObj.push({
					key: listItem.reportId,
					elementId: listItem.reportId,
					label: listItem.reportName,
					text: listItem.reportName,
					value: serviceName,
					dataSetOrder: listItem.dataSetOrder,
				});
				return dropDownObj;
			});
			dropDownObj.sort((prev, next) => {
				return prev.dataSetOrder - next.dataSetOrder;
			});
			setDatasetObject(dropDownObj);
		}
	};
	// below method fires action to read data from cosmos DB by passing id as query param
	const onWorkbookIdRecevied = useCallback(
		id => {
			if (!isEmpty(id) && !!id[0][0]) {
				setWorkbookId(id[0]);
				readDataset(id[0]);
			} else {
				clearDataset();
			}
		},
		[readDataset, clearDataset],
	);

	useEffect(() => {
		firstRender.current = false;
		if (user && user.firstName) {
			getWorkbookId(onWorkbookIdRecevied);
		}
		return () => {
			firstRender.current = true;
		};
	}, [user, onWorkbookIdRecevied]);

	useEffect(() => {
		const authService = new AuthService();
		const g = getGlobal();
		authService.getLoggedInUser().then(userData => {
			const userprofile = user || userData;
			const userName = get(userprofile, 'firstName');
			if (!user && userprofile) {
				putUserInContext(userprofile);
			}
			if (userprofile && localStorage.getItem('loggedIn') === 'yes') {
			
				getTrust();
				getWelcomeMessages(setWelcomeListMessage);
				setHeading(`Welcome ${userName} to`);
			} else {
				g.state.isSignedIn = false;
				g.state.isMenuEnabled = false;
				updateRibbon();
				history.push('/login');
			}
		});
	}, [user]);

	// useEffect triggers when dataset object change
	useEffect(() => {
		if (workbookId) {
			setReportDatasetObject(dataSet);
			createDatasetObject(dataSet);
		}
	}, [workbookId, dataSet, serviceDataObject]);

	const onNextClick = () => {
		const { createNewDatasetButton } = gaEventTags;
		gtmEventTrack('dd_create_new_dataset', createNewDatasetButton);
		history.push(`/create-new-dataset`);
	};
	const isClick = () => {
		setIsWelcomeShow(!isWelcomeShow);
	};
	// fires when on dropdown dataset selected
	const onSelectService = (event, option) => {
		const { key } = option;
		setCurrentService(key);
		updateUniqueId();
		const data = createReportDatasetObject(reportDatasetObject, key);
		selectedDataset(data);
		serviceClicked(data.serviceData, history);
	};

	return (
		<Fabric>
			<WelcomeBody
				{...messages}
				heading={heading}
				onNextClick={onNextClick}
				dataSetObject={!firstRender.current ? datasetObject : null}
				selectedKeyItem={currentService}
				onSelectService={onSelectService}
				welComeAccordion={messages.welcomeListMessage}
				welcomeListMessage={welcomeListMessage}
				isClick={isClick}
				isWelcomeShow={isWelcomeShow}
				totalMessages={messages.totalMessages}
			/>
		</Fabric>
	);
};
const mapStateToProps = state => ({
	dataSet: get(state, 'data.dataSet.data'),
	user: get(state, 'data.userInContext.user.profile'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			selectedDataset: onDatasetSelect,
			serviceClicked: onServiceClick,
			readDataset: getDataset,
			clearDataset: cleareStoredDataset,
			getTrust: retrieveMasterTrust,
			getWelcomeMessages: retrieveWelcomeMessages,
			putUserInContext: userInContext,
		},
		dispatch,
	),
});
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
