export const CreateNewDataConst = {
	FETCH_SERVICE_BEGIN: 'FETCH_SERVICE_BEGIN',
	FETCH_SERVICE_SUCCESS: 'FETCH_SERVICE_SUCCESS',
	FETCH_SERVICE_FAILURE: 'FETCH_SERVICE_FAILURE',
	FETCH_CREATE_DATA_SUCCESS: 'FETCH_CREATE_DATA_SUCCESS',
	FETCH_CREATE_DATA_FAILURE: 'FETCH_CREATE_DATA_FAILURE',
	BACK_BUTTON_CLICKED: 'BACK_BUTTON_CLICKED',
	NEW_SERVICE_SELECTED: 'NEW_SERVICE_SELECTED',
	UPDATE_NODE_DATA: 'UPDATE_NODE_DATA',
	UPDATE_INPUT_DATA: 'UPDATE_INPUT_DATA',
	UPDATE_ARRANGE_NODE: 'UPDATE_ARRANGE_NODE',
	NEW_GET_DATA_BEGIN: 'NEW_GET_DATA_BEGIN',
	SELECTED_DATASET: 'SELECTED_DATASET',
	UPDATE_SELECTED_DATASET: 'UPDATE_SELECTED_DATASET',
	MASTERTRUST_UPDATE: 'MASTERTRUST_UPDATE',
};

export const SettingFormConst = {
	viewType: 'ViewType',
	timePeriod: 'TimePeriod',
	PerformanceIdentifier: 'PerformanceIdentifier',
	PerformanceObject: 'PerformanceObject',
	multiRefCurrency: 'MultiRefCurrency',
	referenceDateId: 'ReferenceDate',
	serviceNames: {
		marketValue: 'Market Value Summary',
		serviceId: 'MVS',
	},
	clientId: {
		groupId: 'ClientId',
		colId: 'ClientId',
		colText: 'Master Trust',
		type: 'DROPDOWNSEARCHASYNC',
		colHelp: 'Select the group(s) for your report.',
	},
};
