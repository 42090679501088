import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PrimaryButton } from 'office-ui-fabric-react';
import { get } from 'lodash';
import messages from './messages/messages';
import { useModal } from '../../hooks/useModal';
import { runAllFailMsg } from '../datasetManager/DatasetManagerAction';

const ErrorBoundaryContainer = props => {
	const { errorMessage, isRunAllClick, showFailMsg } = props;
	const { showNewRunAllFailMsg } = props;
	const [msgText, setMsgText] = useState('');
	const status = get(errorMessage, 'status');
	const { show, hide, RenderModal } = useModal();
	const { modalTexts } = messages;

	const getMsgText = () => {
		let msgDescription = '';
		switch (status) {
			case 400:
				msgDescription = get(errorMessage, 'message');
				setMsgText(msgDescription);
				break;
			default:
				msgDescription = showNewRunAllFailMsg
					? modalTexts.runAllFailMsg
					: modalTexts.description;
				setMsgText(msgDescription);
				break;
		}
	};

	useEffect(() => {
		getMsgText();
		if (errorMessage && status !== 401 && !isRunAllClick) {
			show();
		}
	}, [errorMessage, isRunAllClick]);
	const onCloseModal = () => {
		hide();
		showFailMsg(false);
	};
	return (
		<RenderModal
			title={modalTexts.title}
			showIcon
			onClickCanel={onCloseModal}
		>
			<p>{msgText}</p>
			<PrimaryButton
				id="ok-btn"
				text={modalTexts.btnYes}
				onClick={onCloseModal}
				style={{ margin: '6px', float: 'left' }}
			/>
		</RenderModal>
	);
};

const mapStateToProps = state => ({
	errorMessage: get(state, 'data.getData.error'),
	isRunAllClick: get(state, 'data.dataSetManager.isRunAllClick'),
	showNewRunAllFailMsg: get(state, 'data.dataSetManager.showRunAllFailMsg'),
});
const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			showFailMsg: runAllFailMsg,
		},
		dispatch,
	),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ErrorBoundaryContainer);
