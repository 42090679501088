export const dataOption = [
	{
		key: null,
		text: null,
	},
	{
		key: 'last_month',
		text: 'Last day of last month',
	},
	{
		key: 'last_quarter',
		text: 'Last day of last quarter',
	},
	{
		key: 'last_year',
		text: 'Last day of last year',
	},
	{
		key: 'previous_business_day',
		text: 'Previous business day',
	},
	{
		key: 'none',
		text: 'None',
	},
];
