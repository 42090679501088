import OutPutPage from './OutPutPage';

export default {
	routeProps: {
		path: '/output-form',
		exact: true,
		component: OutPutPage,
	},
	name: 'OutPutPage',
};
