import React from 'react';
import {
	initializeIcons,
	Icon,
	TooltipHost,
	Label,
} from 'office-ui-fabric-react';
import './InformationIcon.scss';

initializeIcons();

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };
const InformationIcon = props => {
	const { labelId, label, tooltipMessage, mandatory } = props;
	return (
		<Label htmlFor={labelId} className="label-icon-container">
			{label}
			{mandatory ? <span className="mandatory-asterick">*</span> : null}

			<TooltipHost
				content={tooltipMessage}
				id="icon-button-tooltip"
				calloutProps={calloutProps}
				styles={hostStyles}
			>
				<Icon iconName="info" className="tooltip-icon" />
			</TooltipHost>
		</Label>
	);
};
export default InformationIcon;
