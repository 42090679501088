import InputPage from './InputPage';

export default {
	routeProps: {
		path: '/input-form',
		exact: true,
		component: InputPage,
	},
	name: 'InputPage',
};
