import React, { useEffect } from 'react';
import { AuthService } from '../../services/AuthService';

const LogoutPage = () => {
	useEffect(() => {
		new AuthService().logout();
	}, []);
	return <div />;
};

export default LogoutPage;
