/* global Office */
import React, { useState, useEffect, useRef } from 'react';
import {
	Footer,
	Header,
	PivotBasicTab,
	ExcelCell,
	DialogBoxHeader,
	DialogBoxFooter,
	ThrobberMessage,
} from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, filter, cloneDeep } from 'lodash';
import {
	SearchBox,
	Separator,
	mergeStyleSets,
	ActionButton,
	Spinner,
	SpinnerSize,
} from 'office-ui-fabric-react';
import LoadingOverlay from 'react-loading-overlay';
import {
	updateLocalStorage,
	getLocalStorageItem,
	updateSessionStorage,
	getSessionStorageItem,
	showGetDataBtn,
	scrollToBottom,
} from 'utils/CommonUtil';
import { updateOutputCellAddress } from 'utils/datasetUtil';
import {
	clearAndPopulateDatasetTitle,
	renderInputOuputExcelData,
	objectRequiredforPayload,
	renderGetData,
	updateRunAllStatus,
} from 'utils/GetDataUtil';
import { validateCellIdSheetName } from 'utils/validationRules';
import {
	dataPopulationOutput,
	clearExcelData,
	moveToCellSheet,
	onInputCellChange,
	spiltTopRowCellValue,
} from 'utils/excelUtils/ExcelDataPopulate';
import { excelCellClickCheck } from 'utils/excelUtils/ExcelCellValidate';
import { updateSelectedDataset } from 'containers/createNewDataset/CreateNewDatasetAction';
import moveIcon from 'images/SVG/group-2.svg';
import { updateDataset } from '../datasetManager/DatasetManagerAction';
import {
	displaySettingAction,
	onDataRendered,
	updateDatasetToCosmos,
} from '../displaySettings/DisplaySettingsAction';
import { useModal } from '../../hooks/useModal';//../../../hooks/useModal
import {
	PrimaryButton,
	DefaultButton,
} from 'office-ui-fabric-react';
// import { ServiceContext } from 'contexts/ServiceContext';
import {
	filteredNodes,
	updateArrangeNodeData,
	selectedMapping,
	childNodesExpand,
	shortCondition,
	manipulateOutPutNodes,
	removeCellDataValue,
	setCellSeetValue,
	searchField,
	cellSheetNameSet,
	resetSearchResult,
	isValidExcelSheet,
	onNextBtnClick,
	updateSelectedDatasetOutput,
	outputNodeSelect,
	selectAll,
	clearSelection,
	defaultSelection,
	setNodes,
	isValidCell,
	renderData,
} from './OutPutAction';
import OutputTexts from './messages/OutputTexts';
import './Output.scss';

const styles = mergeStyleSets({
	root: [
		{
			selectors: {
				'::before': {
					background: '#ccc',
				},
			},
		},
	],
	fileIconCell: {
		textAlign: 'center',
		width: '28px',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '28px',
				visibility: 'hidden',
			},
		},
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
});

export const OutputContainer = props => {
	const [isOutputEditable, setIsOutputEditable]= useState(false);
	let dialog;
	const { investmentHierarchy, iconName } = OutputTexts;
	const { applyButtonMsg, cancelButtonMsg } = get(OutputTexts, 'messageType');
	const addIcon = { iconName: iconName.addIcon };
	const {
		selectedServiceName,
		outputNodeItems,
		updateArrangeNode,
		selectedDataset,
		updateSelectedDatasetItems,
		isLoading,
		performance,
	} = props;
	const hideSelectAll = true;
	// const hideSelectAll = selectedServiceName === investmentHierarchy;
	const isCancelled = useRef(false);
	const messagesEndRef = useRef(null);
	const [isDialogBox, setIsDialogBox] = useState(
		getLocalStorageItem('showDialogBox'),
	);
	const [isClickClear, setIsClickClear] = useState(
		getLocalStorageItem('isClickClear'),
	);
	const [cellValue, setCellValue] = useState(
		getSessionStorageItem('outputCellValue') || '',
	);
	const [isReadOnlyText, setIsReadOnlyText] = useState((selectedDataset && selectedDataset.editClicked) ? true :false,	);

	const [sheetName, setSheetName] = useState(
		getSessionStorageItem('outputSheetName') || '',
	);
	const [outputNodes, setOutPutNodes] = useState([]);
	const [searchOutputNodes, setSearchOutPutNodes] = useState([]);
	const [arrangeFieldNodes, setArrangeFieldNodes] = useState(
		getLocalStorageItem('outputData') || [],
	);
	const [searchArrangeFieldNodes, setSearchArrangeFieldNodes] = useState(
		getLocalStorageItem('outputData') || [],
	);
	const [defaultOutputNode, setDefaultOutputNode] = useState([]);
	const [tabValue, setTabValue] = useState('Select Fields');
	const [searchBoxValue, setSearchBoxValue] = useState('');
	const [columns, setColumns] = useState([]);
	const [isUsedCellAddress, setIsUsedCellAddress] = useState(true);
	const [isValidExcelField, setIsValidExcelField] = useState({
		isValidCellId: true,
		isValidSheetName: true,
	});
	const { RenderModal, hide, show  } = useModal();
	const [isFirstRowSelected, setIsFirstRowSelected] = useState(false);
	const [selectedDatasetCellValue, setSelectedDatasetCellValue] = useState(
		'',
	);
	const updateLocaleData = data => {
		updateLocalStorage('outputData', data);
	};
	useEffect(() => {
		updateLocalStorage('showDialogBox', isDialogBox);
		if(isOutputEditable){
			show();
		}else{
			hide();
		}
	}, [isDialogBox, isOutputEditable]);
	const updateCellOnDuplicate = outputNodesData => {
		const {
			outputCellNo,
			outputSheetName,
			endOutputCellValue,
		} = outputNodesData;
		setCellValue(outputCellNo);
		setSheetName(outputSheetName);
		setSelectedDatasetCellValue(outputCellNo);
		updateSessionStorage('startOutputCellValue', outputCellNo);
		updateSessionStorage('removeOutputSheetName', outputSheetName);
		updateSessionStorage('endOutputCellValue', endOutputCellValue);
		updateSessionStorage('outputCellValue', outputCellNo);
		updateSessionStorage('outputSheetName', outputSheetName);
		/* Function to update Last Cell Address */
		updateOutputCellAddress(outputNodesData, outputCellNo);
		moveToCellSheet(outputCellNo, outputSheetName);
	};

	// check selected cell already used by input data.
	const checkValidCellSelected = cellVal => {
		isValidCell(
			isCancelled,
			excelCellClickCheck,
			cellVal,
			setIsUsedCellAddress,
		);
	};

	const setCellSheet = value => {
		const data = [selectedDataset, isCancelled];
		cellSheetNameSet(
			value,
			setSheetName,
			setCellValue,
			data,
			setIsUsedCellAddress,
			setIsFirstRowSelected,
			setIsValidExcelField,
		);
	};
	const updateColumns = () => {
		const outputColumns = [
			{
				key: 'column1',
				name: 'moveIcon',
				className: styles.fileIconCell,
				iconClassName: styles.fileIconImg,
				ariaLabel: 'Move Icon',
				iconName: 'Move',
				isIconOnly: true,
				fieldName: 'moveIcon',
				minWidth: 16,
				maxWidth: 16,
				iconPath: { moveIcon },
			},
			{
				key: 'column2',
				name: 'Name',
				fieldName: 'colText',
			},
		];
		setColumns(outputColumns);
	};
	const MyCellHandler = eventArgs => {
		Excel.run(context => {
			const range = context.workbook.getSelectedRange();
			const activeSheet = context.workbook.worksheets.getActiveWorksheet();
			range.load();
			activeSheet.load('name');
			return context.sync().then(() => {
				const { address, values } = range;
				const sheetNameId = address.split('!');
				const { name } = activeSheet;
				if (sheetNameId[1] !== '1:1048576') {
					const excelValue = {};
					const cellValue = sheetNameId[1];
					const newVal = values[0][0];
					excelValue.rangeValue = newVal;
					excelValue.sheetName = name;
					excelValue.cellValue = cellValue;
					excelValue.isInputCellValid = cellValue;
					excelValue.isFirstRowSelected = spiltTopRowCellValue(
						cellValue,
					);
					setCellSheet(excelValue);
				}
			});
		});
	};
	useEffect(() => {
		const duplicateClicked = get(selectedDataset, 'duplicateClicked');
		const document = get(Office, 'context.document');
		// if (document && (!selectedDataset || duplicateClicked)) {
		if (document) {
			Office.context.document.addHandlerAsync(
				Office.EventType.DocumentSelectionChanged,
				MyCellHandler,
			);
		}
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
		const object = [
			outputNodeItems,
			selectedDataset,
			arrangeFieldNodes,
			isDialogBox,
			isClickClear,
		];
		manipulateOutPutNodes(
			object,
			updateCellOnDuplicate,
			setOutPutNodes,
			setSearchOutPutNodes,
			setArrangeFieldNodes,
			setSearchArrangeFieldNodes,
			updateLocaleData,
		);
		setDefaultOutputNode(outputNodeItems);
		setCellSeetValue(
			cellValue,
			sheetName,
			setSelectedDatasetCellValue,
			moveToCellSheet,
			selectedDataset,
			checkValidCellSelected,
		);
		updateColumns();
		return () => {
			isCancelled.current = true;
		};
	}, []);

	useEffect(() => {
		updateLocalStorage('showDialogBox', isDialogBox);
	}, [isDialogBox]);

	useEffect(() => {
		updateLocalStorage('isClickClear', isClickClear);
	}, [isClickClear]);

	const onCellChange = event => {
		const {
			isValidSheetName,
			isInputCellValid,
			isValidCellId,
		} = onInputCellChange(event);
		const inputCellValue = get(onInputCellChange(event), 'cellValue');
		const inputSheetValue = get(onInputCellChange(event), 'sheetName');
		const isFirstRowSet = get(onInputCellChange(event), 'isFirstRowSet');
		setCellValue(inputCellValue);
		setSheetName(inputSheetValue);
		const cellAddressVal = shortCondition(
			selectedDatasetCellValue === inputCellValue,
			isInputCellValid,
			true,
		);
		setIsUsedCellAddress(cellAddressVal);
		setIsFirstRowSelected(isFirstRowSet);
		setIsValidExcelField({
			isValidSheetName,
			isValidCellId,
		});
	};
	const onSearchArrangeField = searchValue => {
		const newNodes = filteredNodes(searchValue, searchArrangeFieldNodes);
		setArrangeFieldNodes(newNodes);
	};
	const onSearchChange = event => {
		const searchTxt = event ? event.target.value : '';
		const searchValue = shortCondition(event, '', searchTxt);
		setSearchBoxValue(searchValue);
		searchField(
			tabValue,
			searchValue,
			searchOutputNodes,
			arrangeFieldNodes,
			setOutPutNodes,
			onSearchArrangeField,
		);
	};
	const onClickTab = event => {
		setTabValue(event.props.headerText);
		setSearchBoxValue('');
		const newNodes = selectedMapping(
			searchOutputNodes,
			searchArrangeFieldNodes,
		);
		setOutPutNodes(newNodes);
		setArrangeFieldNodes(searchArrangeFieldNodes);
	};
	const clearSearchResult = () => {
		setSearchBoxValue('');
		resetSearchResult(
			setOutPutNodes,
			searchOutputNodes,
			setArrangeFieldNodes,
			searchArrangeFieldNodes,
		);
	};
	const validateExcelCellSheetName = () => {
		const data = [cellValue, sheetName];
		return isValidExcelSheet(
			data,
			validateCellIdSheetName,
			scrollToBottom,
			setIsValidExcelField,
			messagesEndRef,
			isUsedCellAddress,
			isFirstRowSelected,
		);
	};
	const updateOutputCellValue = () => {
		setCellValue(cellValue);
	}
	const updateDatasetCellAddress = (startOutputCellOldValue,outputSheetOldValue) => {
		const outputNodesData = get(selectedDataset, 'outputNodes');
		setCellValue(startOutputCellOldValue || cellValue);
		setSheetName(outputSheetOldValue || sheetName);

		updateSessionStorage('startOutputCellValue', startOutputCellOldValue || cellValue);
		updateSessionStorage('outputCellValue', startOutputCellOldValue || cellValue);
		updateSessionStorage('outputSheetName', outputSheetOldValue || sheetName);

		const outPutEndCellValue = updateOutputCellAddress(
			outputNodesData,
			startOutputCellOldValue || cellValue,
			outputSheetOldValue || sheetName
		);

		if (selectedDataset) {
			const datasetRangeSplit = get(selectedDataset, 'datasetRange');
			const newDatasetRange = datasetRangeSplit.split('!');
			const newDatasetRangeRow = newDatasetRange[0].split(':');
			const endRowValue = newDatasetRangeRow[1].replace(/[^0-9]/g, '');
			const newEndRowValue = `${
				newDatasetRangeRow[0]
			}:${outPutEndCellValue.replace(/[0-9]/g, '')}${endRowValue}!${
				newDatasetRange[1]
			}`;
			selectedDataset.datasetRange = newEndRowValue;
		}
		outputNodesData.outputCellNo = startOutputCellOldValue || cellValue;
		outputNodesData.outputSheetName = outputSheetOldValue || sheetName;
		outputNodesData.endOutputCellValue = outPutEndCellValue;
		updateSelectedDatasetItems(selectedDataset);
	};

	const updateCellValueIntoExcel = (startOutputCellOldValue, outputSheetOldValue) => {
		updateSessionStorage('outputCellValue', startOutputCellOldValue || cellValue);
		updateSessionStorage('outputSheetName', outputSheetOldValue || sheetName);
		const { removeRange, removeSheet } = removeCellDataValue(
			selectedDataset,
		);
		clearExcelData(removeRange, removeSheet);
		updateSessionStorage(
			'startOutputCellValue',
			getSessionStorageItem('outputCellValue'),
		);
		updateSessionStorage(
			'removeOutputSheetName',
			getSessionStorageItem('outputSheetName'),
		);
	};
	// XDE-5631
	const submitForm = () => {
		const { history } = props;
		const isValidData = validateExcelCellSheetName();
		const data = [isValidData, searchArrangeFieldNodes, selectedDataset];
		
		const startOutputCellValueOld = getSessionStorageItem('startOutputCellValue_old');
		const outputSheetNameOld = getSessionStorageItem('outputSheetName_Old');
		//XDE-5631
		updateOutputCellValue();

		if (selectedDataset && selectedDataset.editClicked) {
			if (startOutputCellValueOld !== cellValue && outputSheetNameOld !== sheetName) {
				
				show();
			} else if (startOutputCellValueOld !== cellValue && outputSheetNameOld === sheetName) {
				
				show();
			} else if(startOutputCellValueOld === cellValue && outputSheetNameOld !== sheetName) {
				show();
			} else {
				hide();
				updateSelectedDatasetOutput(
					searchArrangeFieldNodes,
					selectedDataset,
					updateSelectedDatasetItems,
				);
				onNextBtnClick(
					data,
					updateCellValueIntoExcel,
					updateDatasetCellAddress,
					updateArrangeNode,
					history
				);
			}
		} else {
			hide();
			updateSelectedDatasetOutput(
				searchArrangeFieldNodes,
				selectedDataset,
				updateSelectedDatasetItems,
			);
			onNextBtnClick(
				data,
				updateCellValueIntoExcel,
				updateDatasetCellAddress,
				updateArrangeNode,
				history
			);
		}
	};
		
	// XDE-5631
	const onYesChangeCell = () => {
		hide();
		updateSessionStorage('noBtnClickFlag', 'NO');
		updateSessionStorage('outputSheet_Change', 'N');
		const { history } = props;
		const isValidData = validateExcelCellSheetName();
		const data = [isValidData, searchArrangeFieldNodes, selectedDataset];

		updateSelectedDatasetOutput(
			searchArrangeFieldNodes,
			selectedDataset,
			updateSelectedDatasetItems,
		);
		onNextBtnClick(
			data,
			updateCellValueIntoExcel,
			updateDatasetCellAddress,
			updateArrangeNode,
			history
		);
		history.push(`/display-settings`);
	};
	// XDE-5631
	const onNoCellChange = () => {
		updateSessionStorage('noBtnClickFlag', 'YES');
		updateSessionStorage('outputSheet_Change', 'Y');
		hide();
		
		const { history } = props;
		const isValidData = validateExcelCellSheetName();
		const data = [isValidData, searchArrangeFieldNodes, selectedDataset];
		const startOutputCellOldValue = getSessionStorageItem('outputCellValue_old');
		const outputSheetOldValue = getSessionStorageItem('outputSheetName_Old');

		setCellValue(startOutputCellOldValue);
		setSheetName(outputSheetOldValue);
	
		updateSessionStorage('outputCellValue', startOutputCellOldValue);
		updateSessionStorage('startOutputCellValue', startOutputCellOldValue);
		updateSessionStorage('outputSheetName', outputSheetOldValue);

		updateDatasetCellAddress(startOutputCellOldValue, outputSheetOldValue);

		selectedDataset.outputNodes.outputCellNo = startOutputCellOldValue;	
		selectedDataset.outputNodes.outputSheetName = outputSheetOldValue;
		

		updateSelectedDatasetOutput(
			searchArrangeFieldNodes,
			selectedDataset,
			updateSelectedDatasetItems,
		);
		onNextBtnClick(
			data,
			updateCellValueIntoExcel,
			updateDatasetCellAddress,
			updateArrangeNode,
			history,
			startOutputCellOldValue,
			outputSheetOldValue
		);
		history.push(`/display-settings`);
	};
	const onCancelClick = () => {
		const { history } = props;
		if (selectedDataset && !selectedDataset.duplicateClicked) {
			const isValidData = validateExcelCellSheetName();
			updateSelectedDatasetOutput(
				searchArrangeFieldNodes,
				selectedDataset,
				updateSelectedDatasetItems,
			);
			if (isValidData) {
				updateCellValueIntoExcel();
				dataPopulationOutput(searchArrangeFieldNodes);
				updateDatasetCellAddress();
				updateArrangeNode(searchArrangeFieldNodes);
			}
		}
		history.push('/input-form');
	};
	const isGetDataRendered = datasetRange => {
		const { updateDatasetValue, isDataRendered } = props;
		const dataset = updateDatasetValue(datasetRange);
		updateRunAllStatus(dataset, isDataRendered);
		// isDataRendered(dataset);
		if (selectedDataset) {
			selectedDataset.datasetRange = getLocalStorageItem(
				'outputDataRange',
			);
		}
	};
	const onClickGetData = event => {
		event.preventDefault();
		const objectForGetData = objectRequiredforPayload(props);
		const { getData } = objectForGetData;
		const isValidData = validateExcelCellSheetName();
		const data = [
			isValidData,
			searchArrangeFieldNodes,
			selectedDataset,
			updateSelectedDatasetItems,
			updateCellValueIntoExcel,
			updateDatasetCellAddress,
			objectForGetData,
			getData,
		];
		renderData(
			data,
			clearAndPopulateDatasetTitle,
			renderInputOuputExcelData,
			renderGetData,
			isGetDataRendered,
		);
	};
	const onExpandClick = event => {
		const colId = event.target.id.split('-');
		const nodes = [...outputNodes];
		childNodesExpand(nodes, colId);
		setOutPutNodes(nodes);
	};

	const removeUnselectedItem = (arrangeFieldData, item) => {
		return filter(arrangeFieldData, selectedItem => {
			return selectedItem.colId !== item.colId;
		});
	};
	const onOutputNodeSelect = event => {
		const datasetData = get(selectedDataset, 'outputNodes');
		const selectedDatasetOutput = cloneDeep(datasetData);
		const selected = event.target.checked;
		const colId = event.target.id;
		const node = [...outputNodes];
		const data = [selected, colId, arrangeFieldNodes];
		const [nodes, arrangeFieldData] = outputNodeSelect(
			node,
			data,
			removeUnselectedItem,
		);
		setArrangeFieldNodes(arrangeFieldData);
		setSearchArrangeFieldNodes(arrangeFieldData);
		updateLocaleData(arrangeFieldData);
		setOutPutNodes(nodes);
		if (datasetData && selectedDatasetOutput) {
			selectedDataset.outputNodes = selectedDatasetOutput;
		}
	};
	// Popup Screen Select all functionality
	const onSelectAllClick = () => {
		const datasetData = get(selectedDataset, 'outputNodes');
		const selectedDatasetOutput = cloneDeep(datasetData);
		const node = [...outputNodes];
		const [nodes, arrangeFieldData] = selectAll(node, arrangeFieldNodes);
		setArrangeFieldNodes(arrangeFieldData);
		setSearchArrangeFieldNodes(arrangeFieldData);
		updateLocaleData(arrangeFieldData);
		setOutPutNodes(nodes);
		if (datasetData && selectedDatasetOutput) {
			selectedDataset.outputNodes = selectedDatasetOutput;
		}
	};

	// On Clear all selection
	const onClearSelection = () => {
		const nodes = clearSelection(
			searchBoxValue,
			searchOutputNodes,
			outputNodes,
		);
		setArrangeFieldNodes([]);
		setSearchArrangeFieldNodes([]);
		updateLocaleData([]);
		setOutPutNodes(nodes);
		setSearchBoxValue('');
		setIsClickClear(true);
	};
	const dragDropEvents = arrangedItem => {
		updateLocaleData(arrangedItem);
		setSearchArrangeFieldNodes(arrangedItem);
		setArrangeFieldNodes(arrangedItem);
	};
	const commonMessageObject = messageObject => {
		const jsonMessage = JSON.stringify(messageObject);
		if (Office && Office.context) {
			Office.context.ui.messageParent(jsonMessage);
		}
	};
	const closeDailogBox = () => {
		const messageObject = {
			messageType: cancelButtonMsg,
		};
		commonMessageObject(messageObject);
	};

	const onClickApplyOuput = () => {
		const nodes = get(defaultOutputNode, 'NonMandatoryFields');
		const messageObject = {
			messageType: applyButtonMsg,
			selectedItem: searchArrangeFieldNodes,
			outputNodesExpand: nodes,
			outputNodesSearch: outputNodes,
		};
		commonMessageObject(messageObject);
	};

	const updateStateOnCloseCancel = () => {
		const nodes = [...outputNodes];
		const arrangeFieldData = [...arrangeFieldNodes];
		setArrangeFieldNodes(arrangeFieldData);
		setSearchArrangeFieldNodes(arrangeFieldData);
		updateLocaleData(arrangeFieldData);
		setOutPutNodes(nodes);
	};
	const processMessage = arg => {
		const messageFromDialog = JSON.parse(arg.message);
		const data = [messageFromDialog, applyButtonMsg];
		setIsDialogBox(false);
		setNodes(
			data,
			setArrangeFieldNodes,
			setSearchArrangeFieldNodes,
			updateLocaleData,
			setOutPutNodes,
			setSearchOutPutNodes,
			updateStateOnCloseCancel,
		);
		dialog.close();
	};
	const processDialogEvent = () => {
		setIsDialogBox(false);
		updateStateOnCloseCancel();
		dialog.close();
	};
	const openDialogBox = () => {
		const urlDialogBox = `${window.location.origin}/#/output-form/`;
		if (Office.context && Office.context.ui) {
			Office.context.ui.displayDialogAsync(
				urlDialogBox,
				{
					height: 99,
					width: 99,
					displayInIframe: true,
				},
				asyncResult => {
					dialog = asyncResult.value;
					dialog.addEventHandler(
						Office.EventType.DialogEventReceived,
						processDialogEvent,
					);
					dialog.addEventHandler(
						Office.EventType.DialogMessageReceived,
						processMessage,
					);
				},
			);
		}
	};
	const layoutView = hideSelectAll
		? 'ms-Grid-col ms-sm4 ms-md4 ms-lg4 search-box ms-smPush8 col-padding'
		: 'ms-Grid-col ms-sm6 ms-md6 ms-lg6 search-box ms-smPush6 col-padding';

	const expandOutputView = () => {
		setIsDialogBox(true);
		openDialogBox();
	};
	const handleCellChange = event => {
		onCellChange(event, setCellSheet);
	};
	return !isDialogBox ? (
		<LoadingOverlay
			active={isLoading}
			spinner={<Spinner size={SpinnerSize.large} />}
			text={<ThrobberMessage />}
		>
			<div className="ms-Grid out-put-container " dir="ltr">
				<Header
					title={OutputTexts.title}
					onClickGetData={onClickGetData}
					showGetDataButton={showGetDataBtn(selectedDataset)}
				/>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 search-box">
						<SearchBox
							placeholder={OutputTexts.searchBarPlaceholder}
							id="output-search"
							onChange={onSearchChange}
							onClear={clearSearchResult}
							value={searchBoxValue}
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm6 ms-md12 ms-lg12 ms-smPush8">
						<ActionButton
							onClick={expandOutputView}
							iconProps={addIcon}
							id="expand-button"
							className="fontStyle"
						>
							Expand
						</ActionButton>
					</div>
				</div>
				<PivotBasicTab
					fields={outputNodes}
					columns={columns}
					arrangeFields={arrangeFieldNodes}
					onSelect={onOutputNodeSelect}
					handleDragDropEvents={dragDropEvents}
					isArrangeField={searchBoxValue === ''}
					onLinkClick={onClickTab}
					onExpandClick={onExpandClick}
				/>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<Separator styles={styles} className="separator" />
					</div>
				</div>

				<RenderModal title="Edit Output Screen"  showButtonModal={true} >
					<p>
						{'You have changed the cell coordinate for populating the output header. Click "Yes" to change the cell coordinate as per the new selection. Click "No" to retain the old cell coordinates.'}
					</p>
					<DefaultButton
						id="no-btn"
						text={'No'}
						onClick={onNoCellChange}
						style={{ margin: '6px', float: 'left' }}
					/>
					<PrimaryButton
						id="yes-btn"
						text={'Yes'}
						onClick={onYesChangeCell}
						style={{ margin: '6px', float: 'left' }}
					/>
				</RenderModal>
				<ExcelCell
					messages={OutputTexts}
					isValidExcelField={isValidExcelField}
					handleChange={handleCellChange}
					cellValue={cellValue}
					sheetName={sheetName}
					isUsedCellAddress={isUsedCellAddress}
					isFirstRowSelected={isFirstRowSelected}
					isReadOnlyText={isReadOnlyText}
				/>
				<div className="ms-Grid-row">
					<Footer
						showBackButton
						backButtonText="Back"
						backButtonId="outputform-backbutton"
						onClickedBackButton={onCancelClick}
						onClickedNextButton={submitForm}
						showNextButton
						nextButtonId="outputform-nextbutton"
						nextButtonText="Next"
						serviceName={selectedServiceName}
						stepPage="2"
					/>
				</div>
				<div ref={messagesEndRef} />
			</div>
		</LoadingOverlay>
	) : (
		<div
			className="ms-Grid out-put-container output-expand-margin-bottom"
			dir="ltr"
		>
			<DialogBoxHeader
				outputMsg={OutputTexts}
				onSelectAllClick={onSelectAllClick}
				onClearSelection={onClearSelection}
				onDefaultSelection={() =>
					defaultSelection(
						outputNodeItems,
						setOutPutNodes,
						setSearchOutPutNodes,
						setArrangeFieldNodes,
						setSearchArrangeFieldNodes,
						updateLocaleData,
					)
				}
				hideSelectAll={hideSelectAll}
			/>
			<div className="ms-Grid-row">
				<div className={layoutView}>
					<SearchBox
						placeholder={OutputTexts.searchBarPlaceholder}
						id="output-search"
						onChange={onSearchChange}
						onClear={clearSearchResult}
						value={searchBoxValue}
						className="search-box-dialog"
					/>
				</div>
			</div>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 col-padding top-padding-pivot">
					<PivotBasicTab
						fields={outputNodes}
						columns={columns}
						arrangeFields={arrangeFieldNodes}
						onSelect={onOutputNodeSelect}
						handleDragDropEvents={dragDropEvents}
						isArrangeField={searchBoxValue === ''}
						onLinkClick={onClickTab}
						isExpand
						onExpandClick={onExpandClick}
					/>
				</div>
			</div>

			<div className="ms-Grid-row">
				<DialogBoxFooter
					outputMsg={OutputTexts}
					closeDailogBox={closeDailogBox}
					onClickApplyOuput={onClickApplyOuput}
				/>
			</div>
		</div>
	);
};
// export default OutputContainer;

const mapStateToProps = state => ({
	selectedServiceName: get(state, 'data.createNewDataSet.selectedService'),
	outputNodeItems: get(state, 'data.createNewDataSet.outputNodes'),
	selectedDataset: get(state, 'data.createNewDataSet.selectedDataset'),
	isGetData: get(state, 'data.getData.isGetDataClicked'),
	servicePath: get(state, 'data.createNewDataSet.servicePath'),
	isLoading: get(state, 'data.getData.loading'),
	profile: get(state, 'data.userInContext.user.profile'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			updateArrangeNode: updateArrangeNodeData,
			updateSelectedDatasetItems: updateSelectedDataset,
			getData: displaySettingAction,
			isDataRendered: onDataRendered,
			storeDataset: updateDatasetToCosmos,
			updateDatasetValue: updateDataset,
			// createNewDatasetValue,
			// redirectTo,
		},
		dispatch,
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutputContainer);
