import React, { useEffect } from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { InputContainer } from 'containers';
import { gtmTrackPage } from 'utils/GTM/GTMUtil';
import { gaPageTrackTags } from 'utils/GTM/GATags';

const InputPage = props => {
	useEffect(() => {
		gtmTrackPage(gaPageTrackTags.inputPage);
	}, []);
	return (
		<Fabric>
			<InputContainer {...props} />
		</Fabric>
	);
};

export default InputPage;
