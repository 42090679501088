import { get } from 'lodash';
import DatasetManagerConst from './DatasetManagerConst';

const { RUN_ALL_CLICK, SHOW_RUNALL_MSG } = DatasetManagerConst;

const initialState = {
	isRunAllClick: false,
	totalRunDataset: 0,
	showRunAllFailMsg: false,
};

export default function productReducer(state = initialState, action = {}) {
	const status = get(action, 'payload');
	switch (action.type) {
		case RUN_ALL_CLICK:
			return {
				...state,
				isRunAllClick: status,
			};
		case SHOW_RUNALL_MSG:
			return {
				...state,
				showRunAllFailMsg: status,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
