import React, { useEffect } from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { CreateNewDatasetContainer } from 'containers';
import { gtmTrackPage } from 'utils/GTM/GTMUtil';
import { gaPageTrackTags } from 'utils/GTM/GATags';
import { checkIsLoggedIn } from 'utils/CommonUtil';

const CreateNewDataset = props => {
	useEffect(() => {
		gtmTrackPage(gaPageTrackTags.createNewDatasetPage);
		checkIsLoggedIn(props);
	}, [props]);
	return (
		<Fabric>
			<CreateNewDatasetContainer {...props} />
		</Fabric>
	);
};

export default CreateNewDataset;
