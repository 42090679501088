import { get } from 'lodash';
import DisplaySettingsConst from './DisplaySettingsConst';

const {
	FETCH_GET_DATA_BEGIN,
	FETCH_GET_DATA_SUCCESS,
	FETCH_GET_DATA_FAILURE,
	NEW_GET_DATA_BEGIN,
} = DisplaySettingsConst;

const initialState = {
	isGetDataClicked: false,
	loading: false,
	error: null,
};

export default function productReducer(state = initialState, action = {}) {
	let errorMsg;
	const errMsg = get(action, 'payload.message');
	if(errMsg &&  Object.keys(errMsg).length > 1 && errMsg.errorMessage){
		errorMsg = errMsg.errorMessage;
	}else {
		errorMsg = errMsg;
	}
	const status = get(action, 'payload.status');
	switch (action.type) {
		case NEW_GET_DATA_BEGIN:
			return {
				...state,
				isGetDataClicked: false,
			};
		case FETCH_GET_DATA_BEGIN:
			// Mark the state as "loading" so we can show a spinner or something
			// Also, reset any errors. We're starting fresh.
			return {
				...state,
				loading: true,
				isGetDataClicked: true,
				error: null,
			};

		case FETCH_GET_DATA_SUCCESS:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				loading: false,
			};

		case FETCH_GET_DATA_FAILURE:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				loading: false,
				error: {
					message: errorMsg,
					status,
				},
			};

		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
