const FeedbackTexts = {
	feedbackTexts: {
		thankYou: 'Thank You',
		description: 'Thank you for taking the time to provide your feedback.',
		content: 'Your feedback is vital as we work to improve the experience.',
		feedbackHeading: 'Feedback',
		feedbackContent:
			'Please help us improve the application by providing feedback and suggestions on how we can improve your experience.',
		feedbackButton: 'Click Here',
	},
};

export default FeedbackTexts;
