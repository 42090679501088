import React from 'react';
import { Text } from 'office-ui-fabric-react';

const WelcomeDescription = props => {
	const { content } = props;
	return (
		<div>
			<div className="ms-Grid-col ms-sm12 block">
				<Text className="welcome-text" variant="mediumPlus" block>
					{content.description}
				</Text>
			</div>
			<div className="ms-Grid-col ms-sm12">
				<Text className="welcome-text" variant="mediumPlus" block>
					{content.subDescription}
				</Text>
			</div>
		</div>
	);
};

export default WelcomeDescription;
