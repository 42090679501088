import React from 'react';
import { Text, PrimaryButton } from 'office-ui-fabric-react';
import NtLogo from 'components/common/NtLogo';
import { connect } from 'react-redux';
import { Constants } from 'utils/Constants';
import { get } from 'lodash';
import { getGlobal } from '../../commands/commands';
import FeedbackTexts from './messages/FeedbackTexts';
import './Feedback.module.scss';

const Feedback = props => {
	const {
		feedbackHeading,
		feedbackContent,
		feedbackButton,
	} = FeedbackTexts.feedbackTexts;

	const onClickedFeedBackButton = () => {
		const personaId = get(props, 'profile.personaId');
		const { history } = props;
		const g = getGlobal();
		const { isSignedIn } = g.state;
		const url = `${Constants.feedbackUrl}&Parm1=${personaId}`;
		if (personaId && isSignedIn) {
			window.open(url, '_blank');
		}
		history.push('/thankyou');
	};
	return (
		<div className="feedback-container">
			<div className="ms-Grid">
				<NtLogo
					className="feedback-nt-logo"
					alttext="Stacked Centered Default Anchor Logo"
				/>
				<div className="ms-Grid-row feedback-heading">
					<div className="ms-Grid-col ms-sm12">{feedbackHeading}</div>
				</div>
				<div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12 feedback-description">
					<div className="ms-Grid-col ms-sm12 block">
						<Text
							className="feedback-text"
							variant="mediumPlus"
							block
						>
							{feedbackContent}
						</Text>

						<div className="feedback-btn">
							<PrimaryButton
								id="feedback-button-id"
								text={feedbackButton}
								onClick={onClickedFeedBackButton}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	profile: get(state, 'data.userInContext.user.profile'),
});

export default connect(mapStateToProps, undefined)(Feedback);
