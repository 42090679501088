import { get } from 'lodash';
import { isEmptyDateRange } from 'utils/inputFormUtil';

const defaultDisplayData = MandatoryFields => {
	return MandatoryFields.filter(item => {
		if (item.fieldType === 'RadioButton') {
			return item.defaultValue !== item.value;
		}
		return item.groupId === 'DisplayOption';
	});
};
const emptyValueItem = backWarningValidation => {
	return backWarningValidation.filter(item => {
		if (item.fieldType !== 'RadioButton') {
			const itemValue = get(item, 'value');
			if (item.fieldType === 'DateRange') {
				const itemDateRange = isEmptyDateRange(itemValue);
				if (itemDateRange) {
					return itemValue;
				}
			}
			if (
				itemValue &&
				itemValue.length !== 0 &&
				item.fieldType !== 'DateRange'
			) {
				return itemValue;
			}
		}
		return null;
	});
};

export { emptyValueItem, defaultDisplayData };
