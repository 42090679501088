const DatasetManagerTexts = {
	datasetTexts: {
		heading: 'Dataset Manager',
		subHeading: 'Dataset Global Settings',
		availableText: 'Available Datasets',
		noAvailableDatasetText:
			'There are currently no available datasets within the workbook.',
		clickBelowText: 'Click below to get started.',
		createNewButtonText: 'CREATE NEW DATA SET',
		runAll: 'Run All',
		noAsOfDateMsg: 'Edit dataset to change your custom periods.',
	},
	modalTexts: {
		btnYes: 'YES',
		btnNo: 'NO',
		title: 'Delete Dataset',
		description:
			'The <text> will be permanently deleted. Do you wish to continue?',
	},
	globalSettingTexts: {
		dropdownTxt: {
			label: 'Datasets',
			placeholder: 'Select datasets',
		},
		datePickerTxt: {
			label: 'As of date',
			placeholder: 'Select date',
		},
		buttonTxt: {
			text: 'Apply To Selection',
		},
	},
	actionMenu: [
		{
			key: 'GO_TO_SHEET',
			id: 'GO_TO_SHEET',
			name: 'Go to sheet',
		},
		{
			key: 'EDIT_DATASET',
			id: 'EDIT_DATASET',
			name: 'Edit Dataset',
		},
		{
			key: 'DUPLICATE_DATASET',
			id: 'DUPLICATE_DATASET',
			name: 'Duplicate Dataset',
		},
		{
			key: 'RENAME_DATASET',
			id: 'RENAME_DATASET',
			name: 'Rename Dataset',
		},
		{
			key: 'DELETE_DATASET',
			id: 'DELETE_DATASET',
			name: 'Delete Dataset',
		},
	],
	validationMsg: {
		notEmtpyErrorMsg: 'Please provide Dataset name',
		invalidErrorMsg: 'Special Characters not allowed',
		duplicateErrMsg: 'Dataset already exists with this name',
	},
};

export default DatasetManagerTexts;
