const WelcomeTexts = {
	welcomeTexts: {
		heading: 'Welcome to',
		subHeading: 'Data Direct',
		dataSetOr: 'OR',
		content: {
			description:
				'Data Direct is your window to Northern Trust data in Excel. To accommodate your analytical and reporting requirements the tool helps you extract your data from Northern Trust’s secure database.',
		},
	},
	createNewButtonText: 'CREATE NEW DATA SET',
	currentDatasetDropdown: {
		label: 'Select current workbook datasets',
		placeholder: 'No dataset available',
	},
	totalMessages: 'You have <text>',
	welcomeListMessage: [
		{
			name: 'Data Direct Tutorial',
			description:
				'Help with getting started. We created a tutorial to take you through the basics.',
			show: false,
			tooltipMessage: 'Under Development',
		},
		{
			name: 'Data Dictionary',
			description: 'Access a quick guide to improve your experience.',
			show: false,
		},
		{
			name: 'See the Latest Updates',
			description:
				"We've been improving Data Direct. Check out the latest updates here",
			show: true,
		},
	],
};

export default WelcomeTexts;
