import { createAction } from 'utils/helpers';
import { CreateNewDataConst } from 'containers/createNewDataset/CreateNewDatasetConst';

export const { UPDATE_INPUT_DATA } = CreateNewDataConst;
export function updateNodesInputData(nodeData) {
	return dispatch => {
		dispatch(createAction(UPDATE_INPUT_DATA, nodeData));
		return nodeData;
	};
}
