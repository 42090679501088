import React from 'react';
import { Pivot, PivotItem, initializeIcons } from 'office-ui-fabric-react';
import SelectFieldsPivot from './SelectFieldsPivot';
import ArrangeFieldsPivot from './ArrangeFieldsPivot';
import './PivotBasicTab.scss';

initializeIcons(undefined, { disableWarnings: true });
const styles = {
	text: {
		fontSize: '12px',
	},
};
const PivotBasicTab = props => {
	const {
		fields,
		arrangeFields,
		columns,
		onSelect,
		handleDragDropEvents,
		isArrangeField,
		onLinkClick,
		isExpand,
		onExpandClick,
	} = props;
	return (
		<Pivot
			className="pivot-container"
			aria-label="Basic Pivot Example"
			onLinkClick={onLinkClick}
			styles={styles}
		>
			<PivotItem
				headerText="Select Fields"
				id="select-field"
				className="pivot-item"
			>
				<SelectFieldsPivot
					fields={fields}
					onSelect={onSelect}
					isExpand={isExpand}
					onExpandClick={onExpandClick}
				/>
			</PivotItem>
			<PivotItem
				headerText="Arrange Fields"
				id="arrange-field"
				style={{ height: isExpand ? window.innerHeight - 214 : 'auto' }}
			>
				<ArrangeFieldsPivot
					fields={arrangeFields}
					columns={columns}
					handleDragDropEvents={handleDragDropEvents}
					isArrangeField={isArrangeField}
				/>
			</PivotItem>
		</Pivot>
	);
};

export default PivotBasicTab;
