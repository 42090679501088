import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { routerReducer } from 'react-router-redux';
import SettingFormReducer from 'containers/createNewDataset/SettingFormReducer';
import CreateNewDatasetReducer from 'containers/createNewDataset/CreateNewDatasetReducer';
import DisplaySettingsReducer from 'containers/displaySettings/DisplaySettingsReducer';
import WelcomeReducer from 'containers/welcome/WelcomeReducer';
import PerformancReducer from 'containers/performance/PerformancReducer';
import LoginReducer from 'containers/login/LoginReducer';
import DatasetManagerReducer from 'containers/datasetManager/DatasetManagerReducer';

// Import all of your reducers here:
const dataReducers = combineReducers({
	mvsData: SettingFormReducer,
	createNewDataSet: CreateNewDatasetReducer,
	getData: DisplaySettingsReducer,
	dataSet: WelcomeReducer,
	performance: PerformancReducer,
	userInContext: LoginReducer,
	dataSetManager: DatasetManagerReducer,
});

/* Persist state config */
const dataPersistConfig = {
	key: 'data',
	storage,
	whitelist: ['createNewDataSet', 'performance', 'userInContext', 'dataSet'],
	stateReconciler: autoMergeLevel2,
};
// Apply all of the reducers here.
// routing: routerReducer,
// ui: uiReducer,
const rootReducer = combineReducers({
	routing: routerReducer,
	data: persistReducer(dataPersistConfig, dataReducers),
});

export default rootReducer;
