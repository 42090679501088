import CreateNewDataset from './createNewDatasetPage';
import LandingPage from './landingPage';
import OutPutPage from './outPutPage';
import DisplaySettingsPage from './displaySettingsPage';
import DatasetManagerPage from './datasetManagerPage';
import FeedBack from './feedBack';
import LoginPage from './loginPage';
import AuthPage from './authPage';
import InputPage from './inputPage';

import LogoutPage from './logoutPage';
import LogoutSuccessPage from './logoutSuccessPage';
import ThankYouPage from './thankYouPage';

export default [
	CreateNewDataset,
	LandingPage,
	OutPutPage,
	DisplaySettingsPage,
	DatasetManagerPage,
	FeedBack,
	LoginPage,
	AuthPage,
	InputPage,
	LogoutPage,
	LogoutSuccessPage,
	ThankYouPage,
];
