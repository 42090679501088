import React from 'react';
import DetailsListDragDrop from 'components/common/list/DetailsListDragDrop';

const ArrangeFieldsPivot = props => {
	const { fields, columns, handleDragDropEvents, isArrangeField } = props;
	return (
		<>
			<DetailsListDragDrop
				items={fields}
				columns={columns}
				handleDragDropEvents={handleDragDropEvents}
				isArrangeField={isArrangeField}
			/>
		</>
	);
};

export default ArrangeFieldsPivot;
