import { get, find, filter, isEmpty, forEach, map } from 'lodash';
import {
	generateUniqueIdData,
	setInputDataDisplay,
	setOutputDataDisplay,
	updateInputDatasetNode,
	renderInputOuputExcelData,
	clearAndPopulateDatasetTitle,
	serviceNameByPath,
	updateUniqueId,
} from 'utils/GetDataUtil';
import { getRangeToCleare } from 'utils/datasetUtil';
import { formatDate } from 'utils/dateUtil';
import { validateReportTitle } from 'utils/validationRules';
import {
	getDatasetObject,
	createHiddenSheet,
	ExcelGetData,
	clearExcelData,
} from 'utils/excelUtils/ExcelDataPopulate';
import { createAction } from 'utils/helpers';
import DatasetManagerConst from './DatasetManagerConst';

const { RUN_ALL_CLICK, SHOW_RUNALL_MSG } = DatasetManagerConst;
export const updateDataset = datasetRange => (dispatch, getState) => {
	const state = getState();
	const { data } = state;
	const profile = get(data, 'userInContext.user.profile');
	const { uuId, uniqueId, fullDateFormat } = generateUniqueIdData(
		profile.personaId,
	);
	const existingDataSet = get(data, 'dataSet.data');
	const {
		inputNodes,
		selectedDataset,
		servicePath,
		selectedServiceData,
	} = get(data, 'createNewDataSet');
	const reportTitle = get(selectedDataset, 'reportName');
	const displaySettingObject = get(selectedDataset, 'displaySetting');
	const outputArrangeNodes = get(selectedDataset, 'outputNodes');
	const selectedServiceId = get(selectedDataset, 'reportId');
	const serviceData = selectedServiceData;
	const parameterObj = {
		inputNodes,
		outputArrangeNodes,
		uniqueId,
		uuId,
		fullDateFormat,
		reportTitle,
		displaySettingObject,
		servicePath,
		serviceData,
		existingDataSet,
		datasetRange,
		selectedServiceId,
	};
	// createHiddenSheet(uniqueId);
	return getDatasetObject(parameterObj);
};
export const updateCellOnDuplicate = data => {
	const inputNodes = get(data, 'inputNodes');
	const outputNodes = get(data, 'outputNodes');
	inputNodes.inputCellNo = '';
	inputNodes.inputCellValueRemove = '';
	inputNodes.inputSheetName = '';
	outputNodes.outputCellNo = '';
	outputNodes.outputSheetName = '';
	outputNodes.endOutputCellValue = '';
};

export const manipulateDataset = (reports, newAsOfDateCal, id) => {
	return filter(reports, itemList => {
		if (itemList.reportId === id || itemList.reportName === id) {
			itemList.lastRefreshTime = new Date();
			const { inputNodes } = itemList;
			const { mandatoryFields } = inputNodes;
			filter(mandatoryFields, item => {
				if (item.groupId === 'ReferenceDate') {
					item.value = new Date(newAsOfDateCal);
					item.smartDateKey = null;
				}
			});
		}
		return reports;
	});
};
export const datasetObjIDUpdate = (datasetObject, id, newAsOfDateCal) => {
	return datasetObject.map(item => {
		if (item.key === id && newAsOfDateCal) {
			item.asOfDate = new Date(newAsOfDateCal);
			item.lastRefreshTime = new Date();
		}
		return item;
	});
};
export const isDatasetLoaded = (firstRender, datasetObject) => {
	return !firstRender.current ? datasetObject : null;
};
export const eventDateFormat = event => {
	return event ? formatDate(event, 'mm/dd/yyyy') : null;
};
export const selectedDatasetObjVal = (datasetObject, option) => {
	return datasetObject.map(item => {
		if (item.key === option.key) {
			item.selected = option.selected;
		}
		return item;
	});
};
const updateDatasetTitle = (item, renamedatasetValue) => {
	const displaySettingObject = get(item, 'displaySetting.displaySetting');
	displaySettingObject.map(itemTitle => {
		if (itemTitle.id === 'dataset-title') {
			itemTitle.value = renamedatasetValue;
		}
		return itemTitle;
	});
};
export const updateDataSetValMethod = (
	dataSet,
	renamedataset,
	renamedatasetValue,
	setIsValidReportTitle,
	validationMsg,
) => {
	const { reports } = dataSet;
	let id;
	filter(reports, item => {
		if (item.reportName === renamedataset) {
			id = item.reportId;
		}
		return null;
	});
	const isValidTitle = validateReportTitle(
		renamedatasetValue,
		validationMsg,
		dataSet,
		id,
	);
	setIsValidReportTitle({
		valid: isValidTitle === '',
		errorMessage: isValidTitle,
	});
	if (isValidTitle !== '') {
		return null;
	}
	return filter(reports, item => {
		if (item.reportName === renamedataset) {
			item.reportName = renamedatasetValue;
			item.lastRefreshTime = new Date();
			updateDatasetTitle(item, renamedatasetValue);
		}
		return item;
	});
};
export const datasetObjUpdate = (datasetObject, reports, asOfDate) => {
	let newReport;
	const datasetUpdateObject = datasetObject.map(item => {
		if (asOfDate && item.selected && validateFutureDate(asOfDate)) {
			newReport = manipulateDataset(reports, asOfDate, item.key);
			item.asOfDate = new Date(asOfDate);
			item.lastRefreshTime = new Date();
		}
		return item;
	});
	return { datasetUpdateObject, newReport };
};

export const validateFutureDate = (GivenDate) => {
	var CurrentDate = new Date();
	GivenDate = new Date(GivenDate);
	return GivenDate < CurrentDate;
};

export const updateDatasetVal = (datasetUpdateObject, selectedSet) => {
	filter(datasetUpdateObject, item => {
		if (item.selected) {
			selectedSet.push(item.key);
		}
		return item.selected;
	});
};
export const valueAsofDate = inputNodes => {
	const inputData = find(inputNodes, item => {
		return item.groupId === 'ReferenceDate';
	});
	return inputData && inputData.value ? inputData.value : null;
};
const getSelectedDataset = (reports, id) => {
	return find(reports, item => {
		if (item.reportId === id || item.reportName === id) {
			item.lastRefreshTime = new Date();
			return item;
		}
		return null;
	});
};

const filterSelectedDataset = (dataset, datasetObject, setDatasetObject) => {
	const filteredDataset = filter(datasetObject, item => item.selected);
	forEach(filteredDataset, itm => {
		map(dataset, newItem => {
			if (itm.key === newItem.key && itm.selected) {
				newItem.selected = itm.selected;
			}
			return newItem;
		});
	});
	setDatasetObject(dataset);
};
export const createDatasetObject = (data, datasetObject, setDatasetObject) => {
	const dropDownObj = [];
	const reports = get(data, 'reports');
	if (!isEmpty(reports)) {
		reports.map(listItem => {
			const inputNodes = get(listItem, 'inputNodes.mandatoryFields');
			const newAsOfDate = valueAsofDate(inputNodes);
			const serviceName = get(listItem, 'serviceData.servicename');
			dropDownObj.push({
				id: listItem.reportId,
				key: listItem.reportName,
				text: listItem.reportName,
				serviceSelect: serviceName,
				lastRefreshTime: listItem.lastRefreshTime,
				asOfDate: newAsOfDate,
				inputDataNode: listItem.inputNodes,
				reportRange: getRangeToCleare(listItem),
				dataSetOrder: listItem.dataSetOrder,
				disabled: !newAsOfDate,
				isRunAllFail: listItem.isRunAllFail,
			});
			return dropDownObj;
		});
		dropDownObj.sort((prev, next) => {
			return prev.dataSetOrder - next.dataSetOrder;
		});
		filterSelectedDataset(dropDownObj, datasetObject, setDatasetObject);
	}
	// return dropDownObj;
};
export const onOptionsSelect = (
	option,
	datasetObject,
	setSelectedKeys,
	setDatasetObject,
) => {
	const selectedSet = [];
	if (!isEmpty(datasetObject)) {
		const datasetUpdateObject = selectedDatasetObjVal(
			datasetObject,
			option,
		);
		updateDatasetVal(datasetUpdateObject, selectedSet);
		setSelectedKeys(selectedSet);
		setDatasetObject(datasetUpdateObject);
	}
};
export const onAllClick = (
	dataSet,
	datasetObject,
	asOfDate,
	updateAsOfDate,
	setDatasetObject,
) => {
	const { reports } = dataSet;
	const { datasetUpdateObject, newReport } = datasetObjUpdate(
		datasetObject,
		reports,
		asOfDate,
	);
	if (newReport) {
		dataSet.reports = newReport;
		updateAsOfDate(dataSet);
		setDatasetObject(datasetUpdateObject);
	}
};
export const cellUpdateOnDuplicate = (duplicateDataset, newData) => {
	if (duplicateDataset) {
		updateCellOnDuplicate(newData);
	}
};

export const getDatasetRange = (datasetRange, id) => (dispatch, getState) => {
	const state = getState();
	const { data } = state;
	const profile = get(data, 'userInContext.user.profile');
	const reports = get(data, 'dataSet.data.reports');
	const { uuId, uniqueId, fullDateFormat } = generateUniqueIdData(
		profile.personaId,
	);
	const existingDataSet = get(data, 'dataSet.data');
	const selectedDataset = getSelectedDataset(reports, id);
	const { inputNodes, serviceData } = selectedDataset;
	const reportTitle = get(selectedDataset, 'reportName');
	const displaySettingObject = get(selectedDataset, 'displaySetting');
	const outputArrangeNodes = get(selectedDataset, 'outputNodes');
	const servicePath = get(selectedDataset, 'service.endpoint');
	const selectedServiceId = get(selectedDataset, 'reportId');
	const { inputCellNo, inputSheetName } = inputNodes;
	inputNodes.dataSheetDetail = `${inputCellNo}!${inputSheetName}`;
	const parameterObj = {
		inputNodes,
		outputArrangeNodes,
		uniqueId,
		uuId,
		fullDateFormat,
		reportTitle,
		displaySettingObject,
		servicePath,
		serviceData,
		existingDataSet,
		datasetRange,
		selectedServiceId,
	};
	// createHiddenSheet(uniqueId);
	return getDatasetObject(parameterObj);
};

export const getData = (id, onGetData, isGetDataRendered) => (
	dispatch,
	getState,
) => {
	const state = getState();
	const profile = get(state, 'data.userInContext.user.profile');
	const reports = get(state, 'data.dataSet.data.reports');
	const selectedDataset = getSelectedDataset(reports, id);
	const path = get(selectedDataset, 'service.endpoint');
	let service = get(selectedDataset, 'serviceData.servicename');
	const serviceMetaDataPath = get(
		selectedDataset,
		'serviceData.serviceMetaDataPath',
	);
	if (serviceMetaDataPath) {
		service = serviceNameByPath(serviceMetaDataPath);
	}
	const datasetParam = { ...selectedDataset, profile };
	const selectedServiceName = get(selectedDataset, 'serviceData.servicename');
	const dataset = { ...selectedDataset, selectedServiceName };
	clearAndPopulateDatasetTitle(datasetParam);
	renderInputOuputExcelData(true, dataset);
	const { inputNodes, outputNodes } = selectedDataset;
	const inputData = updateInputDatasetNode(inputNodes);
	const outputData = get(selectedDataset, 'outputNodes.nonMandatoryFields');
	const dataRangeToClear = get(selectedDataset, 'datasetRange');
	const refreshCellId =	datasetParam.displaySetting.cellId;
	const inputOutputDataRequest = setInputDataDisplay(
		outputData,
		service,
		inputData,
		profile,
	);
	const outputDataList = setOutputDataDisplay(outputData);
	const { outputCellNo, outputSheetName, endOutputCellValue } = outputNodes;
	const excelDataOutput = {
		outputCellNo,
		endOutputCellValue,
		outputSheetName,
	};
	updateUniqueId();
	if (dataRangeToClear) {
		const range = dataRangeToClear.split('!');
		clearExcelData(range[0], range[1]);
	}

	return onGetData(inputOutputDataRequest, path)
		.then(response => {
			ExcelGetData(
				response,
				outputDataList,
				isGetDataRendered,
				excelDataOutput,
				id,
				refreshCellId,
			);
			return { success: true };
		})
		.catch(error => {
			return { success: false, error };
		});
};
export const runAllClick = isRunAllCLick => {
	return dispatch => {
		dispatch(createAction(RUN_ALL_CLICK, isRunAllCLick));
	};
};
export const runAllFailMsg = value => {
	return dispatch => {
		dispatch(createAction(SHOW_RUNALL_MSG, value));
	};
};
export const udpateAsofDateDataset = data => dispatch => {
	dispatch(createAction('UPDATE_AS_OF_DATE', data));
};
