import { getGlobal } from '../commands/commands';

export const commonObjectKey = {
	timePeriod: 'TimePeriod',
	referenceDate: 'ReferenceDate',
	calendarMonthTimePeriod: 'CalendarMonthTimePeriods',
	PerformanceIdentifier: 'PerformanceIdentifier',
	IndexPerformanceObject: 'IndexPerformanceObject',
	timePeriodSingle: 'TimePeriodSingle',
	CTP: {
		startDate: 'CTP.StartDate',
		endDate: 'CTP.EndDate',
		ctp1: 'CTP1',
		ctp2: 'CTP2',
	},
};
export const updateLocalStorage = (key, data) => {
	try {
		const serializedState = JSON.stringify(data);
		window.localStorage.setItem(key, serializedState);
	} catch {
		// ignore write errors
	}
};

export const getLocalStorageItem = key => {
	try {
		const serializedState = window.localStorage.getItem(key);
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const updateSessionStorage = (key, data) => {
	window.sessionStorage.setItem(key, JSON.stringify(data));
};

export const getSessionStorageItem = key =>
	JSON.parse(window.sessionStorage.getItem(key));

export const uniqueUUId = pattern => {
	return pattern.replace(/[xy]/g, changeValue => {
		const newValue = Math.random() * 16;
		// eslint-disable-next-line no-bitwise
		const randomValue = newValue | 0;
		const uniqueValue =
			// eslint-disable-next-line no-bitwise
			changeValue === 'x' ? randomValue : (randomValue && 0x3) | 0x8;
		return uniqueValue.toString(16);
	});
};
// Function to get Next Letter
const nextLetter = incrementValue => {
	if (incrementValue < 90) {
		return String.fromCharCode(incrementValue + 1);
	}
	return 'A';
};

// Function to check Same characters
const isSameCharacters = (str, char) => {
	let characterLenght = str.length;
	while (characterLenght > 0) {
		if (str[characterLenght] !== char) {
			return false;
		}
		characterLenght -= 1;
	}
	return true;
};
// Check next character function
export const nextChar = characterValue => {
	const newCharacter = characterValue.toUpperCase();
	let valueString = '';
	let valueStringSlice = '';
	let txt = '';
	let characterLenght = newCharacter.length;
	if (isSameCharacters(newCharacter, 'Z')) {
		while (characterLenght > 0) {
			txt += 'A';
			characterLenght -= 1;
		}
		return `${txt}A`;
	}

	if (newCharacter.length > 1) {
		valueString = newCharacter.substring(0, newCharacter.length - 1);
		valueStringSlice = String.fromCharCode(
			valueString.slice(-1).charCodeAt(0),
		);
	}
	const firstCharacter = newCharacter.slice(-1).charCodeAt(0);
	const lastCharacter = nextLetter(firstCharacter);
	if (lastCharacter === 'A') {
		return (
			valueString.slice(0, -1) +
			nextLetter(valueStringSlice.slice(-1).charCodeAt(0)) +
			lastCharacter
		);
	}
	return valueString + lastCharacter;
};
export const fieldExpandView = (field, labelColor, labelExpand) => {
	if (field.expand) {
		return labelColor;
	}
	return labelExpand;
};
export const onGetDataClear = () => {
	window.sessionStorage.removeItem('removeSheetName');
	window.sessionStorage.removeItem('startOutputCellValue');
	window.sessionStorage.removeItem('startOutputCellValue');
	window.sessionStorage.removeItem('removeOutputSheetName');
	window.sessionStorage.removeItem('startCellValue');
	window.sessionStorage.removeItem('endOutputCellValue');
	window.sessionStorage.removeItem('inputCellValueRemove');
	window.sessionStorage.removeItem('getDataCellValue');
	//XDE-5631
	window.sessionStorage.removeItem('getDataCellValue_old');
	window.sessionStorage.removeItem('startCellValue_old');
	window.sessionStorage.removeItem('startOutputCellValue_old');
	window.sessionStorage.removeItem('outputCellValue_old');
	window.sessionStorage.removeItem('isDisplayDataCellChanged');
	window.sessionStorage.removeItem('noBtnClickFlag');
	window.sessionStorage.removeItem('sessionCellValue_Old');
	window.sessionStorage.removeItem('isInputCellNotChanged');
	window.sessionStorage.removeItem('outputSheetName_Old');
	window.sessionStorage.removeItem('getDataSheetName_Old');
	window.sessionStorage.removeItem('inputSheet_Change');
	window.sessionStorage.removeItem('outputSheet_Change');
	window.sessionStorage.removeItem('displaySheet_Change');
	window.sessionStorage.removeItem('inputSheetName_Old');
};

export const removeStorage = isGetDataClicked => {
	window.localStorage.removeItem('inputData');
	window.localStorage.removeItem('outputData');
	window.localStorage.removeItem('uniqueUUId');
	window.localStorage.removeItem('outputDataRange');
	window.sessionStorage.removeItem('inputCellValue');
	window.sessionStorage.removeItem('inputSheetName');
	window.sessionStorage.removeItem('outputCellValue');
	window.sessionStorage.removeItem('outputSheetName');
	window.localStorage.removeItem('showDialogBox');
	window.localStorage.removeItem('isClickClear');
	//XDE-5631
	window.sessionStorage.removeItem('getDataCellValue_old');
	window.sessionStorage.removeItem('startCellValue_old');
	window.sessionStorage.removeItem('startOutputCellValue_old');
	window.sessionStorage.removeItem('outputCellValue_old');
	window.sessionStorage.removeItem('isDisplayDataCellChanged');
	window.sessionStorage.removeItem('sessionCellValue_Old');
	window.sessionStorage.removeItem('noBtnClickFlag');
	window.sessionStorage.removeItem('isInputCellNotChanged');
	window.sessionStorage.removeItem('outputSheetName_Old');
	window.sessionStorage.removeItem('getDataSheetName_Old');
	window.sessionStorage.removeItem('inputSheet_Change');
	window.sessionStorage.removeItem('outputSheet_Change');
	window.sessionStorage.removeItem('displaySheet_Change');
	window.sessionStorage.removeItem('inputSheetName_Old');
	if (isGetDataClicked) {
		onGetDataClear();
	}
};

export const firstLetterCaps = myStr => {
	return myStr
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
};

export const replaceStr = (message, replaceVal) => {
	const myRegExp = new RegExp('<text>');
	return message.replace(myRegExp, replaceVal);
};

export const showGetDataBtn = dataset => {
	return !!dataset && !dataset.duplicateClicked;
};

export const scrollToBottom = messagesEndRef => {
	messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
};

export const checkIsLoggedIn = properties => {
	const g = getGlobal();
	const { isSignedIn } = g.state;
	if (!isSignedIn) {
		const { history } = properties;
		history.push('/login');
	}
};
