import React, { memo } from 'react';
import {
	PrimaryButton,
	DefaultButton,
	Stack,
	Text,
} from 'office-ui-fabric-react';
import FooterTexts from './FooterTexts';
import './footer.scss';
import { INDECESERVICENAME } from 'utils/Constants';

const stackTokens = { childrenGap: 0 };
const Footer = props => {
	const {
		onClickedBackButton,
		onClickedIndexSearchButton,
		showBackButton,
		backButtonId,
		indexSearchButtonId,
		backButtonText,
		showNextButton,
		showSearchButton,
		nextButtonId,
		nextButtonType,
		nextButtonText,
		onClickedNextButton,
		backWarning,
		backWarningMsg,
		serviceName,
		stepPage,
		isButtonDisable,
	} = props;
	const pushCss = showNextButton ? '' : 'ms-smPush5';
	const { backWarningMessage, backText, nextText } = FooterTexts;
	const flag = INDECESERVICENAME.includes(serviceName) && showSearchButton ? true : false;
	const checkflage=false;
	return (
		<div className="footer-container">
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 footer-fixed rectangle">
				{showNextButton && (
					<div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
						<Text variant="smallPlus" className="service-name">
							{serviceName}
						</Text>
						<div>
							<Text variant="medium" className="step-page">
								Step {stepPage} of 3
							</Text>
						</div>
					</div>
				)}
				<div className={`ms-Grid-col ms-sm7 ms-md7 ms-lg7  ${pushCss}`}>
					<Stack
						horizontal
						tokens={stackTokens}
						horizontalAlign="end"
					>
					{//flag && (
					checkflage && (
						<DefaultButton
							id={indexSearchButtonId}
							text={
								'Index Search'
							}
							onClick={onClickedIndexSearchButton}
						/>
					)}
						{showBackButton && (
							<DefaultButton
								id={backButtonId}
								text={
									!backButtonText ? backText : backButtonText
								}
								onClick={onClickedBackButton}
								//disabled={isButtonDisable}
							/>
						)}

						{showNextButton && (
							<PrimaryButton
								id={nextButtonId}
								type={nextButtonType}
								text={
									!nextButtonText ? nextText : nextButtonText
								}
								onClick={onClickedNextButton}
							/>
						)}
					</Stack>
					{backWarning && (
						<div className="warningClass">
							{backWarningMsg || backWarningMessage}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(Footer);
