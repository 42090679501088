import { Api } from 'api/Api';
import {
	getInputNodeData,
	updateValues,
	updateInputNodesData,
	getSelectedServiceName,
	updateResWithRadioBtn,
	checkIsPerformanceId,
	checkMultiselect,
	updateDD,
	templateData,
	manipulateAccountGroupData,
	manipulateIndexSearchData,
	getElementId,
	getMultiselectedValue,
	updateElementId,
	updateShowCTP,
	manipulateUniverseSearchData,
	manipulateCompositeNameSearchData,
	manipulateCompositeIDSearchData
} from 'utils/inputFormUtil';
import { getSessionStorageItem, updateSessionStorage } from 'utils/CommonUtil';

import {
	mapSelectedDataSetToInput,
	isFreqeuncyOrientation,
} from 'utils/datasetUtil';
import { filter, find, get, isEmpty, map, trim } from 'lodash';
import { createAction } from 'utils/helpers';
import {
	getSmartDate,
	getSmartDateData,
	updateNodeDateChange,
} from 'utils/dateUtil';
import {
	validateFieldValues,
	validateCellIdSheetName,
	validateDateRange,
	validateDateDiffError,
	validateTemplateField,
	validateFutureDate,
} from 'utils/validationRules';
import {
	updateCellValueIntoExcel,
	onClickGetDataBtn,
	updateDatasetCellAddress,
	updateUniqueId,
	isCTPSelected,
	isTimePeriodExist,
	isSingleTimePeriodExist,
} from 'utils/GetDataUtil';
import {
	onInputCellChange,
	moveToCellSheet,
} from 'utils/excelUtils/ExcelDataPopulate';
import { PERFORMANCE_COMMON } from 'utils/Constants';
import { PERFORMANCEFORM_CONST, INDEX_DROPDOWN_LOADING_NA, UNIVERSE_DROPDOWN_LOADING_NA, COMPOSITE_DROPDOWN_LOADING_NA } from './PerformanceConst';

const {
	CTR_SERVICE_NAME,
	INDICES_SERVICE_NAME,
	INDICES_FCTP_SERVICE_NAME,
	INDICES_FCTP
} = PERFORMANCE_COMMON;

const checkPerseverFlow = state => {
	const performance = get(state, 'data.performance');
	const mandatoryFields = get(performance, 'inputNodes.mandatoryFields');
	const optionalFields = get(performance, 'inputNodes.optionalFields');
	return !isEmpty(mandatoryFields) || !isEmpty(optionalFields);
};
// method creates service name query param to pass metadata service on radio button change.
const getQueryParam = (id, fields) => {
	const { ORIENTATION, FREQUENCY } = PERFORMANCE_COMMON;
	let preSelectedId = ORIENTATION.groupId;
	let param = '';
	const value = trim(id.value);
	if (id.elementId === ORIENTATION.groupId) {
		preSelectedId = FREQUENCY.groupId;
	}
	const preSelectedRd = find(fields, item => {
		if (item.groupId === id.elementId) {
			item.value = value;
		}
		return item.groupId === preSelectedId;
	});
	const preSelectedValue = preSelectedRd ? trim(preSelectedRd.value) : '';
	if (preSelectedId === FREQUENCY.groupId) {
		param = preSelectedValue ? `${preSelectedValue} ${value}` : value;
	} else {
		param = preSelectedValue ? `${value} ${preSelectedValue} ` : value;
	}
	return param;
};
const getNodes = (fields, mandatory, serviceName) => {
	const { REFRENCE_DATE, TEMPLATE, TIME_PERIOD, TIME_PERIOD_Single } = PERFORMANCEFORM_CONST;
	const isTemplate = checkIsPerformanceId(fields, TEMPLATE);
	fields.map(item => {
		if (item.colId === REFRENCE_DATE) {
			item.defaultOption = item.datasetValue
				? item.smartDateKey
				: 'last_month';
		}
		if (checkMultiselect(item.groupId) && !isTemplate) {
			item.isMultiSelect = true;
		}
		if (isTemplate && item.groupId === TIME_PERIOD) {
			item.isMultiSelect = true;
		}
		if (item.groupId === 'CTP1') {
			item.showCTPLable = true;
		}

		return null;
	});
	return fields ? getInputNodeData(fields, mandatory, serviceName) : [];
};

// method called in case of first time service selected
const initiateNewFlow = (inputNodes, serviceName, dispatch, getState) => {
	const { UNIVERSES, UNIVERSES_ACCOUNT_PAR, COMPOSITE_REPORTING, COMPOSITE_CTP, COMPOSITE_NAME } = PERFORMANCEFORM_CONST;
	const state = getState();
	const data = get(state, 'data.createNewDataSet');
	const userId = get(state, 'data.userInContext.user.profile.userId');
	const { MandatoryFields, NonMandatoryFields } = inputNodes;
	const mandatoryObj = getNodes(MandatoryFields, true, serviceName);
	let optionObj = getNodes(NonMandatoryFields, false, serviceName);
	const isTimePeriodPresent = isTimePeriodExist(mandatoryObj);
	if (isTimePeriodPresent) {
		const isCTP = isCTPSelected(mandatoryObj);
		const ctpLength = isCTP.length > 0;
		optionObj = updateShowCTP(optionObj, ctpLength);
	}

	const isSingleTimePeriodPresent = isSingleTimePeriodExist(mandatoryObj);
	if (isSingleTimePeriodPresent) {
		const isCTP = isCTPSelected(mandatoryObj);
		optionObj = updateShowCTP(optionObj, (isCTP ? true : false));
	}
	if (CTR_SERVICE_NAME === serviceName) {
		const frequencyValue = optionObj.filter(item => item.groupId === 'InputFrequency')[0]?.value;
		updateCTRMandatory(optionObj, frequencyValue);
	}

	if (INDICES_SERVICE_NAME === serviceName || INDICES_FCTP_SERVICE_NAME === serviceName || INDICES_FCTP === serviceName) {
		//getIndexSearchList(serviceName, mandatoryObj, inputNodes, userId, true, dispatch, true);
	}

	if (UNIVERSES === serviceName || UNIVERSES_ACCOUNT_PAR === serviceName) {
		retrieveUniverseName(serviceName, mandatoryObj, inputNodes, userId, dispatch, true);
	}

	if (COMPOSITE_REPORTING === serviceName || COMPOSITE_CTP === serviceName) {
		retrieveCompositeName(serviceName, mandatoryObj, inputNodes, userId, dispatch, true);
	}

	if (COMPOSITE_REPORTING === serviceName || COMPOSITE_CTP === serviceName) {
		const compositeId = mandatoryObj.filter(item => item.groupId === COMPOSITE_NAME)[0]?.value?.key;
		if(compositeId){
		retrieveCompositeID(serviceName, mandatoryObj, inputNodes, userId, dispatch, true);
	}
}
	return {
		mandatoryFields: mandatoryObj,
		optionalFields: optionObj,
	};
};

const updateSelectedDatasetValue = (
	selectedDataset,
	mandatoryFields,
	optionalFields,
) => {
	if (selectedDataset) {
		const datasetMandatoryFields = get(selectedDataset, 'inputNodes');
		datasetMandatoryFields.mandatoryFields = mandatoryFields;
		datasetMandatoryFields.nonMandatoryFields = optionalFields;
	}
};
const onDateChange = (mandatoryFields, optionalFields, newDate) => {
	let referenceDate = '';
	let isCTPPresent = false;
	find(mandatoryFields, item => {
		if (item.groupId === 'ReferenceDate') {
			referenceDate = item.value;
		}
		if (item.groupId === 'TimePeriod' || item.groupId === 'TimePeriodSingle' ) {
			isCTPPresent = true;
		}
	});
	find(optionalFields, item => {
		if (item.groupId === 'CTP1' && isCTPPresent) {
			// this.onChangeEndDate(date);
			item.valid = {
				...item.valid,
				endDate: true,
				endDateErrorMsg: '',
			};
			item.value = {
				...item.value,
				endDate: referenceDate || new Date(newDate),
			};
			item.referenceDate = referenceDate;
			item.showCTP = true;
		}
		return item.groupId === 'CTP1';
	});
};
const updateNodeValues = (event, id, option) => (dispatch, getState) => {
	const state = getState();
	const { data } = state;
	const { selectedDataset } = get(state, 'data.createNewDataSet');
	const { inputNodes } = data.performance;
	let { mandatoryFields, optionalFields } = inputNodes;
	let selectedSmartDateKey = null;
	const checkMandatory = filter(mandatoryFields, item => item.groupId === id);
	const value = get(event, 'target.value');
	if (id === 'smart-search' || id === 'ReferenceDate') {
		selectedSmartDateKey =
			option && option.key !== 'none' ? option.key : null;
		mandatoryFields = getSmartDateData(
			mandatoryFields,
			event,
			selectedSmartDateKey,
		);
	} else if (!isEmpty(checkMandatory)) {
		mandatoryFields = updateValues(mandatoryFields, value, id);
	} else {
		optionalFields = updateValues(optionalFields, value, id);
	}
	const isCTPPresent = isCTPSelected(mandatoryFields);
	if (!isEmpty(isCTPPresent)) {
		onDateChange(mandatoryFields, optionalFields, event);
	}
	const inputData = {
		mandatoryFields,
		optionalFields,
		isValueUpdated: true,
	};
	updateSelectedDatasetValue(
		selectedDataset,
		mandatoryFields,
		optionalFields,
	);
	dispatch(createAction('UPDATE_VALUES', inputData));
};
const populateAccountGroupData = (
	data,
	inputNodes,
	masterTrustChange,
	dispatch,
	groupId,
) => {
	const { mandatoryFields, optionalFields } = inputNodes;
	let isValueUpdated = false;
	find(mandatoryFields, item => {
		if (data && item.groupId === data.groupId) {
			const newDataDrp = find(data.dropDowns, itemData => {
				return itemData.value === get(item, 'value.value');
			});
			const defaultValue = masterTrustChange ? [] : item.value;
			if (Array.isArray(item.value)) {
				item.value = masterTrustChange
					? data.dropDowns[0]
					: get(item, 'value');
			} else {
				item.value = newDataDrp || data.dropDowns[0];
			}
			item.dropDowns = data.dropDowns;
			item.valid = true;
			item.errorMsg = '';
			isValueUpdated = defaultValue !== '';
		}
		return data && item.groupId === data.groupId;
	});

	const isSingleTimePeriodPresent = isSingleTimePeriodExist(mandatoryFields);
	let optionObj = optionalFields;
	if (isSingleTimePeriodPresent) {
		const isCTP = isCTPSelected(mandatoryFields);
		optionObj = updateShowCTP(optionalFields, (isCTP ? true : false));
	}

	const inputData = {
		mandatoryFields,
		optionalFields: optionObj, isValueUpdated
	};
	dispatch(createAction('UPDATE_VALUES', inputData));
};

const populateIndexSearchData = (
	data,
	inputNodes,
	masterTrustChange,
	dispatch,
	groupId,
	flag
) => {
	//1163204:Index Search
	if (flag) {
		const { MandatoryFields, NonMandatoryFields } = inputNodes;
		let isValueUpdated = false;
		find(MandatoryFields, item => {
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
			return data && item.groupId === data.groupId;
		});
		const mandatoryFields = MandatoryFields;
		const optionalFields = NonMandatoryFields;
		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	} else {
		const { mandatoryFields, optionalFields } = inputNodes;
		let isValueUpdated = false;
		find(mandatoryFields, item => {
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
			return data && item.groupId === data.groupId;
		});

		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	}


};

const retrieveAccountGroupId = (
	trustId,
	inputNodes,
	userId,
	masterTrustChange,
	dispatch,
) => {
	const { PERFORMANCE_IDENTIFIER, TEMPLATE } = PERFORMANCEFORM_CONST;
	Api.getAccountGroups(trustId, userId)
		.then(res => {
			const data = get(res, 'data');
			let accountGroupIdData;
			if (!isEmpty(data)) {
				updateElementId(data, PERFORMANCE_IDENTIFIER);
				accountGroupIdData = {
					dropDowns: manipulateAccountGroupData(data),
					groupId: PERFORMANCE_IDENTIFIER,
				};
			}
			populateAccountGroupData(
				accountGroupIdData,
				inputNodes,
				masterTrustChange,
				dispatch,
				TEMPLATE,
			);
			return accountGroupIdData;
		})
		.catch(error => {
			return error;
		});
};
//1163204:Index Search
export const getIndexSearchList = (serviceName, mandatoryObj, inputNodes, userId, masterTrustChange, dispatch, flag) => {
	masterTrustChange = true;
	const { INDEX_PERFORMANCE_OBJECT } = PERFORMANCEFORM_CONST;
	if (INDICES_SERVICE_NAME === serviceName || INDICES_FCTP_SERVICE_NAME === serviceName || INDICES_FCTP === serviceName) {
		const currencyValue = mandatoryObj.filter(item => item.groupId === 'Currency')[0]?.value;
		const indexTypeValue = mandatoryObj.filter(item => item.groupId === 'Index Type')[0]?.value;
		const countryRegionValue = mandatoryObj.filter(item => item.groupId === 'CountryRegion')[0]?.value;
		const assetClassValue = mandatoryObj.filter(item => item.groupId === 'AssetClass')[0]?.value;
		const institutionValue = mandatoryObj.filter(item => item.groupId === 'Institution')[0]?.value;
		const searchValue = mandatoryObj.filter(item => item.groupId === 'searchText')[0]?.value;
		
		let requestParameterValues;
		if (userId) {
			requestParameterValues = `?userId=${userId}`;
			if (searchValue && searchValue !== '') {
				requestParameterValues = `${requestParameterValues}&searchText=${searchValue}`;
			}
			if (countryRegionValue && countryRegionValue.listId !== 'ALL') {
				requestParameterValues = `${requestParameterValues}&geo=${countryRegionValue.listId}`;
			}
			if (currencyValue && currencyValue.listId !== 'ALL') {
				requestParameterValues = `${requestParameterValues}&currency=${currencyValue.listId}`;
			}
			if (institutionValue && institutionValue.listId !== '-999') {
				requestParameterValues = `${requestParameterValues}&institution=${institutionValue.listId}`;
			}
			if (indexTypeValue && indexTypeValue.listId !== 'X') {
				requestParameterValues = `${requestParameterValues}&indexType=${indexTypeValue.listId}`;
			}
			if (assetClassValue && assetClassValue.listId !== 'ALL') {
				requestParameterValues = `${requestParameterValues}&assetType=${assetClassValue.listId}`;
			}

			dispatch(createAction('FETCH_INDEX_SEARCH'));
			Api.getIndexSearchData(requestParameterValues)
				.then(res => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					const data = get(res, 'data');
					let indexSearchDataList;
					if (!isEmpty(data)) {
						const { indexes } = data;
						indexSearchDataList = {
							dropDowns: manipulateIndexSearchData(indexes, INDEX_PERFORMANCE_OBJECT),
							groupId: INDEX_PERFORMANCE_OBJECT,
						};
					}
					populateIndexSearchData(
						indexSearchDataList,
						inputNodes,
						masterTrustChange,
						dispatch,
						INDEX_PERFORMANCE_OBJECT,
						flag
					);
					return indexSearchDataList;
				})
				.catch(error => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					return error;
				});


		}
	}
}

const defaultTemplate = (templates, accountId) => {
	return filter(templates, items => {
		return items.accountGrpId === accountId;
	});
};
const updateDropdownOptions = (res, inputNodes, changed, dispatch) => {
	const { TEMPLATE, PERFORMANCE_IDENTIFIER_TMP } = PERFORMANCEFORM_CONST;
	const templates = get(res, 'data.templates');
	const accountGroups = get(res, 'data.accountGroup');
	let newTemplates;
	if (!isEmpty(accountGroups)) {
		updateElementId(accountGroups, PERFORMANCE_IDENTIFIER_TMP);
		const newMandatoryFieldsVal = get(inputNodes, 'mandatoryFields');
		const selectedPerformance = find(newMandatoryFieldsVal, items => {
			return items.groupId === PERFORMANCE_IDENTIFIER_TMP;
		});
		const newPerformanceVal = get(selectedPerformance, 'value.value');
		const newAccId = find(accountGroups, itemAcc => {
			return itemAcc.accountId === newPerformanceVal;
		});
		const defaultAcctId = newAccId
			? newAccId.accountId
			: accountGroups[0].accountId;
		newTemplates = defaultTemplate(templates, defaultAcctId);
	}
	const templateManipulate = !isEmpty(accountGroups)
		? newTemplates
		: templates;
	const templateOptions = templateData(templateManipulate);
	const accountGrpOptions = manipulateAccountGroupData(accountGroups);
	const optionsAcct = {
		dropDowns: accountGrpOptions,
		groupId: PERFORMANCE_IDENTIFIER_TMP,
	};
	const options = {
		dropDowns: templateOptions,
		groupId: TEMPLATE,
	};
	if (!isEmpty(accountGroups)) {
		populateAccountGroupData(
			optionsAcct,
			inputNodes,
			changed,
			dispatch,
			PERFORMANCE_IDENTIFIER_TMP,
		);
	}
	if (!isEmpty(templates)) {
		populateAccountGroupData(
			options,
			inputNodes,
			changed,
			dispatch,
			TEMPLATE,
		);
	} else {
		validateTemplateField(
			inputNodes.mandatoryFields,
			PERFORMANCE_IDENTIFIER_TMP,
		);
		validateTemplateField(inputNodes.mandatoryFields, TEMPLATE);
		// dispatch(createAction('UPDATE_INPUT_DATA', inputNodes));
		const inputData = {
			mandatoryFields: inputNodes.mandatoryFields,
			optionalFields: inputNodes.optionalFields,
		};
		dispatch(
			createAction('VALIDATE_FIELDS', {
				inputData,
				isValidFields: false,
				isUsedCellAddress: true,
				isValidExcelField: {
					isValidCellId: true,
					isValidSheetName: true,
				},
			}),
		);
	}
};
const retrieveTemplates = (trustId, nodes, isChange) => (
	dispatch,
	getState,
) => {
	const state = getState();
	const { selectedServiceData } = get(state, 'data.createNewDataSet');
	const serviceName = get(selectedServiceData, 'servicename');
	dispatch(createAction('FETCH_TEMPLATE_SERVICE'));
	Api.getTemplates(trustId, serviceName)
		.then(res => {
			dispatch(createAction('UPDATE_TEMPLATE', res));
			updateDropdownOptions(res, nodes, isChange, dispatch);
		})
		.catch(error => {
			dispatch(createAction('FETCH_TEMPLATE_SERVICE_FAILURE'));
			return error;
		});
};
const updateAccountGroupDropdown = (
	inputNodes,
	userId,
	dispatch,
	defaultMasterTrust,
) => {
	const { MandatoryFields, NonMandatoryFields } = inputNodes;
	const {
		PERFORMANCE_IDENTIFIER,
		PERFORMANCE_OBJECT,
		TEMPLATE,
	} = PERFORMANCEFORM_CONST;
	const inputNodeData = {
		mandatoryFields: MandatoryFields,
		optionalFields: NonMandatoryFields,
	};
	const isPerfIdentifier = checkIsPerformanceId(
		MandatoryFields,
		PERFORMANCE_IDENTIFIER,
	);
	const isTemplate = checkIsPerformanceId(MandatoryFields, TEMPLATE);

	find(MandatoryFields, item => {
		const selectedMasterId = find(MandatoryFields, newItem => {
			return newItem.groupId === PERFORMANCE_OBJECT;
		});
		const newMasterId = get(selectedMasterId, 'value.value');
		const masterId = defaultMasterTrust || newMasterId;
		if (
			isPerfIdentifier &&
			item.groupId === PERFORMANCE_OBJECT &&
			!isTemplate
		) {
			item.mandatory = false;
			retrieveAccountGroupId(
				masterId,
				inputNodeData,
				userId,
				false,
				dispatch,
			);
		}
		if (isTemplate && item.groupId === TEMPLATE) {
			dispatch(retrieveTemplates(masterId, inputNodeData, true));
		}
	});
};

const externalUserMasterTrust = (inputNodes, dispatch, getState) => {
	const state = getState();
	const userId = get(state, 'data.userInContext.user.profile.userId');
	const isInternal = get(state, 'data.userInContext.user.profile.isInternal');
	const { selectedDataset } = get(state, 'data.createNewDataSet');
	const masterTrust = get(state, 'data.createNewDataSet.masterTrust_DD');
	const { PERFORMANCE_OBJECT } = PERFORMANCEFORM_CONST;
	const selectedInputNodes = get(
		selectedDataset,
		'inputNodes.mandatoryFields',
	);
	const newMasterSelected = find(selectedInputNodes, itemNew => {
		return itemNew.groupId === PERFORMANCE_OBJECT;
	});
	const masterTrustSelectedValue = get(newMasterSelected, 'value.value');
	if (isInternal === 'N' && !isEmpty(masterTrust)) {
		inputNodes.MandatoryFields.map(item => {
			const { groupId } = item;
			if (groupId === PERFORMANCE_OBJECT) {
				const masterTrustId =
					masterTrustSelectedValue || masterTrust[0].id;
				item.dropDowns = updateDD(masterTrust);
				item.defaultOption = newMasterSelected
					? newMasterSelected.text
					: masterTrust[0].masterTrustNameId;
				updateAccountGroupDropdown(
					inputNodes,
					userId,
					dispatch,
					masterTrustId,
				);
			}
			return null;
		});
	}
};
// ACTION get called on radio button change to make API call
const makeApiCallOnRdoChange = id => (dispatch, getState) => {
	const state = getState();
	const serivcedata = get(state, 'data.createNewDataSet.selectedServiceData');
	const performance = get(state, 'data.performance');
	const mandatoryFields = get(performance, 'inputNodes.mandatoryFields');
	const { servicename, serviceMetaDataPath } = serivcedata;
	const metadataPath = serviceMetaDataPath.split('?');
	const params = `${servicename} ${getQueryParam(id, mandatoryFields)}`;
	const newParams = params.trim();
	const path = `${metadataPath[0]}?serviceName=${newParams}`;
	serivcedata.serviceMetaDataPath = path;
	updateUniqueId();
	map(mandatoryFields, item => {
		if (item.groupId === id.elementId) {
			item.value = id.value;
			item.defaultValue = id.value;
		}
	});
	dispatch(
		createAction('UPDATE_RADIO_OPTIONS', {
			mandatoryFields,
			serviceName: newParams,
		}),
	);
	dispatch(createAction('FETCH_SERVICE_BEGIN'));
	Api.retriveServiceData(path).then(res => {
		const data = updateResWithRadioBtn(res.data, serivcedata, false);
		const inputNodes = get(data, 'inputNodes');
		externalUserMasterTrust(inputNodes, dispatch, getState);
		inputNodes.dataSheetDetail = `${performance.cellValue}!${performance.sheetName}`;
		data.inputNodes = inputNodes;
		dispatch(createAction('FETCH_SERVICE_SUCCESS', data));
		const manipulatedNodes = initiateNewFlow(inputNodes, servicename, dispatch, getState);
		dispatch(createAction('MANIPULATE_INPUT_NODES', manipulatedNodes));
	});
};
// ACTION get called on radio button change
export const radioBtnAction = (event, id) => dispatch => {
	const { ORIENTATION, FREQUENCY } = PERFORMANCE_COMMON;
	if (
		id.elementId !== ORIENTATION.groupId &&
		id.elementId !== FREQUENCY.groupId
	) {
		dispatch(updateNodeValues(event, id.elementId));
	} else {
		dispatch(makeApiCallOnRdoChange(id));
	}
};

const showBackWrnAction = (dispatch, showWrning, isValueUpdate) => {
	dispatch(
		createAction('UPDATE_BACK_WARNING', {
			backWarning: showWrning,
			isValueUpdate,
		}),
	);
};
const ctpSequence = inputNodes => {
	const newInputNodes = get(inputNodes, 'NonMandatoryFields');
	const newMandatoryNodes = get(inputNodes, 'MandatoryFields');
	const isCTPPresent = isCTPSelected(newMandatoryNodes);
	if (isCTPPresent) {
		const optionalFieldsData = filter(newInputNodes, item => {
			return item.groupId !== 'CTP1';
		});
		const ctpField = find(newInputNodes, item => {
			return item.groupId === 'CTP1';
		});
		inputNodes.NonMandatoryFields = [ctpField, ...optionalFieldsData];
	}
};
export const manipulateInputData = () => (dispatch, getState) => {
	const state = getState();
	const isPersever = checkPerseverFlow(state);
	if (isPersever) {
		showBackWrnAction(dispatch, false, true);
		return;
	}
	const data = get(state, 'data.createNewDataSet');
	const userId = get(state, 'data.userInContext.user.profile.userId');
	const isInternal = get(state, 'data.userInContext.user.profile.isInternal');
	const { selectedServiceData, inputNodes, selectedDataset } = data;
	const { MandatoryFields } = inputNodes;

	if(selectedDataset && selectedDataset.editClicked == true ){
		dispatch(createAction('READ_ONLY_TEXT', true));
	}


	isFreqeuncyOrientation(selectedDataset, MandatoryFields);
	ctpSequence(inputNodes);
	externalUserMasterTrust(inputNodes, dispatch, getState);
	if (selectedDataset) {
		const { servicename } = selectedServiceData;
		// mapEmpty Time Period for C2R at the time of Edit 
		if (CTR_SERVICE_NAME === servicename) {
			const nonMandatoryFieldsTP = get(state, 'data.createNewDataSet.selectedDataset.inputNodes.nonMandatoryFields');
			mapEmptyTimePeriodCTR(inputNodes, nonMandatoryFieldsTP);
		}
		const { inputCellNo, inputSheetName } = get(
			selectedDataset,
			'inputNodes',
		);
		inputNodes.dataSheetDetail = `${inputCellNo}!${inputSheetName}`;
		const { duplicateClicked } = selectedDataset;
		const { cellChangeData } = mapSelectedDataSetToInput(
			inputNodes,
			selectedDataset,
			moveToCellSheet,
			duplicateClicked,
		);

		if (isInternal === 'Y') {
			updateAccountGroupDropdown(inputNodes, userId, dispatch);
		}

		if (!duplicateClicked) {
			dispatch(createAction('CELL_CHANGE', cellChangeData));
		}
	}

	const serviceName = getSelectedServiceName(selectedServiceData);
	const inputNodeData = initiateNewFlow(inputNodes, serviceName, dispatch, getState);
	dispatch(createAction('MANIPULATE_INPUT_NODES', inputNodeData));
	dispatch(
		createAction('DEFAULT_SERVICE_NAME', {
			serviceName,
		}),
	);

	function mapEmptyTimePeriodCTR(inputNodes, nonMandatoryFieldsTP) {
		const { MandatoryFields, NonMandatoryFields } = inputNodes;
		let updatedOptionalFileds = NonMandatoryFields;
		const timePeriodDefaultValue = nonMandatoryFieldsTP.filter(item => item.groupId === 'TimePeriod')[0]?.value;
		if (!timePeriodDefaultValue) {

			const defaultOptionTimePeriod = find(NonMandatoryFields, item => {
				item.defaultOption = item.groupId === 'TimePeriod' == null;
				item.smartDateKey = item.groupId === 'TimePeriod' == null;
				return item.groupId === 'TimePeriod';
			});

			[defaultOptionTimePeriod, ...updatedOptionalFileds];
		}

		inputNodes = {
			MandatoryFields,
			NonMandatoryFields: updatedOptionalFileds,
		};
	}
};

// ON SMART DATE DROPDOWN CHANGE UPDATE DATE IN BELOW METHOD
export const onSmartDateChange = (event, option) => dispatch => {
	const newDate = getSmartDate(option);
	const { id } = event.target;
	dispatch(updateNodeValues(newDate, id, option));
};

export const dateChange = (event, id) => dispatch => {
	dispatch(updateNodeValues(event, id));
};

export const dateRangeChange = (date, type) => (dispatch, getState) => {
	const state = getState();
	const { data } = state;
	const { selectedDataset } = get(state, 'data.createNewDataSet');
	const { inputNodes } = data.performance;

	const { mandatoryFields, optionalFields } = inputNodes;
	const optionalData = updateNodeDateChange(optionalFields, date, type);
	const mandatoryData = updateNodeDateChange(mandatoryFields, date, type);
	const inputData = {
		mandatoryFields: mandatoryData,
		optionalFields: optionalData,
		isValueUpdated: true,
	};
	updateSelectedDatasetValue(selectedDataset, mandatoryFields, optionalData);
	dispatch(createAction('UPDATE_VALUES', inputData));
};
const updateMultipleValue = (fieldData, elementId, option) => {
	find(fieldData, item => {
		if (item.isMultiSelect && item.groupId === elementId && elementId) {
			const selectedItems = getMultiselectedValue(item.value, option);
			item.value = selectedItems;
		}
	});
};

export const selectionChange = (event, option) => (dispatch, getState) => {
	const state = getState();
	const { data } = state;
	const { selectedDataset } = get(state, 'data.createNewDataSet');
	const userId = get(data, 'userInContext.user.profile.userId');
	const isInternal = get(data, 'userInContext.user.profile.isInternal');
	const { inputNodes, templates } = data.performance;
	const { selectedService } = data.createNewDataSet;
	const { mandatoryFields, optionalFields } = inputNodes;
	const defaultAsOfdate = { key: 'last_month' };
	const newDate = getSmartDate(defaultAsOfdate);
	const isTimePeriod = isTimePeriodExist(mandatoryFields);
	const isSingleTimePeriodPresent = isSingleTimePeriodExist(mandatoryFields);

	// CTR Frequecy optional fields Start Date and End Date wil be mandatory 
	if (CTR_SERVICE_NAME === selectedService) {
		updateCTRMandatory(optionalFields, option);
	}

	if (isTimePeriod) {
		if (option.value === 'CTP' && !option.selected) {
			updateShowCTP(optionalFields, false);
		}
		if (option.value === 'CTP' && option.selected) {
			onDateChange(mandatoryFields, optionalFields, newDate);
		}
	}

	if (isSingleTimePeriodPresent && option.elementId === 'TimePeriodSingle') {
		if (option.value !== 'CTP') {
			updateShowCTP(optionalFields, false);
		}
		if (option.value === 'CTP') {
			onDateChange(mandatoryFields, optionalFields, newDate);
		}
	}

	const {
		PERFORMANCE_OBJECT,
		PERFORMANCE_IDENTIFIER,
		PERFORMANCE_IDENTIFIER_TMP,
		TEMPLATE,
		UNIVERSES, 
		UNIVERSES_ACCOUNT_PAR,
		COMPOSITE_REPORTING,
	    COMPOSITE_CTP
	} = PERFORMANCEFORM_CONST;

	const isMultiSelect = checkMultiselect(option.elementId);
	const elementId = getElementId(isMultiSelect, event, option);
	const value = option && option.text ? option : event;

	const isTemplate = checkIsPerformanceId(mandatoryFields, TEMPLATE);
	// Changes for Account/Template
	if (elementId === PERFORMANCE_IDENTIFIER_TMP && isTemplate) {
		const selectedAccountId = get(value, 'value');
		const newTemplates = get(templates, 'templates');
		const newTemplateData = defaultTemplate(
			newTemplates,
			selectedAccountId,
		);
		const templateOptions = templateData(newTemplateData);
		const options = {
			dropDowns: templateOptions,
			groupId: TEMPLATE,
		};
		populateAccountGroupData(options, inputNodes, true, dispatch, TEMPLATE);
	}

	const mandatoryFieldData = updateValues(mandatoryFields, value, elementId);
	const optionalFieldData = updateValues(optionalFields, value, elementId);

	updateMultipleValue(mandatoryFieldData, elementId, option);
	updateMultipleValue(optionalFieldData, elementId, option);

	const inputData = {
		mandatoryFields: mandatoryFieldData,
		optionalFields: optionalFieldData,
		isValueUpdated: true,
	};
	updateSelectedDatasetValue(
		selectedDataset,
		mandatoryFields,
		optionalFieldData,
	);
	const eventValue = event ? event.value : null;
	const masterTrust = isInternal === 'Y' ? eventValue : option.value;
	const isPerfIdentifier = checkIsPerformanceId(
		mandatoryFields,
		PERFORMANCE_IDENTIFIER,
	);
	if (elementId === PERFORMANCE_OBJECT && isPerfIdentifier && !isTemplate) {
		retrieveAccountGroupId(masterTrust, inputNodes, userId, true, dispatch);
	}
	//1163204:Index Search
	// CTR Frequecy optional fields Start Date and End Date wil be mandatory 
	if (INDICES_SERVICE_NAME === selectedService || INDICES_FCTP_SERVICE_NAME === selectedService || INDICES_FCTP === selectedService) {
		if(!INDEX_DROPDOWN_LOADING_NA.includes(option.colText) && !INDEX_DROPDOWN_LOADING_NA.includes(option.elementId)){
	//	getIndexSearchList(selectedService, mandatoryFields, inputNodes, userId, true, dispatch, false);
		}
	}

	if (UNIVERSES === selectedService || UNIVERSES_ACCOUNT_PAR === selectedService) {
		if(!UNIVERSE_DROPDOWN_LOADING_NA.includes(option.colText)  && !UNIVERSE_DROPDOWN_LOADING_NA.includes(option.elementId) && ('PerformanceObject' !== event.elementId) ){
		retrieveUniverseName(selectedService, mandatoryFields, inputNodes, userId, dispatch, false);
		}
	}

	if (COMPOSITE_REPORTING === selectedService || COMPOSITE_CTP === selectedService) {
			if(COMPOSITE_DROPDOWN_LOADING_NA.includes(option.colText) && COMPOSITE_DROPDOWN_LOADING_NA.includes(event.elementId)){
				find(inputNodes.mandatoryFields, item => {
					if (item.groupId === "CompositeGroupID"){
						item.value= null;
						return item.groupId === "CompositeGroupID";
					}
					return;
				});
				retrieveCompositeID(selectedService, mandatoryFields, inputNodes, userId, dispatch, false);
		}
	}

	if (elementId === PERFORMANCE_OBJECT && isTemplate) {
		dispatch(retrieveTemplates(masterTrust, inputNodes, true));
	}
	dispatch(createAction('UPDATE_VALUES', inputData));
};

export const changeTextField = event => dispatch => {
	const { id } = event.target;
	dispatch(updateNodeValues(event, id));
};

const updateCTRMandatory = (optionalFields, option) => {
	if (option && option.value !== 'NA') {
		const optionalFieldsData = filter(optionalFields, item => {
			return item.groupId !== 'CTP1';
		});
		let mandatoryStartEndDate = find(optionalFields, item => {
			item.mandatory = item.groupId === 'CTP1' ? true : false;
			return item.groupId === 'CTP1';
		});
		mandatoryStartEndDate = find(optionalFields, item => {
			item.errorMsg = item.groupId === 'TimePeriod'? null : null;
			return item.groupId === 'TimePeriod';
		});
		return [mandatoryStartEndDate, ...optionalFieldsData];
	} else {
		return optionalFields;
	}
}

// ACTION - Callback for cell id and sheet name change
export const cellChange = event => (dispatch, getState) => {
	const {
		cellValue,
		sheetName,
		isValidSheetName,
		isInputCellValid,
		isValidCellId,
		isFirstRowSelected,
	} = onInputCellChange(event);
	const state = getState();
	const { data } = state;
	const { inputNodes } = get(data, 'createNewDataSet');
	inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
	const cellChangeData = {
		cellValue,
		sheetName,
		isValidExcelField: {
			isValidCellId,
			isValidSheetName,
		},
		isUsedCellAddress: isInputCellValid,
		isFirstRowSelected,
		isCellSheetSelected: !cellValue && !sheetName,
	};
	dispatch(createAction('CELL_CHANGE', cellChangeData));
};

// Action - registered on spread sheet/ID change on component mount
export const cellSheetAction = value => (dispatch, getState) => {
	const {
		sheetName,
		cellValue,
		isFirstRowSelected,
		isInputCellValid,
		rangeValue,
	} = value;
	const state = getState();
	const inputPrevCell = get(state, 'data.performance.cellValue');
	const validCell = inputPrevCell === isInputCellValid || rangeValue === '';
	const { data } = state;
	const { inputNodes, selectedDataset } = get(data, 'createNewDataSet');
	let flagEdit = true;
	if(selectedDataset && selectedDataset.editClicked == true){
	flagEdit =  false;
	}
	if (flagEdit) {
	inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
	const cellChangeData = {
		cellValue,
		sheetName,
		isValidExcelField: {
			isValidCellId: cellValue !== '',
			isValidSheetName: sheetName !== '',
		},
		isUsedCellAddress: validCell,
		isFirstRowSelected,
		isCellSheetSelected: !cellValue && !sheetName,
	};
	dispatch(createAction('CELL_CHANGE', cellChangeData));
}
};

const checkOptionalFieldValidity = (optionalFields, formValid) => {
	find(optionalFields, item => {
		if (item.groupId === 'CTP1' && item.showCTP) {
			const { startDate, endDate } = item.value;
			formValid = startDate || endDate ? validateDateRange(item) : true;
		}
	});
	return formValid;
};
// VALIDATE ALL INPUT MANDATORY FIELDS ON NEXT CLICK
const validateFields = (data, indexSearchText) => {
	const { performance, createNewDataSet } = data;
	const { selectedDataset } = createNewDataSet;
	const { inputNodes, serviceName } = performance;
	const { mandatoryFields, optionalFields } = inputNodes;
	const nonValidField = validateFieldValues(mandatoryFields, indexSearchText);
	const optionValidate = checkOptionalFieldValidity(optionalFields, true);
	const frequencyValue = optionalFields.filter(item => item.groupId === 'InputFrequency')[0]?.value.value;
	const ReferenceDate = mandatoryFields.filter(item => item.groupId === 'ReferenceDate')[0]?.value;
	let formValid = true;
	const isRollingDays = find(optionalFields, item => {
		return item.CTPLable === 'Frequency' && item.value.key === 'RD';
	});
	let validDateDiff;
	if (CTR_SERVICE_NAME === serviceName) {
		if (frequencyValue !== 'NA') {
			find(optionalFields, item => {
				if (item.groupId === 'CTP1' && item.showCTP) {
					formValid = frequencyValue !== 'NA' ? validateDateRange(item) : true;
				}
			});
		}
		if (isRollingDays) {
			const dateRange = find(optionalFields, item => {
				return item.CTPLable === 'Custom Time Periods';
			});
			validDateDiff = validateDateDiffError(dateRange.value);
		}
	} else {
		if (isRollingDays) {
			const dateRange = find(mandatoryFields, item => {
				return item.CTPLable === 'Custom Time Periods';
			});
			validDateDiff = validateDateDiffError(dateRange.value);
		}
	}
	let validFutureDate = true;
	if (ReferenceDate) {
		validFutureDate = validateFutureDate(ReferenceDate);
	}

	let updatedOptionalFileds = optionalFields;
	let isTimePeriod = true;
	if (CTR_SERVICE_NAME === serviceName) {
		const isTimePeriodSelectedEdited = optionalFields.filter(item => item.groupId === 'TimePeriod')[0]?.value?.length;
		const isTimePeriodSelected = optionalFields.filter(item => item.groupId === 'TimePeriod')[0]?.value;
		if (selectedDataset) {
			isTimePeriod = isTimePeriodSelectedEdited > 0 ? true : false;
		} else {
			isTimePeriod = isTimePeriodSelected ? true : false;
		}

		if (frequencyValue === 'NA' && !isTimePeriod) {
			const errorMsgTimePeriod = find(optionalFields, item => {
				item.errorMsg = item.groupId === 'TimePeriod' ? "Please select at least one Time Period" : null;
				return item.groupId === 'TimePeriod';
			});
			[errorMsgTimePeriod, ...updatedOptionalFileds];
		} else {
			isTimePeriod = true;
			const errorMsgTimePeriod = find(optionalFields, item => {
				item.errorMsg = item.groupId === 'TimePeriod' ? null : null;
				return item.groupId === 'TimePeriod';
			});
			[errorMsgTimePeriod, ...updatedOptionalFileds];
		}
	}

	const isValidFieldStatus =
		isEmpty(nonValidField) && optionValidate && !validDateDiff && formValid && validFutureDate && isTimePeriod;
	const inputData = {
		mandatoryFields,
		optionalFields: updatedOptionalFileds,
	};
	return {
		inputData,
		isValidFields: isValidFieldStatus,
	};
};

// VALIDATE SELECTED CELL ID AND SHEET NAME
const validateExcelCellSheetName = data => {
	const { performance } = data;
	const {
		cellValue,
		sheetName,
		isUsedCellAddress,
		isFirstRowSelected,
	} = performance;
	const { isValidCellId, isValidSheetName } = validateCellIdSheetName(
		cellValue,
		sheetName,
	);
	const isValidCellSheet =
		isValidCellId &&
		isValidSheetName &&
		isUsedCellAddress &&
		!isFirstRowSelected;
	return {
		isValidExcelField: { isValidCellId, isValidSheetName },
		isValidCellSheet,
		isUsedCellAddress,
	};
};

export const backBtnClick = history => (dispatch, getState) => {
	const state = getState();
	const { data } = state;
	const { performance, createNewDataSet } = data;
	const { isValueUpdate } = performance;
	const { selectedDataset } = createNewDataSet;
	if (isValueUpdate) {
		showBackWrnAction(dispatch, true);
	} else {
		showBackWrnAction(dispatch, false, false);
		dispatch(createAction('BACK_BUTTON_CLICKED'));
		selectedDataset ? history.push('/dataset-manager') : history.push(`/create-new-dataset`);
	}
};

export const indexSearchBtnClick = history => (dispatch, getState) => {
	
	const state = getState();
	const { data } = state;
	const userId = get(data, 'userInContext.user.profile.userId');
	const { inputNodes } = data.performance;
	const { selectedService } = data.createNewDataSet;
	const { mandatoryFields } = inputNodes;
	const indexSearchText = "indexSearchText";


	const { validateFieldsValue } = commanDataObject(dispatch, data, indexSearchText);
	if (validateFieldsValue) {
		return;
	}
	getIndexSearchList(selectedService, mandatoryFields, inputNodes, userId, true, dispatch, false);
};

const updateInputData = inputDataNode => {
	const { mandatoryFields, optionalFields } = inputDataNode;

	const mandatoryInputData = updateInputNodesData(mandatoryFields);
	const isCTPPresent = isCTPSelected(mandatoryFields);
	const optionalInputData = updateInputNodesData(
		optionalFields,
		isCTPPresent,
	);
	return { ...mandatoryInputData, ...optionalInputData };
};
export const commanDataObject = (dispatch, data, indexSearchText) => {
	const { inputData, isValidFields } = validateFields(data, indexSearchText);
	const {
		isValidExcelField,
		isValidCellSheet,
		isUsedCellAddress,
	} = validateExcelCellSheetName(data);
	const inputDataObject = updateInputData(inputData);
	dispatch(createAction('UPDATE_INPUT_DATA', inputDataObject));
	dispatch(
		createAction('VALIDATE_FIELDS', {
			inputData,
			isValidFields,
			isValidExcelField,
			isUsedCellAddress,
		}),
	);
	const validateFieldsValue = indexSearchText === "indexSearchText" ? !isValidFields :  !isValidFields || !isValidCellSheet;
	return { inputDataObject, validateFieldsValue };
};
// On CLick GetData Button
export const onClickGetData = (event, getData, isGetDataRendered) => (
	dispatch,
	getState,
) => {
	event.preventDefault();
	const { data } = getState();
	const { validateFieldsValue } = commanDataObject(dispatch, data);
	if (validateFieldsValue) {
		return;
	}
	const { performance } = data;
	const { cellValue, sheetName } = performance;
	const dataProps = get(data, 'createNewDataSet');
	dataProps.performance = performance;
	dataProps.existingDataSet = get(data, 'dataSet.data');
	dataProps.profile = get(data, 'userInContext.user.profile');
	onClickGetDataBtn(
		dataProps,
		getData,
		cellValue,
		sheetName,
		isGetDataRendered,
	);
};
// Action called - on performance input screen NEXT Button click
export const submitForm = (event, history) => (dispatch, getState) => {
	event.preventDefault();
	const { data } = getState();
	const { inputDataObject, validateFieldsValue } = commanDataObject(
		dispatch,
		data
	);
	if (validateFieldsValue) {
		return;
	}
	const { selectedDataset } = get(data, 'createNewDataSet');
	const { performance } = data;
	const { cellValue, sheetName } = performance;
	showBackWrnAction(dispatch, false, false);
	//XDE-5631
	const startCellValueOld = getSessionStorageItem('startCellValue_old');
	const inputSheetNameOld = getSessionStorageItem('inputSheetName_Old');
	if (selectedDataset && selectedDataset.editClicked) {
		if (startCellValueOld !== cellValue && inputSheetNameOld !== sheetName) {
			dispatch(createAction('OPEN_POPUP', true));
		} else if (startCellValueOld !== cellValue && inputSheetNameOld === sheetName) {
			dispatch(createAction('OPEN_POPUP', true));
		} else if (startCellValueOld === cellValue && inputSheetNameOld !== sheetName) {
			dispatch(createAction('OPEN_POPUP', true));
		} else {
			updateCellValueIntoExcel(
				inputDataObject,
				selectedDataset,
				cellValue,
				sheetName,
			);
			if (selectedDataset && !selectedDataset.duplicateClicked) {
				updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
			}
			dispatch(createAction('OPEN_POPUP', false))
			history.push(`/output-form`);
		}
	} else {
		updateCellValueIntoExcel(
			inputDataObject,
			selectedDataset,
			cellValue,
			sheetName,
		);
		if (selectedDataset && !selectedDataset.duplicateClicked) {
			updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
		}
		dispatch(createAction('OPEN_POPUP', false))
		history.push(`/output-form`);
	}
};

//1163208:Universe Search
//To retrive universe name by passing universe provider
export const retrieveUniverseName = (serviceName, mandatoryObj, inputNodes, userId, dispatch, flag) => {
	const {UNIVERSES_ID, UNIVERSE_PROVIDER, UNIVERSES, UNIVERSES_ACCOUNT_PAR } = PERFORMANCEFORM_CONST;
	if (UNIVERSES === serviceName || UNIVERSES_ACCOUNT_PAR === serviceName) {

		const universeId = mandatoryObj.filter(item => item.groupId === 'UniverseProvider')[0]?.value.key;
		let requestParameterValues;

			if ( universeId ) {
				requestParameterValues = `${universeId}/universes?userId=${userId}`;
			}

			dispatch(createAction('FETCH_INDEX_SEARCH'));
			Api.getUniverseName(requestParameterValues)
				.then(res => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					const data = get(res, 'data');
					let universeSearchDataList;
					if (!isEmpty(data)) {
						const { universes } = data;	
						universeSearchDataList = {
							dropDowns: manipulateUniverseSearchData(universes, UNIVERSES_ID),
							groupId: UNIVERSES_ID,
						};
					}
					populateUniverseSearchData(
						universeSearchDataList,
						inputNodes,
						dispatch,
						UNIVERSES_ID,
						flag
					);
					return universeSearchDataList;
				})
				.catch(error => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					return error;
				});
		}
};

const populateUniverseSearchData = (
	data,
	inputNodes,
	dispatch,
	groupId,
	flag
) => {
	//1163204:Index Search
	if (flag) {
		const { MandatoryFields, NonMandatoryFields } = inputNodes;
		let isValueUpdated = false;
		find(MandatoryFields, item => {
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
			return data && item.groupId === data.groupId;
		});
		const mandatoryFields = MandatoryFields;
		const optionalFields = NonMandatoryFields;
		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	} else {
		const { mandatoryFields, optionalFields } = inputNodes;
		let isValueUpdated = false;
		find(mandatoryFields, item => {
			if (item.groupId === groupId){
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}else{
				const defaultValue = [];
				item.dropDowns = [];
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
		}
			return data && item.groupId === data.groupId;
		});

		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	}
};

//1163208:populateCompositeSearchDatav Search
//To retrive universe name by passing Composite provider
export const retrieveCompositeName = (serviceName, mandatoryObj, inputNodes, userId, dispatch, flag) => {
	const { COMPOSITE_REPORTING, COMPOSITE_CTP, COMPOSITE_NAME } = PERFORMANCEFORM_CONST;
	if (COMPOSITE_REPORTING === serviceName || COMPOSITE_CTP === serviceName) {

		let requestParameterValues;
			if ( userId ) {
				requestParameterValues = `userId=${userId}`;
			}
			dispatch(createAction('FETCH_INDEX_SEARCH'));
			Api.getCompositeID(requestParameterValues)
				.then(res => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					
					const data = get(res, 'data');
					let compositeNameSearchDataList;
					if (!isEmpty(data)) {
						const { sponsor } = data.searchResult;	
						compositeNameSearchDataList = {
							dropDowns: manipulateCompositeNameSearchData(sponsor, COMPOSITE_NAME),
							groupId: COMPOSITE_NAME,
						};
					}
					populateCompositeNameSearchData(
						compositeNameSearchDataList,
						inputNodes,
						dispatch,
						COMPOSITE_NAME,
						flag
					);
					return compositeNameSearchDataList;
				})
				.catch(error => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					return error;
				});
		}
};

const populateCompositeNameSearchData = (
	data,
	inputNodes,
	dispatch,
	groupId,
	flag
) => {
	//1163204:Index Search
	if (flag) {
		const { MandatoryFields, NonMandatoryFields } = inputNodes;
		let isValueUpdated = false;
		find(MandatoryFields, item => {
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
			return data && item.groupId === data.groupId;
		});
		const mandatoryFields = MandatoryFields;
		const optionalFields = NonMandatoryFields;
		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	} else {
		const { mandatoryFields, optionalFields } = inputNodes;
		let isValueUpdated = false;
		find(mandatoryFields, item => {
			if (item.groupId === groupId){
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}else{
				const defaultValue = [];
				item.dropDowns = [];
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
		}
			return data && item.groupId === data.groupId;
		});

		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	}
};

//-------------------- Composite ID

//1163208:populateCompositeSearchDatav Search
//To retrive universe name by passing Composite provider
export const retrieveCompositeID = (serviceName, mandatoryObj, inputNodes, userId, dispatch, flag) => {
	const { COMPOSITE_REPORTING, COMPOSITE_CTP, COMPOSITE_GROUP_ID, COMPOSITE_NAME } = PERFORMANCEFORM_CONST;
	if (COMPOSITE_REPORTING === serviceName || COMPOSITE_CTP === serviceName) {

		const compositeId = mandatoryObj.filter(item => item.groupId === COMPOSITE_NAME)[0]?.value.key;
		let requestParameterValues;
			if ( compositeId ) {
				requestParameterValues = `userId=${userId}&sponsorCode=${compositeId}`;
			}
			dispatch(createAction('FETCH_INDEX_SEARCH'));
			Api.getCompositeID(requestParameterValues)
				.then(res => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					const data = get(res, 'data');
					let compositeGroupIDSearchDataList;
					if (!isEmpty(data)) {
						const { sponsor } = data.searchResult;
						compositeGroupIDSearchDataList = {
							dropDowns: manipulateCompositeIDSearchData(sponsor, COMPOSITE_GROUP_ID),
							groupId: COMPOSITE_GROUP_ID,
						};
					}
					populateCompositeIDSearchData(
						compositeGroupIDSearchDataList,
						inputNodes,
						dispatch,
						COMPOSITE_GROUP_ID,
						flag
					);
					return compositeGroupIDSearchDataList;
				})
				.catch(error => {
					dispatch(createAction('SUCCESS_INDEX_SEARCH'));
					return error;
				});
		}
};

const populateCompositeIDSearchData = (
	data,
	inputNodes,
	dispatch,
	groupId,
	flag
) => {
	//1163204:Index Search
	if (flag) {
		const { MandatoryFields, NonMandatoryFields } = inputNodes;
		let isValueUpdated = false;
		find(MandatoryFields, item => {
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
			return data && item.groupId === data.groupId;
		});
		const mandatoryFields = MandatoryFields;
		const optionalFields = NonMandatoryFields;
		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	} else {
		const { mandatoryFields, optionalFields } = inputNodes;
		let isValueUpdated = false;
		find(mandatoryFields, item => {
			if (item.groupId === groupId){
			if (data && item.groupId === data.groupId) {
				const defaultValue = [];
				item.dropDowns = data.dropDowns;
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}else{
				const defaultValue = [];
				item.dropDowns = [];
				item.valid = true;
				item.errorMsg = '';
				isValueUpdated = defaultValue !== '';
			}
		}
			return data && item.groupId === data.groupId;
		});

		const inputData = { mandatoryFields, optionalFields, isValueUpdated };
		dispatch(createAction('UPDATE_VALUES', inputData));
	}
};

