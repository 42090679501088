export const welcomeConst = {
	FETCH_DATASET_BEGIN: 'FETCH_DATASET_BEGIN',
	FETCH_DATASET_SUCCESS: 'FETCH_DATASET_SUCCESS',
	FETCH_DATASET_FAILURE: 'FETCH_DATASET_FAILURE',
	STORE_DATASET_SUCCESS: 'STORE_DATASET_SUCCESS',
	CLEAR_STORED_DATASET: 'CLEAR_STORED_DATASET',
	UPDATE_AS_OF_DATE: 'UPDATE_AS_OF_DATE',
	MASTERTRUST_UPDATE: 'MASTERTRUST_UPDATE',
	SIGN_OUT_CLEAR_DATASET: 'SIGN_OUT_CLEAR_DATASET',
};
