import React from 'react';
import { LoginContainer } from 'containers';

const LoginPage = props => {
	return (
		<>
			<LoginContainer {...props} />
		</>
	);
};

export default LoginPage;
