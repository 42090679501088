import React from 'react';
import { Dropdown, DatePicker, PrimaryButton } from 'office-ui-fabric-react';
import { formatDate } from '../../utils/dateUtil';

const DatasetGlobalSettings = props => {
	const {
		dataset,
		asOfDate,
		changeDate,
		applyAll,
		onDatasetChange,
		messages,
		selectedKeys,
		showErrorMsg
	} = props;
	const selectedDate = asOfDate ? new Date(asOfDate) : null;
	const { dropdownTxt, datePickerTxt, buttonTxt } = messages;
	return (
		<div className="global-settings">
			<div className="ms-Grid-row dropdown-row">
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
					<Dropdown
						id="dataset"
						className="dropdown-lbl"
						label={dropdownTxt.label}
						multiSelect
						placeholder={dropdownTxt.placeholder}
						options={dataset}
						onChange={onDatasetChange}
						selectedKeys={selectedKeys}
						required = {true}
					/>
				</div>
			</div>
			<div className="ms-Grid-row datepicker-row">
				<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					<DatePicker
						// className={style}
						id="referancedate"
						label={datePickerTxt.label}
						showMonthPickerAsOverlay
						onSelectDate={changeDate}
						allowTextInput
						isMonthPickerVisible={false}
						formatDate={date => formatDate(date, 'mm/dd/yyyy')}
						maxDate={new Date()}
						value={selectedDate}
						placeholder={datePickerTxt.placeholder}
						isRequired
					/>
				</div>
				<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 btn-apply-all">
					<PrimaryButton
						id="apply-as-of-date"
						text={buttonTxt.text}
						onClick={applyAll}
					/>
				</div>
				{showErrorMsg ? (
					<div className="ms-Grid-col ms-sm12 ms-lg6">
					<span className="errorMessage">{"Please ensure to select valid dataset(s) and a date."}</span>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default DatasetGlobalSettings;
