import React, { useState, useEffect } from 'react';
import { THROBBER_TXT } from './ThrobberMessageConst';

const ThrobberMessage = () => {
	const [messageIndex, setMessageIndex] = useState(0);

	const changeThobberTxt = () => {
		const messageLength = THROBBER_TXT.length;
		const msgLength = messageLength - 1;

		return messageIndex < msgLength
			? setMessageIndex(messageIndex + 1)
			: setMessageIndex(0);
	};
	useEffect(() => {
		let time = 4000;
		if (messageIndex === 0) {
			time = 10000;
		}
		const timeout = setTimeout(() => changeThobberTxt(), time);
		return () => {
			clearTimeout(timeout);
		};
	}, [messageIndex]);

	return THROBBER_TXT[messageIndex];
};
export default ThrobberMessage;
