import LandingPage from './LandingPage';

export default {
	routeProps: {
		path: '/',
		exact: true,
		component: LandingPage,
	},
	name: 'LanidngPage',
};
