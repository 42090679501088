import React from 'react';
import {
	Image,
	OverflowSet,
	Text,
	Icon,
	IconButton,
	PrimaryButton,
	TextField,
} from 'office-ui-fabric-react';
import { get, toUpper } from 'lodash';
import RunAll from 'images/Run_all.png';
import DatasetManagerConst from './DatasetManagerConst';
import { convertDateTimeGBFormat } from '../../utils/dateUtil';
import DatasetTileDetail from './DatasetTitleDetail';
import './DatasetManagerBody.module.scss';
import IconDatepicker from '../common/datePicker/iconDatepicker/IconDatepicker';

const DatasetTile = props => {
	const {
		data,
		actionMenu,
		isCalloutVisible,
		onClickInfoIcon,
		idSelectedTile,
		onClickEditOption,
		changeDate,
		onClickDeleteOption,
		onClickRenameOption,
		renamedataset,
		onChangeTextField,
		renamedatasetValue,
		onSaveRenamedDataset,
		onClickDuplicateOption,
		onGetDataIconClick,
		isValidReportTitle,
		messages,
	} = props;
	const actionObject = {
		GO_TO_SHEET: onClickEditOption,
		EDIT_DATASET: onClickEditOption,
		DUPLICATE_DATASET: onClickDuplicateOption,
		RENAME_DATASET: onClickRenameOption,
		DELETE_DATASET: onClickDeleteOption,
	};
	const { asOfDateText, lastModifiedText, tooltip } = get(
		DatasetManagerConst,
		'label',
	);
	const { datasetInfo } = DatasetManagerConst;
	const onRenderOverflowButton = overflowItems => {
		const buttonStyles = {
			root: {
				minWidth: 0,
				padding: '0 4px',
				height: 'auto',
			},
		};
		return (
			<IconButton
				id="more-vertical"
				role="menuitem"
				title="More options"
				styles={buttonStyles}
				menuIconProps={{ iconName: 'More' }}
				menuProps={{ items: overflowItems }}
			/>
		);
	};
	const manipulateActionMenu = itemList => {
		const datasetMenu = [];
		actionMenu.map(item => {
			datasetMenu.push({
				key: `${item.key}_${itemList.key}`,
				id: itemList.key,
				value: itemList.serviceSelect,
				serviceText: itemList.text,
				name: item.name,
				range: itemList.reportRange,
				onClick: actionObject[item.key],
			});
			return datasetMenu;
		});
		return datasetMenu;
	};
	const OverflowSetVerticalExample = itemList => {
		const datasetAction = manipulateActionMenu(itemList);
		return (
			<OverflowSet
				aria-label="Horizontal bar"
				role="menubar"
				overflowItems={datasetAction}
				onRenderOverflowButton={onRenderOverflowButton}
			/>
		);
	};

	const createTile = () => {
		return data.map((itemList, index) => {
			const { date, time } = convertDateTimeGBFormat(
				itemList.lastRefreshTime,
				true,
			);
			const formateAsOfDate = convertDateTimeGBFormat(
				itemList.asOfDate,
				false,
			);
			const asOfDate = itemList.asOfDate ? formateAsOfDate.date : null;
			const tileDefaultClass = 'default-tile-bgcolor';
			const tileClassName = index % 2 > '0' ? '' : tileDefaultClass;
			const showHideStatus =
				isCalloutVisible && idSelectedTile === itemList.key;
			return (
				<div
					className="ms-Grid-row "
					key={`${itemList.text}_${itemList.key}`}
					id={`${itemList.text}_${itemList.key}`}
				>
					<div
						className={`ms-Grid-col ms-sm12 ms-md12 ms-lg12 rectangle-box ${tileClassName}`}
					>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
								{renamedataset === itemList.text ? (
									<span className="btn-save">
										<TextField
											name={itemList.text}
											value={renamedatasetValue}
											id={itemList.text}
											onChange={onChangeTextField}
											errorMessage={
												!isValidReportTitle.valid
											}
										/>
										<PrimaryButton
											id="save-button"
											text="Save"
											onClick={onSaveRenamedDataset}
										/>
									</span>
								) : (
									<span className="dataset-name">
										{itemList.text}
									</span>
								)}
							</div>
							<div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
								<span className="run-icon" title={tooltip}>
									<Image
										src={RunAll}
										onClick={() =>
											onGetDataIconClick(itemList.key)
										}
									/>
								</span>
								<span className="command-icon">
									{OverflowSetVerticalExample(itemList)}
								</span>
							</div>
						</div>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<span className="errorMessage">
									{renamedataset === itemList.text
										? isValidReportTitle.errorMessage
										: null}
								</span>
							</div>
						</div>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								{asOfDate && asOfDate !== '' ? (
									<div className="as-of-date-label">
										<Text className="as-of-date-text">
											{asOfDateText}:{' '}
										</Text>
										<span className="as-of-date">
											{asOfDate}
										</span>
										<span>
											<IconDatepicker
												datepickerId={itemList.key}
												changeDate={changeDate}
												labelId={itemList.id}
											/>
										</span>
									</div>
								) : (
									<div className="edit-dataset-text">
										<Text className="last-modified-text">
											{messages.noAsOfDateMsg}
										</Text>
									</div>
								)}

								<div className="last-modified-date-label">
									<span>{lastModifiedText}: </span>
									<span className="last-modified-date">
										{date}
										{toUpper(time)}
									</span>
									<span className="last-modified-date-icon">
										<div
											className={`dataset-tile${itemList.id}`}
										>
											<Icon
												iconName="Info"
												title="Info"
												ariaLabel="Info"
												className="info-icon"
												id={itemList.key}
												onClick={onClickInfoIcon}
											/>
										</div>
										{showHideStatus ? (
											<DatasetTileDetail
												onClickInfoIcon={
													onClickInfoIcon
												}
												inputDataNode={
													itemList.inputDataNode
												}
												itemKey={itemList.id}
												datasetInfo={datasetInfo}
											/>
										) : (
											''
										)}
									</span>
								</div>
								<div style={{ padding: '2px' }}>
									{itemList.isRunAllFail ? (
										<Text
											className="as-of-date-label"
											style={{
												color: 'red',
												fontWeight: 'bold',
											}}
										>
											Failed!
										</Text>
									) : null}{' '}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	return <>{createTile()}</>;
};

export default DatasetTile;
