import LoginPage from './AuthPage';

export default {
	routeProps: {
		path: '/auth',
		exact: true,
		component: LoginPage,
	},
	name: 'AuthPage',
};
