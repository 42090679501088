import { get, find, filter, toUpper } from 'lodash';
import { PERFORMANCE_COMMON } from 'utils/Constants';
import {
	updateSessionStorage,
	nextChar,
	getLocalStorageItem,
} from './CommonUtil';
import {
	isCTP,
	colTextValue,
	spiltCellValue,
	clearExcelData,
} from './excelUtils/ExcelDataPopulate';
import { formatDate } from './dateUtil';
// Update CTP & InputData value
export const getInputData = item => {
	let inputDataValue = {};
	const value = get(item, 'value');
	if (item.groupId !== 'CTP1' && value) {
		inputDataValue = { value, colText: item.colText };
	} else if (item.groupId === 'CTP1') {
		inputDataValue = {
			startDate: {
				colText: 'Start Date',
				value: value.startDate,
			},
			endDate: {
				colText: 'End Date',
				value: value.endDate,
			},
		};
	}
	return inputDataValue;
};

// Update Value for Radio Button
export const getRadioBtnDefaultValue = item => {
	if (item.value && item.value !== '') {
		return item.value;
	}
	const selectedValue = get(item, 'dropDowns');
	const defaultValue = toUpper(item.fieldType) === toUpper('Radiobutton');
	return defaultValue ? selectedValue[0].listVal : '';
};
const getDateValue = dateValue => {
	return dateValue ? new Date(dateValue) : '';
};

// Get Values for Object
export const getValues = item => {
	let value;
	if (item.groupId === 'ReferenceDate') {
		value = getDateValue(item.value);
	} else if (item.fieldType === 'DateRange') {
		const startDate = get(item, 'value.startDate');
		const endDate = get(item, 'value.endDate');
		value = {
			startDate: startDate ? new Date(startDate) : null,
			endDate: endDate ? new Date(endDate) : null,
		};
	} else {
		value = item.value;
	}
	return value;
};

// Manipulate Mandatory, NonMandatory InputFields
export const manipulateSelectedDataset = (
	dataItem,
	datasetObject,
	inputData,
) => {
	dataItem.map(items => {
		find(datasetObject, selectedItem => {
			if (
				items.groupId === selectedItem.groupId &&
				selectedItem.value &&
				selectedItem.groupId === 'ReferenceDate'
			) {
				items.value = new Date(selectedItem.value);
				items.showCTP = selectedItem.showCTP;
				items.smartDateKey = selectedItem.smartDateKey;
				inputData[selectedItem.groupId] = {
					value: new Date(selectedItem.value),
					colText: selectedItem.colText,
				};
			} else if (
				items.groupId === selectedItem.groupId &&
				selectedItem.value &&
				selectedItem.groupId !== 'ReferenceDate'
			) {
				if (selectedItem.groupId === 'CTP1') {
					const startDateValue = get(selectedItem, 'value.startDate');
					const endDateValue = get(selectedItem, 'value.endDate');
					const selectedValue = {
						startDate: new Date(startDateValue),
						endDate: new Date(endDateValue),
					};
					items.value = selectedValue;
					items.showCTP = selectedItem.showCTP;
					inputData[selectedItem.groupId] = {
						startDate: {
							colText: 'Start Date',
							value: new Date(startDateValue),
						},
						endDate: {
							colText: 'End Date',
							value: new Date(endDateValue),
						},
					};
				} else {
					items.value = selectedItem.value;
					items.showCTP = selectedItem.showCTP;
					inputData[selectedItem.groupId] = getInputData(
						selectedItem,
					);
				}
			}
		});
		return null;
	});
	return dataItem;
};
const columnIncrement = (columnObj, cellColumn, incrmtValue) => {
	for (let cnt = 1; cnt <= incrmtValue; cnt += 1) {
		cellColumn = nextChar(`${cellColumn}`);
	}
	columnObj.cellColumn = cellColumn;
	return cellColumn;
};
const timePeriodNode = (itemTimePeriod, columnObj, cellColumn) => {
	let tpCellColumn = nextChar(`${cellColumn}`);
	let isCTPStatus = false;
	if (isCTP(itemTimePeriod, itemTimePeriod.key)) {
		const newCellColumn = columnIncrement(columnObj, cellColumn, 2);
		tpCellColumn = nextChar(`${newCellColumn}`);
		isCTPStatus = true;
	} else {
		columnObj.cellColumn = cellColumn;
	}
	return { tpCellColumn, isCTPStatus };
};

const nonMandatoryCTP = (
	fields,
	columnObj,
	cellColumn,
	isCTPLoaded,
	nodeObj,
) => {
	if (fields) {
		fields.map(itemList => {
			if (itemList.groupId !== 'CTP1' && itemList.value) {
				columnObj.cellColumn = cellColumn;
				cellColumn = nextChar(`${cellColumn}`);
			}
			if (itemList.groupId === 'CTP1' && !isCTPLoaded) {
				const newCellColumn = columnIncrement(columnObj, cellColumn, 2);
				cellColumn = nextChar(`${newCellColumn}`);
				return nodeObj;
			}
			return null;
		});
	}
};
export const updateColumnLastCellAddress = (inputNodes, inputCellValue) => {
	const { mandatoryFields, nonMandatoryFields, sponsorCodeNode } = inputNodes;
	let { cellColumn } = spiltCellValue(inputCellValue);
	const { cellRow } = spiltCellValue(inputCellValue);
	const nodeObj = [];
	const columnObj = { cellColumn, cellRow };
	let isCTPLoaded = false;
	if (sponsorCodeNode) {
		const newCellRow = cellRow + 1;
		columnObj.cellNewRow = newCellRow;
		cellColumn = nextChar(`${cellColumn}`);
	}
	const cellNewRow = cellRow + 1;
	if (mandatoryFields) {
		mandatoryFields.map(itemList => {
			const inputItemValue = itemList.value;
			columnObj.itemList = itemList;
			columnObj.cellNewRow = cellNewRow;
			if (Array.isArray(inputItemValue)) {
				inputItemValue.map(itemTimePeriod => {
					if (itemList.groupId === 'TimePeriod') {
						const { tpCellColumn, isCTPStatus } = timePeriodNode(
							itemTimePeriod,
							columnObj,
							cellColumn,
						);
						cellColumn = tpCellColumn;
						isCTPLoaded = isCTPStatus;
						return nodeObj;
					}

					columnObj.cellColumn = cellColumn;
					cellColumn = nextChar(`${cellColumn}`);
					return nodeObj;
				});
			} else if (itemList.groupId === 'CTP1') {
				const newCellColumn = columnIncrement(columnObj, cellColumn, 2);
				cellColumn = nextChar(`${newCellColumn}`);
				return nodeObj;
			} else if (inputItemValue !== '') {
				columnObj.cellColumn = cellColumn;
				cellColumn = nextChar(`${cellColumn}`);
				return nodeObj;
			}
			return null;
		});
	}
	nonMandatoryCTP(
		nonMandatoryFields,
		columnObj,
		cellColumn,
		isCTPLoaded,
		nodeObj,
	);
	updateSessionStorage(
		'inputCellValueRemove',
		`${columnObj.cellColumn}${columnObj.cellNewRow}`,
	);

	return `${columnObj.cellColumn}${columnObj.cellNewRow}`;
};

export const updateOutputCellAddress = (outputNodes, outputCellValue) => {
	const outputSelectedDataset = get(outputNodes, 'nonMandatoryFields');
	const { cellRow, cellColumn } = spiltCellValue(outputCellValue);
	let newCellColumn = cellColumn;
	outputSelectedDataset.map((itemList, index) => {
		if (index > 0) {
			newCellColumn = nextChar(`${newCellColumn}`);
		}
		return null;
	});
	const endOutputCellValue = `${newCellColumn}${cellRow}`;
	updateSessionStorage('endOutputCellValue', endOutputCellValue);
	return endOutputCellValue;
};
// Manipulate Mandatoryfields Values
export const getDatasetInput = (fieldsNode, mapFieldsValues) => {
	fieldsNode.map(items => {
		find(mapFieldsValues, selectedItem => {
			if (items.groupId === selectedItem.groupId && selectedItem.value) {
				items.value = getValues(selectedItem);
				items.defaultValue = getRadioBtnDefaultValue(selectedItem);
				items.smartDateKey = selectedItem.smartDateKey;
				items.datasetValue = getValues(selectedItem);
				items.showRadioBtn = selectedItem.showRadioBtn;
			}
		});
		return null;
	});
	return fieldsNode;
};
export const isFreqeuncyOrientation = (selectedDataset, mandatoryFields) => {
	const { ORIENTATION, FREQUENCY } = PERFORMANCE_COMMON;
	filter(mandatoryFields, itemList => {
		if (
			itemList.groupId === ORIENTATION.groupId ||
			itemList.groupId === FREQUENCY.groupId
		) {
			itemList.showRadioBtn = !!selectedDataset;
		}
		return itemList;
	});
};
// Function to Map selected Dataset with Service Fields
export const mapSelectedDataSetToInput = (
	inputNodes,
	selectedDataset,
	moveToCellSheet,
	duplicateClicked,
) => {
	const { MandatoryFields, NonMandatoryFields } = inputNodes;
	const {
		mandatoryFields,
		nonMandatoryFields,
		inputCellNo,
		inputSheetName,
	} = get(selectedDataset, 'inputNodes');
	isFreqeuncyOrientation(selectedDataset, mandatoryFields);
	const mandatoryInputObj = getDatasetInput(MandatoryFields, mandatoryFields);
	const nonMandatoryInputObj = getDatasetInput(
		NonMandatoryFields,
		nonMandatoryFields,
	);
	const inputObj = { ...mandatoryInputObj, ...nonMandatoryInputObj };
	const cellChangeData = {
		cellValue: inputCellNo,
		sheetName: inputSheetName,
		isValidExcelField: {
			isValidCellId: true,
			isValidSheetName: true,
		},
		isUsedCellAddress: true,
		isFirstRowSelected: false,
		isCellSheetSelected: !inputCellNo && !inputSheetName,
	};
	if (!duplicateClicked) {
		moveToCellSheet(inputCellNo, inputSheetName);
	}

	return { inputObj, cellChangeData };
};

// reorder optional fields and update CTP status for Dataset
export const updateDatasetOptionalField = optionalFields => {
	const optionalFieldsData = filter(optionalFields, item => {
		return item.groupId !== 'CTP1';
	});
	const ctpField = find(optionalFields, item => {
		return item.groupId === 'CTP1';
	});
	return [ctpField, ...optionalFieldsData];
};
const updateDatasetObject = (groupId, itemValue, itemText) => {
	return {
		key: groupId,
		text: itemText,
		value: itemValue,
	};
};
const nonMandatoryFieldsCTP = inputNodes => {
	const { nonMandatoryFields } = inputNodes;
	return find(nonMandatoryFields, item => {
		if (item.groupId === 'CTP1') {
			item.errorMsg = '';
		}
		return item.groupId === 'CTP1';
	});
};
const checkDateRangeValue = data => {
	const { startDate, endDate } = data;
	return startDate && endDate;
};
const ctpDatasetTileObj = (itemList, nodeObj) => {
	Object.keys(itemList.value).map(itemDate => {
		if (itemList.value[itemDate]) {
			const dateText = itemDate === 'startDate' ? 'StartDate' : 'EndDate';
			const dateFormatValue = formatDate(
				itemList.value[itemDate],
				'mm/dd/yyyy',
			);
			nodeObj.push(
				updateDatasetObject(
					itemList.groupId,
					dateFormatValue,
					dateText,
				),
			);
			return nodeObj;
		}
		return null;
	});
};
const updateInputCTPNodes = paramCTPNodes => {
	const {
		itemTimePeriod,
		inputNodes,
		nodeObj,
		timePeriodObject,
		itemTextValue,
		newColTextValue,
		groupId,
	} = paramCTPNodes;
	let { valueStatus } = paramCTPNodes;
	let isCTPStatus;
	if (isCTP(itemTimePeriod, itemTimePeriod.key)) {
		const valueDate = nonMandatoryFieldsCTP(inputNodes);
		const startDate = get(valueDate, 'value.startDate');
		const endDate = get(valueDate, 'value.endDate');
		const startDateText = colTextValue(itemTimePeriod, 'StartDate');
		const startDateFormat = formatDate(startDate, 'mm/dd/yyyy');
		const endDateText = colTextValue(itemTimePeriod, 'EndDate');
		const endDateFormat = formatDate(endDate, 'mm/dd/yyyy');
		const startDateGroupId = 'CTP.StartDate';
		nodeObj.push(
			updateDatasetObject(
				startDateGroupId,
				startDateFormat,
				startDateText,
			),
		);
		const endDateGroupId = 'CTP.EndDate';
		nodeObj.push(
			updateDatasetObject(endDateGroupId, endDateFormat, endDateText),
		);
		isCTPStatus = true;
	} else {
		timePeriodObject.push({
			key: itemTimePeriod.key,
			text: itemTextValue,
		});
		if (valueStatus < 1) {
			nodeObj.push({
				key: groupId,
				value: null,
				text: newColTextValue,
			});
		}
		valueStatus += 1;
	}
	const newValuestaus = valueStatus;
	return { newValuestaus, isCTPStatus };
};
const updateSponsorNode = (sponsorCodeNode, nodeObj) => {
	if (sponsorCodeNode) {
		const value = get(sponsorCodeNode, 'value.text');
		const { groupId, colText } = sponsorCodeNode;
		nodeObj.push(updateDatasetObject(groupId, value, colText));
	}
};
const arrayPrototypeObj = dataObj => {
	return Array.prototype.map
		.call(dataObj, item => {
			return item.text;
		})
		.join(',');
};
//1163204:Index Search
export const arrayPrototypeIndexObj = dataObj => {
	return Array.prototype.map
		.call(dataObj, item => {
			return item.value;
		})
		.join(',');
};

export const updateInputNodeobj = inputNodes => {
	const { mandatoryFields, nonMandatoryFields, sponsorCodeNode } = inputNodes;
	const nodeObj = [];
	const timePeriodObject = [];
	let isCTPLoaded = false;
	updateSponsorNode(sponsorCodeNode, nodeObj);
	mandatoryFields.map(itemList => {
		const newColTextValue = itemList.colText;
		const { groupId, value } = itemList;
		if (Array.isArray(value)) {
			let valueStatus = 0;
			value.map(itemTimePeriod => {
				const itemTextValue = get(itemTimePeriod, 'text');
				if (groupId === 'TimePeriod') {
					const paramCTPNodes = {
						itemTimePeriod,
						inputNodes,
						nodeObj,
						timePeriodObject,
						itemTextValue,
						valueStatus,
						newColTextValue,
						groupId,
					};
					const { newValuestaus, isCTPStatus } = updateInputCTPNodes(
						paramCTPNodes,
					);
					valueStatus = newValuestaus;
					isCTPLoaded = isCTPStatus;
					return nodeObj;
				}
				nodeObj.push(
					updateDatasetObject(
						groupId,
						itemTextValue,
						newColTextValue,
					),
				);
				return nodeObj;
			});
		} else if (groupId === 'FromDate' || groupId === 'CTP1') {
			ctpDatasetTileObj(itemList, nodeObj);
		} else if (groupId === 'ReferenceDate') {
			const dateFormatValue = formatDate(value, 'mm/dd/yyyy');
			nodeObj.push(
				updateDatasetObject(groupId, dateFormatValue, newColTextValue),
			);
		} else if (value !== '') {
			const inputTextValue = get(value, 'text');
			const newInputItemValue = !inputTextValue ? value : inputTextValue;

			nodeObj.push(
				updateDatasetObject(
					groupId,
					newInputItemValue,
					newColTextValue,
				),
			);
			return nodeObj;
		}
		return null;
	});
	nonMandatoryFields.map(itemList => {
		const { value } = itemList;
		if (itemList.groupId !== 'CTP1' && itemList.value) {
			if (Array.isArray(value)) {
				const objectItem = [];
				value.map(valueList => {
					objectItem.push(valueList);
					return valueList;
				});
				const newVal = arrayPrototypeObj(objectItem);
				nodeObj.push(
					updateDatasetObject(
						itemList.groupId,
						newVal,
						itemList.colText,
					),
				);
			} else {
				const { text } = get(itemList, 'value');
				const newColTextValue = itemList.colText;
				nodeObj.push(
					updateDatasetObject(
						itemList.groupId,
						text || value,
						newColTextValue,
					),
				);
			}
		}
		if (itemList.groupId === 'CTP1' && !isCTPLoaded) {
			const dateRange = checkDateRangeValue(itemList.value);
			if (dateRange) {
				ctpDatasetTileObj(itemList, nodeObj);
			}
		}
		return null;
	});
	find(nodeObj, itemList => {
		if (itemList.key === 'TimePeriod') {
			itemList.value = timePeriodObject?.length>0 ? arrayPrototypeObj(timePeriodObject) : itemList.value;
		}
		return null;
	});
	return nodeObj;
};
export const createReportDatasetObject = (data, id) => {
	const { reports } = data;
	return find(reports, listItem => {
		return listItem.reportId === id || listItem.reportName === id;
	});
};

export const getRangeToCleare = item => {
	const { inputNodes, outputNodes, displaySetting, datasetRange } = item;
	const inputRange = `${inputNodes.inputCellNo}:${inputNodes.inputCellValueRemove}!${inputNodes.inputSheetName}`;
	const outputRange = `${outputNodes.outputCellNo}:${outputNodes.endOutputCellValue}!${outputNodes.outputSheetName}`;
	const titleRange = `${displaySetting.cellId}!${displaySetting.sheetName}`;
	return { titleRange, inputRange, outputRange, datasetRange };
};

export const clearDatasetData = range => {
	const { datasetRange, inputRange, outputRange, titleRange } = range;
	const reportRange = datasetRange ? datasetRange.split('!') : [];
	const input = inputRange.split('!');
	const output = outputRange.split('!');
	const title = titleRange.split('!');
	clearExcelData(reportRange[0], reportRange[1]);
	clearExcelData(input[0], input[1]);
	clearExcelData(output[0], output[1]);
	clearExcelData(title[0], title[1]);
};

export const clearRenderedData = dataset => {
	let dataRangeToClear;
	let clearData = false;
	if (dataset && !dataset.duplicateClicked) {
		dataRangeToClear = dataset.datasetRange;
	} else {
		dataRangeToClear = getLocalStorageItem('outputDataRange');
		clearData = true;
	}
	if (dataRangeToClear) {
		const range = dataRangeToClear.split('!');
		if (clearData) {
			clearExcelData(range[0], range[1]);
		}
	}
};
