import React, { useState, useEffect } from 'react';
import {
	DatePicker,
	initializeIcons,
	createTheme,
} from 'office-ui-fabric-react';
import { formatDate } from 'utils/dateUtil';
import { validateDateDiffError } from 'utils/validationRules';
import { DefaultColors } from 'styles/colors';
import { get, find } from 'lodash';
import { DayPickerStrings } from '../constants/constants';
import InformationIcon from '../../informationIcon/InformationIcon';
import DateRangeConst from './DateRangeTexts';
import './DateRange.scss';

initializeIcons();

const DateRange = props => {
	const { pattern, field, onDateRangeChange, messages, data, allowTextInput } = props;
	const { showWarnging, referenceDate } = field;
	const [showEndDateWarning, setShowEndDateWarning] = useState(false);
	const { startDateStrings, endDateStrings } = DayPickerStrings;
	const dateRangePlaceholder = get(messages, 'dateRangePlaceholder');
	const dateRangeTooltip = get(messages, 'dateRangeTooltip');
	const { defaultPlaceholder, defaultTooltip } = DateRangeConst;
	const { optionalFields } = data;
	const showDiffError = find(optionalFields, item => {
		return item.CTPLable === 'Frequency' && item.value.key === 'RD';
	});

	const onFormatDate = date => {
		return formatDate(date, pattern);
	};

	const onDateChangeClick = (id, endDate) => {
		const isEndDate = id === 'endDate' && endDate;
		const isReferenceDate = referenceDate && referenceDate !== '';
		const showWarning = isEndDate && isReferenceDate;
		if (showWarning) {
			setShowEndDateWarning(true);
		}
	};
	const startDateGreaterErrorMsg = (value, text) => {
		const startDate = get(value, 'startDate');
		const endDate = get(value, 'endDate');
		const dateValue = startDate && endDate;
		const textId = text.id === 'startDate' && startDate > endDate;
		return dateValue && textId;
	};
	const startDateEqualErrorMsg = (value, text) => {
		let startDate = get(value, 'startDate');
		let endDate = get(value, 'endDate');
		startDate = startDate ? formatDate(startDate, pattern) : null;
		endDate = endDate ? formatDate(endDate, pattern) : null;
		const dateValue = startDate && endDate;
		const textId = text.id === 'startDate' && startDate === endDate;
		return dateValue && textId;
	};
	const emptyDateErrorMsg = (valid, value, text) => {
		const dateValue = value[text.id];
		const isDateValid = valid[text.id];
		return !dateValue && !isDateValid;
	};
	const dateDiffErrorMsg = (value, text) => {
		const isValidDateDiff = validateDateDiffError(value);
		return showDiffError && isValidDateDiff && text.id === 'endDate';
	};
	const borderErrorBox = (valid, value, text) => {
		const showBorderErrorBox = emptyDateErrorMsg(valid, value, text);
		const startDateGrater = startDateGreaterErrorMsg(value, text);
		const startDateEqualEndDate = startDateEqualErrorMsg(value, text);
		const startDateDiffEndDate = dateDiffErrorMsg(value, text);
		return (
			showBorderErrorBox ||
			startDateGrater ||
			startDateEqualEndDate ||
			startDateDiffEndDate
		);
	};
	useEffect(() => {
		setShowEndDateWarning(showWarnging);
	}, [referenceDate]);

	const getDateValue = value => {
		return value && value !== '' && new Date(value);
	};

	const getDatePickerComponent = text => {
		const { value, valid, mandatory } = field;
		const showWarning =
			text.id === 'endDate' && referenceDate && referenceDate !== '';
		const theme = createTheme({
			palette: {
				neutralSecondary: borderErrorBox(valid, value, text)
					? DefaultColors.ErrorColor
					: DefaultColors.BoxColor,
			},
		});
		const selectedDate = value ? getDateValue(value[text.id]) : null;
		return (
			<div className="date-range">
				<InformationIcon
					labelId={text.id}
					label={text.label}
					tooltipMessage={
						dateRangeTooltip
							? dateRangeTooltip[text.id]
							: defaultTooltip[text.id]
					}
					mandatory={mandatory}
				/>

				<DatePicker
					// className={style}
					// label={text.label}
					allowTextInput={allowTextInput}
					placeholder={
						dateRangePlaceholder ||
						text.placeholder ||
						defaultPlaceholder
					}
					id={
						borderErrorBox(valid, value, text)
							? `${text.id}Error`
							: text.id
					}
					showMonthPickerAsOverlay
					strings={DayPickerStrings}
					onSelectDate={date =>
						onDateRangeChange(date, text.id, showWarning)
					}
					onFocus={() => onDateChangeClick(text.id, value.endDate)}
					isMonthPickerVisible={false}
					formatDate={date => onFormatDate(date)}
					maxDate={new Date()}
					value={selectedDate}
					theme={theme}
				/>
				{showEndDateWarning ? (
					<p className="warningClass">{text.warningMsg}</p>
				) : null}
				{emptyDateErrorMsg(valid, value, text) ? (
					<p className="errorClass">{valid[`${text.id}ErrorMsg`]}</p>
				) : null}
				{startDateGreaterErrorMsg(value, text) ? (
					<p className="errorClass">
						{text.startDateGreaterErrorMsg}
					</p>
				) : null}
				{startDateEqualErrorMsg(value, text) ? (
					<p className="errorClass">{text.startDateEqualErrorMsg}</p>
				) : null}
				{dateDiffErrorMsg(value, text) ? (
					<p className="errorClass">{text.startDateDiffErrorMsg}</p>
				) : null}
			</div>
		);
	};
	return (
		<>
			<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
				{getDatePickerComponent(startDateStrings)}
			</div>
			<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
				{getDatePickerComponent(endDateStrings)}
			</div>
		</>
	);
};

export default DateRange;
