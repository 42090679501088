import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
	Fabric,
	PrimaryButton,
	DefaultButton,
	Spinner,
	SpinnerSize,
} from 'office-ui-fabric-react';
import LoadingOverlay from 'react-loading-overlay';
import { ThrobberMessage } from 'components';
import DatasetManagerBody from 'components/datasetManager/DatasetManagerBody';
import messages from 'containers/datasetManager/messages/DatasetManagerTexts';
import {
	onServiceClick,
	onDatasetSelect,
} from 'containers/createNewDataset/CreateNewDatasetAction';
import { createReportDatasetObject, clearDatasetData } from 'utils/datasetUtil';
import {
	generateUniqueIdData,
	updateUniqueId,
	updateRunAllStatus,
} from 'utils/GetDataUtil';
import { replaceStr } from 'utils/CommonUtil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getWorkbookId } from 'utils/excelUtils/ExcelDataPopulate';
import {
	getDataset,
	cleareStoredDataset,
} from 'containers/welcome/WelcomeAction';
import { get, isEmpty, filter, cloneDeep, find } from 'lodash';
import DatasetManagerDefaultBody from 'components/datasetManager/DatasetManagerDefaultBody';
import {
	updateDatasetToCosmos,
	displaySettingAction,
	onDataRendered,
} from 'containers/displaySettings/DisplaySettingsAction';
import { useModal } from '../../hooks/useModal';
import {
	updateSessionStorage,
} from 'utils/CommonUtil';
import {
	getData,
	udpateAsofDateDataset,
	getDatasetRange,
	updateDatasetVal,
	manipulateDataset,
	cellUpdateOnDuplicate,
	datasetObjIDUpdate,
	updateDataSetValMethod,
	eventDateFormat,
	isDatasetLoaded,
	createDatasetObject,
	onOptionsSelect,
	onAllClick,
	runAllClick,
	runAllFailMsg,
	validateFutureDate,
} from './DatasetManagerAction';

const DatasetManager = props => {
	const {
		history,
		dataSet,
		readDataset,
		clearDataset,
		selectedDataset,
		serviceClicked,
		updateDataset,
		profile,
		isLoading,
		getDataClick,
		onGetData,
		updateAsOfDate,
		isDataRendered,
		updateDatasetRange,
		onRunAllClick,
		isRunAllClick,
		showFailMsg,
	} = props;
	const { modalTexts, validationMsg } = messages;
	const { show, hide, RenderModal } = useModal(); // we could also spread 'hide' here, if we somehow needed it outside of the modal
	const [workbookId, setWorkbookId] = useState('');
	const [datasetObject, setDatasetObject] = useState([]);
	const [reportDatasetObject, setReportDatasetObject] = useState([]);
	const [asOfDate, setAsOfDate] = useState(null);
	const [isCalloutVisible, setIsCallOutVisible] = useState(false);
	const [idSelectedTile, setIdSelectedTitle] = useState(null);
	const [isDatasetEmpty, setIsDatasetEmpty] = useState(true);
	const [datasetToDelete, setDatasetToDelete] = useState(null);
	const [renamedataset, setRenameDataset] = useState('');
	const [renamedatasetValue, setRenameDatasetValue] = useState('');
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [isValidReportTitle, setIsValidReportTitle] = useState({
		valid: true,
		errorMessage: '',
	});

	const [showErrorMsg, setShowErrorMsg] = useState(false);
	
	const firstRender = useRef(true);
	const successReportValue = [];
	const onClickInfoIcon = event => {
		setIdSelectedTitle(event.target.id);
		setIsCallOutVisible(!isCalloutVisible);
	};
	// below method fires action to read data from cosmos DB by passing id as query param

	const onWorkbookIdRecevied = useCallback(
		id => {
			if (!isEmpty(id) && !!id[0][0]) {
				setWorkbookId(id[0]);
				readDataset(id[0]);
			} else {
				clearDataset();
			}
		},
		[readDataset, clearDataset],
	);

	useEffect(() => {
		firstRender.current = false;
		return () => {
			firstRender.current = true;
		};
	}, []);

	useEffect(() => {
		getWorkbookId(onWorkbookIdRecevied);
	}, [onWorkbookIdRecevied]);

	const createDatasetNewObject = useCallback(
		data => {
			createDatasetObject(data, datasetObject, setDatasetObject);
		},
		[datasetObject],
	);
	// useEffect triggers when dataset object change
	useEffect(() => {
		if (workbookId) {
			setReportDatasetObject(dataSet);
			createDatasetNewObject(dataSet);
		}
		setIsDatasetEmpty(dataSet && isEmpty(dataSet.reports));
	}, [dataSet, workbookId]);

	const isGetDataRendered = (datasetRange, id) => {
		const selectedSet = [];
		const dataset = updateDatasetRange(datasetRange, id);
		updateDatasetVal(datasetObject, selectedSet);
		setSelectedKeys(selectedSet);
		updateRunAllStatus(dataset, isDataRendered);
	};

	const onCreateNewDatasetClick = () => {
		history.push(`/create-new-dataset`);
	};

	const onAsOfDateChange = event => {
		const newAsOfDate = eventDateFormat(event);
		setAsOfDate(newAsOfDate);
	};

	const onAsOfDateCalChange = (event, id) => {
		const newAsOfDateCal = eventDateFormat(event);
		const { reports } = dataSet;
		const newReport = manipulateDataset(reports, newAsOfDateCal, id);
		dataSet.reports = newReport;
		updateAsOfDate(dataSet);
		const datasetUpdateObject = datasetObjIDUpdate(
			datasetObject,
			id,
			newAsOfDateCal,
		);
		setDatasetObject(datasetUpdateObject);
	};

	const onApplyAllClick = () => {
		onAllClick(
			dataSet,
			datasetObject,
			asOfDate,
			updateAsOfDate,
			setDatasetObject,
		);
		let selectedItem= datasetObject?.some(item=>item.selected===true);
			if (!!selectedItem && asOfDate && !!validateFutureDate(asOfDate)) {
				setShowErrorMsg(false);
			}else{
				setShowErrorMsg(true);
			}
	};

	const onChangeDatasetSelect = (event, option) => {
		onOptionsSelect(
			option,
			datasetObject,
			setSelectedKeys,
			setDatasetObject,
		);
	};

	const callAction = (option, type) => {
		const { id } = option;
		updateUniqueId(true);
		const data = createReportDatasetObject(reportDatasetObject, id);
		const newData = cloneDeep(data);
		const isDuplicate = type === 'DUPLICATE';
		if (newData) {
			updateSessionStorage('getDataCellValue', newData.displaySetting.cellId);
			cellUpdateOnDuplicate(isDuplicate, newData);
			newData.duplicateClicked = isDuplicate;
			newData.editClicked = type === 'EDIT';
			//XDE-5631
			if(type === 'EDIT'){
				updateSessionStorage('getDataCellValue_old', newData.displaySetting.cellId);
				updateSessionStorage('startCellValue_old', newData.inputNodes.inputCellNo);
				updateSessionStorage('startOutputCellValue_old',newData.outputNodes.outputCellNo);
				updateSessionStorage('outputCellValue_old',newData.outputNodes.outputCellNo);
				updateSessionStorage('isDisplayDataCellChanged', 'Y');
				updateSessionStorage('noBtnClickFlag', 'NO');
				updateSessionStorage('isInputCellNotChanged', 'NO');
				updateSessionStorage('outputSheetName_Old', newData.outputNodes.outputSheetName);
				updateSessionStorage('inputSheetName_Old', newData.inputNodes.inputSheetName);
				updateSessionStorage('getDataSheetName_Old', newData.displaySetting.sheetName);
				updateSessionStorage('inputSheet_Change', 'N');
				updateSessionStorage('outputSheet_Change', 'N');
				updateSessionStorage('displaySheet_Change', 'N');
				
			}
			selectedDataset(newData);
			serviceClicked(newData.serviceData, history);
		}
	};

	const onClickEditOption = (event, option) => {
		callAction(option, 'EDIT');
	};

	const onClickDeleteOption = (event, option) => {
		setDatasetToDelete(option);
		show();
	};

	const modifyDataset = (
		updatedDataset,
		updateDatasetToDB,
		range,
		isClearDataset,
	) => {
		const { uniqueId } = generateUniqueIdData(profile.personaId);
		// createHiddenSheet(uniqueId);
		dataSet.reports = updatedDataset;
		dataSet.hiddenWorksheetID = uniqueId;
		dataSet.id = uniqueId;
		dataSet.sheetID = uniqueId;
		updateDatasetToDB(JSON.stringify(dataSet)).then(() => {
			if (isClearDataset) {
				setSelectedKeys([]);
				setAsOfDate(null);
				clearDatasetData(range);
			}
		});
	};

	const onYesClick = () => {
		const { range, id } = datasetToDelete;
		const { reports } = dataSet;
		const updatedDataset = filter(reports, item => {
			return item.reportName !== id;
		});
		modifyDataset(updatedDataset, updateDataset, range, true);
		hide();
	};

	const onClickRenameOption = (event, value) => {
		setRenameDataset(value.serviceText);
		setRenameDatasetValue(value.serviceText);
	};

	const onChangeTextField = event => {
		const { value } = event.target;
		setRenameDatasetValue(value);
		setIsValidReportTitle({
			valid: true,
			errorMessage: '',
		});
	};

	const onSaveRenamedDataset = () => {
		const updatedDataset = updateDataSetValMethod(
			dataSet,
			renamedataset,
			renamedatasetValue,
			setIsValidReportTitle,
			validationMsg,
		);
		if (updatedDataset) {
			modifyDataset(updatedDataset, updateDataset);
			setRenameDataset('');
		}
	};

	const onDuplicateDataset = (event, option) => {
		callAction(option, 'DUPLICATE');
	};
	const onGetDataIconClick = id => {
		if (id) {
			getDataClick(id, onGetData, isGetDataRendered);
		}
	};
	const updateDatasetAfterRunAll = (successDatasetId, runAllId, reports) => {
		if (parseInt(successDatasetId, 10) === parseInt(runAllId, 10)) {
			delete reports.totalRunReports;
			modifyDataset(reports, updateDataset);
			onRunAllClick(false);
			successReportValue.splice(0, successReportValue.length);
		}
	};
	const isGetDataAll = (datasetRange, id) => {
		const { reports } = dataSet;
		const totalRunReports = get(reports, 'totalRunReports');
		if (reports) {
			find(reports, items => {
				if (items.reportId === id) {
					items.datasetRange = datasetRange;
					successReportValue.push(id);
				}
			});
			const totalDatasetId = successReportValue.length;
			updateDatasetAfterRunAll(totalDatasetId, totalRunReports, reports);
		}
	};
	const runAllBtnClick = () => {
		const { reports } = dataSet;
		onRunAllClick(true);
		const promises = [];
		reports.forEach(item => {
			promises.push(getDataClick(item.reportId, onGetData, isGetDataAll));
		});
		try {
			Promise.all(promises).then(response => {
				let runAllId;
				Object.keys(reports).map(key => {
					if (!response[key].success) {
						reports[key].isRunAllFail = true;
						showFailMsg(true);
					} else {
						reports[key].isRunAllFail = false;
						runAllId = runAllId ? parseInt(runAllId, 10) + 1 : 1;
					}
					return reports;
				});
				reports.totalRunReports = runAllId;
				const totalDatasetId = successReportValue.length;
				updateDatasetAfterRunAll(totalDatasetId, runAllId, reports);
				return response;
			});
		} catch (e) {
			return e;
		}
		return null;
	};
	const isDatasetObject = isDatasetLoaded(firstRender, datasetObject);
	return (
		<Fabric>
			{isDatasetEmpty ? (
				<DatasetManagerDefaultBody
					{...messages}
					onCreateNewDatasetClick={onCreateNewDatasetClick}
				/>
			) : (
				<>
					<LoadingOverlay
						active={isLoading || isRunAllClick}
						spinner={<Spinner size={SpinnerSize.large} />}
						text={<ThrobberMessage />}
					>
						<DatasetManagerBody
							{...messages}
							dataSetObject={isDatasetObject}
							asOfDate={asOfDate}
							onAsOfDateChange={onAsOfDateChange}
							onApplyAllClick={onApplyAllClick}
							onDatasetSelect={onChangeDatasetSelect}
							selectedKeys={selectedKeys}
							isCalloutVisible={isCalloutVisible}
							onClickInfoIcon={onClickInfoIcon}
							idSelectedTile={idSelectedTile}
							onClickEditOption={onClickEditOption}
							onAsOfDateCalChange={onAsOfDateCalChange}
							onClickDeleteOption={onClickDeleteOption}
							onClickRenameOption={onClickRenameOption}
							renamedataset={renamedataset}
							onChangeTextField={onChangeTextField}
							renamedatasetValue={renamedatasetValue}
							onSaveRenamedDataset={onSaveRenamedDataset}
							onDuplicateClicked={onDuplicateDataset}
							onGetDataIconClick={onGetDataIconClick}
							runAll={runAllBtnClick}
							isValidReportTitle={isValidReportTitle}
							showErrorMsg={showErrorMsg}
						/>
						<RenderModal title={modalTexts.title}>
							<p>
								{replaceStr(
									modalTexts.description,
									get(datasetToDelete, 'serviceText'),
								)}
							</p>
							<DefaultButton
								id="no-btn"
								text={modalTexts.btnNo}
								onClick={hide}
								style={{ margin: '6px', float: 'left' }}
							/>
							<PrimaryButton
								id="yes-btn"
								text={modalTexts.btnYes}
								onClick={onYesClick}
								style={{ margin: '6px', float: 'left' }}
							/>
						</RenderModal>
					</LoadingOverlay>
				</>
			)}
		</Fabric>
	);
};

const mapStateToProps = state => ({
	dataSet: get(state, 'data.dataSet.data'),
	profile: get(state, 'data.userInContext.user.profile'),
	isLoading: get(state, 'data.getData.loading'),
	isRunAllClick: get(state, 'data.dataSetManager.isRunAllClick'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			selectedDataset: onDatasetSelect,
			serviceClicked: onServiceClick,
			readDataset: getDataset,
			clearDataset: cleareStoredDataset,
			updateDataset: updateDatasetToCosmos,
			getDataClick: getData,
			onGetData: displaySettingAction,
			isDataRendered: onDataRendered,
			updateAsOfDate: udpateAsofDateDataset,
			updateDatasetRange: getDatasetRange,
			onRunAllClick: runAllClick,
			showFailMsg: runAllFailMsg,
		},
		dispatch,
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatasetManager);
