import React from 'react';
import './WelcomeBody.module.scss';
import { Text, PrimaryButton, Dropdown } from 'office-ui-fabric-react';
import NtLogo from 'components/common/NtLogo';
import BasicList from 'components/common/list/BasicList';
import { isEmpty } from 'lodash';
import WelcomeDescription from './WelcomeDescription';

const WelcomeBody = props => {
	const {
		disabled,
		checked,
		welcomeTexts,
		welcomeListMessage,
		createNewButtonText,
		currentDatasetDropdown,
		onNextClick,
		dataSetObject,
		onSelectService,
		selectedKeyItem,
		heading,
		isWelcomeShow,
		isClick,
		welComeAccordion,
		totalMessages,
	} = props;
	return (
		<div className="welcome-container">
			<div className="ms-Grid">
				<div>
					<NtLogo
						className="welcome-nt-logo"
						id="welcome-logo"
						alttext="Stacked Centered Default Anchor Logo"
					/>
				</div>
				<div className="ms-Grid-row welcome-heading">
					<div className="ms-Grid-col ms-sm12">
						{heading || welcomeTexts.heading}
					</div>
				</div>
				<div className="ms-Grid-row welcome-sub-heading">
					<div className="ms-Grid-col ms-sm12">
						{welcomeTexts.subHeading}
					</div>
				</div>
				<div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12 welcome-description">
					<WelcomeDescription content={welcomeTexts.content} />
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 create-dataset-button">
						<PrimaryButton
							className="create-dataset"
							id="create-dataset-button"
							text={createNewButtonText}
							onClick={onNextClick}
							allowDisabledFocus
							disabled={disabled}
							checked={checked}
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<Text className="or-text" variant="medium" block>
						{welcomeTexts.dataSetOr}
					</Text>
				</div>

				<div className="ms-Grid-row">
					<Dropdown
						id="save-dataset"
						className="save-dataset"
						selectedKey={selectedKeyItem || null}
						placeholder={
							!isEmpty(dataSetObject)
								? currentDatasetDropdown.label
								: currentDatasetDropdown.placeholder
						}
						onChange={onSelectService}
						options={dataSetObject || []}
						disabled={isEmpty(dataSetObject)}
					/>
				</div>
				<div className="ms-Grid-row">
					<BasicList
						message={welcomeListMessage}
						welComeAccordion={welComeAccordion}
						isClick={isClick}
						isWelcomeShow={isWelcomeShow}
						totalMessages={totalMessages}
					/>
				</div>
			</div>
		</div>
	);
};

export default WelcomeBody;
