// import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';
import { DarkPalette } from './palette';

export const DefaultColors = {
	Application: {
		Primary: '#0078d7',
		Secondary: '#e5e5e5',
		LightBackground: '#ffffff',
		NeutralBackground: '#e5e5e5',
	},
	VisualIndicators: {
		GrayIndicator: '#7a7574',
		GreenIndicator: '#498205',
		YellowIndicator: '#fce100',
		RedIndicator: '#d1343b',
		BlueIndicator: '#0078d7',
	},
	Text: {
		Header: '#333333',
		Primary: '#333333',
		Secondary: '#858585',
		HeroAccent: '#0078D7',
		Error: '#a4262c',
	},
	Link: {
		PrimaryLink: '#0078d4',
		HeroLink: '#005a9e',
	},
	List: {
		HeaderText: '#858585',
		RowText: '#333333',
	},
	Item: {
		ListItemHoverBackgroundColor: '#faf9f8',
		ItemIndicator: '#0078d7',
	},
	Icon: {
		SecondaryIcon: '#858585',
		PrimaryIcon: '#333333',
	},
	ErrorColor: '#a4262c',
	BoxColor: '#605e5c',
};

export const DarkColors = {
	Application: {
		Primary: '#0078d7',
		Secondary: '#e5e5e5',
		LightBackground: '#ffffff',
		NeutralBackground: '#e5e5e5',
	},
	VisualIndicators: {
		GrayIndicator: '#7a7574',
		GreenIndicator: '#498205',
		YellowIndicator: '#fce100',
		RedIndicator: '#d1343b',
		BlueIndicator: '#0078d7',
	},
	Text: {
		Header: DarkPalette.neutralPrimary,
		Primary: DarkPalette.neutralPrimary,
		Secondary: DarkPalette.neutralSecondary,
		HeroAccent: '#0078D7',
		Error: DarkPalette.redDark,
	},
	Link: {
		PrimaryLink: DarkPalette.themePrimary,
		HeroLink: DarkPalette.themeDark,
	},
	List: {
		HeaderText: DarkPalette.neutralSecondary,
		RowText: DarkPalette.neutralPrimary,
	},
	Item: {
		ListItemHoverBackgroundColor: DarkPalette.neutralLighterAlt,
		ItemIndicator: '#0078d7',
	},
	Icon: {
		SecondaryIcon: DarkPalette.neutralSecondary,
		PrimaryIcon: DarkPalette.neutralPrimary,
	},
};
