import DatasetManagerPage from './DatasetManagerPage';

export default {
	routeProps: {
		path: '/dataset-manager',
		exact: true,
		component: DatasetManagerPage,
	},
	name: 'DatasetManagerPage',
};
