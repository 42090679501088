import { get, toUpper, find, keys, filter, isEmpty } from 'lodash';
import {
	getSmartDate,
	lastDateOfLastYear,
	lastDateofLastMonth,
	lastDateOfMidYear,
	lastDateOfMidMonth,
} from 'utils/dateUtil';
import { PERFORMANCE_COMMON } from 'utils/Constants';

const {
	RADIO_BUTTON,
	TIME_PERIOD,
	REFRENCE_DATE,
	DATE_RANGE,
	DISPLAY_ORIENTATION,
	PERFORMANCE_IDENTIFIER,
	ORIENTATION,
	FREQUENCY,
	CALENDAR_MONTH_TIME_PERIOD,
	TIME_PERIOD_Single,
	CTR_SERVICE_NAME,
	RFCTPRBR,
	RFCTPSBS,
	RFCTP
} = PERFORMANCE_COMMON;
// Update Value for Radio Button
const getRadioBtnDefaultValue = item => {
	if (item.value && item.value !== '') {
		return item.value;
	}
	const selectedValue = get(item, 'dropDowns');
	const isValidVal =
		!isEmpty(selectedValue) &&
		toUpper(item.fieldType) === toUpper('Radiobutton');
	return isValidVal ? selectedValue[0].listVal : '';
};

const getDateValue = item => {
	const options = { key: item.defaultOption };
	const defaultValue = item.defaultOption ? getSmartDate(options) : '';
	return item.value ? new Date(item.value) : defaultValue;
};
export const getDefaultValueOptional = (item, defaultOption) => {
	const { dropDowns } = item;
	return find(dropDowns, option => {
		return option.listVal === defaultOption;
	});
};
export const defaultTimePeriod = item => {
	const defaultData = item.defaultOption ? item.defaultOption.split(',') : [];
	const dataObj = [];
	defaultData.map(iteList => {
		const newValue = getDefaultValueOptional(item, iteList);
		dataObj.push(newValue);
		return null;
	});
	return dataObj;
};
const getDefaultValue = item => {
	const { defaultOption } = item;
	if (item.fieldType === RADIO_BUTTON) {
		return defaultOption;
	}
	if (item.groupId === TIME_PERIOD) {
		return defaultTimePeriod(item);
	}
	const { dropDowns } = item;
	return find(dropDowns, option => {
		return option.listVal === defaultOption;
	});
};
const getDefaultDate = (item, mandatory, serviceName) => {
	const dateDefault = item.colId === 'CTP' && mandatory;
	const starDateVal = get(item, 'value.startDate');
	const endDateVal = get(item, 'value.endDate');
	const startDate =
		dateDefault && !starDateVal
			? new Date(lastDateOfMidYear())
			: starDateVal;
	const endDate =
		dateDefault && !endDateVal
			? new Date(lastDateOfMidMonth())
			: endDateVal;
	return { startDate, endDate };
};
const getValues = (item, mandatory, serviceName) => {
	let value;
	if (item.groupId === REFRENCE_DATE) {
		value = item.value || getDateValue(item);
	} else if (item.fieldType === DATE_RANGE || item.groupId === 'CTP1') {
		const { startDate, endDate } = getDefaultDate(item, mandatory, serviceName);
		value = {
			startDate: startDate ? new Date(startDate) : null,
			endDate: endDate ? new Date(endDate) : null,
		};
	} else {
		value = item.value || getDefaultValue(item);
	}
	return value;
};
// reorder optional fields and update CTP status
export const updateShowCTP = (optionalFields, showCTPFlag) => {
	const optionalFieldsData = filter(optionalFields, item => {
		item.showCTP = item.groupId === 'CTP1' ? showCTPFlag : false;
		return item.groupId !== 'CTP1';
	});
	const ctpField = find(optionalFields, item => {
		item.showCTP = item.groupId === 'CTP1' ? showCTPFlag : false;
		return item.groupId === 'CTP1';
	});
	return [ctpField, ...optionalFieldsData];
};
// method used to create CTP object for time period drop down
export const getCustomTP = items => {
	return {
		key: 'CTP',
		listId: 'CTP',
		listVal: 'Custom Time Periods',
		text: 'Custom Time Periods',
		label: 'Custom Time Periods',
		value: 'CTP',
		elementId: items.groupId,
	};
};
const getListData = (items, serviceName) => {
	const { dropDowns } = items;
	const isCTPPresent = filter(items.dropDowns, item => item.key === 'CTP');

	const uniqData = dropDowns.map(item => {
		return {
			listId: item.listId,
			listVal: item.listVal,
			key: item.listId,
			text: item.listVal,
			label: item.listVal,
			value: item.listId,
			elementId: items.groupId,
			helpText: item.helpText,
		};
	});
	
	if ((items.groupId === 'TimePeriod' || items.groupId === 'TimePeriodSingle') && isEmpty(isCTPPresent) && CTR_SERVICE_NAME !== serviceName) {
		const ctp = getCustomTP(items);
		uniqData.push(ctp);
	}
	return uniqData;
};

const getIsValid = item => {
	const dateStatus = {
		startDate: true,
		endDate: true,
	};
	return item.fieldType === DATE_RANGE ? dateStatus : null;
};
// used to create inputField object for dynamic form rendering
export const getInputNodeData = (nodeData, mandatory, serviceName) => {
	const nodeObject = [];
	nodeData.forEach(item => {
		if (Object.keys(item).length !== 0 && item.constructor === Object) {
			const { smartDateKey, colHelp } = item;
			item.listItem = getListData(item, serviceName);
			item.dropDowns = getListData(item, serviceName);
			const defaultValue = getRadioBtnDefaultValue(item);
			const selectedValue = getValues(item, mandatory, serviceName);
			item.mandatory = mandatory;
			item.value = selectedValue || defaultValue;
			item.smartDateKey = smartDateKey || item.defaultOption;
			item.showCTP = item.fieldType === DATE_RANGE;
			item.errorMsg = '';
			item.CTPLable = item.colText;
			item.showCTPLable = false;
			item.valid = getIsValid(item) || true;
			item.toolTipMsg = colHelp;
			item.referenceDate = null;
			item.defaultValue = defaultValue;
			nodeObject.push(item);
		}
	});
	return nodeObject;
};

const dropDownObject = (newItem, toolTipMsg, showIconbar) => {
	return {
		listId: newItem.trim(),
		listVal: newItem.trim(),
		helpText: showIconbar ? toolTipMsg : null,
	};
};
// common method to create radio button. pass tooltipMsg param for options level help text
export const createRadioBtn = (group, options, tooltipMsg) => {
	group.dropDowns = options.map((newItem, index) => {
		let helpText;
		if (toUpper(group.groupId) === DISPLAY_ORIENTATION && tooltipMsg) {
			const { rowByRowMsg, sideBySideMsg } = tooltipMsg;
			helpText = index > 0 ? sideBySideMsg : rowByRowMsg;
		}
		const showHelpIcon = !!helpText;
		return dropDownObject(newItem, helpText, showHelpIcon);
	});
	return group;
};

export const updateValues = (fields, value, id) => {
	find(fields, item => {
		const validId = item.groupId === id || item.groupId === id.elementId;
		if (validId) {
			item.value =
				item.groupId === TIME_PERIOD ||
				item.groupId === CALENDAR_MONTH_TIME_PERIOD
					? item.value
					: value;
			item.valid = true;
			item.errorMsg = '';
			item.defaultValue = '';
		}
		return validId;
	});
	return fields;
};

export const isEmptyDateRange = itemValue => {
	const startDateValue = get(itemValue, 'startDate');
	const endDateValue = get(itemValue, 'endDate');
	return startDateValue || endDateValue;
};
export const checkMultiselect = itemId => {
	return (
		itemId === PERFORMANCE_IDENTIFIER ||
		itemId === TIME_PERIOD ||
		itemId === CALENDAR_MONTH_TIME_PERIOD
	);
};
const inputDataValue = item => {
	if (
		item.groupId === REFRENCE_DATE ||
		item.groupId === PERFORMANCE_IDENTIFIER ||
		item.groupId === TIME_PERIOD ||
		item.groupId === CALENDAR_MONTH_TIME_PERIOD ||
		item.groupId === TIME_PERIOD_Single
	) {
		return item.value;
	}
	return { key: item.value, text: item.value };
};
const inputDataObject = (item, groupId) => {
	if (groupId === 'CTP1' || groupId === 'FromDate') {
		return { CTP: item };
	}
	return { key: item.key, text: item.label };
};
const isEmptyValue = itemVal => {
	if (
		!!itemVal &&
		itemVal.constructor === Object &&
		keys(itemVal).length !== 0
	) {
		return true;
	}
	if (!!itemVal || !isEmpty(itemVal)) {
		return true;
	}
	return false;
};
export const getSelectedServiceName = data => {
	const { servicename, displayOrientation, valuationFrequency } = data;
	const orientationOptions = displayOrientation.split(',');
	const frequencyOptions = valuationFrequency.split(',');
	const newServicename = `${servicename} ${frequencyOptions[0]} ${orientationOptions[0]}`;
	return newServicename.trim();
};
export const updateInputNodesData = (fields, isCTPPresent) => {
	const inputData = {};

	fields.map(item => {
		const itemValue = get(item, 'value');
		const isValueObj =
			keys(itemValue).length !== 0 && itemValue.constructor === Object;
		const emptyRange =
			item.groupId === 'CTP1'
				? isEmptyDateRange(itemValue)
				: isEmptyValue(itemValue);

		if (emptyRange) {
			if (isCTPPresent && item.groupId === 'CTP1') {
				const startDate = get(item, 'value.startDate');
				const endDate = get(item, 'value.endDate');
				inputData[item.groupId] = {
					colText: item.colText,
					startDate: {
						colText: 'Start Date',
						value: new Date(startDate),
					},
					endDate: {
						colText: 'End Date',
						value: new Date(endDate),
					},
				};
			} else {
				inputData[item.groupId] = {
					value: isValueObj
						? inputDataObject(itemValue, item.groupId)
						: inputDataValue(item),
					colText: item.colText,
				};
			}

			return inputData;
		}
		return null;
	});
	return inputData;
};
export const updateDD = masterTrust => {
	const dropDown = [];
	let data = {};
	masterTrust.map(item => {
		data = {
			listId: item.id,
			helpText: item.masterTrustNameId,
			listVal: item.masterTrustNameId,
			value: item.id,
		};
		dropDown.push(data);
		return null;
	});
	return dropDown;
};
export const checkIsPerformanceId = (fields, groupId) => {
	const nodes = filter(fields, item => {
		return item.groupId === groupId;
	});
	return nodes.length > 0;
};

const createRadioWithOptions = (type, radioBtnOpt, isNewFlow) => {
	let options;
	if (type === 'OR' && !isEmpty(radioBtnOpt)) {
		options = radioBtnOpt ? createRadioBtn(ORIENTATION, radioBtnOpt) : [];
	} else if (!isEmpty(radioBtnOpt)) {
		options = radioBtnOpt ? createRadioBtn(FREQUENCY, radioBtnOpt) : [];
	}
	if (type === 'OR' && !isEmpty(radioBtnOpt) && isNewFlow) {
		const orientationVal = radioBtnOpt[0];
		options.defaultValue = orientationVal;
		options.value = orientationVal;
	} else if (type === 'FR' && !isEmpty(radioBtnOpt) && isNewFlow) {
		const frequencyVal = radioBtnOpt[0];
		options.defaultValue = frequencyVal;
		options.value = frequencyVal;
	}
	return options;
};

export const updateResWithRadioBtn = (response, serviceObj, isNewFlow) => {
	const { inputNodes } = response;
	const { MandatoryFields } = inputNodes;
	const { displayOrientation, valuationFrequency } = serviceObj;
	const orientation = displayOrientation ? displayOrientation.split(',') : [];
	const frequency = valuationFrequency ? valuationFrequency.split(',') : [];
	const radioDisplyOri = createRadioWithOptions('OR', orientation, isNewFlow);
	const radioValuFreq = createRadioWithOptions('FR', frequency, isNewFlow);
	const radioBtnGrp = [];
	if (radioDisplyOri && !isEmpty(radioDisplyOri.dropDowns)) {
		radioBtnGrp.push(radioDisplyOri);
	}
	if (radioValuFreq && !isEmpty(radioValuFreq.dropDowns)) {
		radioBtnGrp.push(radioValuFreq);
	}
	const updatedMandatory = [...radioBtnGrp, ...MandatoryFields];
	response.inputNodes.MandatoryFields = [...updatedMandatory];
	return response;
};

// set multiselected drop down values
export const getMultiselectedValue = (items, option) => {
	let selectedValues = [];
	let listValues = [];
	if (option.selected) {
		selectedValues.push(option);
		listValues = items
			? [...items, ...selectedValues]
			: [...selectedValues];
	} else {
		selectedValues = filter(items, item => {
			return item.value !== option.value;
		});
		listValues = isEmpty(selectedValues) ? null : [...selectedValues];
	}
	return listValues;
};

export const getElementId = (isMultiSelect, event, option) => {
	let elementId;
	if (isMultiSelect && option) {
		elementId = option.groupId || option.elementId;
	} else if (option && (option.elementId || option.groupId)) {
		elementId = option.elementId || option.groupId;
	} else {
		elementId = event.elementId;
	}
	return elementId;
};

export const updateElementId = (data, dataTypeId) => {
	data.map(items => {
		items.elementId = dataTypeId;
		return items;
	});
};
// creating account/grop id drop down object on success of API call
export const manipulateAccountGroupData = data => {
	return data.map(item => {
		return {
			key: item.key,
			text: item.acctgrpInfo,
			label: item.acctgrpInfo,
			value: item.accountId,
			type: item.accountType,
			elementId: item.elementId,
		};
	});
};
//1163204:Index Search
// creating account/grop id drop down object on success of API call
// Category Names are not returnd from RPM only returned category_ID
export const manipulateIndexSearchData = (data, INDEX_PERFORMANCE_OBJECT) => {
	return data.map(item => {
		return {
			key: item.idToService,
			text: item.name,
			label: `${item.name}-${item.id}-${item.catg}-${item.geo}`,
			value: item.idToService,
			type: item.indexType,
			elementId: INDEX_PERFORMANCE_OBJECT,
		};
	});
};
export const templateData = templates => {
	return templates.map(item => {
		return {
			key: item.id,
			text: item.templateNameId,
			label: item.name,
			value: item.id,
			type: item.type,
			elementId: 'Template',
		};
	});
};

//1163204:Universe Search
// creating Universe id drop down object on success of API call
// Universe Names are not returnd from RPM only returned category_ID
export const manipulateUniverseSearchData = (data, UNIVERSES_ID) => {
	return data.map(item => {
		return {
			key: item.universeID,
			text: item.name,
			label: item.name,
			value: item.universeID,
			type: item.universeType,
			elementId: UNIVERSES_ID,
		};
	});
};

//1163204:COMPOSITE_NAME Search
// creating COMPOSITE_NAME id drop down object on success of API call
// COMPOSITE_NAME Names are not returnd from RPM only returned composite_ID
export const manipulateCompositeNameSearchData = (data, COMPOSITE_NAME) => {
	return data.map(item => {
		return {
			key: item.key,
			text: item.value,
			label: item.value,
			value: item.key,
			type: item.key,
			elementId: COMPOSITE_NAME,
		};
	});
};

//1163204:COMPOSITE_ID Search
// creating COMPOSITE_ID id drop down object on success of API call
// COMPOSITE_ID Names are not returnd from RPM only returned composite_ID
export const manipulateCompositeIDSearchData = (data, COMPOSITE_ID) => {
	return data.map(item => {
		return {
			key: item.key,
			text: item.value,
			label: item.value,
			value: item.key,
			type: item.key,
			elementId: COMPOSITE_ID,
		};
	});
};