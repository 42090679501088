const DisplaySettingsTexts = {
	title: 'Display Settings',
	reportTitleLabel: 'Dataset Title',
	showDetailsLabel: 'Show Details',
	createCellLabel: 'Select Cell',
	createSheetLabel: 'Select Sheet',
	emptyCellIdErrorMsg: 'Please select cell Id',
	emptySheetNameErrorMsg: 'Please select Sheet Name',
	cellIdToolTipMsg: 'Specify cell location for display settings',
	sheetNameToolTipMsg: 'Specify sheet location for display settings',
	validationMsg: {
		notEmtpyErrorMsg: 'Please provide Dataset name',
		invalidErrorMsg: 'Special Characters not allowed',
		duplicateErrMsg:
			'Dataset already exists with this name, Please provide different name',
		sheetNameToolTipMsg: 'Specify sheet location for display settings',
		blankCellAddressMsg: 'Please select blank cell or row for headers',
	},
};

export default DisplaySettingsTexts;
