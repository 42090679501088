import React, { useEffect } from 'react';
import { get } from 'lodash';

const LoginSuccess = props => {
	useEffect(() => {
		const previousUrl = get(props.history.location, 'hash');
		if (previousUrl) {
			const hashSplit = previousUrl.split('#');
			const answer = hashSplit[hashSplit.length - 1];
			props.history.push(answer);
		} else {
			props.history.push('/');
		}
	});

	return <div />;
};

export default LoginSuccess;
