/* global Excel */
import { colName } from './ExcelDataPopulate';

const checkValidity = range => {
	return range && range.values[0][0] === '';
};

export const excelCellClickCheck = cellValue => {
	let isValid = true;
	return Excel.run(ctx => {
		const startRange = ctx.workbook.getSelectedRange().load('rowIndex');
		startRange.load('columnIndex');
		const range = ctx.workbook.getSelectedRange();
		range.load('values,address');
		let bottomCellRange;
		return ctx.sync().then(() => {
			const row = startRange.rowIndex;
			const col = startRange.columnIndex;
			const bottomRowCell = `${colName(col)}${row + 2}`;
			const newAddress = range.address.split('!');
			const isValidCellAddress = range.values[0][0] === '';
			const datasheet = ctx.workbook.worksheets.getItem(newAddress[0]);
			bottomCellRange = datasheet.getRange(bottomRowCell);
			bottomCellRange.load('values');
			const cellWithTopRow = row === 0 && isValidCellAddress;

			if (cellValue !== newAddress[1]) {
				isValid = isValidCellAddress;
			}
			return ctx.sync().then(() => {
				if (cellWithTopRow) {
					isValid = bottomCellRange.values[0][0] === '';
				} else if (cellValue !== newAddress[1]) {
					isValid = checkValidity(range);
				}
				return isValid;
			});
		});
	});
};
