const OutputTexts = {
	title: 'Configure Outputs',
	expandPageTitle: 'Select and arrange outputs',
	actionButtonLable: {
		defaultSelection: 'Default Selection',
		selectAll: 'Select All',
		clearSelection: 'Clear Selection',
		cancelButton: 'Cancel',
		applyButton: 'Apply Outputs',
	},
	messageType: {
		applyButtonMsg: 'Apply Outputs',
		cancelButtonMsg: 'Cancel',
	},
	iconName: {
		addIcon: 'MiniExpandMirrored',
		acceptIcon: 'Touch',
	},
	createCellLabel: 'Select Cell',
	createSheetLabel: 'Select Sheet',
	emptyCellIdErrorMsg: 'Please select cell Id',
	emptySheetNameErrorMsg: 'Please select Sheet Name',
	searchBarPlaceholder: 'Search output here..',
	cellIdToolTipMsg: 'Specify cell location for output',
	sheetNameToolTipMsg: 'Specify sheet location for output',
	blankCellAddressMsg: 'Please select blank cell or row for headers',
	investmentHierarchy: 'Investment Hierarchy',
};

export default OutputTexts;
