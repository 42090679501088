import { Api } from 'api/Api';
import { get, isEmpty, toUpper, find, filter } from 'lodash';

const createNonItem = items => {
	return {
		key: 'none',
		text: 'None',
		label: 'None',
		value: '',
		elementId: items.groupId,
	};
};

const getCachedDateRange = selectedOption => {
	const startDateValue = get(selectedOption, 'startDate.value');
	const endDateValue = get(selectedOption, 'endDate.value');
	let selectedValue;
	if (startDateValue && endDateValue) {
		selectedValue = {
			startDate: new Date(startDateValue),
			endDate: new Date(endDateValue),
		};
	} else {
		selectedValue = {};
	}
	return selectedValue;
};

const getLocalSmartDateKey = () => {
	const localData = JSON.parse(localStorage.getItem('inputData'));
	return get(localData, 'SmartDate');
};

const getLocalStateValue = (data, isMultiSelect) => {
	const localData = JSON.parse(localStorage.getItem('inputData'));
	const selectedOption = localData ? localData[data.groupId] : null;
	const checkNotEmpty =
		selectedOption !== '' || !isEmpty(selectedOption.value);
	let selectedValue;
	if (checkNotEmpty && data.groupId === 'ReferenceDate') {
		selectedValue =
			selectedOption &&
			selectedOption.value &&
			new Date(selectedOption.value);
	} else if (checkNotEmpty && data.groupId === 'CTP1') {
		selectedValue = getCachedDateRange(selectedOption);
	} else if (checkNotEmpty) {
		selectedValue = get(selectedOption, 'value');
	} else if (isMultiSelect) {
		selectedValue = [];
	} else {
		selectedValue = '';
	}
	return selectedValue;
};

// method used to set default value for category field
const getDefaultValue = (data, isMultiSelect) => {
	const options = data.dropDowns;
	let defaultVal;
	const localData = JSON.parse(localStorage.getItem('inputData'));
	const category = get(localData, 'Category.value');
	const categoryLocalValue = category || '';
	const showTotalFundValue =
		categoryLocalValue === '' ||
		(categoryLocalValue && categoryLocalValue.key === '1');
	if (showTotalFundValue && toUpper(data.groupId) === toUpper('Category')) {
		defaultVal = find(options, item => {
			return item.key === '1';
		});
	} else {
		defaultVal = getLocalStateValue(data, isMultiSelect);
	}
	return defaultVal;
};

const getIsValid = item => {
	const dateStatus = {
		startDate: true,
		endDate: true,
	};
	return item.fieldType === 'DateRange' ? dateStatus : null;
};

const getAccountGroupIds = (trustId, userId) => {
	return Api.getAccountGroups(trustId, userId);
};

export {
	getAccountGroupIds,
	getLocalStateValue,
	getDefaultValue,
	createNonItem,
	getIsValid,
	getLocalSmartDateKey,
};
