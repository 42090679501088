import React, { useEffect, useRef } from 'react';
import { Header, DynamicForm, ThrobberMessage } from 'components';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './Performance.scss';
import { updateRunAllStatus } from 'utils/GetDataUtil';
import { useDispatch } from 'react-redux'
import { createAction } from 'utils/helpers';
import {
	cellSheetPopulation,
	moveToCellSheet,
} from 'utils/excelUtils/ExcelDataPopulate';
import {
	updateSessionStorage,
	getSessionStorageItem,
} from 'utils/CommonUtil';
import {
	updateCellValueIntoExcel,
	updateDatasetCellAddress
} from 'utils/GetDataUtil';
import {
	PrimaryButton,
	DefaultButton,
	Spinner,
	SpinnerSize,
} from 'office-ui-fabric-react';
import LoadingOverlay from 'react-loading-overlay';
import { showGetDataBtn, scrollToBottom } from 'utils/CommonUtil';
import {
	displaySettingAction,
	onDataRendered,
	updateDatasetToCosmos,
} from '../displaySettings/DisplaySettingsAction';
import { updateDataset } from '../datasetManager/DatasetManagerAction';
import { useModal } from '../../hooks/useModal';
import {
	manipulateInputData,
	onSmartDateChange,
	dateChange,
	dateRangeChange,
	selectionChange,
	submitForm,
	changeTextField,
	cellChange,
	cellSheetAction,
	backBtnClick,
	indexSearchBtnClick,
	radioBtnAction,
	onClickGetData,
	commanDataObject,
} from './PerformanceAction';

const PerformanceContainer = props => {
	const {
		selectedServiceName,
		performance,
		inputData,
		onSmartDateSelect,
		onDateChange,
		onDateRangeChange,
		onSelectionChange,
		onSubmitForm,
		onChangeTextField,
		onCellChange,
		onSetCellSheet,
		onBackBtnClick,
		onIndexSearchBtnClick,
		history,
		onRadioBtnChange,
		selectedDataset,
		onClickGetDataBtn,
		getData,
		isLoading,
		updateDatasetValue,
		isDataRendered,
		loading,
		user,
		dataValues
	} = props;
	const {
		isValidExcelField,
		inputNodes,
		cellValue,
		sheetName,
		isFirstRowSelected,
		isUsedCellAddress,
		backWarning,
		isEditable,
        isReadOnlyText,
	} = performance;
	const dispatch = useDispatch();
	const { isValidCellId, isValidSheetName } = isValidExcelField;
	const isCancelled = useRef(false);
	const messagesEndRef = useRef(null);
	const setCellSheet = value => {
		if (!isCancelled.current) {
			onSetCellSheet(value);
		}
	};
	//XDE-5631
	const { RenderModal, hide, show,  } = useModal();
	useEffect(() => {
		if (isEditable === true) {
			show();
		}else{
			dispatch(createAction('OPEN_POPUP', false))
			hide();
		}
	}, [isEditable]);

	useEffect(() => {
		window.scrollTo(0, 0);
		inputData();
		cellSheetPopulation(setCellSheet);
		if (cellValue && sheetName) {
			moveToCellSheet(cellValue, sheetName);
		}
		return () => {
			isCancelled.current = true;
		};
	}, []);
	useEffect(() => {
		if (!isValidCellId || !isValidSheetName) {
			scrollToBottom(messagesEndRef);
		}
	}, [isValidCellId, isValidSheetName]);
	const isGetDataRendered = datasetRange => {
		const dataset = updateDatasetValue(datasetRange);
		updateRunAllStatus(dataset, isDataRendered);
	};

	//XDE-5631
	const onYesChangeCell = () => {
		updateSessionStorage('isInputCellNotChanged','NO');
		updateSessionStorage('inputSheet_Change', 'N');
		hide();
		const { inputDataObject, validateFieldsValue } = commanDataObject(dispatch,dataValues);
		if (validateFieldsValue) {
			return;
		}
		const { selectedDataset } = get(dataValues, 'createNewDataSet');
		const { performance } = dataValues;
		const { sheetName } = performance;
		updateCellValueIntoExcel(
			inputDataObject,
			selectedDataset,
			cellValue,
			sheetName,
		);
		if (selectedDataset && !selectedDataset.duplicateClicked) {
			updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
		}
		dispatch(createAction('OPEN_POPUP', false))
		history.push(`/output-form`);
	};
	//XDE-5631
	const onNoCellChange = (history) => {
		hide();
		updateSessionStorage('isInputCellNotChanged','YES'); 
		updateSessionStorage('inputSheet_Change', 'Y');
		dispatch(createAction('OPEN_POPUP', false))
		const cellValue = getSessionStorageItem('startCellValue_old');
		const inputSheetNameOld = getSessionStorageItem('inputSheetName_Old');
		updateSessionStorage('startCellValue',cellValue);
		updateSessionStorage('inputSheetName',inputSheetNameOld);
		dataValues.createNewDataSet.selectedDataset.inputNodes.inputCellNo = cellValue;
		dataValues.createNewDataSet.selectedDataset.inputNodes.inputSheetName = inputSheetNameOld;
		dataValues.performance.sheetName = inputSheetNameOld;
		
		const { inputDataObject, validateFieldsValue } = commanDataObject(
			dispatch,
			dataValues,
		);
		if (validateFieldsValue) {
			return;
		}
		const { selectedDataset } = get(dataValues, 'createNewDataSet');
		const { performance } = dataValues;
		const { sheetName } = performance;
		updateCellValueIntoExcel(
			inputDataObject,
			selectedDataset,
			cellValue,
			sheetName,
		);
		if (selectedDataset && !selectedDataset.duplicateClicked) {
			updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
		}
		history.push(`/output-form`);
	};

	return (
		<LoadingOverlay
			active={isLoading || loading}
			spinner={<Spinner size={SpinnerSize.large} />}
			text={isLoading ? <ThrobberMessage /> : ''}
		>
			<div className="ms-Grid performance-container " dir="ltr">
				<Header
					onClickGetData={event =>
						onClickGetDataBtn(event, getData, isGetDataRendered)
					}
					showGetDataButton={showGetDataBtn(selectedDataset)}
				/>
				{inputNodes && inputNodes.mandatoryFields ? (
					<DynamicForm
						fields={inputNodes}
						cellValue={cellValue}
						sheetName={sheetName}
						backWarning={backWarning}
						onCancelClick={() => onBackBtnClick(history)}
						onIndexSearchClick={() => onIndexSearchBtnClick(history)}
						onChangeRadioButton={onRadioBtnChange}
						onDateChange={onDateChange}
						onSmartDateChange={onSmartDateSelect}
						onDateRangeChange={onDateRangeChange}
						smartDateKey={null}
						onSelectionChange={onSelectionChange}
						onMasterTrustChange={onSelectionChange}
						onIndexSearchChange={onSelectionChange}
						onChangeTextField={onChangeTextField}
						onCellChange={onCellChange}
						isValidExcelField={isValidExcelField}
						isFirstRowSelected={isFirstRowSelected}
						isUsedCellAddress={isUsedCellAddress}
						serviceName={selectedServiceName}
						stepPage={1}
						submitForm={event => onSubmitForm(event, history)}
						isButtonDisable={!!selectedDataset}
						loading={loading}
						isReadOnlyText={isReadOnlyText}
					/>
				) : null}
				<div ref={messagesEndRef} />
				
				{isEditable && (<RenderModal title="Edit Input Screen" showButtonModal={true}>
				
				<p>
					{'You have changed the cell coordinate for populating the input header. Click "Yes" to change the cell coordinate as per the new selection. Click "No" to retain the old cell coordinates.'}
				</p>
				<DefaultButton
					id="no-btn"
					text={'No'}
					onClick={() => onNoCellChange (history)}
					style={{ margin: '6px', float: 'left' }}
				/>
				<PrimaryButton
					id="yes-btn"
					text={'Yes'}
					onClick={onYesChangeCell}
					style={{ margin: '6px', float: 'left' }}
				/>
			</RenderModal>)}
			
			</div>
		</LoadingOverlay>
	);
};

const mapStateToProps = state => ({
	selectedServiceName: get(state, 'data.createNewDataSet.selectedService'),
	performance: get(state, 'data.performance'),
	selectedDataset: get(state, 'data.createNewDataSet.selectedDataset'),
	isLoading: get(state, 'data.getData.loading'),
	loading:
		get(state, 'data.createNewDataSet.loading') ||
		get(state, 'data.performance.loading'),
	dataSet: get(state, 'data.dataSet.data'),
	user: get(state, 'data.userInContext.user'),
	dataValues: get(state, 'data')
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			inputData: manipulateInputData,
			onSmartDateSelect: onSmartDateChange,
			onDateChange: dateChange,
			onDateRangeChange: dateRangeChange,
			onSelectionChange: selectionChange,
			onSubmitForm: submitForm,
			onChangeTextField: changeTextField,
			onCellChange: cellChange,
			onSetCellSheet: cellSheetAction,
			onBackBtnClick: backBtnClick,
			onIndexSearchBtnClick: indexSearchBtnClick,
			onRadioBtnChange: radioBtnAction,
			onClickGetDataBtn: onClickGetData,
			getData: displaySettingAction,
			isDataRendered: onDataRendered,
			storeDataset: updateDatasetToCosmos,
			updateDatasetValue: updateDataset,
		},
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PerformanceContainer);
