import { createTheme } from 'office-ui-fabric-react';

export const theme = createTheme({
	palette: {
		themePrimary: '#3b845c',
		themeLighterAlt: '#f3faf4',
		themeLighter: '#f5f5f5',
		// themeLight: '#aed9b1',
		themeTertiary: '#3b845c',
		themeSecondary: '#3b845c',
		themeDarkAlt: '#3b845c',
		themeDark: '#3b845c',
		themeDarker: '#3b845c',
		neutralLighterAlt: '#faf9f8',
		// neutralLighter: '#f3f2f1',
		neutralLighter: '#f5f5f5',
		// neutralLight: '#edebe9',
		neutralLight: '#f5f5f5',
		neutralQuaternaryAlt: '#e1dfdd',
		neutralQuaternary: '#d0d0d0',
		neutralTertiaryAlt: '#c8c6c4',
		neutralTertiary: '#a19f9d',
		neutralSecondary: '#605e5c',
		neutralPrimaryAlt: '#3b3a39',
		neutralPrimary: '#020202',
		neutralDark: '#201f1e',
		black: '#000000',
		white: '#ffffff',
	},
});
