import React, { useEffect } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Customizer } from 'office-ui-fabric-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { theme } from 'styles/theme';
import 'styles/styles.scss';
import { ensureStateInitialized } from 'utils/excelUtils/office-apis-helpers';
import ErrorBoundary from 'containers/errorBoundary/ErrorBoundaryContainer';
import { getAppInsights } from 'utils/AppInsights/TelemetryService';
import TelemetryProvider from 'utils/AppInsights/TelemetryProvider';
import { ENVIRONMENT } from 'utils/Constants';
import { checkEnv } from 'utils/config';
import { store, persistor } from './store';
import modules from './pages';

const App = () => {
	const env = checkEnv();
	let appInsights;
	useEffect(() => {
		ensureStateInitialized(true);
	}, []);

	return (
		<Customizer settings={{ theme }}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router>
						<TelemetryProvider
							instrumentationKey={ENVIRONMENT[env].iKey}
							after={() => {
								appInsights = getAppInsights();
							}}
						>
							<div className="app">
								<Switch>
									{modules.map(module => (
										<Route
											{...module.routeProps}
											key={module.name}
										/>
									))}
								</Switch>
								<ErrorBoundary />
							</div>
						</TelemetryProvider>
					</Router>
				</PersistGate>
			</Provider>
		</Customizer>
	);
};

export default App;
