const DatasetManagerConst = {
	label: {
		asOfDateText: 'As Of Date',
		lastModifiedText: 'Last modified',
		tooltip: 'Get Data',
	},
	datasetInfo: 'Dataset Info',
};

export default DatasetManagerConst;
