/* global Office */
import React, { useEffect } from 'react';

const LogoutPage = () => {
	useEffect(() => {
		Office.onReady(() => {
			Office.context.ui.messageParent(
				JSON.stringify({
					status: 'success',
					result: 'logout',
				}),
			);
		});
	}, []);
	return <div />;
};

export default LogoutPage;
