import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import styles from './Accordion.module.scss';

/**
 * Icon styles. Feel free to change them
 */
const collapsedIcon = {
	iconName: 'ChevronRight',
	className: styles.accordionChevron,
};
const expandedIcon = {
	iconName: 'ChevronDown',
	className: styles.accordionChevron,
};

const Accordion = props => {
	const { title, children, expanded, onExpandClick } = props;
	const iconProp = expanded ? expandedIcon : collapsedIcon;
	const showExpandClass = expanded ? 'show-expand' : '';
	return (
		<div className="ms-Grid-row accordion">
			<div className={`accordion-content ${showExpandClass}`}>
				<div
					role="button"
					tabIndex={0}
					className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 btn-expand"
					onClick={onExpandClick}
					onKeyDown={() => null}
				>
					<span className="accordion-title">{title}</span>
					<span className="accordion-icon">
						<Icon {...iconProp} />
					</span>
				</div>
				{expanded ? children : null}
			</div>
		</div>
	);
};

export default Accordion;
