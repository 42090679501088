/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { DatePicker, initializeIcons } from 'office-ui-fabric-react';
import { formatDate } from 'utils/dateUtil';
import './IconDatepicker.module.scss';

initializeIcons();
const styles = {
	textField: {
		width: '2px',
		marginTop: '-23px',
		marginLeft: '175px',
		input: { display: 'none' },
		div: {
			height: '0px !important',
			div: { height: '0px !important', border: '0px' },
		},
	},
};
const IconDatepicker = props => {
	const { changeDate, datepickerId, labelId } = props;
	const calloutProps = {
		disabled: true,
	};
	return (
		<label
			className="icon-datepicker"
			id={`icon-datepicker-label-${labelId}`}
		>
			<DatePicker
				id={`icon-calender-${labelId}`}
				key={datepickerId}
				showMonthPickerAsOverlay
				onSelectDate={event => {
					changeDate(event, datepickerId);
				}}
				isMonthPickerVisible={false}
				formatDate={date => formatDate(date, 'mm/dd/yyyy')}
				maxDate={new Date()}
				textField={calloutProps}
				styles={styles}
			/>
		</label>
	);
};

export default IconDatepicker;
