import React, { memo } from 'react';
import {
	FocusZone,
	FocusZoneDirection,
	mergeStyleSets,
	getTheme,
	getFocusStyle,
	Icon,
	List,
	TooltipHost,
} from 'office-ui-fabric-react';
import { replaceStr } from 'utils/CommonUtil';
import { get, find } from 'lodash';

const theme = getTheme();
const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };
const { palette, semanticColors } = theme;
const classNames = mergeStyleSets({
	itemCell: [
		getFocusStyle(theme, { inset: -1 }),
		{
			minHeight: 54,
			padding: 10,
			boxSizing: 'border-box',
			// borderTop: `1px solid ${semanticColors.bodyDivider}`,
			borderBottom: `1px solid ${semanticColors.bodyDivider}`,
			paddingLeft: '15px',
			paddingRight: '15px',
			display: 'flex',
			selectors: {
				'&:hover': { background: palette.neutralLight },
			},
		},
	],
	itemContent: {
		// marginLeft: 10,
		overflow: 'hidden',
		flexGrow: 1,
		textAlign: 'left',
	},
	itemName: [
		{
			fontSize: '14px',
			paddingBottom: '5px',
			paddingTop: '8px',
			letterSpacing: '0.11px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontWeight: 600,
		},
	],
	itemDescription: [
		{
			fontSize: '12px',
		},
	],
	chevron: {
		alignSelf: 'center',
		marginLeft: 10,
		color: palette.black,
		fontSize: '12px',
		flexShrink: 0,
	},
});

const updateTotalMessageTxt = totalMsg => {
	if (totalMsg === 0) {
		return 'no messages';
	}
	if (totalMsg > 1) {
		return `${totalMsg} messages`;
	}
	return `${totalMsg} message`;
};
const alertIconCount = (welcomeMessages, totalMessagesTxt) => {
	if (welcomeMessages) {
		const totalMessages = replaceStr(
			totalMessagesTxt,
			updateTotalMessageTxt(welcomeMessages.length),
		);
		return (
			<TooltipHost
				content={totalMessages}
				id="icon-button-tooltip"
				calloutProps={calloutProps}
				styles={hostStyles}
			>
				<Icon className="alert-icon" iconName="AlertSolid" />
			</TooltipHost>
		);
	}
	return null;
};
const BasicList = props => {
	const {
		message,
		isClick,
		isWelcomeShow,
		welComeAccordion,
		totalMessages,
	} = props;
	const welcomeMessages = get(message, 'welcomeMessages');
	const isExpandStatus = isWelcomeShow ? 'ChevronDown' : 'ChevronRight';
	const newMessage = find(welComeAccordion, item => {
		return item.show === true;
	});
	const onRenderCell = item => {
		return (
			<div className={classNames.itemCell} data-is-focusable>
				<div className={classNames.itemContent}>
					<div className={classNames.itemDescription}>
						<div
							dangerouslySetInnerHTML={{
								__html: item.message,
							}}
						/>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div>
			<FocusZone direction={FocusZoneDirection.vertical}>
				<div
					className={classNames.itemCell}
					data-is-focusable
					role="button"
					tabIndex={0}
					title={newMessage.tooltipMessage}
					onClick={() => isClick()}
					onKeyDown={() => isClick()}
				>
					<div className={classNames.itemContent}>
						<div className={classNames.itemName}>
							{newMessage.name}{' '}
							{alertIconCount(welcomeMessages, totalMessages)}
						</div>
						<div className={classNames.itemDescription}>
							{newMessage.description}
						</div>
					</div>
					<Icon
						className={classNames.chevron}
						iconName={isExpandStatus}
					/>
				</div>
				<div>
					<div
						style={{
							display: isWelcomeShow ? 'block' : 'none',
							paddingLeft: '12px',
						}}
					>
						<List
							items={welcomeMessages}
							onRenderCell={onRenderCell}
						/>
					</div>
				</div>
			</FocusZone>
		</div>
	);
};

export default memo(BasicList);
