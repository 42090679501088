const initialState = {
	user: {},
};

export default function productReducer(state = initialState, action = {}) {
	const { payload } = action;
	switch (action.type) {
		case 'USER_IN_CONTEXT':
			return {
				...state,
				user: payload,
			};
		case 'PASSPORT_PROFILE_SUCCESS':
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...state.user.profile,
						personaId: payload.personaId,
					},
				},
			};
		case 'SIGN_OUT':
			return {
				...state,
				user: {},
			};
		case 'PASSPORT_PROFILE_FAILURE':
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...state.user.profile,
						personaId: null,
					},
				},
				error: payload,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
