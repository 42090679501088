import React, { memo } from 'react';
import { Checkbox, initializeIcons, Icon } from 'office-ui-fabric-react';
import { isEmpty } from 'lodash';
import { fieldExpandView } from 'utils/CommonUtil';
import './PivotBasicTab.scss';
import InformationIcon from '../informationIcon/InformationIcon';

initializeIcons(undefined, { disableWarnings: true });
const SelectFieldsPivot = props => {
	const iconChevDown = 'ChevronDown';
	const iconChevRight = 'ChevronRight';
	const labelColor = 'icon-font-expand label-color';
	const labelExpand = 'icon-font-expand';
	const flagPivContainer = 'pivot-container';
	const flagPivStyle = 'pivot-container parentListStyle';
	const iconFontLabel = 'icon-font label-color';
	const iconFont = 'icon-font';
	const { fields, onSelect, isExpand, onExpandClick } = props;
	let isChildNodes = false;
	fields.map(field => {
		if (!isEmpty(field.dropDowns)) {
			isChildNodes = true;
		}
		return null;
	});
	const getCheckbox = field => {
		return (
			<div className={(field.mandatoryYN !== null && field.mandatoryYN === 'Y') ? 'mandatory-checkbox' : ''}>
				{
					field.mandatoryYN !== null && field.mandatoryYN === 'Y' ?
						<InformationIcon
							labelId={''}
							label={''}
							tooltipMessage={"This field is mandatory."}
							mandatory={true}
						></InformationIcon> : ''
				}
				<Checkbox
					id={field.colId}
					label={field.colText}
					onChange={onSelect}
					checked={field.selected}
					disabled={(field.mandatoryYN !== null && field.mandatoryYN === 'Y') ? true : false}

				/></div>
		);
	};
	const getCheckboxChildField = field => {
		const listStyleExpand = (
			<ul className="listStyle">
				{field.dropDowns.map(fieldNodes => {
					return getCheckboxChildField(fieldNodes);
				})}
			</ul>
		);
		const fieldIconFont = fieldExpandView(field, iconFontLabel, iconFont);
		const fieldChev = fieldExpandView(field, iconChevDown, iconChevRight);
		const fieldDropdown = fieldExpandView(field, listStyleExpand, null);

		return (
			<li style={{ border: 'none' }} key={field.colId}>
				{!isEmpty(field.dropDowns) ? (
					<>
						<Icon
							className="icon"
							id={`${field.colId}-icon`}
							iconName={fieldChev}
							onClick={onExpandClick}
						/>
						<label htmlFor={field.colId} className={fieldIconFont}>
							{field.colText}
						</label>
						{fieldDropdown}
					</>
				) : (
					getCheckbox(field)
				)}
			</li>
		);
	};
	const getCheckboxChildExpandField = (field, flag) => {
		const listStyleExpand = (
			<ul className="listStyleExpand">
				{field.dropDowns.map(fieldNodes => {
					return getCheckboxChildExpandField(fieldNodes, true);
				})}
			</ul>
		);
		const fieldDropdown = fieldExpandView(field, listStyleExpand, null);
		const fieldExpand = fieldExpandView(field, labelColor, labelExpand);
		const fieldChev = fieldExpandView(field, iconChevDown, iconChevRight);
		const fieldFlag = flag ? flagPivStyle : flagPivContainer;
		return (
			<li key={field.colId} className={fieldFlag}>
				{!isEmpty(field.dropDowns) ? (
					<>
						<Icon
							className="arrow-icon"
							id={`${field.colId}-icon`}
							iconName={fieldChev}
							onClick={onExpandClick}
						/>
						<ul className="listStyleExpand">
							<li className="listItemStyle">
								<label
									htmlFor={field.colId}
									className={fieldExpand}
								>
									{field.colText}
								</label>
								{fieldDropdown}
							</li>
						</ul>
					</>
				) : (
					<ul className="listStyleExpand">
						<li className="listItemStyle">{getCheckbox(field)}</li>
					</ul>
				)}
			</li>
		);
	};
	const getCheckboxExpandField = field => {
		return <li key={field.colId}>{getCheckbox(field)}</li>;
	};
	const getCheckboxField = field => {
		return (
			<div className="ms-Grid-row" key={field.colId}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" dir="ltr">
					{getCheckbox(field)}
				</div>
			</div>
		);
	};
	const childExpandView = field => {
		return (
			<div key="is_expand">
				<ul
					className="dialog-box-expand scroll"
					style={{ height: window.innerHeight - 214 }}
				>
					{field.map(item => {
						return getCheckboxChildExpandField(item, false);
					})}
				</ul>
			</div>
		);
	};
	const childView = field => {
		return (
			<div key="is_expand">
				<ul className="dialog-box scroll-hidden">
					{field.map(item => {
						return getCheckboxChildField(item);
					})}
				</ul>
			</div>
		);
	};
	const expandView = field => {
		return (
			<div key="is_expand">
				<ul
					className="dialog-list"
					style={{ height: window.innerHeight - 214 }}
				>
					{field.map(item => {
						return getCheckboxExpandField(item);
					})}
				</ul>
			</div>
		);
	};
	const defaultView = field => {
		return field.map(item => {
			return getCheckboxField(item);
		});
	};
	if (isExpand && isChildNodes) {
		return childExpandView(fields);
	}
	if (isChildNodes && !isExpand) {
		return childView(fields);
	}
	if (isExpand) {
		return expandView(fields);
	}
	if (!isExpand) {
		return defaultView(fields);
	}
	return null;
};

export default memo(SelectFieldsPivot);
