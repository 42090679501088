/* global OfficeRuntime, Office */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createHashHistory } from 'history';
import { AuthService } from '../../services/AuthService';
import { getGlobal } from '../../commands/commands';
import { ENVIRONMENT, EXTERNALROOTURL, Constants } from 'utils/Constants';

export const SetRuntimeVisibleHelper = visible => {
	let p;
	if (visible) {
		p = Office.addin.showAsTaskpane();
	} else {
		p = Office.addin.hide();
	}

	return p
		.then(() => {
			return visible;
		})
		.catch(error => {
			return error.code;
		});
};

export const enableButton = () => {
	OfficeRuntime.ui.getRibbon().then(ribbon => {
		ribbon.requestUpdate({
			tabs: [
				{
					id: 'ShareTime',
					controls: [
						{
							id: 'BtnLogoutService',
							enabled: true,
						},
						{
							id: 'BtnNTHome',
							enabled: true,
						},
						{
							id: 'BtnCreateDataset',
							enabled: true,
						},
						{
							id: 'BtnDatasetManage',
							enabled: true,
						},
						{
							id: 'BtnFeedbackService',
							enabled: true,
						},
					],
				},
			],
		});
	});
};

export const updateRibbon = () => {
	const g = getGlobal();
	const { Office, Excel } = window;
	if (Office && Excel) {
		OfficeRuntime.ui.getRibbon().then(ribbon => {
			ribbon.requestUpdate({
				tabs: [
					{
						id: 'ShareTime',
						controls: [
							{
								id: 'BtnLoginService',
								enabled: !g.state.isMenuEnabled,
							},
							{
								id: 'BtnLogoutService',
								enabled: g.state.isMenuEnabled,
							},
							{
								id: 'BtnNTHome',
								enabled: g.state.isMenuEnabled,
							},
							{
								id: 'BtnCreateDataset',
								enabled: g.state.isMenuEnabled,
							},
							{
								id: 'BtnDatasetManage',
								enabled: g.state.isMenuEnabled,
							},
							{
								id: 'BtnFeedbackService',
								enabled: g.state.isMenuEnabled,
							},
						],
					},
				],
			});
		});
	}
};

const redirectTo = () => {
	const appHistory = createHashHistory();
	const currentPath = window.location.hash;
	const hashPath = currentPath.slice(1);
	if (hashPath && hashPath.indexOf('output-form') !== -1) {
		appHistory.push(hashPath);
	} else {
		appHistory.push('/');
	}
};

// This will check if state is initialized, and if not, initialize it.
// Useful as there are multiple entry points that need the state and it is not clear which one will get called first.
export const ensureStateInitialized = isOfficeInitializing => {
	const g = getGlobal();
	const loggedIn = localStorage.getItem('loggedIn') === 'yes';
	let initValue = false;
	if (isOfficeInitializing) {
		if (g.state !== undefined) {
			if (g.state.isInitialized === false) {
				g.state.isInitialized = true;
			}
		}
		if (g.state === undefined) {
			initValue = true;
		}
	}

	if (g.state === undefined) {
		g.state = {
			isMenuEnabled: loggedIn,
			isSignedIn: loggedIn,
			isTaskpaneOpen: false,
			isInitialized: initValue,
			setTaskpaneStatus: opened => {
				g.state.isTaskpaneOpen = opened;
				updateRibbon();
			},
		};
	}
	if (loggedIn) {
		g.state.isSignedIn = loggedIn;
		g.state.isMenuEnabled = loggedIn;
		g.state.envConfig = JSON.parse(localStorage.getItem('envConfig'));
		redirectTo();
	} else {
		g.state.isSignedIn = loggedIn;
		g.state.isMenuEnabled = loggedIn;
		g.state.envConfig = null;
		localStorage.removeItem('envConfig');
	}
	updateRibbon();
};

const processDialogEvent = (arg, setState, displayError) => {
	switch (arg.error) {
		case 12002:
			displayError(
				'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.',
			);
			break;
		case 12003:
			displayError(
				'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.',
			);
			break;
		case 12006:
			// 12006 means that the user closed the dialog instead of waiting for it to close.
			// It is not known if the user completed the login or logout, so assume the user is
			// logged out and revert to the app's starting state. It does no harm for a user to
			// press the login button again even if the user is logged in.
			setState({
				authStatus: 'notLoggedIn',
				headerMessage: 'logout',
				user: {},
			});
			break;
		default:
			displayError('Unknown error in dialog box.');
			break;
	}
};

const updateStatus = isLoggedIn => {
	const g = getGlobal();
	if (isLoggedIn) {
		g.state.isSignedIn = true;
		g.state.isTaskpaneOpen = true;
		g.state.isMenuEnabled = true;
		sessionStorage.setItem('loggedIn', true);
		updateRibbon();
		// enableButton();
	} else {
		g.state.isTaskpaneOpen = false;
		g.state.isMenuEnabled = false;
		g.state.isSignedIn = false;
		SetRuntimeVisibleHelper(false);
		sessionStorage.setItem('loggedIn', false);
		updateRibbon();
	}
};

let loginDialog;
const port = window.location.port ? `:${window.location.port}` : '';
const dialogUrl = `${window.location.protocol}//${window.location.hostname}${port}`; // /login/login.html`;

export const signInO365 = (setState, setToken, displayError) => {
	if (localStorage.getItem('loggedIn') === 'yes') {
		const appHistory = createHashHistory();
		updateStatus(true);
		setToken(localStorage.getItem('authToken'));
		appHistory.push('/');
		return;
	}
	const processLoginMessage = arg => {
		const messageFromDialog = JSON.parse(arg.message);
		if (messageFromDialog.status === 'success') {
			let profileData = messageFromDialog.result.profile
			const validRootURL = Constants.clientRoot;
			const ntUserType = EXTERNALROOTURL.includes(validRootURL) ? 'N' : 'Y';
			const userId = messageFromDialog.result.profile.preferred_username;
			const isInternal = ntUserType;
			const firstName = messageFromDialog.result.profile.given_name;
			let profile = { ...profileData, userId, isInternal, firstName };
			let resultData = messageFromDialog.result
			const result = { ...resultData, profile };
			loginDialog.close();
			updateStatus(true);
			// We now have a valid access token.
			localStorage.setItem('loggedIn', 'yes');
			localStorage.setItem('authToken', messageFromDialog.result.access_token);
			setToken(messageFromDialog.result.access_token);
			setState({
				authStatus: 'loggedIn',
				user: result,
				headerMessage: 'Get Data',
				status: true,
			});
		} else {
			// Something went wrong with authentication or the authorization of the web application.
			loginDialog.close();
			updateStatus(false);
			displayError(messageFromDialog.result);
		}
	};

	const processLoginDialogEvent = arg => {
		processDialogEvent(arg, setState, displayError);
		updateStatus(false);
	};

	Office.onReady(() => {
		Office.context.ui.displayDialogAsync(
			`${dialogUrl}/#/auth`,
			{ height: 70, width: 40, promptBeforeOpen: false },
			result => {
				if (result.status === Office.AsyncResultStatus.Failed) {
					displayError(
						`${result.error.code} ${result.error.message}`,
					);
				} else {
					loginDialog = result.value;
					loginDialog.addEventHandler(
						Office.EventType.DialogMessageReceived,
						processLoginMessage,
					);
					loginDialog.addEventHandler(
						Office.EventType.DialogEventReceived,
						processLoginDialogEvent,
					);
				}
			},
		);
	});
};

export const signOutO365 = (setState, setToken, displayError) => {
	const processLogoutMessage = arg => {
		const messageFromDialog = JSON.parse(arg.message);
		if (messageFromDialog.status === 'success') {
			// localStorage.removeItem('authToken');
			// localStorage.setItem('loggedIn', 'no');
			// sessionStorage.setItem('loggedIn', false);
			window.localStorage.clear();
			window.sessionStorage.clear();
			setToken('');
			setState({
				authStatus: 'notLoggedIn',
				user: messageFromDialog.result,
				headerMessage: 'Get Data',
				status: false,
			});
			loginDialog.close();
			updateStatus(false);
		} else {
			// Something went wrong with authentication or the authorization of the web application.
			loginDialog.close();
			updateStatus(false);
			displayError(messageFromDialog.result);
		}
	};
	const processLogoutDialogEvent = arg => {
		processDialogEvent(arg, setState, displayError);
	};
	Office.context.ui.displayDialogAsync(
		`${dialogUrl}/#/logout`,
		{ height: 45, width: 40, promptBeforeOpen: false },
		result => {
			if (result.status === Office.AsyncResultStatus.Failed) {
				displayError(`${result.error.code} ${result.error.message}`);
			} else {
				loginDialog = result.value;
				loginDialog.addEventHandler(
					Office.EventType.DialogMessageReceived,
					processLogoutMessage,
				);
				loginDialog.addEventHandler(
					Office.EventType.DialogEventReceived,
					processLogoutDialogEvent,
				);
			}
		},
	);
};


// singouton sessiontimeout 
export const signOutOnSessionTimeOut = () => {
	const processLogoutMessage = arg => {
		const messageFromDialog = JSON.parse(arg.message);
		if (messageFromDialog.status === 'success') {
			window.localStorage.clear();
			window.sessionStorage.clear();
			loginDialog.close();
			updateStatus(false);
		} else {
			// Something went wrong with authentication or the authorization of the web application.
			loginDialog.close();
			updateStatus(false);
		}
	};
	const processLogoutDialogEvent = arg => {
		processDialogEvent(arg);
	};
	Office.context.ui.displayDialogAsync(
		`${dialogUrl}/#/logout`,
		{ height: 45, width: 40, promptBeforeOpen: false },
		result => {
			if (result.status === Office.AsyncResultStatus.Failed) {
			} else {
				loginDialog = result.value;
				loginDialog.addEventHandler(
					Office.EventType.DialogMessageReceived,
					processLogoutMessage,
				);
				loginDialog.addEventHandler(
					Office.EventType.DialogEventReceived,
					processLogoutDialogEvent,
				);
			}
		},
	);
};

