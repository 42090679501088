/* eslint-disable import/no-named-as-default */
import SettingFormContainer from 'containers/createNewDataset/SettingFormContainer';
import AccountingContainer from 'containers/accounting/AccountingContainer';
import PerformanceContainer from 'containers/performance/PerformanceContainer';

// key needs to keep same with service id which we are receiving in getServiceList API response

export const Containers = {
	PositionsReport: AccountingContainer,
	ValuationDetailsReport: AccountingContainer,
	TransactionReport: AccountingContainer,
	AssetAndLiabilityDetail: AccountingContainer,
	AssetDetailByAccount: AccountingContainer,
	StandardHoldingsDownload: AccountingContainer,
	PriceDetailReport: AccountingContainer,
	PositionQuery: AccountingContainer,
	MarketValueSummary: PerformanceContainer,
	InvestmentHierarchy: PerformanceContainer,
	RFAG: PerformanceContainer,
	RFCTP: PerformanceContainer,
	Holdings: PerformanceContainer,
	RegionSectorReporting: PerformanceContainer,
	HedgedCurrencyReporting: PerformanceContainer,
	Indices: PerformanceContainer,
	IndicesForCustomTimePeriods: PerformanceContainer,
	EquityAttributionRegionalAndSector: PerformanceContainer,
	EquityStockLevelAttribution: PerformanceContainer,
	ContributiontoReturn: PerformanceContainer,
	PolicyLevelAttribution: PerformanceContainer,
	PolicyLevelContribution: PerformanceContainer,
	FXInvestmentHierarchy: PerformanceContainer,
	FXRFAG: PerformanceContainer,
	FXRFCTP: PerformanceContainer,
	DurationAdjustedExposure: PerformanceContainer,
	CompositeReporting: PerformanceContainer,
	CompositeReportingForCustomTimePeriods: PerformanceContainer,
	NorthernTrustPeerUniversesUniverses: PerformanceContainer,
	UniverseAccountPlanandRankings: PerformanceContainer,
};
