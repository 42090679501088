import Analytics from 'xps';

// Below method creates analytics singleton instance
const GTMInstance = () => {
	let instance;
	// create analytics instance
	const createInstance = () => {
		instance = new Analytics({
			gtmContainerIds: ['GTM-WSGB2P8', 'GTM-KV7MR8P'],
			gtmEnv: [
				'Institutional Passport - Production',
				'Institutional Passport - UAT',
				'Institutional Passport - System',
				'Institutional Passport - Performance',
			],
			gtmExperience: 'Institutional',
		});
		return instance;
	};
	return !instance ? createInstance() : instance;
};

Object.freeze(GTMInstance);

export default GTMInstance;
