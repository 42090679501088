import LoginPage from './LoginPage';

export default {
	routeProps: {
		path: '/login',
		exact: true,
		component: LoginPage,
	},
	name: 'LoginPage',
};
