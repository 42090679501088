import React, { useEffect } from 'react';
import { DatasetManager } from 'containers';
import { gtmTrackPage } from 'utils/GTM/GTMUtil';
import { gaPageTrackTags } from 'utils/GTM/GATags';
import { checkIsLoggedIn } from 'utils/CommonUtil';

const DatasetManagerPage = props => {
	useEffect(() => {
		gtmTrackPage(gaPageTrackTags.dataSetManagerPage);
		checkIsLoggedIn(props);
	}, [props]);
	return (
		<>
			<DatasetManager {...props} />
		</>
	);
};

export default DatasetManagerPage;
