import React, { memo } from 'react';
import {
	Text,
	Separator,
	Link,
	Image,
	PrimaryButton,
} from 'office-ui-fabric-react';
import runAll from 'images/Run_all.png';
import './Header.scss';
import HeaderTexts from './HeaderTexts';

const styles = {
	root: [
		{
			selectors: {
				'::before': {
					background: '#ccc',
				},
			},
		},
	],
	fontSize: {
		fontSize: '18px',
	},
};

const Header = props => {
	const {
		title,
		description,
		showRun,
		refresh,
		onClickGetData,
		showGetDataButton,
	} = props;

	return (
		<div className="header-container" data-test="headerContainer" dir="ltr">
			<div className="ms-Grid-row">
				<div
					className={`ms-Grid-col ${
						showGetDataButton || showRun
							? 'ms-sm8 ms-md8 ms-lg8'
							: 'ms-sm12 ms-md12 ms-lg12'
					}`}
				>
					<Text
						variant="xLarge"
						id="title"
						className={styles.fontSize}
					>
						{title || HeaderTexts.title}
					</Text>
				</div>
				{showGetDataButton ? (
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
						<PrimaryButton
							id="header-get-data"
							text="Get Data"
							onClick={onClickGetData}
						/>
					</div>
				) : null}
				{showRun ? (
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
						<Link
							id="run-dataset"
							className="run-link"
							href={refresh}
						>
							<Image
								className="run-icon"
								src={runAll}
								alt="run-all"
							/>
							Run
						</Link>
					</div>
				) : null}
			</div>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
					<Text variant="medium" id="description">
						{description}
					</Text>
				</div>
			</div>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
					<Separator styles={styles} className="header-separator" />
				</div>
			</div>
		</div>
	);
};

export default memo(Header);
