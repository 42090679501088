import React, { memo } from 'react';
import { TextField } from 'office-ui-fabric-react';
import InformationIcon from '../informationIcon/InformationIcon';
import ExcelCommonTexts from './ExcelCellText';

const ExcelCell = props => {
	const {
		handleChange,
		sheetName,
		cellValue,
		messages,
		isValidExcelField,
		isUsedCellAddress,
		isFirstRowSelected,
		isReadOnlyText
	} = props;
	const { isValidCellId, isValidSheetName } = isValidExcelField;
	const {
		createCellLabel,
		cellIdToolTipMsg,
		createSheetLabel,
		sheetNameToolTipMsg,
		emptySheetNameErrorMsg,
		emptyCellIdErrorMsg,
		blankCellAddressMsg,
	} = messages || {};

	const emptyCellIdMsg = isValidCellId
		? ''
		: emptyCellIdErrorMsg || ExcelCommonTexts.emptyCellIdErrorMsg;

	const usedCellIdMsg = !isUsedCellAddress
		? blankCellAddressMsg || ExcelCommonTexts.blankCellAddressMsg
		: '';

	const reserveCellMsg = isFirstRowSelected
		? ExcelCommonTexts.firstRowReserveMsg
		: '';
	const onRenderLabel = items => {
		const { label, required, tooltipMessage } = items;
		return (
			<InformationIcon
				labelId={label}
				label={label}
				tooltipMessage={tooltipMessage}
				mandatory={required}
			/>
		);
	};
	return (
		<>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					<TextField
						label={
							createCellLabel || ExcelCommonTexts.createCellLabel
						}
						id="cell-no"
						name="cellNumber"
						readOnly={isReadOnlyText}
						placeholder={
							createCellLabel || ExcelCommonTexts.createCellLabel
						}
						value={cellValue}
						onChange={handleChange}
						required
						errorMessage={
							emptyCellIdMsg || reserveCellMsg || usedCellIdMsg
						}
						tooltipMessage={
							cellIdToolTipMsg ||
							ExcelCommonTexts.cellIdToolTipMsg
						}
						onRenderLabel={onRenderLabel}
					/>
				</div>
				<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					<TextField
						label={
							createSheetLabel ||
							ExcelCommonTexts.createSheetLabel
						}
						id="sheet-name"
						name="sheetName"
						readOnly={isReadOnlyText}
						placeholder={
							createSheetLabel ||
							ExcelCommonTexts.createSheetLabel
						}
						value={sheetName}
						onChange={handleChange}
						required
						errorMessage={
							isValidSheetName
								? ''
								: emptySheetNameErrorMsg ||
								  ExcelCommonTexts.emptySheetNameErrorMsg
						}
						tooltipMessage={
							sheetNameToolTipMsg ||
							ExcelCommonTexts.sheetNameToolTipMsg
						}
						onRenderLabel={onRenderLabel}
					/>
				</div>
			</div>
		</>
	);
};

export default memo(ExcelCell);
