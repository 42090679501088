import React from 'react';
import { Callout, mergeStyleSets } from 'office-ui-fabric-react';
import { chunk } from 'lodash';
import { updateInputNodeobj } from '../../utils/datasetUtil';

const styles = mergeStyleSets({
	callout: {
		maxWidth: 600,
		width: 320,
		height: 100,
	},
	actions: {
		position: 'relative',
		marginTop: 20,
		width: '100%',
		whiteSpace: 'nowrap',
	},
});

const DatasetTileDetail = props => {
	const { onClickInfoIcon, inputDataNode, itemKey, datasetInfo } = props;
	let FREQUENCY_FLAG = '';
	const createDatasetElement = dataFields => {
		let count = 0;
		const InputFrequencykey = 'InputFrequency';
		const FREQUENCY_VAL = 'None';
		let FREQUENCY_CHECK = true;
		return dataFields.map(itemNode => {
			const { value, key, text } = itemNode;
			if (key === InputFrequencykey && value === FREQUENCY_VAL) {
				FREQUENCY_FLAG = 'Y';
			}
			count += 1;
			const borderClass = count % 2 > 0 ? 'right-border' : 'left-border';
			if (FREQUENCY_FLAG === 'Y') {
				if (key === 'CTP1') {
					FREQUENCY_CHECK = false;
				} else {
					FREQUENCY_CHECK = true;
				}
			}
			if (FREQUENCY_CHECK) {
				return (
					<div
						className={`ms-Grid-col ms-sm6 ms-md6 ms-lg6 tile-detail-box ${borderClass}`}
						key={key}
					>
						<div className="label-detail-info">{text}</div>
						<div className="value-detail-info">{value}</div>
					</div>
				);
			} else { return; }
		});
	};
	const manipulateData = () => {
		const inputNodesValue = updateInputNodeobj(inputDataNode);
		const splitValue = chunk(inputNodesValue, 2);
		let count = 0;
		return splitValue.map(itemValue => {
			count += 1;
			const backgroundClass = count % 2 > 0 ? '' : 'background-grey';
			return (
				<div
					key={`wrapper-div${count}`}
					className={`ms-Grid-row ${backgroundClass}`}
				>
					{createDatasetElement(itemValue)}
				</div>
			);
		});
	};
	return (
		<>
			<Callout
				className={styles}
				ariaLabelledBy="callout-label"
				ariaDescribedBy="callout-description"
				role="alertdialog"
				target={`.dataset-tile${itemKey}`}
				onDismiss={onClickInfoIcon}
			>
				<div className="ms-Grid" dir="ltr">
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 box-fixed-height">
							<div className="dataset-title-info">
								{datasetInfo}
							</div>
							<div className="dataset-detail-info">
								{manipulateData()}
							</div>
						</div>
					</div>
				</div>
			</Callout>
		</>
	);
};
export default DatasetTileDetail;
