import React from 'react';
import { Text } from 'office-ui-fabric-react';
import NtLogo from 'components/common/NtLogo';
import FeedbackTexts from './messages/FeedbackTexts';
import './Feedback.module.scss';

const ThankYouContainer = () => {
	const { thankYou, description, content } = FeedbackTexts.feedbackTexts;
	return (
		<div className="feedback-container">
			<div className="ms-Grid">
				<NtLogo
					className="feedback-nt-logo"
					alttext="Stacked Centered Default Anchor Logo"
				/>
				<div className="ms-Grid-row feedback-heading">
					<div className="ms-Grid-col ms-sm12">{thankYou}</div>
				</div>
				<div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12 feedback-description">
					<div className="ms-Grid-col ms-sm12 block">
						<Text
							className="feedback-text"
							variant="mediumPlus"
							block
						>
							{description}
						</Text>
						<Text
							className="feedback-text"
							variant="mediumPlus"
							block
						>
							{content}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThankYouContainer;
