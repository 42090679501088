import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty } from 'lodash';
import { DynamicForm, Header, ThrobberMessage } from 'components';
import {
	getSmartDate,
	getSmartDateData,
	updateNodeDateChange,
} from 'utils/dateUtil';
import {
	validateFieldValues,
	validateCellIdSheetName,
} from 'utils/validationRules';
import {
	updateSessionStorage,
	removeStorage,
	getSessionStorageItem,
	showGetDataBtn,
} from 'utils/CommonUtil';
import { getDatasetInput } from 'utils/datasetUtil';
import {
	objectRequiredforPayload,
	updateCellValueIntoExcel,
	updateDatasetCellAddress,
	onClickGetDataBtn,
	updateCellValueOnDuplicate,
	updateRunAllStatus,
} from 'utils/GetDataUtil';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import LoadingOverlay from 'react-loading-overlay';
import {
	cellSheetPopulation,
	onInputCellChange,
	moveToCellSheet,
} from 'utils/excelUtils/ExcelDataPopulate';
import { excelCellClickCheck } from 'utils/excelUtils/ExcelCellValidate';
import {
	getInputNodeData,
	updateValues,
	updateInputNodesData,
} from 'utils/inputFormUtil';
import {
	updateNodeData,
	updateSelectedDataset,
} from 'containers/createNewDataset/CreateNewDatasetAction';
import {
	displaySettingAction,
	onDataRendered,
	updateDatasetToCosmos,
} from '../displaySettings/DisplaySettingsAction';
import { updateDataset } from '../datasetManager/DatasetManagerAction';
import './Accounting.scss';
import { emptyValueItem, defaultDisplayData } from './AccountingAction';
import { updateNodesInputData } from './AccountingReducer';

export class AccountingContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputFields: {
				mandatoryFields: [],
				optionalFields: [],
			},
			cellValue: getSessionStorageItem('inputCellValue') || '',
			sheetName: getSessionStorageItem('inputSheetName') || '',
			isValidExcelField: {
				isValidCellId: true,
				isValidSheetName: true,
			},
			isUsedCellAddress: true,
			backWarning: false,
			isCellSheetSelected: true,
			isFirstRowSelected: false,
		};
		this.mounted = false;
		this.analytics = window.analytics;
	}

	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);
		this.analytics.page({
			properties: {
				pageName: 'Welcome Page',
				pageRoute: window.location.hash,
				experience: 'Institutional',
			},
			// env: 'Institutional Passport - System',
			// experience: 'Institutional',
		});
		const { inputNodes, selectedDataset } = this.props;
		const { cellValue, sheetName } = this.state;
		const data = {
			inputNodes: { ...inputNodes },
			selectedDataset,
		};
		this.manipulateInputNodes(data);
		cellSheetPopulation(this.setCellSheet);

		if (cellValue && sheetName && this.mounted) {
			moveToCellSheet(cellValue, sheetName);
			this.checkValidCellSelected(cellValue);
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	// on success of metadata service method used to create inputFields for input form
	manipulateInputNodes = data => {
		const { inputFields } = this.state;
		const { inputNodes, selectedDataset } = data;
		const { MandatoryFields } = inputNodes;
		const mandatoryNodes = getInputNodeData(MandatoryFields, true);
		// inputFields.mandatoryFields = mandatoryNodes;
		inputFields.mandatoryFields = selectedDataset
			? this.getDataFromDatasetInput(mandatoryNodes, selectedDataset)
			: mandatoryNodes;
		this.setState({
			inputFields,
		});
	};

	// Dataset InputNode
	getDataFromDatasetInput = (mandatoryNodes, selectedDataset) => {
		const { inputNodes, duplicateClicked } = selectedDataset;
		const { mandatoryFields, inputCellNo, inputSheetName } = inputNodes;
		inputNodes.dataSheetDetail = `${inputCellNo}!${inputSheetName}`;

		if (!duplicateClicked) {
			updateCellValueOnDuplicate(selectedDataset);
		}
		const { cellValue, sheetName } = this.state;
		this.setState({
			cellValue: inputCellNo || cellValue,
			sheetName: inputSheetName || sheetName,
		});
		return getDatasetInput(mandatoryNodes, mandatoryFields);
	};

	// COMMON METHOD TO UPDATE FIELD VALUES
	updateNodeValues = (event, id, option) => {
		const { inputFields } = this.state;
		const {
			inputNodes,
			updateNodeDataItems,
			selectedDataset,
			updateSelectedDatasetItems,
		} = this.props;
		/* Selected Dataset Node update */
		let { mandatoryFields } = selectedDataset
			? get(selectedDataset, 'inputNodes')
			: { inputNodes: { mandatoryFields: null } };
		let { MandatoryFields } = inputNodes;
		let selectedSmartDateKey = null;
		if (id === 'smart-search' || id === 'ReferenceDate') {
			selectedSmartDateKey =
				option && option.key !== 'none' ? option.key : null;
			MandatoryFields = getSmartDateData(
				MandatoryFields,
				event,
				selectedSmartDateKey,
			);
			/* Selected Dataset Mandatory Node update */
			mandatoryFields = selectedDataset
				? getSmartDateData(mandatoryFields, event, selectedSmartDateKey)
				: null;
		} else if (event) {
			const { value } = event.target;
			MandatoryFields = updateValues(MandatoryFields, value, id);
			/* Selected Dataset Mandatory Node update */
			mandatoryFields = selectedDataset
				? updateValues(mandatoryFields, value, id)
				: null;
		}
		updateNodeDataItems(inputNodes);
		if (selectedDataset) {
			updateSelectedDatasetItems(selectedDataset);
			const selectedDatasetNodes = get(selectedDataset, 'inputNodes');
			selectedDatasetNodes.mandatoryFields = mandatoryFields;
		}
		inputFields.mandatoryFields = MandatoryFields;
		this.setState({
			inputFields,
			smartDateKey: selectedSmartDateKey,
		});
	};

	// ON TEXT FIELD CHANGE UPDATE ENTERED VALUE
	onChangeTextField = event => {
		const { id } = event.target;
		this.updateNodeValues(event, id);
	};

	// ON RADIO BUTTON CHANGE UPDATE SELECTED VALUE
	onChangeRadioButton = (event, id) => {
		this.updateNodeValues(event, id);
	};

	// ON SMART DATE DROPDOWN CHANGE UPDATE DATE IN BELOW METHOD
	onSmartDateChange = (event, option) => {
		const newDate = getSmartDate(option);
		const { id } = event.target;
		this.updateNodeValues(newDate, id, option);
	};

	// ON DATE SELECTED IN DATEPICKER BELOW METHOD UPDATES THE VALUE
	onDateChange = (event, id) => {
		this.updateNodeValues(event, id);
	};

	onDateRangeChange = (date, type) => {
		const { inputFields } = this.state;
		const {
			inputNodes,
			updateNodeDataItems,
			updateSelectedDatasetItems,
			selectedDataset,
		} = this.props;

		const { MandatoryFields } = inputNodes;
		// const referenceDate = get(this.inputData, 'ReferenceDate.value');
		inputNodes.MandatoryFields = updateNodeDateChange(
			MandatoryFields,
			date,
			type,
		);
		if (selectedDataset) {
			const selectedDatasetNew = get(selectedDataset, 'inputNodes');
			const dataUpdateFields = updateNodeDateChange(
				MandatoryFields,
				date,
				type,
			);
			selectedDatasetNew.mandatoryFields = dataUpdateFields;
			updateSelectedDatasetItems(selectedDataset);
		}
		updateNodeDataItems(inputNodes);
		inputFields.mandatoryFields = MandatoryFields;
		this.setState({
			inputFields,
		});
	};

	onCellChange = event => {
		const {
			cellValue,
			sheetName,
			isValidSheetName,
			isInputCellValid,
			isValidCellId,
			isFirstRowSelected,
		} = onInputCellChange(event);
		const newCellSelectedData = this.checkSelectedDatasetCell();
		const validCellAddress = newCellSelectedData
			? newCellSelectedData === isInputCellValid
			: isInputCellValid;
		const { inputNodes } = this.props;
		inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
		this.setState(
			{
				cellValue,
				sheetName,
				isValidExcelField: {
					isValidCellId,
					isValidSheetName,
				},
				isUsedCellAddress: validCellAddress,
				isCellSheetSelected: !cellValue && !sheetName,
				isFirstRowSelected,
			},
			() => {
				updateSessionStorage('inputCellValue', cellValue);
				updateSessionStorage('inputSheetName', sheetName);
			},
		);
	};

	checkSelectedDatasetCell = () => {
		const { selectedDataset } = this.props;
		if (selectedDataset) {
			const { inputCellNo } = get(selectedDataset, 'inputNodes');
			return inputCellNo;
		}
		return null;
	};

	setCellSheet = value => {
		if (this.mounted) {
			const {
				sheetName,
				cellValue,
				isFirstRowSelected,
				isInputCellValid,
				rangeValue,
			} = value;
			const newCellSelectedData = this.checkSelectedDatasetCell();
			const validCellAddress =
				newCellSelectedData === isInputCellValid || rangeValue === '';
			const { inputNodes } = this.props;
			inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
			this.setState(
				{
					sheetName,
					cellValue,
					isValidExcelField: {
						isValidCellId: cellValue !== '',
						isValidSheetName: sheetName !== '',
					},
					isUsedCellAddress: validCellAddress,
					isFirstRowSelected,
					isCellSheetSelected: !cellValue && !sheetName,
				},
				() => {
					updateSessionStorage('inputCellValue', value.cellValue);
					updateSessionStorage('inputSheetName', value.sheetName);
				},
			);
		}
	};

	updateInputData = () => {
		const { inputNodes } = this.props;
		const { MandatoryFields } = inputNodes;
		return updateInputNodesData(MandatoryFields);
	};

	// VALIDATE ALL MANDATORY FIELDS ON NEXT CLICK
	validateFields = () => {
		const { inputFields } = this.state;
		const { inputNodes } = this.props;
		const { MandatoryFields } = inputNodes;
		const nonValidField = validateFieldValues(MandatoryFields);
		inputFields.mandatoryFields = MandatoryFields;
		this.setState({
			inputFields,
			smartDateKey: null,
		});
		return isEmpty(nonValidField);
	};

	// validate excel cell id and sheet name fields
	validateExcelCellSheetName = () => {
		const {
			cellValue,
			sheetName,
			isUsedCellAddress,
			isFirstRowSelected,
		} = this.state;
		const { isValidCellId, isValidSheetName } = validateCellIdSheetName(
			cellValue,
			sheetName,
		);
		this.setState({
			isValidExcelField: { isValidCellId, isValidSheetName },
		});
		return (
			isValidCellId &&
			isValidSheetName &&
			isUsedCellAddress &&
			!isFirstRowSelected
		);
	};

	// check selected cell already used by input data.
	checkValidCellSelected = cellValue => {
		Office.onReady(() => {
			const document = get(Office, 'context.document');
			if (document) {
				document.addHandlerAsync(
					Office.EventType.DocumentSelectionChanged,
					() => {
						if (this.mounted) {
							Excel.run(() => {
								return excelCellClickCheck(cellValue).then(
									res => {
										this.setState({
											isUsedCellAddress: res,
										});
									},
								);
							});
						}
					},
				);
			}
		});
	};

	// Warning message for Input selected
	warningMessage = () => {
		const { inputFields } = this.state;
		const backWarningValidation = [...inputFields.mandatoryFields];
		const backWarningValidationData = emptyValueItem(backWarningValidation);
		const defaultDisplayOption = defaultDisplayData(backWarningValidation);
		return { backWarningValidationData, defaultDisplayOption };
	};

	// Update Input Cell Header

	// Check empty value object and return back to CreateDataset page
	onCancelClick = () => {
		const { history, isGetData } = this.props;
		const { backWarning, isCellSheetSelected } = this.state;
		const warningMessageStatus = this.warningMessage();
		const defaultSelectedStatus =
			isEmpty(warningMessageStatus.defaultDisplayOption) || backWarning;
		const backDefaultStatus =
			isEmpty(warningMessageStatus.backWarningValidationData) ||
			backWarning;
		if (defaultSelectedStatus && backDefaultStatus && isCellSheetSelected) {
			removeStorage(isGetData);
			history.push(`/create-new-dataset`);
		} else {
			this.setState({ backWarning: true, isCellSheetSelected: true });
		}
	};

	validationStatus = () => {
		const isAllFieldsValid = this.validateFields();
		const isValidCellSheet = this.validateExcelCellSheetName();
		return isAllFieldsValid && isValidCellSheet;
	};

	onSubmitForm = event => {
		event.preventDefault();
		const {
			history,
			updateNodesInputItems,
			selectedDataset,
			inputNodes,
		} = this.props;
		const { cellValue, sheetName } = this.state;

		const inputData = this.updateInputData();
		const isValidateStatus = this.validationStatus();
		inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
		if (isValidateStatus) {
			updateCellValueIntoExcel(
				inputData,
				selectedDataset,
				cellValue,
				sheetName,
			);
			updateNodesInputItems(inputData);
			if (selectedDataset && !selectedDataset.duplicateClicked) {
				updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
			}
			history.push(`/output-form`);
		}
	};

	isGetDataRendered = datasetRange => {
		const { updateDatasetValue, isDataRendered } = this.props;
		const dataset = updateDatasetValue(datasetRange);
		updateRunAllStatus(dataset, isDataRendered);
	};

	onClickGetData = event => {
		event.preventDefault();
		const { cellValue, sheetName } = this.state;
		const dataProps = this.props;
		const { inputNodes, updateNodeDataItems } = dataProps;
		inputNodes.dataSheetDetail = `${cellValue}!${sheetName}`;
		const objectForGetData = objectRequiredforPayload(dataProps);
		const isValidateStatus = this.validationStatus();
		const { getData } = objectForGetData;
		if (isValidateStatus) {
			updateNodeDataItems(inputNodes);
			onClickGetDataBtn(
				dataProps,
				getData,
				cellValue,
				sheetName,
				this.isGetDataRendered,
			);
		}
	};

	render() {
		const {
			inputFields,
			backWarning,
			smartDateKey,
			isValidExcelField,
			isUsedCellAddress,
			cellValue,
			sheetName,
			isFirstRowSelected,
		} = this.state;
		const { selectedServiceName, selectedDataset, isLoading } = this.props;
		return (
			<LoadingOverlay
				active={isLoading}
				spinner={<Spinner size={SpinnerSize.large} />}
				text={<ThrobberMessage />}
			>
				<div className="fund-accounting-container">
					<Header
						onClickGetData={this.onClickGetData}
						showGetDataButton={showGetDataBtn(selectedDataset)}
					/>
					<DynamicForm
						fields={inputFields}
						backWarning={backWarning}
						cellValue={cellValue}
						sheetName={sheetName}
						onCancelClick={this.onCancelClick}
						onChangeRadioButton={this.onChangeRadioButton}
						onDateChange={this.onDateChange}
						onSmartDateChange={this.onSmartDateChange}
						onDateRangeChange={this.onDateRangeChange}
						smartDateKey={smartDateKey}
						onChangeTextField={this.onChangeTextField}
						onCellChange={this.onCellChange}
						isValidExcelField={isValidExcelField}
						isUsedCellAddress={isUsedCellAddress}
						isFirstRowSelected={isFirstRowSelected}
						submitForm={this.onSubmitForm}
						serviceName={selectedServiceName}
						stepPage={1}
						isButtonDisable={!!selectedDataset}
					/>
				</div>
			</LoadingOverlay>
		);
	}
}

const mapStateToProps = state => ({
	selectedServiceName: get(state, 'data.createNewDataSet.selectedService'),
	inputNodes: get(state, 'data.createNewDataSet.inputNodes'),
	isGetData: get(state, 'data.getData.isGetDataClicked'),
	servicePath: get(state, 'data.createNewDataSet.servicePath'),
	selectedDataset: get(state, 'data.createNewDataSet.selectedDataset'),
	isLoading: get(state, 'data.getData.loading'),
	profile: get(state, 'data.userInContext.user.profile'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			updateNodeDataItems: updateNodeData,
			updateNodesInputItems: updateNodesInputData,
			updateSelectedDatasetItems: updateSelectedDataset,
			getData: displaySettingAction,
			isDataRendered: onDataRendered,
			storeDataset: updateDatasetToCosmos,
			updateDatasetValue: updateDataset,
			// redirectTo,
		},
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AccountingContainer);
