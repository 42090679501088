import { get, find, trim, filter, map } from 'lodash';
import {
	getLocalStorageItem,
	getSessionStorageItem,
	updateSessionStorage,
	commonObjectKey,
	updateLocalStorage,
	uniqueUUId,
	removeStorage,
} from './CommonUtil';
import { uuIdPattern, dateFormatText, ACCOUNTINGSERVICES } from './Constants';
import DisplaySettingsConst from '../containers/displaySettings/DisplaySettingsConst';
import { formatDate, getOriginDateFormat } from './dateUtil';
import { getInputData, updateColumnLastCellAddress, arrayPrototypeIndexObj } from './datasetUtil';
import { PERFORMANCE_COMMON, INDECESERVICES } from 'utils/Constants';
import {
	dataPopulationInput,
	dataPopulationOutput,
	clearExcelData,
	populateTitleToExcel,
	ExcelGetData,
	moveToCellSheet,
} from './excelUtils/ExcelDataPopulate';
import { updateInputNodesData } from './inputFormUtil';

const { PERFORMANCE_SERVICES } = DisplaySettingsConst;

const checkIsMVS = selectedServiceName => {
	const isMVS = PERFORMANCE_SERVICES.indexOf(selectedServiceName);
	return isMVS !== -1;
};

const generateUserUniqueId = (userId, format, uuId) => {
	return `${userId}_${format}_${uuId}`;
};
const getInputRequestData = (groupId, group, value) => {
	const inputGroup = group || groupId;
	if (groupId === "TimePeriodSingle") {
		return {
			id: value.key,
			inputGroup: "TimePeriod",
			value: "Y",
		};
	} else {
		return {
			id: groupId,
			inputGroup,
			value: value.key ? value.key : value,
		};
	}
};

const getFundAccountOutPutData = (outputColId, outputColTxt) => {
	return {
		id: outputColId,
		inputGroup: 'Metrics',
		value: outputColTxt,
	};
};
const refrenDateReturn = items => {
	return {
		value: items.value,
		colText: items.colText,
	};
};
const updateInputData = (dataFields, inputData) => {
	const { referenceDate } = commonObjectKey;
	dataFields.map(items => {
		inputData[items.groupId] =
			items.groupId === referenceDate
				? refrenDateReturn(items)
				: getInputData(items);
		return null;
	});
};

export const updateInputDatasetNode = inputNodes => {
	const { mandatoryFields, nonMandatoryFields } = inputNodes;
	const inputData = {};
	const sponsorData = get(inputNodes, 'sponsorCodeNode');
	if (sponsorData) {
		inputData[sponsorData.groupId] = {
			value: sponsorData.value,
			colText: sponsorData.colText,
		};
	}
	updateInputData(mandatoryFields, inputData);
	updateInputData(nonMandatoryFields, inputData);
	return inputData;
};

const updateOutputDatasetNode = outputNodes => {
	const { nonMandatoryFields } = outputNodes;
	return nonMandatoryFields;
};

const dateFormat = inputFormat => {
	const { dateValueFormat } = dateFormatText;
	const dateObj = new Date(inputFormat);
	return formatDate(dateObj, dateValueFormat);
};

// method returns object containing isValidCTP true/false and startDate, endDate value
const isCheckCTP = (item, itmVal) => {
	const start =
		get(itmVal, 'startDate.value') || get(itmVal, 'value.CTP.startDate');
	const end =
		get(itmVal, 'endDate.value') || get(itmVal, 'value.CTP.endDate');
	const isValidCTP =
		(item === 'CTP1' || item === 'FromDate') && !!start && !!end;
	return {
		startDate: isValidCTP ? dateFormat(start) : null,
		endDate: isValidCTP ? dateFormat(end) : null,
		isValidCTP,
	};
};
const inputArrayObject = (item, inputItemValue, nodeData) => {
	const {
		timePeriod,
		PerformanceIdentifier,
		calendarMonthTimePeriod,
		IndexPerformanceObject,
	} = commonObjectKey;
	if (item === timePeriod || item === calendarMonthTimePeriod) {
		let groupId;
		inputItemValue.forEach(value => {
			groupId = groupId ? `${groupId},${value.key}` : value.key;
		});
		groupId ? nodeData.push(getInputRequestData(groupId, item, 'Y')) : null;
	} else if (item === PerformanceIdentifier) {
		let keyVal;
		inputItemValue.forEach(value => {
			keyVal = keyVal ? `${keyVal},${value.key}` : value.key;
		});
		nodeData.push(getInputRequestData(item, false, keyVal));
	} else if (item === IndexPerformanceObject) {
		//1163204:Index Search
		let keyVal;
		inputItemValue.forEach(value => {
			keyVal = keyVal ? `${keyVal},${value.key}` : value.key;
		});
		nodeData.push(getInputRequestData(item, false, keyVal));
	} else {
		inputItemValue.forEach(value => {
			nodeData.push(getInputRequestData(item, false, value));
		});
	}
};
export const getInputRequestObject = inputData => {
	const { referenceDate, CTP, IndexPerformanceObject } = commonObjectKey;
	const { DATE_FORMAT } = DisplaySettingsConst;
	const nodeData = [];
	Object.keys(inputData).forEach(item => {
		const inputItem = inputData[item];

		// new
		if (inputItem) {
			const inputItemValue = inputItem.value;
			const checkCTPVal = isCheckCTP(item, inputItem);
			if (Array.isArray(inputItemValue)) {
				inputItemValue?.length > 0 ? inputArrayObject(item, inputItemValue, nodeData) : '';
			} else if (item === referenceDate) {
				nodeData.push(
					getInputRequestData(
						item,
						false,
						dateFormat(inputItemValue),
					),
				);
			} else if (checkCTPVal.isValidCTP) {
				const { startDate, endDate } = checkCTPVal;
				nodeData.push(
					getInputRequestData(CTP.startDate, item, startDate),
				);
				nodeData.push(getInputRequestData(CTP.endDate, item, endDate));
			} else if (item === IndexPerformanceObject) {
				//1163204:Index Search
				nodeData.push(
					getInputRequestData(
						item,
						false,
						arrayPrototypeIndexObj(inputData[IndexPerformanceObject].value.key),
					),
				);
			} else if (inputItemValue && inputItemValue.value !== '') {
				nodeData.push(getInputRequestData(item, false, inputItemValue));
			}
		}
	});
	DATE_FORMAT.value = getOriginDateFormat();
	nodeData.push(DATE_FORMAT);
	return nodeData;
};

const checkAccountingService = service => {
	const isAccounting = ACCOUNTINGSERVICES.find(
		item => item.toLowerCase() === service.toLowerCase(),
	);
	return !!isAccounting;
};

export const setInputDataDisplay = (
	outputData,
	selectedService,
	inputData,
	profile,
) => {
	const { INDICES_SERVICE_NAME, INDICES_FCTP_SERVICE_NAME, INDICES_FCTP } = PERFORMANCE_COMMON;
	const loggedInUser = profile ? profile.userId.toUpperCase() : '';
	const isAccountingService = checkAccountingService(selectedService);
	const userId = isAccountingService ? loggedInUser : profile.personaId;
	const fixedData = [
		{ id: 'userId', inputGroup: 'user', value: userId }, // profile.userId
	];
	let nodeData = getInputRequestObject(inputData);
	if (selectedService === INDICES_SERVICE_NAME || INDICES_FCTP_SERVICE_NAME === selectedService || INDICES_FCTP === selectedService) {
		nodeData = nodeData.filter(item => !INDECESERVICES.includes(item.id));
	}
	let payload = [...fixedData, ...nodeData];
	let outputColText;
	let outputColId;
	outputData.map(item => {
		outputColText = outputColText
			? `${outputColText},${item.colText}`
			: item.colText;
		outputColId = outputColId ? `${outputColId},${item.colId}` : item.colId;
		return item.selected;
	});
	payload.push(
		getInputRequestData('ServiceName', 'ServiceName', selectedService),
	);
	if (outputColText && outputColId) {
		payload.push(getFundAccountOutPutData(outputColId, outputColText));
	}
	const FREQUENCY_VAL = 'NA';
	const frequencyValue = payload.some(item => item.inputGroup === 'InputFrequency' && item.value === FREQUENCY_VAL);
	if (frequencyValue) {
		payload = payload.filter(item => item.inputGroup !== 'CTP1');
	}
	return JSON.stringify(payload);
};

export const currentTimeStamp = () => {
	const { dateTimeFormat } = dateFormatText;
	const currentDate = new Date();
	return formatDate(currentDate, dateTimeFormat);
};
// Create DataSet Title String
export const generateDatasetTitle = (data, user) => {
	let dataSetTitle = '';
	const userId = get(user, 'userId');
	data.map(item => {
		if (item.selected) {
			let labelValue = `${item.text}:${item.value}`;
			if (item.id === 'id-stamp' && userId) {
				labelValue = `${item.text}:${userId}`;
			}
			dataSetTitle = dataSetTitle
				? `${dataSetTitle}, ${labelValue}`
				: `${labelValue}`;
			if (item.id === 'dataset-title') {
				dataSetTitle = item.value;
			}
		}
		return dataSetTitle;
	});
	return dataSetTitle;
};

export const setOutputDataDisplay = outputData => {
	const nodeData = [];
	outputData.forEach(item => {
		if (item.selected === true) {
			nodeData.push({
				label: item.colText,
				value: item.colId,
			});
		}
	});
	return nodeData;
};

export const objectRequiredforPayload = dataProps => {
	const {
		selectedDataset,
		isDataRendered,
		servicePath,
		getData,
		selectedServiceName,
		profile,
	} = dataProps;
	const {
		inputNodes,
		outputNodes,
		displaySetting,
		reportName,
		serviceData,
	} = selectedDataset;
	return {
		isDataRendered,
		servicePath,
		serviceData,
		getData,
		selectedServiceName,
		inputNodes,
		outputNodes,
		displaySetting,
		reportName,
		profile,
	};
};
export const uniqueIdDisplaySetting = () => {
	updateLocalStorage('uniqueUUId', uniqueUUId(uuIdPattern));
};
export const updateUniqueId = (isGetDataClicked, isCreateNewDataset) => {
	removeStorage(isGetDataClicked);
	updateLocalStorage('isCreateNewDataset', isCreateNewDataset);
	uniqueIdDisplaySetting();
};
export const generateUniqueIdData = user => {
	const { pattern } = DisplaySettingsConst;
	const date = new Date();
	const fullDateFormat = formatDate(date, pattern);
	const uuidFromStorage = getLocalStorageItem('uniqueUUId');
	const uuId = uuidFromStorage || uniqueUUId(uuIdPattern);
	const uniqueId = generateUserUniqueId(user, fullDateFormat, uuId);
	return { uuId, uniqueId, fullDateFormat };
};

const datasetValueOnGetData = arg => {
	const {
		isDataRendered,
		servicePath,
		getData,
		selectedServiceName,
		inputNodes,
		outputNodes,
		displaySetting,
		reportName,
		serviceData,
		performance,
		existingDataSet,
	} = arg;
	const reportTitle = reportName;
	const { inputCellNo, inputSheetName } = inputNodes;
	const { outputCellNo, outputSheetName, endOutputCellValue } = outputNodes;
	const outputArrangeNodes = outputNodes;
	return {
		inputCellNo,
		inputSheetName,
		outputCellNo,
		outputSheetName,
		endOutputCellValue,
		outputArrangeNodes,
		reportTitle,
		isDataRendered,
		servicePath,
		serviceData,
		getData,
		selectedServiceName,
		displaySetting,
		inputNodes,
		performance,
		existingDataSet,
	};
};

export const removeCellDataValue = selectedDataset => {
	const inputDataSet = get(selectedDataset, 'inputNodes');
	const sessionCellValue = `${getSessionStorageItem(
		'startCellValue',
	)}:${getSessionStorageItem('inputCellValueRemove')}`;
	const removeRange = inputDataSet
		? `${inputDataSet.inputCellNo}:${inputDataSet.inputCellValueRemove}`
		: sessionCellValue;
	const removeSheet = inputDataSet
		? `${inputDataSet.inputSheetName}`
		: getSessionStorageItem('removeSheetName');
	return { removeRange, removeSheet };
};
const inputOutputData = objectForGetData => {
	const { inputNodes, outputNodes } = objectForGetData;
	const inputData = updateInputDatasetNode(inputNodes);
	const outputData = updateOutputDatasetNode(outputNodes);
	return { inputData, outputData };
};

const excelDataHeader = objectNewData => {
	const { inputNodes, outputNodes } = objectNewData;
	const { mandatoryFields, nonMandatoryFields } = inputNodes;
	const mandatoryInputData = updateInputNodesData(mandatoryFields);
	const nonMandatoryInputData = updateInputNodesData(nonMandatoryFields);
	const inputData = { ...mandatoryInputData, ...nonMandatoryInputData };
	const outputData = updateOutputDatasetNode(outputNodes);
	return { inputData, outputData };
};
export const inputOutputRequest = objectForGetData => {
	const { selectedServiceName, profile } = objectForGetData;
	const { inputData, outputData } = inputOutputData(objectForGetData);
	const outputDataList = setOutputDataDisplay(outputData);
	const inputOutputDataRequest = setInputDataDisplay(
		outputData,
		selectedServiceName,
		inputData,
		profile,
	);
	return { inputOutputDataRequest, outputDataList };
};

export const datasetTitleObject = dataObj => {
	const {
		outputArrangeNodes,
		reportTitle,
		servicePath,
		selectedServiceName,
		displaySetting,
		inputNodes,
		serviceData,
		performance,
		existingDataSet,
	} = datasetValueOnGetData(dataObj);
	const displaySettingObject = displaySetting;
	const { profile } = dataObj;
	const { uuId, uniqueId, fullDateFormat } = generateUniqueIdData(
		profile.personaId,
	);
	const parameterObj = {
		inputNodes,
		outputArrangeNodes,
		uniqueId,
		uuId,
		fullDateFormat,
		selectedServiceName,
		reportTitle,
		servicePath,
		displaySettingObject,
		serviceData,
		performance,
		existingDataSet,
	};
	return { parameterObj };
};
export const updateDatasetTimeStamp = displaySettingVal => {
	find(displaySettingVal, setItem => {
		if (setItem.id === 'time-stamp') {
			setItem.value = currentTimeStamp();
		}
	});
	return displaySettingVal;
};
export const clearAndPopulateDatasetTitle = dataSetParam => {
	const { displaySetting, profile } = dataSetParam;
	const { cellId, sheetName } = displaySetting;
	const displaySettingVal = get(displaySetting, 'displaySetting');
	const displaySettingObject = updateDatasetTimeStamp(displaySettingVal);
	const datasetTitle = generateDatasetTitle(displaySettingObject, profile);
	clearExcelData(cellId, sheetName);
	populateTitleToExcel(datasetTitle, cellId, sheetName);
};
// Check TimePeriod
export const isTimePeriodExist = dataList => {
	return find(dataList, itemList => {
		return itemList.groupId === 'TimePeriod';
	});
};
// Check TimePeriod
export const isSingleTimePeriodExist = dataList => {
	return find(dataList, itemList => {
		return itemList.groupId === 'TimePeriodSingle';
	});
};

// Check if CTP is selected in TimePeriod
export const isCTPSelected = dataLIst => {
	const isTimePeriod = isTimePeriodExist(dataLIst);
	const isSingleTimePeriod = isSingleTimePeriodExist(dataLIst);
	if (isTimePeriod) {
		const { value } = isTimePeriod;
		return filter(value, item => item.key === 'CTP');
	}
	 if (isSingleTimePeriod) {
	 	const { value } = isSingleTimePeriod;
		return (value && value.key === 'CTP') ? value : null;
	 }
	return null;
};
export const renderInputOuputExcelData = (isGetDataClicked, objectNewData) => {
	const {
		inputCellNo,
		inputSheetName,
		outputCellNo,
		outputSheetName,
		selectedServiceName,
	} = datasetValueOnGetData(objectNewData);
	const mandatoryObj = get(objectNewData, 'inputNodes.mandatoryFields');
	const isCTP = isCTPSelected(mandatoryObj);
	// const isMVS = checkIsMVS(selectedServiceName);
	const dataObj = isCTP
		? inputOutputData(objectNewData)
		: excelDataHeader(objectNewData);
	const { inputData, outputData } = dataObj;
	dataPopulationInput(
		inputData,
		inputSheetName,
		inputCellNo,
		isGetDataClicked,
	);
	dataPopulationOutput(
		outputData,
		outputCellNo,
		outputSheetName,
		isGetDataClicked,
	);
};

export const updateCellValueIntoExcel = (
	inputData,
	selectedDataset,
	cellValue,
	sheetName,
) => {
	const duplicateClicked = get(selectedDataset, 'duplicateClicked');
	const checkSelectedDuplicate = duplicateClicked ? null : selectedDataset;
	const { removeRange, removeSheet } = removeCellDataValue(
		checkSelectedDuplicate,
	);

	if (removeRange && removeSheet) {
		clearExcelData(removeRange, removeSheet);
	}
	dataPopulationInput(inputData, sheetName, cellValue, !!selectedDataset);
	updateSessionStorage('startCellValue', cellValue);
	updateSessionStorage('removeSheetName', sheetName);
};
export const updateDatasetCellAddress = (cellValue, sheetName, dataset) => {
	if (dataset) {
		const { inputNodes } = dataset;
		inputNodes.inputCellNo = cellValue;
		inputNodes.inputSheetName = sheetName;
		inputNodes.inputCellValueRemove = updateColumnLastCellAddress(
			inputNodes,
			cellValue,
		);
	}
};

export const updateClearCellAddress = (dataProps, cellValue, sheetName) => {
	const { selectedDataset } = dataProps;
	const { inputCellNo, inputSheetName, inputCellValueRemove } = get(
		selectedDataset,
		'inputNodes',
	);
	const datasetRange = get(selectedDataset, 'datasetRange');
	const range = datasetRange.split('!');
	const removeInputRange = `${inputCellNo}:${inputCellValueRemove}`;
	if (removeInputRange && inputSheetName) {
		clearExcelData(removeInputRange, inputSheetName);
	}
	if (range) {
		clearExcelData(range[0], range[1]);
	}
	updateDatasetCellAddress(cellValue, sheetName, selectedDataset);
};

export const serviceNameByPath = serviceMetaDataPath => {
	const newServiceName = serviceMetaDataPath.split('?');
	const serviceValue = newServiceName[1].split('=');
	return trim(serviceValue[1]);
};

export const getServiceName = (
	serviceData,
	performanceGetData,
	serviceName,
	selectedServiceName,
) => {
	const serviceMetaDataPath = get(serviceData, 'serviceMetaDataPath');
	const defaultServicename = performanceGetData
		? serviceName
		: selectedServiceName;
	return serviceMetaDataPath
		? serviceNameByPath(serviceMetaDataPath)
		: defaultServicename;
};
export const renderGetData = (objectForGetData, getData, isGetDataRendered) => {
	const { servicePath, outputNodes, serviceData } = objectForGetData;
	const serviceMetaDataPath = get(serviceData, 'serviceMetaDataPath');
	if (serviceMetaDataPath) {
		objectForGetData.selectedServiceName = serviceNameByPath(
			serviceMetaDataPath,
		);
	}

	const { inputOutputDataRequest, outputDataList } = inputOutputRequest(
		objectForGetData,
	);
	const { outputCellNo, outputSheetName, endOutputCellValue } = outputNodes;
	const excelDataOutput = {
		outputCellNo,
		endOutputCellValue,
		outputSheetName,
	};
	getData(inputOutputDataRequest, servicePath)
		.then(response => {
			ExcelGetData(
				response,
				outputDataList,
				isGetDataRendered,
				excelDataOutput,
			);
		})
		.catch(error => {
			return error;
		});
};
export const onClickGetDataBtn = (
	dataProps,
	getData,
	cellValue,
	sheetName,
	isGetDataRenderedAll,
) => {
	const objectForGetData = objectRequiredforPayload(dataProps);
	updateClearCellAddress(dataProps, cellValue, sheetName);
	clearAndPopulateDatasetTitle(objectForGetData);
	renderInputOuputExcelData(true, objectForGetData);
	renderGetData(objectForGetData, getData, isGetDataRenderedAll);
};
export const updateCellValueOnDuplicate = selectedDataset => {
	const { inputNodes, outputNodes } = selectedDataset;
	const { outputCellNo, endOutputCellValue } = outputNodes;
	const { inputCellNo, inputSheetName, inputCellValueRemove } = inputNodes;
	updateSessionStorage('startCellValue', inputCellNo);
	updateSessionStorage('removeSheetName', inputSheetName);
	updateSessionStorage('inputCellValue', inputCellNo);
	updateSessionStorage('inputSheetName', inputSheetName);
	updateSessionStorage('inputCellValueRemove', inputCellValueRemove);
	updateSessionStorage('startOutputCellValue', outputCellNo);
	updateSessionStorage('endOutputCellValue', endOutputCellValue);
	/* Function to update Last Cell Address */
	updateColumnLastCellAddress(inputNodes, inputCellNo);

	moveToCellSheet(inputCellNo, inputSheetName);
};
const isServiceNameSameWithTitle = (title, selectedService) => {
	const splitted = title.split(' ');
	const titleWithoutNum = splitted.slice(0, splitted.length - 1).join(' ');
	return titleWithoutNum === selectedService;
};

const customSort = (a, b) =>
	Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]);

// filter dataset and check incremental datase and return last title with max number
export const datasetLastTitle = (selectedService, reports) => {
	const reportTitleArr = [];
	map(reports, item => {
		const name = get(item, 'serviceData.servicename');
		const datasetServiceName =
			name && name.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, ' ');
		const isServiceNameTitle = isServiceNameSameWithTitle(
			item.reportName,
			selectedService,
		);
		if (datasetServiceName === selectedService && isServiceNameTitle) {
			reportTitleArr.push(item.reportName);
		}
		return datasetServiceName === selectedService;
	});
	const ascendingArr = reportTitleArr.sort(customSort);
	return ascendingArr[ascendingArr.length - 1];
};
// return title for dataset with numeric value
export const getDatasetTitle = (selectedDataset, serviceName, reports) => {
	const reportName = get(selectedDataset, 'reportName');
	const prevTitle = datasetLastTitle(serviceName, reports);
	const editClicked = get(selectedDataset, 'editClicked');
	if (editClicked) {
		return reportName;
	}
	if (!reports || !prevTitle) {
		return `${serviceName} 1`;
	}
	const splitedName = prevTitle.split(' ');
	const lastWord = splitedName[splitedName.length - 1];
	const isLastWordNum = /^-?\d+$/.test(lastWord);
	if (isLastWordNum) {
		const updatedNum = parseFloat(lastWord) + 1;
		const newName = splitedName.slice(0, splitedName.length - 1).join(' ');
		return `${newName} ${updatedNum}`;
	}
	return `${reportName || serviceName} 1`;
};

export const updateRunAllStatus = (dataset, isDataRendered) => {
	const newDataset = JSON.parse(dataset);
	const { reports } = newDataset;
	reports.map(items => {
		delete items.isRunAllFail;
		return items;
	});
	isDataRendered(JSON.stringify(newDataset));
};
