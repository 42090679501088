import React, { Component } from 'react';
import {
	SearchBox,
	Nav,
	Icon,
	mergeStyleSets,
	getTheme,
	getFocusStyle,
} from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Footer, Header, PivotItemList } from 'components';
import { updateUniqueId } from 'utils/GetDataUtil';
import { gtmEventTrack } from 'utils/GTM/GTMUtil';
import { getWorkbookId } from 'utils/excelUtils/ExcelDataPopulate';
import { cleareStoredDataset } from 'containers/welcome/WelcomeAction';
import {
	createNewDatasetValue,
	onServiceClick,
	onBackClick,
	onDatasetSelect,
} from './CreateNewDatasetAction';
import messages from './messages/CreateNewDataInitTexts';
import './CreateNewDataset.scss';
import { getGlobal } from '../../commands/commands';

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;
const classNames = mergeStyleSets({
	itemCell: [
		getFocusStyle(theme, { inset: -1 }),
		{
			minHeight: 54,
			padding: 10,
			boxSizing: 'border-box',
			borderBottom: `1px solid ${semanticColors.bodyDivider}`,
			display: 'flex',
			selectors: {
				'&:hover': { background: palette.neutralLight },
			},
		},
	],
	itemImage: {
		flexShrink: 0,
	},
	itemContent: {
		marginLeft: 10,
		overflow: 'hidden',
		flexGrow: 1,
		cursor: 'pointer',
		fontSize: '14px',
		fontWeight: 'normal',
	},
	itemName: [
		fonts.Large,
		{
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontSize: '16px',
			fontWeight: '600',
		},
	],
	itemIndex: {
		fontSize: '12px',
		color: palette.neutralSecondary,
		marginBottom: 10,
	},
	itemChildList: {
		paddingTop: '10px',
		paddingBottom: '20px',
	},
	selectedItem: {
		background: '#e2fce3',
	},
	unselectedItem: {
		background: '#fff',
	},
	chevron: {
		alignSelf: 'center',
		marginLeft: 10,
		color: palette.neutralTertiary,
		fontSize: fonts.large.fontSize,
		flexShrink: 0,
	},
});

const labelStyles = {
	root: { marginTop: 10 },
};
class CreateNewDatasetContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItems: props.items || [],
			searchText: null,
			isClickIndex: null,
			childVisible: false,
			count: 0,
		};
		this.global = getGlobal();
	}

	componentDidMount() {
		const { createNewDataset } = this.props;
		if (this.global.state.isSignedIn) {
			getWorkbookId(this.onWorkbookIdRecevied);
			createNewDataset();
		}
	}

	// below method fires action to read data from cosmos DB by passing id as query param
	onWorkbookIdRecevied = id => {
		const { clearDataset } = this.props;
		if (!id) {
			clearDataset();
		}
	};

	datasetSelectEvent = selectedServiceName => {
		const { searchText } = this.state;
		let initialText = messages.selectText;
		const { eventLabel } = messages;
		if (searchText) {
			initialText = messages.searchText;
		}
		return {
			eventCategory: `${initialText} ${messages.category}`,
			eventAction: selectedServiceName,
			eventLabel: searchText ? eventLabel.search : eventLabel.select,
		};
	};

	inputScreen = selectedServiceObj => {
		const { serviceClicked, history, selectedDataset } = this.props;
		const { servicename } = selectedServiceObj;
		const eventTrack = this.datasetSelectEvent(servicename);
		gtmEventTrack('selectDataset', eventTrack);
		updateUniqueId(true);
		selectedDataset(null);
		serviceClicked(selectedServiceObj, history);
	};

	onRenderRow = itemList => {
		return itemList.map(val => {
			return {
				key: val.productName,
				name: val.productName,
				expandAriaLabel: `Expand ${val.productName}`,
				collapseByDefault: true,
				collapseAriaLabel: `Collapse ${val.productName}`,
				isExpanded: val.isExpand,
				title: val.productDescription,
				links: val.serviceNames.map(value => {
					return {
						key: value.servicename,
						name: value.servicename,
						// onClick: () => this.inputScreen(value.servicename),
						onClick: () => this.inputScreen(value),
						title: value.serviceDescription,
					};
				}),
			};
		});
	};

	onClickGroup = (index, isVisible, childVisible) => {
		const { searchText, searchItems } = this.state;
		const { items } = this.props;
		const filteredItems = searchText ? searchItems : items;
		const filtered = [];
		Object.keys(filteredItems).map(key => {
			filtered[key] = {
				dataName: filteredItems[key].dataName,
				productNames: filteredItems[key].productNames,
				serviceCount: filteredItems[key].serviceCount,
				isExpand:
					filteredItems[key].dataName === index
						? !isVisible
						: filteredItems[key].isExpand,
			};
			return filtered;
		});
		this.setState({
			isClickIndex: index,
			childVisible: !childVisible,
			searchItems: filtered,
		});
	};

	isClick = index => {
		const { childVisible, count } = this.state;

		this.setState({
			isClickIndex: index,
			childVisible: !childVisible,
			count: count > 0 ? 0 : count + 1,
		});
	};

	onRenderCell = dataItem => {
		const { childVisible, isClickIndex, searchText } = this.state;
		let count = 0;
		if (!dataItem) {
			return null;
		}
		return dataItem.map((item, index) => {
			const {
				productNames,
				dataDescription,
				dataName,
				isExpand,
				serviceCount,
			} = item;
			const onclickValue = childVisible && isClickIndex === dataName;
			const expandGroup = searchText ? isExpand : onclickValue;
			const isExpandStatus = expandGroup ? 'ChevronDown' : 'ChevronRight';
			count += 1;
			return (
				<div key={dataName}>
					<div
						className={`${classNames.itemCell} ${
							isClickIndex === dataName
								? classNames.selectedItem
								: classNames.unselectedItem
						}`}
						data-is-focusable
						onClick={() => {
							this.onClickGroup(dataName, isExpand, childVisible);
						}}
						role="button"
						tabIndex={index}
						data-test="dynamic-list"
						onKeyDown={this.isClick}
					>
						<div className={classNames.itemContent}>
							<div className={classNames.itemName}>
								{dataName}
							</div>
							<div className={classNames.itemIndex}>
								{serviceCount} {messages.templateText}
							</div>
						</div>

						<Icon
							className={classNames.chevron}
							iconName={isExpandStatus}
						/>
					</div>
					<div>
						{this.onRenderItem(
							productNames,
							dataDescription,
							index,
							expandGroup,
							count,
						)}
					</div>
				</div>
			);
		});
	};

	onClickedBackButton = () => {
		const { history, backClicked } = this.props;
		backClicked();
		history.push(`/`);
	};

	onRenderItem = (item, dataDescription, index, isExpand, count) => {
		const { isClickIndex, childVisible } = this.state;
		const searchDisplay = isExpand ? 'block' : 'none';
		const clickDisplay =
			childVisible && isClickIndex === index ? 'block' : 'none';
		return (
			<div
				className={classNames.itemContent}
				style={{
					display: searchDisplay || clickDisplay,
				}}
				data-test="child-list"
			>
				<div className={classNames.itemChildList}>
					{dataDescription}
				</div>
				{count === 1 ? (
					<div style={{ display: 'none' }}>
						<Nav
							groups={[
								{
									links: [
										{
											name: null,
										},
									],
								},
							]}
						/>
					</div>
				) : null}
				<Nav groups={[{ links: this.onRenderRow(item) }]} />
			</div>
		);
	};

	onGroupSearch = (items, filtered, searchText) => {
		Object.keys(items).map(key => {
			if (
				items[key].dataName
					.toLowerCase()
					.includes(searchText.toLowerCase())
			) {
				filtered[key] = items[key];
				return filtered;
			}
			return null;
		});
	};

	onItemGroupFilter = (itemGroup, searchText) => {
		return itemGroup.filter(filterVal => {
			if (
				filterVal.productName
					.toLowerCase()
					.includes(searchText.toLowerCase())
			) {
				return filterVal;
			}
			return null;
		});
	};

	onSubgroupSearch = (items, filtered, searchText) => {
		Object.keys(items).map(key => {
			const itemGroup = items[key].productNames;
			Object.keys(itemGroup).map(productKey => {
				if (
					itemGroup[productKey].productName
						.toLowerCase()
						.includes(searchText.toLowerCase())
				) {
					filtered[key] = {
						dataDescription: items[key].dataDescription,
						dataId: items[key].dataId,
						dataName: items[key].dataName,
						productNames: this.onItemGroupFilter(
							itemGroup,
							searchText,
						),
						isExpand: true,
					};
					return filtered;
				}
				return null;
			});
			return null;
		});
	};

	onServiceSearch = (productKeyFilter, serviceNameValue, searchText) => {
		serviceNameValue.map(servicenameKey => {
			if (
				servicenameKey.servicename
					.toLowerCase()
					.includes(searchText.toLowerCase())
			) {
				productKeyFilter.push({
					displayOrientation: servicenameKey.displayOrientation,
					serviceDescription: servicenameKey.serviceDescription,
					serviceId: servicenameKey.serviceId,
					serviceMetaDataPath: servicenameKey.serviceMetaDataPath,
					servicename: servicenameKey.servicename,
					valuationFrequency: servicenameKey.valuationFrequency,
				});
				return productKeyFilter;
			}
			return null;
		});
	};

	onSubSubgroupSearch = (items, filtered, searchText) => {
		Object.keys(items).map(key => {
			const productArray = [];
			const itemGroup = items[key].productNames;
			Object.keys(itemGroup).map(productKey => {
				const productKeyFilter = [];
				const serviceNameValue = itemGroup[productKey].serviceNames;
				this.onServiceSearch(
					productKeyFilter,
					serviceNameValue,
					searchText,
				);
				if (productKeyFilter.length > 0) {
					productArray.push({
						productName: itemGroup[productKey].productName,
						productDescription:
							itemGroup[productKey].productDescription,
						productId: itemGroup[productKey].productId,
						serviceNames: productKeyFilter,
						isExpand: true,
					});
					filtered[key] = {
						isExpand: true,
						dataDescription: items[key].dataDescription,
						dataId: items[key].dataId,
						dataName: items[key].dataName,
						productNames: productArray,
						serviceCount: items[key].serviceCount,
					};
					return filtered;
				}
				return null;
			});
			return null;
		});
	};

	onSearchChange = event => {
		const searchText = event ? event.target.value : '';
		const { items } = this.props;
		const filtered = [];
		this.onGroupSearch(items, filtered, searchText);
		this.onSubgroupSearch(items, filtered, searchText);
		this.onSubSubgroupSearch(items, filtered, searchText);
		this.setState({ searchItems: filtered, searchText });
	};

	render() {
		const { searchText, searchItems } = this.state;
		const { items } = this.props;
		const itemsToRender = searchText ? searchItems : items;
		const categoryList = this.onRenderCell(itemsToRender);
		return (
			<div
				className="ms-Grid create-dataset-container"
				data-test="dadaSetContainer"
				dir="ltr"
			>
				<Header title={messages.Title} />
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 search-box">
						<SearchBox
							placeholder={messages.searchInputboxText}
							id="search-box"
							onChange={this.onSearchChange}
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<PivotItemList
							categoryList={categoryList}
							labelStyles={labelStyles}
							messages={messages.pivotText}
							noContentText={messages.noContentText}
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<Footer
						backButtonId="create-dataset-backbutton"
						backButtonText="Back"
						onClickedBackButton={this.onClickedBackButton}
						showBackButton
					/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	items: get(state, 'data.createNewDataSet.items.lstProductData'),
	loading: get(state, 'data.createNewDataSet.loading'),
	error: get(state, 'data.createNewDataSet.error'),
	selectedDataset: get(state, 'data.createNewDataSet.selectedDataset'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			createNewDataset: createNewDatasetValue,
			selectedDataset: onDatasetSelect,
			serviceClicked: onServiceClick,
			backClicked: onBackClick,
			clearDataset: cleareStoredDataset,
		},
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateNewDatasetContainer);
