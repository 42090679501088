import { get } from 'lodash';
import { welcomeConst } from './WelcomeConst';

const {
	FETCH_DATASET_BEGIN,
	FETCH_DATASET_SUCCESS,
	FETCH_DATASET_FAILURE,
	STORE_DATASET_SUCCESS,
	CLEAR_STORED_DATASET,
	UPDATE_AS_OF_DATE,
	SIGN_OUT_CLEAR_DATASET,
} = welcomeConst;

const initialState = {
	loading: false,
	error: null,
	workBookId: '',
	data: {},
};

export default function productReducer(state = initialState, action = {}) {
	switch (action.type) {
		case FETCH_DATASET_BEGIN:
			// Mark the state as "loading" so we can show a spinner or something
			// Also, reset any errors. We're starting fresh.
			return {
				...state,
				loading: true,
			};

		case FETCH_DATASET_SUCCESS:
		case STORE_DATASET_SUCCESS:
			// All done: set loading "false".
			// STORE_DATASET_SUCCESS triggered from getData
			// Also, replace the items with the ones from the server
			return {
				...state,
				loading: false,
				workBookId: get(action, 'payload.id'),
				data: action.payload,
			};
		case FETCH_DATASET_FAILURE:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				data: {},
				loading: false,
			};
		case CLEAR_STORED_DATASET:
			return {
				...state,
				workBookId: '',
				data: {},
			};
		case UPDATE_AS_OF_DATE:
			return {
				...state,
				data: action.payload,
			};
		case SIGN_OUT_CLEAR_DATASET:
			return {
				loading: false,
				error: null,
				workBookId: '',
				isRenameSheet: false,
				data: {},
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
