import { get } from 'lodash';
import { CreateNewDataConst } from './CreateNewDatasetConst';

const {
	FETCH_SERVICE_BEGIN,
	NEW_SERVICE_SELECTED,
	FETCH_SERVICE_SUCCESS,
	FETCH_SERVICE_FAILURE,
	FETCH_CREATE_DATA_SUCCESS,
	FETCH_CREATE_DATA_FAILURE,
	BACK_BUTTON_CLICKED,
	UPDATE_NODE_DATA,
	UPDATE_INPUT_DATA,
	UPDATE_ARRANGE_NODE,
	SELECTED_DATASET,
	UPDATE_SELECTED_DATASET,
	MASTERTRUST_UPDATE,
} = CreateNewDataConst;

const initialState = {
	items: [],
	selectedService: null,
	selectedServiceData: null,
	loading: false,
	error: null,
	inputNodes: {},
	outputNodes: {},
	masterTrustDataList: {},
	inputDataRequest: {},
	outputArrangeNodes: {},
	selectedDataset: {},
	masterTrust_DD: [],
};

export default function productReducer(state = initialState, action = {}) {
	const servicename = get(action, 'payload.selectedSerivceObj.servicename');
	const selectedServiceData = get(action, 'payload.selectedSerivceObj');
	switch (action.type) {
		case FETCH_SERVICE_BEGIN:
			// Mark the state as "loading" so we can show a spinner or something
			// Also, reset any errors. We're starting fresh.
			return {
				...state,
				loading: true,
				error: null,
			};

		case NEW_SERVICE_SELECTED:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				selectedServiceData,
				loading: false,
				selectedService: servicename,
			};

		case FETCH_CREATE_DATA_SUCCESS:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				loading: false,
				items: action.payload,
			};
		case FETCH_CREATE_DATA_FAILURE:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				loading: false,
				error: action.payload,
				items: [],
			};

		case FETCH_SERVICE_SUCCESS:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				loading: false,
				error: null,
				inputNodes: action.payload.inputNodes,
				outputNodes: action.payload.outputNodes,
				masterTrustDataList: action.payload.masterTrustDataList,
				servicePath: action.payload.servicePath,
			};

		case UPDATE_NODE_DATA:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				inputNodes: action.payload,
			};
		case UPDATE_ARRANGE_NODE:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				outputArrangeNodes: action.payload,
			};
		case UPDATE_INPUT_DATA:
			// All done: set loading "false".
			// Also, replace the items with the ones from the server
			return {
				...state,
				inputDataRequest: action.payload,
			};
		case SELECTED_DATASET:
		case UPDATE_SELECTED_DATASET:
			return {
				...state,
				selectedDataset: action.payload,
			};
		case FETCH_SERVICE_FAILURE:
			// The request failed, but it did stop, so set loading to "false".
			// Save the error, and we can display it somewhere
			// Since it failed, we don't have items to display anymore, so set it empty.
			// This is up to you and your app though: maybe you want to keep the items
			// around! Do whatever seems right.
			return {
				...state,
				loading: false,
				error: action.payload,
				inputNodes: {},
				outputNodes: {},
				masterTrustDataList: {},
				servicePath: '',
			};
		case BACK_BUTTON_CLICKED:
			return {
				...state,
				loading: false,
				error: null,
				selectedService: null,
				selectedServiceData: null,
				items: [],
				inputNodes: {},
				outputNodes: {},
				masterTrustDataList: {},
				inputDataRequest: {},
				outputArrangeNodes: {},
				servicePath: '',
				selectedDataset: {},
			};
		case MASTERTRUST_UPDATE:
			return {
				...state,
				masterTrust_DD: action.payload,
			};

		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
