import React, { memo } from 'react';
import {
	ChoiceGroup,
	initializeIcons,
	TooltipHost,
	Icon,
} from 'office-ui-fabric-react';
import InformationIcon from '../informationIcon/InformationIcon';
import './RadioButton.scss';

initializeIcons(undefined, { disableWarnings: true });

const RadioButton = props => {
	const {
		id,
		listItem,
		label,
		tooltipMessage,
		required,
		defaultValue,
		onChangeRadioButton,
		hideRadioBTN,
	} = props;
	const calloutProps = { gapSpace: 0 };
	const hostStyles = { root: { display: 'inline-block' } };

	const labelIconInformation = itemName => {
		return (
			<div className="label-tooltip-spacing" key={itemName.text}>
				<span>{itemName.text}</span>
				<TooltipHost
					content={itemName.helpText}
					id="icon-button-tooltip"
					calloutProps={calloutProps}
					styles={hostStyles}
				>
					<Icon iconName="info" className="tooltip-icon" />
				</TooltipHost>
			</div>
		);
	};
	const updateOnRenderLabel = item => {
		const labelIconInfo = labelIconInformation(item);
		return <>{labelIconInfo}</>;
	};
	listItem.map(item => {
		if (item.helpText) {
			item.onRenderLabel = updateOnRenderLabel;
		}
		return item.helpText;
	});
	return (
		<>
			{hideRadioBTN ? null : (
				<div className="radio-button-container" key={id}>
					<InformationIcon
						labelId={id}
						label={label}
						tooltipMessage={tooltipMessage}
						mandatory={required}
					/>

					<ChoiceGroup
						id={id}
						selectedKey={defaultValue}
						options={listItem}
						ariaLabelledBy={id}
						onChange={(event, option) =>
							onChangeRadioButton(event, option)
						}
					/>
				</div>
			)}
		</>
	);
};
export default memo(RadioButton);
