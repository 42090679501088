import React, { useEffect } from 'react';
import { DisplaySettingsContainer } from 'containers';
import { gtmTrackPage } from 'utils/GTM/GTMUtil';
import { gaPageTrackTags } from 'utils/GTM/GATags';

const DisplaySettingsPage = props => {
	useEffect(() => {
		gtmTrackPage(gaPageTrackTags.displaySettingsPage);
	}, []);
	return (
		<>
			<DisplaySettingsContainer {...props} />
		</>
	);
};

export default DisplaySettingsPage;
