import { host } from './Constants';

export const checkEnv = () => {
	const { origin } = window.location;
	const isDev = origin === host.development || origin === host.devExtHost;
	const isQA = origin === host.qa || origin === host.qaExtHost;
	const isUAT = origin === host.uat || origin === host.uatExtHost;
	let env = 'PROD';
	if (isDev || origin.indexOf('localhost') !== -1) {
		env = 'DEV';
	} else if (isUAT) {
		env = 'UAT';
	} else if (isQA) {
		env = 'QA';
	}
	return env;
};

const commonConfig = {
	isProd: process.env.NODE_ENV === 'production',
	isDev: process.env.NODE_ENV === 'development',
};

const getApiHost = () => {
	const location = window.location.origin;
	const hostEvn = host[process.env.NODE_ENV];
	let url;
	if (location.indexOf('localhost') === -1) {
		url = location.replace(/datadirectui/, 'gatewayservice');
	} else {
		url = hostEvn
			? hostEvn.replace(/datadirectui/, 'gatewayservice')
			: null;
	}
	return url;
};

const prodConfig = {
	...commonConfig,
	serverUrl: getApiHost(),
};

const devConfig = {
	...commonConfig,
	serverUrl: getApiHost(),
};

const getHost = () => {
	let configData;
	if (commonConfig.isProd) {
		configData = prodConfig;
	} else {
		configData = devConfig;
	}
	return configData;
};

export const config = getHost();

// export config;
