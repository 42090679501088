import { get } from 'lodash';
import { redirectToPath } from 'utils/CommonActions';
import { createAction } from 'utils/helpers';
import { Api } from 'api/Api';
import { welcomeConst } from './WelcomeConst';

const {
	FETCH_DATASET_BEGIN,
	FETCH_DATASET_SUCCESS,
	FETCH_DATASET_FAILURE,
	CLEAR_STORED_DATASET,
	MASTERTRUST_UPDATE,
} = welcomeConst;

// getMaster trust list fake call:
export const retrieveMasterTrust = () => (dispatch, getState) => {
	const state = getState();
	const isInternal = get(state, 'data.userInContext.user.profile.isInternal');
	return Api.getMasterTrust()
		.then(json => {
			if (isInternal === 'N') {
				dispatch(
					createAction(MASTERTRUST_UPDATE, json.data.slice(0, 100)),
				);
			}
			return json.data;
		})
		.catch(error => error);
};
// getWelcome Messages:
export const retrieveWelcomeMessages = setWelcomeListMessage => () => {
	return Api.getWelcomeMessages()
		.then(json => {
			setWelcomeListMessage(json.data);
		})
		.catch(error => error);
};
export const onCreateDataSetClicked = redirectUrl => dispatch => {
	dispatch(redirectToPath(redirectUrl));
};

// retrieve dataset from cosmos DB
export const getDataset = id => {
	return dispatch => {
		dispatch(createAction(FETCH_DATASET_BEGIN));
		return Api.retrieveDataSet(id)
			.then(json => {
				dispatch(createAction(FETCH_DATASET_SUCCESS, json.data));
			})
			.catch(error =>
				dispatch(createAction(FETCH_DATASET_FAILURE, error)),
			);
	};
};

export const cleareStoredDataset = () => dispatch => {
	dispatch(createAction(CLEAR_STORED_DATASET));
};
