import React, { Component } from 'react';
import { LoginSuccess, NtLogo, OfficeAddinMessageBar } from 'components';
import { PrimaryButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { signInO365, signOutO365 } from 'utils/excelUtils/office-apis-helpers';
import {
	onSheetRename,
	removeEvent,
	registerOnAddHandler,
} from 'utils/excelUtils/ExcelDataPopulate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoginTexts } from './messages/LoginTexts';
import { AuthService } from '../../services/AuthService';
import { getGlobal } from '../../commands/commands';
import { onSheetNameChange } from '../displaySettings/DisplaySettingsAction';
import {
	userInContext,
	signOut,
	isSignedInWithValidToken,
} from './LoginAction';
import './Login.module.scss';

class LoginContainer extends Component {
	constructor(props) {
		super(props);
		this.authService = new AuthService();
		this.shouldCancel = false;
		this.state = {
			authStatus: 'notLoggedIn',
			processMsg: '',
			errorMessage: '',
			user: {},
			status: localStorage.getItem('loggedIn') === 'yes',
			// api: {},
		};

		// Bind the methods that we want to pass to, and call in, a separate
		// module to this component. And rename setState to boundSetState
		// so code that passes boundSetState is more self-documenting.
		this.boundSetState = this.setState.bind(this);
		this.setToken = this.setToken.bind(this);
		this.displayError = this.displayError.bind(this);
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
		const { isValidToken } = this.props;
		const { status } = this.state;
		if (localStorage.getItem('loggedIn') === 'yes' || status) {
			isValidToken();
		}
	}

	boundSetState = () => {};

	setToken = accesstoken => {
		this.accessToken = accesstoken;
	};

	// Runs when the user clicks the X to close the message bar where
	// the error appears.
	errorDismissed = () => {
		this.setState({ errorMessage: '' });

		// If the error occured during a "in process" phase (logging in or getting files),
		// the action didn't complete, so return the UI to the preceding state/view.
		this.setState(prevState => {
			if (prevState.authStatus === 'loginInProcess') {
				return { authStatus: 'notLoggedIn' };
			}
			return null;
		});
	};

	displayError = error => {
		this.setState({ errorMessage: error });
	};

	login = () => {
		this.setState({
			authStatus: 'loginInProcess',
			processMsg: LoginTexts.loginProcessText,
		});
		signInO365(this.boundSetState, this.setToken, this.displayError);
	};

	logout = () => {
		this.setState({
			authStatus: 'logoutInProcess',
			processMsg: LoginTexts.logoutProcessText,
			user: {},
		});
		const g = getGlobal();
		const { signOutUser, onChangeSheetName } = this.props;
		localStorage.setItem('loggedIn', 'no');
		localStorage.removeItem('authToken');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('id_token');
		
		sessionStorage.setItem('loggedIn', false);
		g.state.isSignedIn = false;
		g.state.isMenuEnabled = false;
		sessionStorage.setItem('isRenameSheet', false);
		removeEvent(onChangeSheetName).then(() => {
			signOutUser();
			signOutO365(this.boundSetState, this.setToken, this.displayError);
		});
	};

	loginSuccessView = () => {
		const { user } = this.state;
		const { putUserInContext } = this.props;
		const { onChangeSheetName } = this.props;
		const isRenameSheetRegistered = sessionStorage.getItem('isRenameSheet');
		const isLoggedIn = localStorage.getItem('loggedIn');
		if (!JSON.parse(isRenameSheetRegistered) && !!isLoggedIn) {
			onSheetRename(onChangeSheetName);
			registerOnAddHandler();
		}
		putUserInContext(user);
		return <LoginSuccess {...this.props} user={user} />;
	};

	render() {
		const g = getGlobal();
		let body;
		const { authStatus, errorMessage, processMsg, status } = this.state;
		if (authStatus === 'notLoggedIn') {
			body = (
				<>
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
							<NtLogo
								className="nt-logo"
								id="logo"
								alttext="Stacked Centered Default Anchor Logo"
							/>
						</div>
					</div>
					<div className="ms-Grid-row login-heading">
						<div className="ms-Grid-col ms-sm12">
							{g.state.isSignedIn && status ? (
								<p>{LoginTexts.logoutDescription}</p>
							) : (
								<p>{LoginTexts.loginDescription}</p>
							)}
						</div>
					</div>
					<div className="ms-Grid-row button-login">
						<div className="ms-Grid-col ms-sm6">
							<PrimaryButton
								className="btn-login"
								id="login"
								text="Sign In"
								onClick={this.login}
								allowDisabledFocus
								disabled={g.state.isSignedIn && status}
							/>
						</div>
						<div className="ms-Grid-col ms-sm6">
							<PrimaryButton
								className="btn-login"
								id="signout"
								text="Sign Out"
								onClick={this.logout}
								allowDisabledFocus
								disabled={!g.state.isSignedIn || !status}
							/>
						</div>
					</div>
				</>
			);
		} else if (authStatus === 'loggedIn') {
			body = this.loginSuccessView();
		} else if (
			authStatus === 'loginInProcess' ||
			authStatus === 'logoutInProcess'
		) {
			body = (
				<Spinner
					className="spinner"
					type={SpinnerSize.large}
					label={processMsg}
				/>
			);
		} else if (errorMessage !== '') {
			body = (
				<OfficeAddinMessageBar
					onDismiss={this.errorDismissed}
					message={`${errorMessage} `}
				/>
			);
		}
		return (
			<div className="auth-login ms-Grid login-container" dir="ltr">
				{body}
			</div>
		);
	}
}
const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			putUserInContext: userInContext,
			signOutUser: signOut,
			isValidToken: isSignedInWithValidToken,
			onChangeSheetName: onSheetNameChange,
		},
		dispatch,
	),
});
export default connect(null, mapDispatchToProps)(LoginContainer);
