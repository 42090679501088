/*
    This component is needed to wrap the Fabric React MessageBar when the latter appears at the
    top of the task pane in an Office Add-in, because the taskpane in an Office Add-in has a
    personality menu that covers a small rectangle of the upper right corner of the task pane.
    This rectangle covers the "dismiss X" on the right end of the MessageBar unless extra padding
    is added.
*/

import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

const OfficeAddinMessageBar = props => {
	const officeAddinTaskpaneStyle = { paddingRight: '20px' };
	const { onDismiss, message } = props;

	return (
		<div style={officeAddinTaskpaneStyle}>
			<MessageBar
				messageBarType={MessageBarType.error}
				isMultiline
				onDismiss={onDismiss}
				dismissButtonAriaLabel="Close"
			>
				{message}.{' '}
			</MessageBar>
		</div>
	);
};

export default OfficeAddinMessageBar;
