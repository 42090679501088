const getPageRoute = () => {
	const hashRote = window.location.hash;
	const route = hashRote && hashRote.substr(1);
	return route;
};

export const gtmTrackPage = pageProps => {
	const { analytics } = window;
	if (analytics) {
		analytics.page({
			pageName: pageProps.pageName,
			pageRoute: getPageRoute(),
			properties: {
				...pageProps,
				experience: 'Institutional',
			},
		});
	}
};

export const gtmEventTrack = (name, eventProps) => {
	const { analytics } = window;
	analytics.track(`gaTrackEvent_${name}`, {
		...eventProps,
		experience: 'Institutional',
	});
};
