import React from 'react';
import { get } from 'lodash';
import { Text, ActionButton } from 'office-ui-fabric-react';

const styles = {
	fontStyle: { fontSize: '12px' },
	fontStyleLarge: { fontSize: '18px' },
};
const DialogBoxHeader = props => {
	const {
		outputMsg,
		onSelectAllClick,
		onClearSelection,
		onDefaultSelection,
		hideSelectAll,
	} = props;
	const classStyle = `ms-Grid-col ms-sm2 ms-md2 ms-lg2 col-padding ${
		hideSelectAll ? 'ms-smPush2' : ''
	}`;
	const actionButtonLable = get(outputMsg, 'actionButtonLable');
	const iconName = get(outputMsg, 'iconName');
	return (
		<div className="ms-Grid-row">
			<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 col-padding">
				<Text
					variant="xLarge"
					id="title"
					className={styles.fontStyleLarge}
				>
					{outputMsg.expandPageTitle}
				</Text>
			</div>
			<div className={classStyle}>
				<ActionButton
					onClick={onDefaultSelection}
					iconProps={{
						iconName: iconName.acceptIcon,
						className: 'icon-background-color',
					}}
					className={styles.fontStyle}
				>
					{actionButtonLable.defaultSelection}
				</ActionButton>
			</div>
			{hideSelectAll ? null : (
				<div className={classStyle}>
					<ActionButton
						onClick={onSelectAllClick}
						className={styles.fontStyle}
						id="select-all"
					>
						{actionButtonLable.selectAll}
					</ActionButton>
				</div>
			)}
			<div className={classStyle}>
				<ActionButton
					onClick={onClearSelection}
					className={styles.fontStyle}
					id="clear-all"
				>
					{actionButtonLable.clearSelection}
				</ActionButton>
			</div>
		</div>
	);
};
export default DialogBoxHeader;
