import { createStore, compose, applyMiddleware } from 'redux';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import { browserHistory } from 'react-router';
// import { throttle } from 'lodash';
// import { saveState } from 'utils/CommonUtil';
// import { promiseMiddleware } from 'redux-promise-middleware';
import rootReducer from './reducers';

// Commonly used middlewares and enhancers */
// See: http://redux.js.org/docs/advanced/Middleware.html*/
const routerHistoryMiddleware = routerMiddleware(browserHistory);
let middlewares = [thunk, routerHistoryMiddleware];
if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
	const loggerMiddleware = createLogger();
	middlewares = [...middlewares, loggerMiddleware];
}

// middlewares.push(routerMiddleware(browserHistory));
// middlewares.push(promiseMiddleware());
// middlewares.push(createLogger());

/* Everyone should use redux dev tools */
/* https://github.com/gaearon/redux-devtools */
/* https://medium.com/@meagle/understanding-87566abcfb7a */
const enhancers = [];
const { devToolsExtension } = window;
if (typeof devToolsExtension === 'function') {
	enhancers.push(devToolsExtension());
}
// enhancers.push(responsiveStoreEnhancer);

const composedEnhancers = compose(
	applyMiddleware(...middlewares),
	...enhancers,
);

const store = createStore(rootReducer, undefined, composedEnhancers);

const persistor = persistStore(store);
export const history = syncHistoryWithStore(browserHistory, store);

/* Hot reloading of reducers.  How futuristic!! */
if (module.hot) {
	module.hot.accept('./reducers', () => {
		/*eslint-disable */ // Allow require
		const nextRootReducer = require('./reducers').default;
		/* eslint-enable */
		store.replaceReducer(nextRootReducer);
	});
}

// store.subscribe(
// 	throttle(() => {
// 		const { createNewDataSet } = store.getState().data;
// 		saveState({
// 			selectedServiceName: createNewDataSet.selectedService,
// 		});
// 	}, 1000),
// );

export { store, persistor };
