import React from 'react';
import {
	DatePicker,
	Label,
	Dropdown,
	initializeIcons,
	createTheme,
} from 'office-ui-fabric-react';
import { get } from 'lodash';
import { formatDate } from 'utils/dateUtil';
import { DefaultColors } from 'styles/colors';
import './SelectAsOfSmartDate.scss';
import { getLocalStorageItem } from 'utils/CommonUtil';
import InformationIcon from '../../informationIcon/InformationIcon';
import { DayPickerStrings } from '../constants/constants';
import { dataOption } from './SelectAsOfSmartDateConst';
import SelectAsOfSmartDateTexts from './SelectAsOfSmartDateTexts';

initializeIcons();

const onFormatDate = (date, pattern) => {
	return formatDate(date, pattern);
};

const SelectAsOfSmartDate = props => {
	const {
		form,
		showMonthPickerAsOverlay,
		messages,
		pattern,
		isSmartDateRequired,
		onDateChange,
		onSmartDateChange,
		allowTextInput,
		style,
		smartDateKey,
	} = props;
	const theme = createTheme({
		palette: {
			neutralSecondary: form.valid
				? DefaultColors.BoxColor
				: DefaultColors.ErrorColor,
		},
	});
	const localData = getLocalStorageItem('inputData');
	const { datePickerPlaceholder, smartDatePlaceholder } = messages || {};
	const {
		defaultDatePlaceholder,
		defaultSDPlaceholder,
	} = SelectAsOfSmartDateTexts;
	const smartDateSelectedKey =
		get(localData, 'SmartDate.key') || form.smartDateKey || smartDateKey;
	const showOrText = !smartDateKey && !smartDateSelectedKey;
	const newValue = get(form, 'value');
	const selectedDate = newValue && newValue !== '' && new Date(newValue);
	const onDateValueChange = value => {
		onDateChange(value, form.groupId);
	};
	const showLabel = showOrText ? (
		<Label className="or-label smart-date-place" variant="medium">
			OR
		</Label>
	) : null;
	return (
		<div className="ms-Grid-row select-as-of-date">
			<div className="ms-Grid-col ms-sm5 ms-md4 ms-lg4">
				<InformationIcon
					labelId={form.colId}
					label={form.colText}
					tooltipMessage={form.toolTipMsg}
					mandatory={form.mandatory}
				/>

				<DatePicker
					className={style}
					id={form.valid ? form.colId : 'referancedate'}
					showMonthPickerAsOverlay={showMonthPickerAsOverlay}
					onSelectDate={onDateValueChange}
					isRequired={form.mandatory}
					strings={DayPickerStrings}
					allowTextInput={allowTextInput}
					isMonthPickerVisible={false}
					formatDate={
						pattern ? date => onFormatDate(date, pattern) : null
					}
					maxDate={new Date()}
					value={selectedDate}
					placeholder={
						datePickerPlaceholder || defaultDatePlaceholder
					}
					theme={theme}
				/>
				{!form.valid ? (
					<p className="errorClass">{form.errorMsg}</p>
				) : null}
			</div>
			{isSmartDateRequired ? (
				<>
					<div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
						{showLabel}
					</div>
					<div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6">
						<Dropdown
							className={`${style} smart-date-place`}
							id="smart-search"
							selectedKey={smartDateSelectedKey}
							placeholder={
								smartDatePlaceholder || defaultSDPlaceholder
							}
							options={dataOption}
							onChange={onSmartDateChange}
						/>
					</div>
				</>
			) : null}
		</div>
	);
};

export default SelectAsOfSmartDate;
