import React from 'react';
import { get } from 'lodash';
import { Stack, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

const stackTokens = { childrenGap: 20 };
const DialogBoxFooter = props => {
	const { outputMsg, closeDailogBox, onClickApplyOuput } = props;
	const actionButtonLable = get(outputMsg, 'actionButtonLable');
	return (
		<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 col-padding footer-fixed">
			<div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 ms-smPush7">
				<Stack horizontal tokens={stackTokens} horizontalAlign="end">
					<DefaultButton
						id="cancel"
						text={actionButtonLable.cancelButton}
						onClick={closeDailogBox}
					/>
					<PrimaryButton
						id="apply-outputs"
						text={actionButtonLable.applyButton}
						onClick={onClickApplyOuput}
					/>
				</Stack>
			</div>
		</div>
	);
};
export default DialogBoxFooter;
