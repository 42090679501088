import ThankYouPage from './ThankYouPage';

export default {
	routeProps: {
		path: '/thankyou',
		exact: true,
		component: ThankYouPage,
	},
	name: 'ThankYouPage',
};
