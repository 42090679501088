import LogoutSuccessPage from './LogoutSuccessPage';

export default {
	routeProps: {
		path: '/logout-success',
		exact: true,
		component: LogoutSuccessPage,
	},
	name: 'LogoutSuccessPage',
};
