import DisplaySettingsPage from './DisplaySettingsPage';

export default {
	routeProps: {
		path: '/display-settings',
		exact: true,
		component: DisplaySettingsPage,
	},
	name: 'DisplaySettingsPage',
};
