import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Containers } from './Containers';

const InputContainer = props => {
	const { serviceData } = props;
	const { serviceId } = serviceData;
	const Component = serviceId ? Containers[`${serviceId}`] : null;
	return Component ? <Component {...props} /> : null;
};

const mapStateToProps = state => ({
	serviceData: get(state, 'data.createNewDataSet.selectedServiceData'),
});

export default connect(mapStateToProps, null)(InputContainer);
