import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';
import { ai } from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	componentDidMount() {
		const { history, instrumentationKey, after } = this.props;
		const { initialized } = this.state;
		const AppInsightsInstrumentationKey = instrumentationKey; // PUT YOUR KEY HERE
		if (
			!initialized &&
			Boolean(AppInsightsInstrumentationKey) &&
			Boolean(history)
		) {
			ai.initialize(AppInsightsInstrumentationKey, history);
			this.setState({ initialized: true });
		}

		after();
	}

	render() {
		const { children } = this.props;
		return <>{children}</>;
	}
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
