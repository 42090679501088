import { get } from 'lodash';
import { Api } from 'api/Api';
import { createAction } from 'utils/helpers';
import { updateLocalStorage } from 'utils/CommonUtil';

// retrieve persona ID for logged in user
const retrievePassportProfile = user => dispatch => {
	const userId = get(user, 'profile.userId');
	Api.getPersonaId()
		.then(json => {
			const res = json.data;
			const profile = res[userId.toUpperCase()] || res[userId];
			dispatch(
				createAction('PASSPORT_PROFILE_SUCCESS', {
					personaId: profile.personaId,
				}),
			);
			return json.data;
		})
		.catch(error =>
			dispatch(createAction('PASSPORT_PROFILE_FAILURE', error)),
		);
};

export const userInContext = user => dispatch => {
	updateLocalStorage('authToken', get(user, 'access_token'));
	updateLocalStorage('refresh_token', get(user, 'refresh_token'));
	updateLocalStorage('id_token', get(user, 'id_token'));
	dispatch(createAction('USER_IN_CONTEXT', user));
	dispatch(retrievePassportProfile(user));
};
export const signOut = () => dispatch => {
	localStorage.removeItem('authToken');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('id_token');
	dispatch(createAction('SIGN_OUT_CLEAR_DATASET', {}));
	dispatch(createAction('SIGN_OUT', {}));
};

export const isSignedInWithValidToken = () => (dispatch, getState) => {
	const state = getState();
	const user = get(state, 'data.userInContext.user');
	dispatch(retrievePassportProfile(user));
};
