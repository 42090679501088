import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { get } from 'lodash';
import { initializeIcons, Icon } from 'office-ui-fabric-react';
import './DropDownAutoComplete.scss';
import { DefaultColors } from 'styles/colors';
import InformationIcon from '../informationIcon/InformationIcon';
import dropDownText from './messages/dropDownText';

initializeIcons();

const CaretDownIcon = () => {
	return <Icon iconName="ChevronDown" className="ms-Dropdown-caretDown" />;
};
const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<CaretDownIcon />
		</components.DropdownIndicator>
	);
};

class DropDownAutoComplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: props.field.value,
			showError: false,
		};
	}

	static getDerivedStateFromProps = props => {
		const value = get(props, 'field.value');
		return { selectedOption: value };
	};

	handleChange = (selectedOption, field, showError) => {
		let length;
		if (field.CTPLable === 'Account/Group' && selectedOption) {
			if (selectedOption.length > 30) {
				this.setState({ showError: true });
			}
			length = field.value && selectedOption.length < field.value.length;
		}
		const limitError =
			!showError && selectedOption && selectedOption.length < 31;
		const isAccountGroupDropDown =
			field.CTPLable !== 'Account/Group' || selectedOption === null;
		const isLimitError = limitError || length;
		if (isAccountGroupDropDown || isLimitError) {
			const { handleChange } = this.props;
			handleChange(selectedOption, field);
			this.setState({ selectedOption, showError: false });
		}
	};

	render() {
		const { selectedOption } = this.state;
		const { dropDownErrorMsg } = dropDownText;
		let { showError } = this.state;
		const {
			field,
			label,
			optionsData,
			isMultiSelect,
			placeholder,
			isCloseMenu,
		} = this.props;
		const selectedOptionValue = optionsData.filter(
			item => selectedOption && item.text === selectedOption.text,
		);
		const defaultValue = isMultiSelect
			? selectedOption
			: selectedOptionValue;
		const isCloseMenuAllow = isCloseMenu ? false : true;
		if (defaultValue && defaultValue.length === 0) {
			showError = false;
		}
		const colourStyles = {
			control: (styles, state) => ({
				...styles,
				backgroundColor: 'white',
				fontFamily: 'Segoe UI',
				fontSize: 12,
				fontWeight: 400,
				height: state.isMulti ? 'auto' : 28,
				minHeight: 28,
				cursor: 'pointer',
				borderColor:
					field.valid && !showError
						? '#444'
						: DefaultColors.ErrorColor,
				borderRadius: 2,
			}),
			menu: provided => ({
				...provided,
				marginTop: 2,
				overflow: 'auto',
			}),
			option: (styles, { isDisabled }) => {
				return {
					...styles,
					cursor: isDisabled ? 'not-allowed' : 'default',
					fontFamily: 'Segoe UI',
					fontSize: 12,
					fontWeight: 400,
					boxSizing: 'border-box',
					display: 'flex',
					textAlign: 'left',
					verticalAlign: 'top',
					paddingTop: 0,
					paddingRight: 8,
					paddingBottom: 0,
					paddingLeft: 8,
					height: 28,
					alignItems: 'center',
					minHeight: 28,
					overflowWrap: 'break-word',
					userSelect: 'none',
					outline: 'transparent',
					':active': {
						...styles[':active'],
					},
				};
			},
			container: base => ({
				...base,
				fontFamily: 'Segoe UI',
				fontSize: 12,
				fontWeight: 400,
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				color: '#444',
			}),
			valueContainer: base => ({
				...base,
				minHeight: 28,
				position: 'static',
				boxShadow: 'none',
				color: '#444',
				cursor: 'pointer',
				display: 'flex',
			}),
			placeholder: styles => ({ ...styles, color: '#444' }),
			singleValue: styles => ({ ...styles }),
			multiValue: styles => {
				return {
					...styles,
					backgroundColor: '#e2fce3',
				};
			},
			multiValueLabel: (styles, { data }) => ({
				...styles,
				color: data.color,
			}),
			multiValueRemove: (styles, { data }) => ({
				...styles,
				color: data.color,
				':hover': {
					backgroundColor: data.color,
					color: 'white',
				},
			}),
		};
		return (
			<div className="dropdown-autocomplete">
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<InformationIcon
							labelId={field.colId}
							label={label}
							tooltipMessage={field.toolTipMsg}
							mandatory={field.mandatory}
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<Select
							defaultValue={selectedOption}
							components={{ DropdownIndicator }}
							id={field.colId}
							placeholder={placeholder}
							value={defaultValue}
							onChange={event =>
								this.handleChange(event, field, showError)
							}
							options={optionsData}
							isMulti={isMultiSelect}
							theme={theme => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									...theme.palette,
									primary25: '#e2fce3',
									primary: '#2d8031',
								},
							})}
							styles={colourStyles}
							closeMenuOnSelect={isCloseMenuAllow}
						/>
						{showError ? (
							<p className="errorClass">{dropDownErrorMsg}</p>
						) : null}
						{!field.valid ? (
							<p className="errorClass">{field.errorMsg}</p>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}
export default DropDownAutoComplete;
