const DisplaySettingsConst = {
	detailList: [
		{
			id: 'dataset-title',
			label: 'Dataset Title',
			text: '',
		},
		{
			id: 'time-stamp',
			label: 'Time Stamp',
			text: 'Last Refreshed',
		},
		{
			id: 'id-stamp',
			label: 'ID Stamp',
			text: 'Refreshed by',
		},
	],
	DATE_FORMAT: {
		id: 'DateFormat',
		inputGroup: 'DateFormat',
		value: 'MM/dd/yyyy',
	},
	userId: 'RT139',
	pattern: 'yyyymmddhhmmss',
	PERFORMANCE_SERVICES: ['Market Value Summary'],
	NEW_GET_DATA_BEGIN: 'NEW_GET_DATA_BEGIN',
	FETCH_GET_DATA_BEGIN: 'FETCH_GET_DATA_BEGIN',
	FETCH_GET_DATA_SUCCESS: 'FETCH_GET_DATA_SUCCESS',
	FETCH_GET_DATA_FAILURE: 'FETCH_GET_DATA_FAILURE',
	STORE_DATASET_BEGIN: 'STORE_DATASET_BEGIN',
	STORE_DATASET_SUCCESS: 'STORE_DATASET_SUCCESS',
	STORE_DATASET_FAILURE: 'STORE_DATASET_FAILURE',
};

export default DisplaySettingsConst;
