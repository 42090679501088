import LogoutPage from './LogoutPage';

export default {
	routeProps: {
		path: '/logout',
		exact: true,
		component: LogoutPage,
	},
	name: 'LogoutPage',
};
