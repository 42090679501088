const CreateNewDataInitTexts = {
	settingFormTexts: {
		title: 'Configure Inputs',
		smartDatePlaceholder: 'Select Smart Date',
		datePickerPlaceholder: 'Select Date',
		sponsorcodetitle: 'Sponsor Code',
		sponsorcodeplaceholder: 'Select Sponsor code',
		dateRangeText: {
			startDate: 'Start Date',
			endDate: 'End date',
		},
	},
	Title: 'Create New Dataset',
	searchInputboxText: 'Search data services here...',
	pivotText: [
		{
			key: 'Product Groups',
			text: 'Product Groups',
			isEnable: true,
		},
		{
			key: 'Reporting Topics',
			text: 'Reporting Topics',
			isEnable: false,
		},
		{
			key: 'Browse Quick Start Templates',
			text: 'Browse Quick Start Templates',
			isEnable: false,
		},
		{
			key: 'My Saved Templates',
			text: 'My Saved Templates',
			isEnable: false,
		},
	],
	noContentText: 'There is no content',
	templateText: 'Templates Available',
	backWarningMessage: 'The inputs entered will be lost',
	categoryDefaultValue: 'Total Fund Gross of Fees',
	searchText: 'Search for',
	selectText: 'Select',
	category: 'dataset template',
	eventLabel: {
		search: 'Search',
		select: 'Directory',
	},
};

export default CreateNewDataInitTexts;
