/* Parse QueryString using String Splitting */
import { config } from './config';

export const parseQueryString = queryString => {
	const dictionary = {};
	let queryParam = queryString;
	// remove the '?' from the beginning of the
	// if it exists
	if (queryParam.indexOf('?') === 0) {
		queryParam = queryString.substr(1);
	}

	// Step 1: separate out each key/value pair
	const parts = queryParam.split('&');

	for (let i = 0; i < parts.length; i += 1) {
		const p = parts[i];
		// Step 2: Split Key/Value pair
		const keyValuePair = p.split('=');

		// Step 3: Add Key/Value pair to Dictionary object
		const key = keyValuePair[0];
		let value = keyValuePair[1];

		// decode URI encoded string
		value = decodeURIComponent(value);
		value = value.replace(/\+/g, ' ');

		dictionary[key] = value;
	}

	// Step 4: Return Dictionary Object
	return dictionary;
};

export const getParameterByName = (name, url = window.location.href) => {
	name = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const createAction = (type, payload) => {
	return { type, payload };
};

export const converToStoreDetails = store => {
	return {
		id: store.id,
		name: store.name,
		deliveryCharges: {
			charge: parseFloat(store.deliveryCharge),
			freeDeliveryText: 'Free delivery above',
			freeDeliveryAmount: parseFloat(store.freeDeliveryAmount),
		},
		minOrderAmount: parseFloat(store.minOrderAmount),
		link: config.basePath,
	};
};

export const trimWhiteSpace = str => {
	if (str) {
		return str.trim();
	}
	return '';
};
