import React, { useState } from 'react';
import { Text } from 'office-ui-fabric-react';
import Accordion from '../common/accordion/Accordion';
import DatasetGlobalSettings from './DatasetGlobalSettings';
import DatasetTile from './DatasetTile';
import './DatasetManagerBody.module.scss';

const DatasetManagerBody = props => {
	const [expanded, setExpanded] = useState(false);
	const {
		datasetTexts,
		globalSettingTexts,
		actionMenu,
		dataSetObject,
		asOfDate,
		onAsOfDateChange,
		onApplyAllClick,
		onDatasetSelect,
		isCalloutVisible,
		onClickInfoIcon,
		idSelectedTile,
		onClickEditOption,
		onAsOfDateCalChange,
		onClickDeleteOption,
		onClickRenameOption,
		renamedataset,
		onChangeTextField,
		renamedatasetValue,
		onSaveRenamedDataset,
		onDuplicateClicked,
		onGetDataIconClick,
		runAll,
		selectedKeys,
		isValidReportTitle,
		showErrorMsg,
	} = props;

	const onExpandClick = () => {
		setExpanded(!expanded);
	};
	const datasetBoxContent = () => {
		if (dataSetObject) {
			return (
				<DatasetTile
					data={dataSetObject}
					messages={datasetTexts}
					actionMenu={actionMenu}
					isCalloutVisible={isCalloutVisible}
					onClickInfoIcon={onClickInfoIcon}
					idSelectedTile={idSelectedTile}
					onClickEditOption={onClickEditOption}
					changeDate={onAsOfDateCalChange}
					onClickDeleteOption={onClickDeleteOption}
					onClickRenameOption={onClickRenameOption}
					renamedataset={renamedataset}
					onChangeTextField={onChangeTextField}
					renamedatasetValue={renamedatasetValue}
					onSaveRenamedDataset={onSaveRenamedDataset}
					onClickDuplicateOption={onDuplicateClicked}
					onGetDataIconClick={onGetDataIconClick}
					isValidReportTitle={isValidReportTitle}
				/>
			);
		}
		return null;
	};
	return (
		<div className="ms-Grid dataset-manager-container" dir="ltr">
			<div className="ms-Grid-row ">
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 dataset-heading">
					<Text variant="xLarge" id="description">
						{datasetTexts.heading}
					</Text>
				</div>
			</div>
			<Accordion
				expanded={expanded}
				title={datasetTexts.subHeading}
				onExpandClick={onExpandClick}
			>
				<DatasetGlobalSettings
					messages={globalSettingTexts}
					dataset={dataSetObject}
					asOfDate={asOfDate}
					changeDate={onAsOfDateChange}
					applyAll={onApplyAllClick}
					onDatasetChange={onDatasetSelect}
					selectedKeys={selectedKeys}
					showErrorMsg={showErrorMsg}
				/>
			</Accordion>
			<div className="ms-Grid-row ">
				<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 available-text">
					{datasetTexts.availableText}
				</div>
				<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
					{dataSetObject.length > 0 ? (
						<button
							type="button"
							id="runAll-dataset"
							className="runAll-btn"
							onClick={runAll}
						>
							{datasetTexts.runAll}
						</button>
					) : null}
				</div>
			</div>
			{datasetBoxContent()}
		</div>
	);
};

export default DatasetManagerBody;
