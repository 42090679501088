export const gaEventTags = {
	createNewDatasetButton: {
		eventAction: 'Create New Dataset',
		eventCategory: 'Create Dataset',
		eventLabel: 'Create New Dataset',
	},
};

export const gaPageTrackTags = {
	welcomePage: {
		pageCategory: 'Welcome',
	},
	createNewDatasetPage: {
		pageCategory: 'Create Dataset',
		pageType: 'Create New Data Set',
		pageSubtype: 'Templete selection',
		pageLabel: '',
	},
	inputPage: {
		pageCategory: 'Create Dataset',
		pageType: 'Create New Data Set',
		pageSubtype: 'Configure Inputs',
		pageLabel: '',
	},
	outputPage: {
		pageCategory: 'Create Dataset',
		pageType: 'Create New Data Set',
		pageSubtype: 'Configure Outputs',
		pageLabel: '',
	},
	displaySettingsPage: {
		pageCategory: 'Create Dataset',
		pageType: 'Create New Data Set',
		pageSubtype: 'Configure Data Set',
		pageLabel: '',
	},
	dataSetManagerPage: {
		pageCategory: 'Create Dataset',
		pageType: 'Data Set Manager',
		pageSubtype: 'Configure Data Set',
		pageLabel: '',
	},
};
