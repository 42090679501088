import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Footer, Header, ExcelCell, ThrobberMessage } from 'components';
import { useDispatch } from 'react-redux';
import { createAction } from 'utils/helpers';
import {
	PrimaryButton,
	DefaultButton,
} from 'office-ui-fabric-react';
import {
	currentTimeStamp,
	updateDatasetTimeStamp,
	generateUniqueIdData,
	setInputDataDisplay,
	setOutputDataDisplay,
	getServiceName,
	getDatasetTitle,
	uniqueIdDisplaySetting,
	generateDatasetTitle,
	updateRunAllStatus,
} from 'utils/GetDataUtil';
import { clearRenderedData } from 'utils/datasetUtil';
import {
	ExcelGetData,
	createHiddenSheet,
	getDatasetObject,
	onInputCellChange,
	clearExcelData,
	cellSheetPopulation,
	populateTitleToExcel,
	getDatasetTitleCellValue,
} from 'utils/excelUtils/ExcelDataPopulate';
import {
	TextField,
	Checkbox,
	Label,
	Spinner,
	SpinnerSize,
} from 'office-ui-fabric-react';
import {
	getLocalStorageItem,
	updateSessionStorage,
	getSessionStorageItem,
} from 'utils/CommonUtil';
import { find, get } from 'lodash';
import { validateReportTitle } from 'utils/validationRules';
import LoadingOverlay from 'react-loading-overlay';
import { displaySettingAction, onDataRendered } from './DisplaySettingsAction';
import DisplaySettingsTexts from './messages/DisplaySettingsTexts';
import DisplaySettingsConst from './DisplaySettingsConst';
import './DisplaySettings.scss';
import { useModal } from '../../hooks/useModal';

export const DisplaySettingsContainer = props => {
	const {
		dataSet,
		selectedServiceName,
		inputDataRequest,
		inputNodes,
		outputArrangeNodes,
		servicePath,
		getData,
		isLoading,
		isDataRendered,
		// storeDataset,
		selectedDataset,
		serviceData,
		performance,
		profile,
	} = props;
	const { detailList } = DisplaySettingsConst;
	const { RenderModal, hide, show  } = useModal();
	const [cellValue, setCellValue] = useState(
		getSessionStorageItem('getDataCellValue') || '',
	);
	const dispatch = useDispatch();

	const [sheetName, setSheetName] = useState(
		getSessionStorageItem('getDataSheetName') || '',
	);
	const [isReadOnlyText, setIsReadOnlyText] = useState((selectedDataset && selectedDataset.editClicked) ? true :false,	);
	const [displaySetting, setDisplaySetting] = useState([...detailList]);
	const [reportTitle, setReportTitle] = useState();
	const [isValidReportTitle, setIsValidReportTitle] = useState({
		valid: true,
		errorMessage: '',
	});
	const isCancelled = useRef(false);
	const [isValidExcelField, setIsValidExcelField] = useState({
		isValidCellId: true,
		isValidSheetName: true,
	});
	const [isFirstRowSelected, setIsFirstRowSelected] = useState(false);

	const setCheckboxValue = () => {
		return displaySetting.map(item => {
			item.selected = true;
			if (item.id === 'dataset-title') {
				item.value = reportTitle;
			}
			if (item.id === 'time-stamp') {
				item.value = currentTimeStamp();
			}
			if (item.id === 'id-stamp') {
				item.value = profile.personaId;
			}
			return item;
		});
	};

	const setCellSheet = value => {
		let flagEdit = true;
		if (selectedDataset && selectedDataset.editClicked == true) {
			flagEdit = false;
		}
		if (flagEdit) {
			if (!isCancelled.current) {
				setSheetName(value.sheetName);
				setCellValue(value.cellValue);
				setIsFirstRowSelected(false);
				setIsValidExcelField({
					isValidCellId: value.cellValue !== '',
					isValidSheetName: value.sheetName !== '',
				});
			}
		}
	};
	const datasetCellValue = () => {
		if (selectedDataset && !selectedDataset.duplicateClicked) {
			const obj = get(selectedDataset, 'displaySetting');
			const cellId = get(obj, 'cellId');
			const sheet = get(obj, 'sheetName');
			const settings = get(obj, 'displaySetting');
			setCellValue(cellId);
			setSheetName(sheet);
			setDisplaySetting(settings);
		}
	};
	useEffect(() => {
		const selectedServiceTitle = selectedServiceName.replace(
			/[&/\\#,+()$~%.'":*?<>{}]/g,
			' ',
		);
		const reports = get(dataSet, 'reports');
		const newTitle = getDatasetTitle(
			selectedDataset,
			selectedServiceTitle,
			reports,
		);
		const settings = setCheckboxValue();
		const inputCellValue = getSessionStorageItem('startCellValue');
		const defaultTitleCellValue = getDatasetTitleCellValue(inputCellValue);
		const defaultTitleSheetName = getSessionStorageItem('removeSheetName');
		const datasetDisplayDetail = get(
			selectedDataset,
			'displaySetting.displaySetting',
		);
		setReportTitle(newTitle);
		setCellValue(defaultTitleCellValue);
		setSheetName(defaultTitleSheetName);
		cellSheetPopulation(setCellSheet);
		if (selectedDataset) {
			setDisplaySetting(datasetDisplayDetail);
		} else {
			setDisplaySetting(settings);
		}
		datasetCellValue();
		return () => {
			isCancelled.current = true;
		};
	}, []);

	useEffect(() => {
		updateSessionStorage('getDataCellValue', cellValue || '');
		updateSessionStorage('getDataSheetName', sheetName || '');
	}, [cellValue, sheetName]);

	const datasetTimeStamp = () => {
		const datasetTimeStampObject = updateDatasetTimeStamp(displaySetting);
		setDisplaySetting(datasetTimeStampObject);
	};
	const displaySettingDataset = () => {
		let actualCellValue = cellValue;
		if(getSessionStorageItem('isDisplayDataCellChanged') === 'N' ){
			actualCellValue = getSessionStorageItem('getDataCellValue_old');
		}
		let actualSheetName = sheetName;
		if(getSessionStorageItem('displaySheet_Change') === 'Y' ){
			actualSheetName = getSessionStorageItem('getDataSheetName_Old');
		}
		return {
			cellId: actualCellValue,
			sheetName: actualSheetName,
			displaySetting,
		};
	};
	const onCancelClick = () => {
		const { history } = props;
		history.push(`/output-form`);
	};

	const onCellChange = event => {
		const cellChangeObj = onInputCellChange(event);
		const { isValidSheetName, isValidCellId } = cellChangeObj;
		const selectedCellValue = cellChangeObj.cellValue;
		const selectedSheetName = cellChangeObj.sheetName;
		setCellValue(selectedCellValue);
		setSheetName(selectedSheetName);
		setIsValidExcelField({
			isValidSheetName,
			isValidCellId,
		});
		updateSessionStorage('getDataCellValue', selectedCellValue);
		updateSessionStorage('getDataSheetName', selectedSheetName);
	};

	const checkIsValidReportTitle = () => {
		const existingDataSet = props.dataSet;
		const { validationMsg } = DisplaySettingsTexts;
		let reportTitleRegEx;
		if (!selectedDataset || selectedDataset.duplicateClicked) {
			reportTitleRegEx = validateReportTitle(
				reportTitle,
				validationMsg,
				existingDataSet,
			);
		} else {
			const selectedServiceId = get(selectedDataset, 'reportId');
			reportTitleRegEx = validateReportTitle(
				reportTitle,
				validationMsg,
				existingDataSet,
				selectedServiceId,
			);
		}

		setIsValidReportTitle({
			valid: reportTitleRegEx !== '',
			errorMessage: reportTitleRegEx,
		});
		return reportTitleRegEx !== '';
	};

	const datasetObject = () => {
		const { performanceGetData, serviceName } = performance;
		const inputData = getLocalStorageItem('inputData') || inputDataRequest;
		const outputData = getLocalStorageItem('outputData');
		const serviceDataObj = get(selectedDataset, 'serviceData');
		const selectedServiceId = get(selectedDataset, 'reportId');
		const duplicateClicked = get(selectedDataset, 'duplicateClicked');
		const service = getServiceName(
			serviceDataObj,
			performanceGetData,
			serviceName,
			selectedServiceName,
		);
		const inputOutputDataRequest = setInputDataDisplay(
			outputData,
			service,
			inputData,
			profile,
		);
		const outputDataList = setOutputDataDisplay(outputData);
		find(displaySetting, item => {
			if (item.id === 'dataset-title') {
				item.value = reportTitle;
			}
		});
		const datasetTitle = generateDatasetTitle(displaySetting, profile);
		const { uuId, uniqueId, fullDateFormat } = generateUniqueIdData(
			profile.personaId,
		);
		const displaySettingObject = displaySettingDataset();
		return {
			inputOutputDataRequest,
			uniqueId,
			fullDateFormat,
			uuId,
			selectedServiceName,
			displaySettingObject,
			outputDataList,
			datasetTitle,
			selectedServiceId,
			duplicateClicked,
		};
	};

	const isGetDataRendered = datasetRange => {
		const newDatasetObject = datasetObject();
		const existingDataSet = props.dataSet;
		const parameterObj = {
			...newDatasetObject,
			inputNodes,
			outputArrangeNodes,
			reportTitle,
			servicePath,
			serviceData,
			existingDataSet,
			datasetRange,
		};
		const dataset = getDatasetObject(parameterObj);
		// createHiddenSheet(parameterObj.uniqueId);
		updateRunAllStatus(dataset, isDataRendered);
		uniqueIdDisplaySetting();
	};
	const clearDatasetCellSheetValue = () => {
		if (selectedDataset && !selectedDataset.duplicateClicked) {
			const obj = get(selectedDataset, 'displaySetting');
			const sheet = get(obj, 'sheetName');
			const cell = get(obj, 'cellId');
			clearExcelData(cell, sheet);
		}
	};

	const renderStoreDataSet = (getDataCellValueOld, sheetNameOld) => {
		const {
			datasetTitle,
			inputOutputDataRequest,
			outputDataList,
		} = datasetObject();

		setCellValue(getDataCellValueOld || cellValue);
		setSheetName(sheetNameOld || sheetName);
		populateTitleToExcel(datasetTitle, getDataCellValueOld || cellValue, sheetNameOld || sheetName);
		getData(inputOutputDataRequest, servicePath)
			.then(response => {
				ExcelGetData(response, outputDataList, isGetDataRendered);
			})
			.catch(error => {
				// isGetDataRendered();
				return error;
			});
	};
	const submitForm = () => {
		//XDE-5631
		const sheetNameOld = getSessionStorageItem('getDataSheetName_Old');
		const sheetNameCurrent = getSessionStorageItem('getDataSheetName');
		const getDataCellValueCurrent = getSessionStorageItem('getDataCellValue');
		const getDataCellValueOld = getSessionStorageItem('getDataCellValue_old');

		if (selectedDataset && selectedDataset.editClicked) {
			if (getDataCellValueOld !== getDataCellValueCurrent && sheetNameOld !== sheetNameCurrent) {
				show();
			} else if (getDataCellValueOld !== getDataCellValueCurrent && sheetNameOld === sheetNameCurrent) {
				show();
			} else if (getDataCellValueOld === getDataCellValueCurrent && sheetNameOld !== sheetNameCurrent) {
				show();
			} else {
				hide();
				const isReportTitleValid = checkIsValidReportTitle();
				if (isReportTitleValid) {
					return;
				}
				clearRenderedData(selectedDataset);
				datasetTimeStamp();
				clearDatasetCellSheetValue();
				renderStoreDataSet();
			}
		} else {
		hide();
		const isReportTitleValid = checkIsValidReportTitle();
		if (isReportTitleValid) {
			return;
		}
		clearRenderedData(selectedDataset);
		datasetTimeStamp();
		clearDatasetCellSheetValue();
		renderStoreDataSet();
		}
	};
	// XDE-5631
	const onYesChangeCell = () => {
		hide();
		updateSessionStorage('isDisplayDataCellChanged', 'Y');
		updateSessionStorage('displaySheet_Change', 'N');
		const isReportTitleValid = checkIsValidReportTitle();
		if (isReportTitleValid) {
			return;
		}
		clearRenderedData(selectedDataset);
		datasetTimeStamp();
		clearDatasetCellSheetValue();
		renderStoreDataSet();
	};
	const onNoCellChange = () => {
		hide();
		updateSessionStorage('isDisplayDataCellChanged', 'N');
		updateSessionStorage('displaySheet_Change', 'Y');
		
		const sheetNameOld = getSessionStorageItem('getDataSheetName_Old')
		const getDataCellOldValue = getSessionStorageItem('getDataCellValue_old');
		
		selectedDataset.displaySetting.cellId = getDataCellOldValue;
		selectedDataset.displaySetting.sheetName = sheetNameOld;
		
		setCellValue(getDataCellOldValue);
		setSheetName(sheetNameOld);

		updateSessionStorage('getDataCellValue', getDataCellOldValue);
		updateSessionStorage('getDataSheetName', sheetNameOld);
		
		const isReportTitleValid = checkIsValidReportTitle();
		if (isReportTitleValid) {
			return;
		}
		clearRenderedData(selectedDataset);
		datasetTimeStamp();
		clearDatasetCellSheetValue();
		renderStoreDataSet(getDataCellOldValue, sheetNameOld);
	};

	const onTitleChange = event => {
		const settings = displaySetting.map(item => {
			if (item.id === 'dataset-title') {
				item.value = event.target.value;
			}
			return item;
		});
		setDisplaySetting(settings);
		setIsValidReportTitle({
			valid: true,
			errorMessage: '',
		});
		setReportTitle(event.target.value);
	};

	const onSelectChange = (event, selected) => {
		const { id } = event.target;
		const settings = displaySetting.map(item => {
			if (item.id === id) {
				if (id === 'time-stamp') {
					item.value = currentTimeStamp();
				}
				item.selected = selected;
			}
			return item;
		});
		setDisplaySetting(settings);
	};

	const getCheckboxField = item => {
		return (
			<div className="ms-Grid-row" key={item.id}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" dir="ltr">
					<Checkbox
						id={item.id}
						label={item.label}
						onChange={onSelectChange}
						checked={item.selected}
					/>
				</div>
			</div>
		);
	};

	const getDisplaySettings = () => {
		return (
			<>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<Label className="show-details" id="show-details">
							{DisplaySettingsTexts.showDetailsLabel}
						</Label>
					</div>
				</div>
				<div className="show-details-list">
					{displaySetting.map(item => {
						return getCheckboxField(item);
					})}
				</div>
			</>
		);
	};

	return (
		<LoadingOverlay
			active={isLoading}
			spinner={<Spinner size={SpinnerSize.large} />}
			text={<ThrobberMessage />}
		>
			<div className="ms-Grid display-settings-container" dir="ltr">
				<Header title={DisplaySettingsTexts.title} />
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<TextField
							className="report-header"
							label={DisplaySettingsTexts.reportTitleLabel}
							id="report-title-lbl"
							name="report-title"
							value={reportTitle}
							onChange={onTitleChange}
							required
							errorMessage={isValidReportTitle.errorMessage}
							// tooltipMessage={DisplaySettingsTexts.cellIdToolTipMsg}
							// onRenderLabel={onRenderLabel}
						/>
					</div>
				</div>
				{getDisplaySettings()}
				<ExcelCell
					messages={DisplaySettingsTexts}
					isValidExcelField={isValidExcelField}
					handleChange={onCellChange}
					isFirstRowSelected={isFirstRowSelected}
					isReadOnlyText={isReadOnlyText}
					cellValue={cellValue}
					sheetName={sheetName}
					isUsedCellAddress
				/>
				<RenderModal title="Edit Display Settings Screen"  showButtonModal={true} >
				<p>
					{'You have changed the cell coordinate for populating the timestamp for last refresh. Click "Yes" to change the cell coordinate as per the new selection.  Click "No" to retain the old cell coordinates.'}
				</p>
				<DefaultButton
					id="no-btn"
					text={'No'}
					onClick={onNoCellChange}
					style={{ margin: '6px', float: 'left' }}
				/>
				<PrimaryButton
					id="yes-btn"
					text={'Yes'}
					onClick={onYesChangeCell}
					style={{ margin: '6px', float: 'left' }}
				/>
			</RenderModal>
				<div className="ms-Grid-row">
					<Footer
						showBackButton
						backButtonText="Back"
						backButtonId="settingform-backbutton"
						onClickedBackButton={onCancelClick}
						onClickedNextButton={submitForm}
						showNextButton
						nextButtonId="get-data-button"
						nextButtonText="Get Data"
						serviceName={selectedServiceName}
						stepPage="3"
					/>
				</div>
			</div>
		</LoadingOverlay>
	);
};

const mapStateToProps = state => ({
	selectedServiceName: get(state, 'data.createNewDataSet.selectedService'),
	inputNodes: get(state, 'data.createNewDataSet.inputNodes'),
	inputDataRequest: get(state, 'data.createNewDataSet.inputDataRequest'),
	outputArrangeNodes: get(state, 'data.createNewDataSet.outputArrangeNodes'),
	servicePath: get(state, 'data.createNewDataSet.servicePath'),
	isLoading: get(state, 'data.getData.loading'),
	selectedDataset: get(state, 'data.createNewDataSet.selectedDataset'),
	serviceData: get(state, 'data.createNewDataSet.selectedServiceData'),
	performance: get(state, 'data.performance'),
	dataSet: get(state, 'data.dataSet.data'),
	profile: get(state, 'data.userInContext.user.profile'),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			// createNewDatasetValue,
			// redirectTo,
			getData: displaySettingAction,
			isDataRendered: onDataRendered,
		},
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DisplaySettingsContainer);
